import React, { FC } from 'react';
import { FormFeedback, FormGroup, Label } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';

import { RcFormlyWrapper } from '@rc-formly/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import { createStyles, makeStyles } from '@material-ui/core/styles';

interface HelperTextProps {
    helperTextTranslate?: string;
}

const useStyles = makeStyles(() =>
    createStyles({
        tooltip: {
            fontSize: '14px'
        }
    })
);

const HelperText: FC<HelperTextProps> = (props) => {
    const { helperTextTranslate } = props;

    const { formatMessage } = useIntl();
    const classes = useStyles();

    if (helperTextTranslate) {
        const helperText = formatMessage({ id: helperTextTranslate });

        return (
            <Box display="inline-block" ml={0.5}>
                <Tooltip classes={{ tooltip: classes.tooltip}} placement="right" title={helperText}>
                    <HelpOutlineIcon fontSize="small" />
                </Tooltip>
            </Box>

        );
    }

    return null;
}

export class FormGroupWrapper extends RcFormlyWrapper {
    render() {
        const { helperTextTranslate, hideLabel, label, labelTranslate, required } = this.to || {};
        const { fieldGroupClassName } = this.props.parentField as any;

        const error = this.getFieldError();
        const hasError = error && (this.wasFieldTouched() || this.wasFormSubmitted());

        return (
            <FormGroup className={fieldGroupClassName}>
                <div>
                    {
                        label && !hideLabel && <Label>
                            { label}
                        </Label>
                    }
                    {
                        labelTranslate && !hideLabel && <Label>
                            <FormattedMessage id={labelTranslate}></FormattedMessage>
                            <HelperText helperTextTranslate={helperTextTranslate} />
                        </Label>
                    }
                    {
                        required && !hideLabel && <span className="text-danger"> *</span>
                    }
                </div>
                { this.props.children }
                {
                    hasError && <FormFeedback valid={false} style={{ display: 'block' }}>
                        { error }
                    </FormFeedback>
                }
            </FormGroup>
        )
    }
}
