export class Environment {
    public static get apiRoot() {
        return process.env.REACT_APP_API_ROOT || '';
    }

    public static get bugsnagKey() {
        return process.env.REACT_APP_BUGSNAG_KEY || 'empty_key';
    }

    public static get clientId() {
        return process.env.REACT_APP_CLIENT_ID || '';
    }

    public static get driftAppId() {
        return process.env.REACT_APP_DRIFT_APP_ID || '';
    }

    public static get domainHost() {
        return process.env.REACT_APP_DOMAIN_HOST || '';
    }

    public static get googleProjectId() {
        return process.env.REACT_APP_GOOGLE_PROJECT_ID || '';
    }

    public static get storageContainerName() {
        return process.env.REACT_APP_STORAGE_CONTAINER_NAME || '';
    }

    public static isDevelopment() {
        return process.env.NODE_ENV === 'development';
    }

    public static isStaging() {
        return this.isProduction();
    }

    public static isProduction() {
        return process.env.NODE_ENV === 'production';
    }
}
