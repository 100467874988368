import { DocumentNode } from "graphql";
import { types } from "typed-graphqlify";

import { LamaCrudOperationType, LamaGraphQlValueWithType } from "./client";
import { KeyValueObject } from "shared/types";

import { LamaGraphQlValueWithTypeCreator } from "shared/graphql/utilities";

export enum GraphQlValueType {
    id = 'ID!',
    boolean = 'Boolean',
    integer = 'Int',
    guid = 'Uuid',
    paginationAmount = 'PaginationAmount',
    sortOperationKind = 'SortOperationKind',
    string = 'String',
}

export const graphQlQueryVariablesObject: GraphQlQueryVariables = {
    after: types.string,
    before: types.string,
    first: LamaGraphQlValueWithTypeCreator.createWithPaginationAmountType(1),
    last: LamaGraphQlValueWithTypeCreator.createWithPaginationAmountType(1),
    order_by: {},
    where: {}
};

export const graphQlQueryVariableKeys = Object.keys(graphQlQueryVariablesObject);

export enum GraphQlQueryEqualityOperator {
    contains = 'contains',
    equals = 'equals',
    lower = 'lower',
    lowerOrEquals = 'lowerOrEquals',
    higher = 'higher',
    higherOrEquals = 'higherOrEquals'
}

export interface GraphQlQueryObjectParameter {
    operator?: GraphQlQueryEqualityOperator;
    value: any;
}

export interface GraphQlQueryVariables<TEntity = KeyValueObject> extends KeyValueObject {
    after?: string;
    before?: string;
    first?: LamaGraphQlValueWithType<number, GraphQlValueType.paginationAmount>;
    last?: LamaGraphQlValueWithType<number, GraphQlValueType.paginationAmount>;
    order_by?: KeyValueObject;
    where?: Partial<TEntity>;
}

export enum GraphQlQueryType {
    customList = 'customList',
    list = 'list',
    paginated = 'paginated',
    single = 'single',
    singleMe = 'singleMe'
}

export interface QueryFromDotNotationResult {
    gqlQuery: DocumentNode;
    variables?: KeyValueObject;
}

export interface QueryFromDotNotationOptions<TQueryObject = KeyValueObject, TVariables = GraphQlQueryVariables<TQueryObject>> {
    modelName: string;
    dotNotationProperties?: string[];
    queryObject?: TQueryObject;
    queryPrefixDotNotation?: string;
    queryType?: GraphQlQueryType;
    variables?: TVariables;
}

export interface GetMutationDataOptions {
    crudOperationType: LamaCrudOperationType;
    data: any;
    modelName: string;
    isBulk?: boolean;
}

export interface GetQueryDataOptions {
    data: any;
    mappedDotNotationProperties?: KeyValueObject;
    dotNotationProperties?: string[];
    modelName: string;
    queryPrefixDotNotation?: string;
    queryType?: GraphQlQueryType;
}

export interface MutationGenerateOptions<TVariables = any> extends MutationGenerateSimpleOptions<TVariables> {
    isBulk?: boolean;
    crudOperationType: LamaCrudOperationType;
}

export interface MutationGenerateNameOptions  {
    crudOperationType: LamaCrudOperationType;
    isBulk?: boolean;
    modelName: string;
}

export interface MutationGenerateSimpleOptions<TVariables = any> {
    modelName: string;
    isJoinedModel?: boolean;
    variables: TVariables;
}

export interface MutationGenerationResult {
    gqlQuery: DocumentNode;
    variables: any;
}
