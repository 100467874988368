import { useEffect } from "react";

import { singleMeTenantQueryOptions } from "features/companies/queries";

import { useCurrentTenantInterface } from "./types";

import { useLamaTenant } from "shared/authentication";
import { useLamaQuery } from "shared/graphql";

import { UriHelper } from "shared/utilities";
import { Environment } from "configs";

export const useCurrentTenant: useCurrentTenantInterface = () => {
    const { data, loading, refetch } = useLamaQuery(singleMeTenantQueryOptions);
    const { currentActiveTenantId } = useLamaTenant();

    useEffect(() => {
        refetch();
    }, [currentActiveTenantId]);

    let companyDomainHostUrl = '';

    if (data) {
        companyDomainHostUrl = UriHelper.combineUrl(Environment.domainHost, `cp/${UriHelper.safeUrl(data.companyOfTenant.title)}/${data.companyOfTenant.id}`);
    }

    return {
        loading,
        companyDomainHostUrl,
        currentTenant: data
    };
};
