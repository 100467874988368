import { ImmerReducer, createActionCreators, createReducerFunction } from 'immer-reducer';

import { IAuthenticationState } from './state';
import { TokenResponseViewModel, JwtDecryptedToken, LamaAuthorizationType } from 'shared/authentication/models';
import { UtilityHelper } from 'shared/utilities';

const initialState: IAuthenticationState = {

};

class AuthenticationReducer extends ImmerReducer<IAuthenticationState> {
    changeCurrentActiveTenantId(newTenantId: string) {
        this.draftState.currentActiveTenantId = newTenantId;
    }

    loggedIn(tokenResponse: TokenResponseViewModel, decodedJwtToken: JwtDecryptedToken) {
        this.draftState.tokenResponse = tokenResponse;

        const permissions = UtilityHelper.getAsArray<string>(decodedJwtToken.permission);
        const authorizationTypeKeys = Object.keys(LamaAuthorizationType);

        this.draftState.account = {
            accountId: decodedJwtToken.nameid,
            fullName: decodedJwtToken.given_name,
            username: decodedJwtToken.unique_name,
            claims: permissions.reduce((claims, claim) => {
                const splittedClaim = claim.split(':');

                if (splittedClaim.length === 2) {
                    const crudTypeValues = splittedClaim[1].split(',');

                    if (UtilityHelper.isNotEmpty(crudTypeValues)) {
                        crudTypeValues.forEach((crudValueType) => {
                            const foundAuthorizationType = authorizationTypeKeys.find(q => q.startsWith(crudValueType));

                            if (foundAuthorizationType) {
                                claims.push(`${splittedClaim[0]}.${foundAuthorizationType}`);
                            }
                        })
                    }
                }

                return claims;
            }, [] as string[]),
            roles: UtilityHelper.getAsArray(decodedJwtToken.role),
            tenantId: ''
        };
    }

    logout() {
        this.draftState = initialState;
    }
}

export const authenticationReducerActionCreators = createActionCreators(AuthenticationReducer);
export const authenticationReducerFunction = createReducerFunction(AuthenticationReducer, initialState);
