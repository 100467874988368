import React, { FC, useContext } from 'react';
import { Redirect, Route, useLocation, RouteProps, RouteComponentProps } from 'react-router-dom';

import { RctThemeProvider } from 'containers/RctThemeProvider';
import { DefaultLayout } from 'containers/DefaultLayout';

import { Login, ForgottenPassword, ResetPassword, Register, ResendActivationEmail } from 'features/authentication';
import { PublicHome, TermsOfUse } from 'features/home';
import { useAuth } from 'shared/authentication';
import { ModularContext } from 'shared/packages/react-modular';
import { UriHelper } from 'shared/utilities';

interface PrivateRouteProps extends RouteProps {
    isLoggedIn: boolean;
}

const PrivateRoute: FC<PrivateRouteProps> = ({ component: Component, isLoggedIn, ...rest }) => {
    if (!Component) {
        return null;
    }

    return (
        <Route
            {...rest}
            render={(props: RouteComponentProps<{}>) =>
                isLoggedIn ? <Component {...props} /> : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
            }
        />
    );
};

const App: FC<RouteComponentProps> = (props) => {
    const { match } = props;
    const { pathPrefix } = useContext(ModularContext);
    const { isAuthenticated } = useAuth();

    const dashboardPath = UriHelper.combineUrl(match.url, pathPrefix as string);

    return (
        <RctThemeProvider>
            <PrivateRoute path={dashboardPath} isLoggedIn={isAuthenticated()} component={DefaultLayout} />
            <Route exact={true} path="/" component={PublicHome} />
            <Route path="/login" component={Login} />
            <Route path="/forgotten-password" component={ForgottenPassword} />
            <Route path="/reset-password" component={ResetPassword} />
            <Route path="/resend-activation" component={ResendActivationEmail} />
            <Route path="/register" component={Register} />
            <Route path="/terms-of-use" component={TermsOfUse} />
        </RctThemeProvider>
    );
};

export default App;
