import React from 'react';
import { RcFormlyField } from '@rc-formly/core';

import { LamaMoneyInput } from 'shared/components/forms/components';
import { UtilityHelper } from 'shared/utilities';

export class LamaMoneyInputType extends RcFormlyField {
    get type() {
        return 'text';
    }

    onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value as any;

        this.handleChange(value);
    };

    render() {
        const { disabled, onInputSubmit, placeholder = '' } = this.to || {};

        const isInvalid = this.hasErrors() && (this.wasFieldTouched() || this.props.formlyProps.formProps.submitCount > 0);

        return (
            <LamaMoneyInput
                autoComplete="off"
                disabled={disabled}
                invalid={isInvalid}
                name={this.getFieldKey()}
                onBlur={this.handleBlur}
                onChange={this.onChange}
                onInputSubmit={onInputSubmit}
                placeholder={placeholder}
                value={this.getFieldValue()}
            />
        );
    }
}
