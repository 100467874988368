import React, { createContext, FC, useState } from 'react';
import { ImporterContextState } from './types';
import { ImportCheckResultViewModel, ImportViewModel } from 'features/imports/models';

export const ImporterContext = createContext<ImporterContextState>({
    setLastImport: () => { },
    setLastImportCheckResult: () => {}
});

export const ImporterProvider: FC = (props) => {
    const { children } = props;

    const [lastImport, setLastImport] = useState<ImportViewModel>();
    const [lastImportCheckResult, setLastImportCheckResultInState] = useState<ImportCheckResultViewModel>();

    const setLastImportCheckResult = (importCheckResult: ImportCheckResultViewModel) => {
        importCheckResult.notMappedCategories = importCheckResult.notMappedCategories || [];
        importCheckResult.notMappedFields = importCheckResult.notMappedFields || [];

        setLastImportCheckResultInState(importCheckResult);
    }

    const contextValue: ImporterContextState = {
        lastImport,
        lastImportCheckResult,
        setLastImport,
        setLastImportCheckResult
    };

    return <ImporterContext.Provider value={contextValue}>{children}</ImporterContext.Provider>;
};
