import React from 'react';
import { RcFormlyField } from '@rc-formly/core';

import { LamaEditor } from 'shared/components/forms/components/LamaEditor';

export class LamaEditorType extends RcFormlyField {
    onChange = (html: string) => {
        this.handleChange(html);
    };

    onBlur = (html: string) => {
        this.handleChange(html);
        this.handleBlur();
    };

    render() {
        const isInvalid = this.hasErrors() && (this.wasFieldTouched() || this.props.formlyProps.formProps.submitCount > 0);
        const placholder = this.to && this.to.placeholder ? this.to.placeholder : '';
        const value = this.getFieldValue() || '';

        return (
            <LamaEditor
                name={this.getFieldKey()}
                isInvalid={isInvalid}
                onBlur={this.onBlur}
                placeholder={placholder}
                value={value}
            />
        );
    }
}
