export enum LamaCrudViewType {
    createOrEdit = 'createOrEdit',
    create = 'create',
    delete = 'delete',
    list = 'list',
    manage = 'manage',
    me = 'me',
    update = 'edit'
}

export const crudViewTypeGroups = new Map<LamaCrudViewType, LamaCrudViewType[]>([
    [LamaCrudViewType.manage, [LamaCrudViewType.create, LamaCrudViewType.delete, LamaCrudViewType.update]]
]);
