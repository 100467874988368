import React, { FC } from "react";
import Box from "@material-ui/core/Box";
import { FormattedMessage } from "react-intl";

export const EmptyStatistcs: FC = () => {
    return (
        <Box display="flex" height="6rem" justifyContent="center" alignItems="center">
            <Box>
                <FormattedMessage id="statistic.notEnoughData" />
            </Box>
        </Box>
    )
}
