import React from 'react';
import { FormattedMessage } from "react-intl";
import { RcFormlyArrayField, IFormlyFieldConfig } from "@rc-formly/core";

import Button from "@material-ui/core/Button";
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';

import { KeyValueObject } from 'shared/types';

import { UtilityHelper } from 'shared/utilities';
import Box from '@material-ui/core/Box';

export class LamaMultipleAddSmallType extends RcFormlyArrayField {
    addNewValue = () => {
        const propertiesToAdd = this.props.field.fieldArray?.fieldGroup?.map(q => q.key) as string[];

        if (UtilityHelper.isNotEmpty(propertiesToAdd)) {
            let newValue: KeyValueObject = {};

            propertiesToAdd!.forEach((property) => {
                const fieldValue = this.getFieldValue(property);
                const lastPropertyName = UtilityHelper.getDotNotationPropertyLast(property);

                if (lastPropertyName) {
                    newValue[lastPropertyName] = fieldValue;
                }

                this.props.formlyProps.setFieldValue(property, null);
            });

            this.pushValue(newValue);
        }
    }

    renderListItem = (item: any, index: number) => {
        let fieldGroup = this.props.field.fieldArray?.fieldGroup?.map(q => {
            const keyAfterArrayKey = UtilityHelper.getDotNotationPropertyLast(q.key!);

            return {
                ...q,
                key: `${this.props.field.key}.${index}.${keyAfterArrayKey}`
            } as IFormlyFieldConfig;
        });

        return (
            <Box key={index} display="flex" flexDirection="row" alignItems="center">
                <Box flexGrow="1">
                    { this.renderFieldGroup(fieldGroup) }
                </Box>
                <Box ml={2}>
                    <IconButton edge="end" aria-label="delete" onClick={() => this.removeValue(index)}>
                      <DeleteIcon />
                    </IconButton>
                </Box>
            </Box>
        );
    }

    renderItems() {
        const values = this.getFieldValue() as any[];

        if (UtilityHelper.isEmpty(values)) {
            return null;
        }

        return (
            <List>
                { values.map(this.renderListItem) }
            </List>
        )
    }

    render() {
        return (
            <div className="multiple-add-small-wrapper">
                <Box display="flex" flexDirection="row">
                    <Box className="field-groups" flexGrow="1">
                        { this.renderFieldGroup() }
                    </Box>
                    <Box ml={2}>
                        <Button variant="contained" color="primary" onClick={this.addNewValue}>
                            <FormattedMessage id="shared.add" />
                        </Button>
                    </Box>
                </Box>
                { this.renderItems() }
            </div>
        );
    }
}
