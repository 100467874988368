import { TemplateOptionsExtended } from "shared/components/forms/models";

interface PackageSelectorTypeProps {
    systemNamePrefix?: string;
}

export interface PackageSelectorTypeTemplateOptions extends TemplateOptionsExtended {
    packageSelectorProps?: PackageSelectorTypeProps
}

export enum PackFormlyTypes {
    packageSelector = 'lm-package-selector'
}
