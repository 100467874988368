import React, { FC, useContext } from 'react';
import Snackbar from '@material-ui/core/Snackbar';

import { LamaAlertMessage, LamaAlertsContext } from 'shared/packages/alerts/hooks';
import { LamaAlert } from './LamaAlert';

export const AlertsContainer: FC = () => {
    const { alerts, removeAlert } = useContext(LamaAlertsContext);

    const onAlertClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, alertMessage: LamaAlertMessage) => {
        handleClose(alertMessage);
    }

    const handleClose = (alert: LamaAlertMessage) => {
        removeAlert(alert.id!);
    };

    return (
        <>
            {alerts.map(q => (
                <Snackbar
                    key={q.id}
                    anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                    open={true}
                    autoHideDuration={q.autoHideDuration}
                    onClose={() => handleClose(q)}
                >
                    <LamaAlert onClick={onAlertClick} alertMessage={q} />
                </Snackbar>
            ))}
        </>
    );
};
