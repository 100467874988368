import React, { FC, useState } from 'react';

import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import DeleteIcon from '@material-ui/icons/Delete';
import Fab from '@material-ui/core/Fab';
import GridList from '@material-ui/core/GridList';
import { createStyles, makeStyles, } from '@material-ui/core/styles';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';

import { Media, MediaSizeType, MediaResourceNames } from 'features/media/models';
import { MediaImage } from 'features/media/components/MediaImage';
import { LamaSelectionItem } from 'shared/components/selection';

import { useRapidModel } from 'shared/packages/rapid-model';
import { useWidth } from 'shared/packages/use-width';
import { useLamaAlerts } from 'shared/packages/alerts';
import { useLamaClient } from 'shared/graphql';

import { DeletConfirmationDialog, LamaLoader } from 'shared/components';

const getNumberOfGridCols = (breakpoint: Breakpoint) => {
    let cols = 6;

    switch (breakpoint) {
        case 'xl':
            cols = 6;

            break;

        case 'lg':
            cols = 5;

            break;

        case "md":
            cols = 4;

            break;

        case 'md':
            cols = 3;

            break;

        case "sm":
            cols = 2;

            break;

        case 'xs':
            cols = 1;

            break;

        default:
            cols = 1;

            break;
    }

    return cols;
}

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            position: 'relative'
        },
        tile: {
            position: 'static'
        },
        deleteButton: {
            borderRadius: '50%',
            right: 0,
            position: 'absolute',
            top: 0,
            zIndex: 2
        }
    })
);

interface MediaFileSelectorItemProps {
    canDeleteMedia?: boolean;
    data: Media[];
    reloadData?: () => void;
}

export const MediaFileSelectorGrid: FC<MediaFileSelectorItemProps> = (props) => {
    const { canDeleteMedia = false, data, reloadData } = props;

    const lamaClient = useLamaClient();
    const { addErrorAlert, addSuccessAlert } = useLamaAlerts();
    const { getModelNameTranslation } = useRapidModel({
        defaultModelName: MediaResourceNames.media
    });
    const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [isLoaderShown, setLoaderShown] = useState(false);
    const [selectedItem, setSelectedItem] = useState<Media | null>(null);

    const classes = useStyles();
    const currentBreakpoint = useWidth();
    const cols = getNumberOfGridCols(currentBreakpoint);

    const onMediaDeleteClick = (media: Media) => () => {
        setSelectedItem(media);
        setDeleteDialogOpen(true);
    };

    const onDeleteConfirmationClose = () => {
        setSelectedItem(null);
        setDeleteDialogOpen(false);
    };

    const onDeleteConfirmed  = async() => {
        setDeleteDialogOpen(false);
        setLoaderShown(true);

        const entityName = getModelNameTranslation();

        try {
            const mutationResult = await lamaClient.deleteMutateModel({
                modelName: MediaResourceNames.media,
                modelId: selectedItem!.id!
            });

            if (mutationResult.data) {
                addSuccessAlert('shared.alerts.delete.success', {
                    entityName,
                    entityTitle: selectedItem!.title
                });
            }

            if (reloadData) {
                reloadData();
            }
        } catch (e) {
            addErrorAlert('shared.alerts.delete.error', {
                entityName,
                entityTitle: selectedItem!.title
            });
        } finally {
            setLoaderShown(false);
        }
    };

    return (
        <>
            <LamaLoader showLoader={isLoaderShown} />
            <GridList cellHeight="auto" spacing={10} cols={cols}>
                {data?.map((media) => (
                    <GridListTile classes={{ root: classes.root, tile: classes.tile }} key={media.id}>
                        {canDeleteMedia && (
                            <Fab size="small" color="secondary" aria-label="delete" className={classes.deleteButton} onClick={onMediaDeleteClick(media)}>
                                <DeleteIcon />
                            </Fab>
                        )}
                        <LamaSelectionItem item={media}>
                            <MediaImage media={media} mediaSizeType={MediaSizeType.thumbnail} />
                            <GridListTileBar
                                title={media.title}
                                subtitle={media.summary}
                                /* actionIcon={
                        <IconButton aria-label={`info about ${media.title}`} className={classes.icon}>
                            <InfoIcon />
                        </IconButton>
                    } */
                            />
                        </LamaSelectionItem>
                    </GridListTile>
                ))}
            </GridList>
            <DeletConfirmationDialog
                isOpen={isDeleteDialogOpen}
                onClose={onDeleteConfirmationClose}
                onConfirm={onDeleteConfirmed}
                entityTitle={selectedItem?.title}
            />
        </>
    );
};
