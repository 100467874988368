import {
    LamaFormlyFieldConfig,
    LamaFormlyWrappers,
    LamaFormlyTypes,
    LamaCollapsibleCardWrapperTemplateOptions,
    LamaEnumRadioGroupTypeTemplateOptions
} from 'shared/components/forms/models';
import { VenueStatus, VenueResourceNames, Venue } from 'features/venues/models';

import { MediaFormlyTypes, MediaEntityChooserTypeTemplateOptions } from 'features/media/models';
import { LocationFormlyTypes } from 'features/locations/models';
import { useLamaQuery } from 'shared/graphql';
import { types } from 'typed-graphqlify';

interface useVenueFormFieldsInterface {
    (): {
        loading: boolean;
        fields: LamaFormlyFieldConfig[];
        venues: Venue[];
    };
}

export const useVenueFormFields: useVenueFormFieldsInterface = () => {
    const { loading, data: venues = [] } = useLamaQuery<Venue[]>({
        modelName: VenueResourceNames.venue,
        queryObject: {
            isMainWarehouse: types.boolean
        } as Venue
    });

    return {
        loading,
        venues,
        fields: [
            {
                wrappers: [LamaFormlyWrappers.row, LamaFormlyWrappers.submitButton],
                fieldGroup: [
                    {
                        fieldGroupClassName: 'col-sm-6',
                        wrappers: [LamaFormlyWrappers.div],
                        fieldGroup: [
                            {
                                wrappers: [LamaFormlyWrappers.card],
                                templateOptions: {
                                    cardProps: {
                                        headingTranslate: 'venue.basic.header'
                                    }
                                } as LamaCollapsibleCardWrapperTemplateOptions,
                                fieldGroup: [
                                    {
                                        key: 'status',
                                        type: LamaFormlyTypes.enumRadioGroup,
                                        wrappers: [LamaFormlyWrappers.formGroup],
                                        templateOptions: {
                                            labelTranslate: 'venue.status',
                                            enumRadioGroupProps: {
                                                enumType: VenueStatus,
                                                translatePrefix: 'venue.venueStatus'
                                            },
                                            required: true
                                        } as LamaEnumRadioGroupTypeTemplateOptions
                                    },
                                    {
                                        key: 'isMainWarehouse',
                                        type: LamaFormlyTypes.checkbox,
                                        wrappers: [LamaFormlyWrappers.formGroup],
                                        templateOptions: {
                                            disabled: venues.length < 1,
                                            labelTranslate: 'venue.isMainWarehouse',
                                            hideLabel: true
                                        }
                                    },
                                    {
                                        key: 'title',
                                        type: LamaFormlyTypes.input,
                                        wrappers: [LamaFormlyWrappers.formGroup],
                                        templateOptions: {
                                            labelTranslate: 'venue.title',
                                            required: true
                                        }
                                    },
                                    {
                                        key: 'description',
                                        type: LamaFormlyTypes.editor,
                                        wrappers: [LamaFormlyWrappers.formGroup],
                                        templateOptions: {
                                            labelTranslate: 'shared.description'
                                        }
                                    }
                                ]
                            },
                            {
                                wrappers: [LamaFormlyWrappers.card],
                                templateOptions: {
                                    cardProps: {
                                        headingTranslate: 'venue.contacting.header',
                                        summaryTranslate: 'venue.contacting.summary'
                                    }
                                } as LamaCollapsibleCardWrapperTemplateOptions,
                                fieldGroup: [
                                    {
                                        key: 'phoneNumber',
                                        type: LamaFormlyTypes.input,
                                        wrappers: [LamaFormlyWrappers.formGroup],
                                        templateOptions: {
                                            labelTranslate: 'venue.phoneNumber'
                                        }
                                    },
                                    {
                                        key: 'email',
                                        type: LamaFormlyTypes.input,
                                        wrappers: [LamaFormlyWrappers.formGroup],
                                        templateOptions: {
                                            labelTranslate: 'venue.email',
                                            type: 'email'
                                        }
                                    },
                                    {
                                        key: 'website',
                                        type: LamaFormlyTypes.input,
                                        wrappers: [LamaFormlyWrappers.formGroup],
                                        templateOptions: {
                                            labelTranslate: 'venue.website',
                                            type: 'url'
                                        }
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        fieldGroupClassName: 'col-sm-6',
                        wrappers: [LamaFormlyWrappers.div],
                        fieldGroup: [
                            {
                                wrappers: [LamaFormlyWrappers.card],
                                templateOptions: {
                                    cardProps: {
                                        headingTranslate: 'venue.displayImage.header'
                                    }
                                } as LamaCollapsibleCardWrapperTemplateOptions,
                                fieldGroup: [
                                    {
                                        key: 'displayImageId',
                                        type: MediaFormlyTypes.mediaSingleChooser,
                                        templateOptions: {
                                            gridSize: 4
                                        } as MediaEntityChooserTypeTemplateOptions
                                    }
                                ]
                            },
                            {
                                wrappers: [LamaFormlyWrappers.card],
                                templateOptions: {
                                    cardProps: {
                                        headingTranslate: 'venue.location.header'
                                    }
                                } as LamaCollapsibleCardWrapperTemplateOptions,
                                fieldGroup: [
                                    {
                                        key: 'place',
                                        type: LocationFormlyTypes.placeSelector,
                                        wrappers: [LamaFormlyWrappers.formGroup],
                                        templateOptions: {
                                            labelTranslate: 'venue.place',
                                            required: true
                                        }
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
        ]
    };
};
