import React from 'react';
import { RcFormlyWrapper } from '@rc-formly/core';

import { LamaFormlyFieldConfig } from 'shared/components/forms/models';

import { LamaCollapsibleCardWithHeader } from 'shared/components/LamaCollapsibleCardWithHeader';

export class LamaCollapsibleCardWrapper extends RcFormlyWrapper {
    render() {
        const fieldGroupClassName = (this.props.parentField as LamaFormlyFieldConfig)?.fieldGroupClassName;
        const cardProps = this.to?.cardProps || {};

        return (
            <LamaCollapsibleCardWithHeader classes={{ root: fieldGroupClassName }} {...cardProps}>
                {this.props.children}
            </LamaCollapsibleCardWithHeader>
        )
    }
}
