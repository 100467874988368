import React, { createContext, FC, useState } from 'react';

import { LamaStepperContextState } from './types';
import { StepItem } from 'shared/components/stepper/types';
import { NumberKeyValueObject } from 'shared/types';

export const LamaStepperContext = createContext<LamaStepperContextState>({
    activeStep: 0,
    completedSteps: {},
    disabledSteps: {},
    steps: [],
    setActiveStep: () => { },
    setCompletedSteps: () => { },
    setDisabledSteps: () => { }
});

interface LamaStepperProviderProps {
    steps: StepItem[];
}

export const LamaStepperProvider: FC<LamaStepperProviderProps> = (props) => {
    const { children, steps } = props;

    const [activeStep, setActiveStep] = useState(0);
    const [completedSteps, setCompletedSteps] = useState<NumberKeyValueObject>({});
    const [disabledSteps, setDisabledSteps] = useState<NumberKeyValueObject>({});

    const contextValue: LamaStepperContextState = {
        activeStep,
        completedSteps,
        disabledSteps,
        steps,
        setActiveStep,
        setCompletedSteps,
        setDisabledSteps
    };

    return <LamaStepperContext.Provider value={contextValue}>{children}</LamaStepperContext.Provider>;
};
