import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete';

import TextField from '@material-ui/core/TextField';

interface LamaAutocompleteInputProps extends AutocompleteRenderInputParams {
    placeholder?: string;
    placeholderTranslate?: string;
}

export const LamaAutocompleteInput: FC<LamaAutocompleteInputProps> = (props) => {
    const { placeholder, placeholderTranslate, inputProps, ...otherProps } = props;

    const { formatMessage } = useIntl();

    return (
        <TextField
            {...otherProps}
            inputProps={{ ...inputProps, autoComplete: 'new-password' }}
            label={placeholderTranslate ? formatMessage({ id: placeholderTranslate }) : placeholder}
            variant="outlined"
        />
    );
};
