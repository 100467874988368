import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button';

import { Import, isImportFinished, ImportCancelCommand } from "features/imports/models";
import { IMPORT_CANCEL, IMPORT_REVERT } from 'features/imports/mutations';

import { LamaGridCellProps, useLamaGrid } from "shared/components/grid";
import { useLamaDialog } from 'shared/packages/dialogs';
import { useLamaClient, LamaGraphQlError } from 'shared/graphql';
import { OperateModelResult } from 'shared/graphql/hooks/use-lama-client';
import { useLamaAlerts } from 'shared/packages/alerts';

export const ImporterListActionCellType: FC<LamaGridCellProps<Import>> = (props) => {
    const { row } = props;
    const { addSuccessAlert, addWarningAlert } = useLamaAlerts();
    const { addWarningDialog } = useLamaDialog();
    const lamaClient = useLamaClient();
    const { reload, toggleLoader } = useLamaGrid();

    const wasImportFinished = isImportFinished(row.status);

    const onButtonClick = () => {
        const dialogType = wasImportFinished ? 'revert' : 'cancel';

        addWarningDialog({
            headerTextTranslate: `import.${dialogType}Dialog.header`,
            messageTextTranslate: `import.${dialogType}Dialog.message`,
            onConfirm: async () => {
                toggleLoader();

                let mutateResult: OperateModelResult<boolean>;

                try {
                    if (wasImportFinished) {
                        mutateResult = await lamaClient.mutate<boolean, { importRevertInput: ImportCancelCommand }>({
                            gqlMutation: IMPORT_REVERT,
                            variables: {
                                importRevertInput: {
                                    importId: row.id || ''
                                }
                            }
                        });
                    }
                    else {
                        mutateResult = await lamaClient.mutate<boolean, { importCancelInput: ImportCancelCommand }>({
                            gqlMutation: IMPORT_CANCEL,
                            variables: {
                                importCancelInput: {
                                    importId: row.id || ''
                                }
                            }
                        });
                    }


                    if (mutateResult.data) {
                        addSuccessAlert(`import.${wasImportFinished ? 'revert': 'cancel'}Success`);

                        toggleLoader();
                        reload();
                    }
                }
                catch (e) {
                    const errorMessage = (e as LamaGraphQlError).messageTranslate;
                    const errorMessageParams = (e as LamaGraphQlError).messageTranslateParams;

                    addWarningAlert(errorMessage, errorMessageParams);
                }
            }
        });
    }

    return (
        <Button color="secondary" variant="contained" onClick={onButtonClick}>
            <FormattedMessage id={wasImportFinished ? 'import.revert' : 'import.cancel' } />
        </Button>
    );
}
