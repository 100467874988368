import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import { LamaGridCellProps, LamaGridEnumColumnAdditionalData } from 'shared/components/grid/types';

import { EmptyCell } from './EmptyCell';
import { DefaultCellType } from './DefaultCellType';
import { FormHelper } from 'shared/components/forms/utilities';

export const EnumCellType: FC<LamaGridCellProps<any, LamaGridEnumColumnAdditionalData>> = props => {
    const { cellValue, column: { additionalData } } = props;

    const { formatMessage } = useIntl();

    if (!cellValue) {
        return <EmptyCell />
    }

    if (!additionalData) {
        return <DefaultCellType {...props} />
    }

    const { enumType, translationPrefix } = additionalData;

    let newCellValue = FormHelper.converEnumValueToTranslationMessage(cellValue, translationPrefix, enumType);

    if (newCellValue) {
        newCellValue = formatMessage({ id: newCellValue });
    }
    else {
        newCellValue = cellValue;
    }

    return (
        <DefaultCellType {...props} cellValue={newCellValue} />
    );
};
