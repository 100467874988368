import React, { createContext, FC, useState, useEffect } from "react";
import { PackageContractContextState } from "./types";
import { PackageContractWithConstrainsViewModel } from "features/contracts/models";
import { useLamaTenant } from "shared/authentication";

export const PackageContractContext = createContext<PackageContractContextState>({
    clearCurrentContract: () => { },
    setCurrentContract: () => { }
});

export const PackageContractProvider: FC = (props) => {
    const { currentActiveTenantId } = useLamaTenant();
    const [currentContract, setCurrentContract] = useState<PackageContractWithConstrainsViewModel>();

    const clearCurrentContract = () => {
        setCurrentContract(undefined);
    };

    const contextValue: PackageContractContextState = {
        currentContract,
        clearCurrentContract,
        setCurrentContract
    };

    useEffect(() => {
        if (currentContract) {
            clearCurrentContract();
        }
    }, [currentActiveTenantId]);

    return (
        <PackageContractContext.Provider value={contextValue}>
            { props.children }
        </PackageContractContext.Provider>
    )
};
