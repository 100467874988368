export { ContentStatus, contentStatusGridAdditionalData, contentStatusGridColorAdditionalData } from './content';
export * from './crud';
export * from './language';
export * from './routes';
export * from './visitModule';

export enum BaseResourceNames {
    category = 'category',
    contract = 'packageContract',
    language = 'language',
    moduleType = 'moduleType',
    tenant = 'tenant',
    subTenant = 'subTenant',
    visitModuleTranslation = 'visitModuleTranslation',
    visitModule = 'visitModule'
}
