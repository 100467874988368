import React, { FC } from 'react';

import { LamaListRouteBase } from 'features/base/components';

import { CategoryResourceNames } from 'features/categories/models';
import { LamaGridProps, LamaGridColumnType } from 'shared/components/grid';

export const CategoryList: FC = () => {
    const gridOptions: LamaGridProps = {
        columns: [
            {
                titleTranslate: 'category.title',
                prop: 'title'
            },
            {
                titleTranslate: 'category.ownerParentCategoryId',
                prop: 'ownerParentCategory.title'
            },
            {
                type: LamaGridColumnType.datetime,
                titleTranslate: 'shared.updatedAt',
                prop: 'updatedAt'
            }
        ],
        serverOptions: {
            modelName: CategoryResourceNames.category
        },
        titleProperty: 'title'
    };

    return <LamaListRouteBase gridProps={gridOptions} />;
};
