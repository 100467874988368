import { types } from 'typed-graphqlify';
import { GraphQlQueryType, LamaGraphQlQueryHelper, QueryFromDotNotationOptions } from 'shared/graphql';

import { ContractResourceNames, PackageContractConstrainsViewModel } from 'features/contracts/models';

export const singleMeContractConstrainsQueryOptions: QueryFromDotNotationOptions<PackageContractConstrainsViewModel> = {
    modelName: ContractResourceNames.contractConstrains as string,
    queryType: GraphQlQueryType.singleMe,
    queryObject: {
        currentNumberOfAccounts: types.number,
        currentNumberOfProducts: types.number,
        currentNumberOfVenues: types.number
    },
    queryPrefixDotNotation: 'admin.me'
};

export const { gqlQuery: singleMeContractContstrainsQuery} = LamaGraphQlQueryHelper.createQueryFromDotNotation<PackageContractConstrainsViewModel>(singleMeContractConstrainsQueryOptions);
