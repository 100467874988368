import React, { ChangeEvent } from 'react';
import { RcFormlyField } from "@rc-formly/core";

import { LamaEnumRadioGroupTypeTemplateOptions } from 'shared/components/forms/models';

import { LamaEnumRadioGroup } from 'shared/components/forms/components';

export class LamaEnumRadioGroupType extends RcFormlyField<LamaEnumRadioGroupTypeTemplateOptions> {
    public onChange = (event: ChangeEvent<HTMLInputElement>) => {
        this.handleChange( event.target.value);
    }

    render() {
        const { disabled, enumRadioGroupProps } = this.to || {};

        if (!enumRadioGroupProps) {
            return null;
        }

        const value = this.getFieldValue();

        return (
            <LamaEnumRadioGroup {...enumRadioGroupProps} disabled={disabled} onChange={this.onChange} value={value} />
        );
    }
}
