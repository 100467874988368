import React, { FC, useState } from 'react';

import { createStyles, makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import { useLamaQuery, SortOperationKind, GraphQlQueryType, PaginationConnection, GraphQlQueryVariables } from 'shared/graphql';

import { MediaResourceNames, Media, DEFAULT_MEDIA_PAGINATION_SIZE } from 'features/media/models';
import { mediaQueryObject } from 'features/media/queries';
import { LamaLoader, LamaPagination, NoData } from 'shared/components';
import { UtilityHelper } from 'shared/utilities';

import { LamaGraphQlValueWithTypeCreator } from 'shared/graphql/utilities';
import { MediaFileSelectorGrid } from './MediaFileSelectorGrid';

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            backgroundColor: theme.palette.background.paper,
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
            width: '100%'
        },
        icon: {
            color: 'rgba(255, 255, 255, 0.54)'
        }
    })
);

interface MediaFileSelectorProps {
    canDeleteMedia?: boolean;
}

export const MediaFileSelector: FC<MediaFileSelectorProps> = (props) => {
    const { canDeleteMedia = false } = props;
    const classes = useStyles();
    const [after, setPaginationAfter] = useState('');

    let variables: GraphQlQueryVariables = {
        first: LamaGraphQlValueWithTypeCreator.createWithPaginationAmountType(DEFAULT_MEDIA_PAGINATION_SIZE),
        order_by: {
            createdAt: SortOperationKind.desc
        }
    };

    if (after) {
        variables.after = after;
    }

    const { data, loading, refetch } = useLamaQuery<PaginationConnection<Media>>({
        variables,
        modelName: MediaResourceNames.media as string,
        queryType: GraphQlQueryType.paginated,
        queryObject: mediaQueryObject
    });

    const empty = UtilityHelper.isEmpty(data?.nodes);

    const handlePagination = (newCursor: string) => {
        setPaginationAfter(newCursor);
    };

    const onGridReloadData = () => {
        refetch();
    };

    const nodes = data?.nodes ? data.nodes : [];

    return (
        <div className={classes.root}>
            <LamaLoader showLoader={loading} />
            {empty && !loading && <NoData />}
            {!empty && (
                <>
                    {data!.totalCount > DEFAULT_MEDIA_PAGINATION_SIZE && (
                        <Box display="flex" justifyContent="center" mb={2}>
                            <LamaPagination first={DEFAULT_MEDIA_PAGINATION_SIZE} connection={data!} onChange={handlePagination} />
                        </Box>
                    )}
                    <MediaFileSelectorGrid canDeleteMedia={canDeleteMedia} data={nodes} reloadData={onGridReloadData} />
                    {data!.totalCount > DEFAULT_MEDIA_PAGINATION_SIZE && (
                        <Box display="flex" justifyContent="center" mt={2}>
                            <LamaPagination first={DEFAULT_MEDIA_PAGINATION_SIZE} connection={data!} onChange={handlePagination} />
                        </Box>
                    )}
                </>
            )}
        </div>
    );
};
