import React, { FC } from 'react';

import { LamaListRouteBase } from 'features/base/components';

import { CompanyResourceNames } from 'features/companies/models';
import { LamaGridProps, LamaGridColumnType } from 'shared/components/grid';

export const TenantList: FC = () => {
    const gridOptions: LamaGridProps = {
        columns: [
            {
                titleTranslate: 'shared.id',
                prop: 'id'
            },
            {
                titleTranslate: 'company.title',
                prop: 'companyOfTenant.title'
            },
            {
                titleTranslate: 'company.address',
                prop: 'companyOfTenant.address.fullAddress'
            },
            {
                titleTranslate: 'company.email',
                prop: 'companyOfTenant.email'
            },
            {
                type: LamaGridColumnType.datetime,
                titleTranslate: 'company.createdAt',
                prop: 'createdAt'
            }
        ],
        serverOptions: {
            modelName: CompanyResourceNames.tenant
        },
        titleProperty: 'companyOfTenant.title'
    };

    return <LamaListRouteBase gridProps={gridOptions} />;
};
