import classNames from 'classnames';
import React, { FC, useState } from 'react';
import Helmet from 'react-helmet';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useBodyClass } from 'shared/packages/use-body-class';
import { Link } from 'react-router-dom';
import { useAuth } from 'shared/authentication';

const useStyles = makeStyles(() =>
    createStyles({
        body: {
            overflow: 'auto'
        },
        mainCtaButton: {
            maxWidth: '100%',
            textOverflow: 'ellipsis',
            overflow: 'hidden'
        },
        topBar: {
            height: '10px',
            backgroundColor: '#5c95ef',
            position: 'fixed',
            width: '100%',
            zIndex: 10
        }
    })
);

const offCanvasMenu = 'offcanvas-menu';

export const PublicHome: FC = () => {
    const { body, mainCtaButton, topBar } = useStyles();
    const { isAuthenticated } = useAuth();
    const [bodyClassNames, setBodyClassNames] = useState([body]);
    useBodyClass(bodyClassNames);

    const toggleMobileMenu = () => {
        if (bodyClassNames.length === 1) {
            setBodyClassNames([body, offCanvasMenu]);
        } else {
            setBodyClassNames([body]);
        }
    };

    return (
        <>
            <Helmet>
                <title>Prodajko.si - nove in rabljene stvari</title>
                <meta property="og:title" content="Prodajko.si - nove in rabljene stvari" />
                <meta property="og:description" content="Prodajko trgovci je platforma za lokacijsko oglaševanje" />

                <link href="https://fonts.googleapis.com/css?family=Nunito:300,400,700" rel="stylesheet" />
                <link rel="stylesheet" href="css/bootstrap.min.css" />
                <link rel="stylesheet" href="fonts/icomoon/style.css" />
                <link rel="stylesheet" href="fonts/flaticon/font/flaticon.css" />

                <link rel="stylesheet" href="css/style.css" />
            </Helmet>
            <div className={topBar}></div>

            <div className="site-mobile-menu site-navbar-target">
                <div className="site-mobile-menu-header">
                    <div className="site-mobile-menu-close mt-3" onClick={toggleMobileMenu}>
                        <span className="icon-close2 js-menu-toggle"></span>
                    </div>
                </div>
                <div className="site-mobile-menu-body">
                    <ul className="site-nav-wrap">
                        <li>
                            <a href="#home-section" className="nav-link active" onClick={toggleMobileMenu}>
                                Predstavitev
                            </a>
                        </li>
                        <li>
                            <a href="#shops-type-section" className="nav-link" onClick={toggleMobileMenu}>
                                Trgovci
                            </a>
                        </li>
                        <li>
                            <a href="#services-section" className="nav-link" onClick={toggleMobileMenu}>
                                Ponudniki storitev
                            </a>
                        </li>
                        {isAuthenticated() ? (
                            <li>
                                <Link to="/dashboard" className="nav-link">
                                    Pojdi v nadzorno ploščp
                                </Link>
                            </li>
                        ) : (
                            <>
                                <li>
                                    <Link to="/login" className="nav-link">
                                        Prijava v sistem
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/register" className="nav-link">
                                        Registriraj se
                                    </Link>
                                </li>
                            </>
                        )}
                    </ul>
                </div>
            </div>

            <header className="site-navbar py-4 js-sticky-header site-navbar-target">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-6 col-md-3 col-xl-4  d-block">
                            <a href="index.html" className="text-black h2 mb-0">
                                <img src="images/logo.svg" alt="Image" className="img-fluid" />
                                <span className="text-primary">.</span>{' '}
                            </a>
                        </div>

                        <div className="col-12 col-md-9 col-xl-8 main-menu">
                            <nav className="site-navigation position-relative text-right">
                                <ul className="site-menu main-menu js-clone-nav mr-auto d-none d-lg-block ml-0 pl-0">
                                    <li>
                                        <a href="#home-section" className="nav-link">
                                            Predstavitev
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#shops-type-section" className="nav-link">
                                            Trgovci
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#services-section" className="nav-link">
                                            Ponudniki storitev
                                        </a>
                                    </li>
                                    <li>
                                        <Link to="/login" className="nav-link">
                                            Prijava v sistem
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/register" className="nav-link">
                                            Registriraj se
                                        </Link>
                                    </li>
                                </ul>
                            </nav>
                        </div>

                        <div className="col-6 col-md-9 d-inline-block d-lg-none ml-md-0" onClick={toggleMobileMenu}>
                            <a href="#" className="site-menu-toggle js-menu-toggle text-black float-right">
                                <span className="icon-menu h3"></span>
                            </a>
                        </div>
                    </div>
                </div>
            </header>

            <div className="site-blocks-cover" style={{ overflow: 'hidden' }}>
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-md-12" style={{ position: 'relative' }} data-aos="fade-up" data-aos-delay="0">
                            <img src="images/undraw_investing_7u74.svg" alt="Image" className="img-fluid img-absolute" width="500" />

                            <div className="row mb-4" data-aos="fade-up" data-aos-delay="0">
                                <div className="col-lg-8 mr-auto">
                                    <h1>
                                        <u>Lokacijsko oglaševanje</u> je ravnokar postalo <u>otročje lahko</u>.
                                    </h1>
                                    <p className="mb-5" style={{ fontSize: '20px' }}>
                                        Prodajko odpira vrata trgovcem in ponudnikom storitev, da ponudbo pričnete oglaševati potencialnim strankam v
                                        svoji bližini.
                                    </p>
                                    <div style={{ marginTop: '-30px' }}>
                                        <Link to="/register" className={classNames('btn btn-primary mr-2 mb-2', mainCtaButton)}>
                                            Ponujamo vam 2-mesečni brezplačni preiskus!
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="testimonial-wrap" style={{ backgroundColor: '#5c95ef', color: '#FFFFFF', padding: '30px' }} id="testimonials-section">
                <div className="container">
                    <div className="col-12 text-center">
                        <h2 className="section-title" style={{ color: '#FFFFFF' }}>
                            Prodajko - skupnost kupcev in prodajalcev
                        </h2>
                        <p className="lead">
                            Smo ena izmed najhitreje rastočih skupnosti za oddajo malih oglasov v Sloveniji. Našim uporabnikom bi radi ponudili še
                            ponudbo izbranih in preverjenih trgovcev ter lokalnih ponudnikov.
                        </p>
                    </div>
                </div>
            </div>

            <div className="site-section">
                <div className="container" style={{ marginBottom: '-150px' }}>
                    <div className="row mb-5 justify-content-center text-center" data-aos="fade-up">
                        <div className="col-7 text-center  mb-5">
                            <h2 className="section-title">Kaj vam ponujamo?</h2>
                            <p className="lead">Povsem nov oglaševalski kanal za pospeševanje prodaje vaših artiklov in storitev.</p>
                        </div>
                    </div>
                    <div className="row align-items-stretch">
                        <div className="col-md-6 col-lg-4 mb-4 mb-lg-4" data-aos="fade-up">
                            <div className="unit-4 d-block">
                                <div className="unit-4-icon mb-3">
                                    <span className="icon-wrap">
                                        <span className="text-primary icon-map-marker"></span>
                                    </span>
                                </div>
                                <div>
                                    <h3>Lokacijsko oglaševanje</h3>
                                    <p>
                                        Dosežite potencialne kupce, ki se nahajajo v vaši bližini. Lokacijsko usmerjeno oglaševanje vam poveča možnost
                                        prodaje tudi do 50 %!
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 mb-4 mb-lg-4" data-aos="fade-up" data-aos-delay="100">
                            <div className="unit-4 d-block">
                                <div className="unit-4-icon mb-3">
                                    <span className="icon-wrap">
                                        <span className="text-primary icon-store_mall_directory"></span>
                                    </span>
                                </div>
                                <div>
                                    <h3>Promocija fizičnih poslovalnic</h3>
                                    <p>
                                        V kolikor imate eno ali več fizičnih poslovalnic, lahko njihove informacije vnesete v sistem in tako povečate
                                        vaš učinek oglaševanja po regijah.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 mb-4 mb-lg-4" data-aos="fade-up" data-aos-delay="200">
                            <div className="unit-4 d-block">
                                <div className="unit-4-icon mb-3">
                                    <span className="icon-wrap">
                                        <span className="text-primary icon-sentiment_very_satisfied"></span>
                                    </span>
                                </div>
                                <div>
                                    <h3>Enostavna uporaba</h3>
                                    <p>
                                        S pomočjo naše moderne in intuitivne nadzorne plošče boste hitro in učinkovito upravljali z vnašanje in
                                        urejanjem vaših izdelkov ali storitev.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-4 mb-4 mb-lg-4" data-aos="fade-up">
                            <div className="unit-4 d-block">
                                <div className="unit-4-icon mb-3">
                                    <span className="icon-wrap">
                                        <span className="text-primary icon-bullhorn"></span>
                                    </span>
                                </div>
                                <div>
                                    <h3>Promocija vaših izdelkov in storitev</h3>
                                    <p>
                                        Vi skrbite za aktualno ponudbo in ugodnosti. Naša naloga pa je promocija in večanje Prodajko skupnosti, ki vam
                                        bo pomagala pri iskanju novih kupcev.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-4 mb-4 mb-lg-4" data-aos="fade-up" data-aos-delay="100">
                            <div className="unit-4 d-block">
                                <div className="unit-4-icon mb-3">
                                    <span className="icon-wrap">
                                        <span className="text-primary icon-bar-chart-o"></span>
                                    </span>
                                </div>
                                <div>
                                    <h3>Napredna statistika</h3>
                                    <p>
                                        Imejte vpogled v uspešnost prodaje preko naše platforme. Na voljo vam bo osnovna ali napredna možnost prikaza
                                        statistike in rezultatov.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-4 mb-4 mb-lg-4" data-aos="fade-up" data-aos-delay="200">
                            <div className="unit-4 d-block">
                                <div className="unit-4-icon mb-3">
                                    <span className="icon-wrap">
                                        <span className="text-primary icon-power"></span>
                                    </span>
                                </div>
                                <div>
                                    <h3>Nemoteno delovanje platforme</h3>
                                    <p>
                                        Vi se osredotočite na prodajo, mi pa poskrbimo, da bo celotna Prodajko platforma delovala nemoteno vseh 365
                                        dni v letu.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="feature-big" style={{ paddingTop: '80px' }} id="shops-type-section">
                <div className="container">
                    <div className="row mb-5">
                        <div className="col-lg-7" data-aos="fade-right">
                            <img src="images/webshop.svg" alt="Image" className="img-fluid" />
                        </div>
                        <div className="col-lg-5 pl-lg-5 ml-auto mt-md-5">
                            <h2 className="text-black">Že imate spletno trgovino? Povečajte svoj obisk!</h2>
                            <p className="mb-4">
                                Tudi v tem primeru je Prodajko odličen oglaševalski kanal. Vse potencialne stranke bomo enostavno preusmerili na vašo
                                spletno stran, kjer bodo lahko opravili nakup. Hitro in enostavno.
                            </p>
                            <div>
                                <Link to="/register" className="btn btn-primary mr-2 mb-2">
                                    Registrirajte se tukaj
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className="mt-5 row mb-5 site-section">
                        <div className="col-lg-7 order-1 order-lg-2" data-aos="fade-left">
                            <img src="images/physical_store.svg" alt="Image" className="img-fluid" />
                        </div>
                        <div className="col-lg-5 pr-lg-5 mr-auto mt-5 order-2 order-lg-1">
                            <h2 className="text-black">
                                Ste trgovec s fizičnimi poslovalnicami? Postanite vidni potencialnim kupcem v vaši okolici!
                            </h2>
                            <p className="mb-4">
                                Prodajko je odlična priložnost, da preizkusite prednosti lokacijsko usmerjenega oglaševanja. Naša platforma bo vaše
                                izdelke še posebej oglaševala uporabnikom, ki so v bližini vaše poslovalnice in tako še dodatno povečala možnost
                                nakupa. Lokacijsko oglaševanje še nikoli ni bilo tako preprosto.
                            </p>

                            <div>
                                <Link to="/register" className="btn btn-primary mr-2 mb-2">
                                    Odprite Prodajko trgovino
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className="row mb-5" id="services-section" style={{ paddingTop: '80px' }}>
                        <div className="col-lg-7" data-aos="fade-right">
                            <img src="images/undraw_multitasking_hqg3.svg" alt="Image" className="img-fluid" />
                        </div>
                        <div className="col-lg-5 pl-lg-5 ml-auto mt-md-5">
                            <h2 className="text-black">
                                Ste podjetje, ki ponuja storitve / dejavnosti? Prodajko vam bo pomagal do strank v vaši bližini!
                            </h2>
                            <p className="mb-4">
                                Oddaljenost igra veliko vlogo pri uporabnikih, ki iščejo določeno storitev. V takih primerih je naša platforma idealna
                                za iskanje novih strank, ki se nahajajo ali živijo v vaši bližini.
                            </p>
                            <div>
                                <Link to="/register" className="btn btn-primary mr-2 mb-2">
                                    Vpišite svojo dejavnost
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="site-section bg-light" id="about-section">
                <div className="container">
                    <div className="row mb-5">
                        <div className="col-12 text-center">
                            <h2 className="section-title mb-3">Kako vas lahko prepričamo?</h2>
                            <p className="mb-4">
                                Zgodba Prodajko platforme se je začela pred dobrimi 3 leti. Takrat smo si zadali cilj, da vpostavimo moderno in
                                tehnološko dovršeno platformo, ki bo združevala tako prodajo rabljenih kot tudi prodajo novih izdelkov na nov, moderen
                                način. Sedaj po 3 letih intenzivnega dela in večanja naše skupnosti vam, s ponosom, predstavljamo kaj smo do sedaj že
                                doseglji.
                            </p>
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className="row">
                            <div className="col-md-6 col-lg-3 mb-4 mb-lg-4">
                                <div className="h-entry text-center">
                                    <h1 className="section-title">40.000+</h1>
                                    <h3 style={{ color: '#000000' }}>Mesečno unikatnih obiskov spletne strani</h3>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 mb-4 mb-lg-4">
                                <div className="h-entry text-center">
                                    <h1 className="section-title">50.000+</h1>
                                    <h3 style={{ color: '#000000' }}>Mesečno aktivnih uporabnikov mobilne aplikacije</h3>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 mb-4 mb-lg-4">
                                <div className="h-entry text-center">
                                    <h1 className="section-title">197</h1>
                                    <h3 style={{ color: '#000000' }}>povprečno dnevno sklenjenih prodaj/nakupov na naši platformi</h3>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 mb-4 mb-lg-4">
                                <div className="h-entry text-center">
                                    <h1 className="section-title">11-14%</h1>
                                    <h3 style={{ color: '#000000' }}>Mesečna rast števila uporabnikov</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="site-section bg-image2 overlay" id="contact-section" style={{ backgroundColor: '#5c95ef' }}>
                    <div className="container">
                        <div className="row mb-5">
                            <div className="col-12 text-center">
                                <h2 className="section-title mb-3 text-white">
                                    Preizkusite našo platformo. Ponujamo vam 2 meseca brezplačne uporabe!
                                </h2>
                                <p className="mb-4" style={{ color: '#FFFFFF' }}>
                                    Vsi naši paketi kot tudi funkcionalnosti, ki jih bomo še dodajali bodo na voljo povsem brezplačno v 2-mesečnem
                                    obdobju. V kolikor vam Prodajko v 2 mesecih ne bo prinesel želenih učinkov lahko brez kakršnih koli stroškov ali
                                    obveznosti zaprete svoj uporabniški račun.
                                </p>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-lg-7 mb-5 text-center">
                                <div>
                                    <Link
                                        to="/register"
                                        className="btn btn-primary mr-2 mb-2"
                                        style={{ color: '#000000', backgroundColor: '#FFFFFF' }}
                                    >
                                        Registriraj se tukaj
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="footer py-5 text-center">
                    <div className="container">
                        <div className="row mb-5">
                            <div className="col-12">
                                <p className="mb-0">
                                    <a href="https://www.facebook.com/prodajko" className="p-3" target="_blank">
                                        <span className="icon-facebook"></span>
                                    </a>

                                    <a href="https://www.instagram.com/prodajko/" className="p-3" target="_blank">
                                        <span className="icon-instagram"></span>
                                    </a>
                                    <a href="https://www.youtube.com/channel/UCuXHO9DzFBT4iEqJ8h-XGAw" className="p-3" target="_blank">
                                        <span className="icon-youtube"></span>
                                    </a>
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <p className="mb-0">
                                    Copyright &copy;{new Date().getFullYear()} FS, računalniške storitve, d. o. o | This template is made with{' '}
                                    <i className="icon-heart text-danger" aria-hidden="true"></i> by{' '}
                                    <a href="https://colorlib.com" target="_blank">
                                        Colorlib
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
