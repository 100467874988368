import { useLamaSelectionInterface } from './types';
import { useContext } from 'react';
import { LamaSelectionContext } from 'shared/components/selection/providers';

export const useLamaSelection: useLamaSelectionInterface = (item?: any) => {
    const {
        addSelectedItems, hasSelectedItem, maxNumberOfSelectedItems, resetSelections,
        selectedItems, toggleSelectedItem: toggleContextSelectedItem
    } = useContext(LamaSelectionContext);

    const toggleSelectedItem = (itemToToggle?: any) => {
        if (itemToToggle) {
            toggleContextSelectedItem(itemToToggle);
        }
        else if (item) {
            toggleContextSelectedItem(item);
        }
    }

    return {
        addSelectedItems,
        maxNumberOfSelectedItems,
        resetSelections,
        selectedItems,
        toggleSelectedItem,
        selectedItem : selectedItems.length ? selectedItems[0] : undefined,
        isSelected: item ? hasSelectedItem(item) : false
    };
};
