import { types } from 'typed-graphqlify';
import { GraphQlQueryType, LamaGraphQlQueryHelper } from 'shared/graphql';

import { PackageResourceNames, LamaFeatureModule } from 'features/packs/models';
import { Tax } from 'features/products/models';
import { BaseEntity } from 'shared/types';

export const lamaFeatureQueryObject: LamaFeatureModule = {
    id: types.string,
    buyingType: types.custom(),
    maxQuantity: types.number,
    maxVisibleQuantity: types.number,
    description: types.string,
    title: types.string,
    type: types.custom(),
    systemName: types.string,
    currentPrice: {
        id: types.string,
        discount: types.number,
        ownerTaxId: types.string,
        priceWithDiscount: types.number,
        priceWithTax: types.number,
        priceWithTaxAndDiscount: types.number,
        priceWithoutTax: types.number,
        ownerTax: {
            value: types.number
        } as Tax
    },
    ownerEntityTypeId: types.string
};

export const lamaFeatureWithOwnerEntityQueryObject: LamaFeatureModule = {
    ...lamaFeatureQueryObject,
    ownerEntityType: {
        id: types.string,
        name: types.string,
        title: types.string
    }
};

export const { gqlQuery: singleFeatureModuleQuery } = LamaGraphQlQueryHelper.createQueryFromDotNotation<LamaFeatureModule, BaseEntity>({
    modelName: PackageResourceNames.featureModule as string,
    queryType: GraphQlQueryType.single,
    queryObject: lamaFeatureQueryObject,
    variables: {
        id: types.string
    }
});
