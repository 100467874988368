import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import Step from '@material-ui/core/Step';
import Stepper from '@material-ui/core/Stepper';
import StepButton from '@material-ui/core/StepButton';
import Box from '@material-ui/core/Box';

import { StepItem } from 'shared/components/stepper/types';
import { LamaStepperProvider } from 'shared/components/stepper/providers';
import { useLamaStepper } from 'shared/components/stepper/hooks';

interface LamaStepperProps {
    steps: StepItem[];
}

const LamaInnerStepper: FC = () => {
    const { activeStep, isStepCompleted, isStepDisabled, setStep, steps } = useLamaStepper();

    const handleStep = (stepIndex: number) => () => {
        setStep(stepIndex);
    };

    const renderStep = (step: StepItem, index: number) => {
        const { component: Component } = step;

        if (index !== activeStep) {
            return null;
        }

        return <Component key={step.label} />;
    };

    return (
        <>
            <Stepper nonLinear activeStep={activeStep}>
                {steps.map((step, index) => (
                    <Step key={step.label}>
                        <StepButton disabled={!isStepCompleted(index) || isStepDisabled(index)} onClick={handleStep(index)} completed={isStepCompleted(index)}>
                            <FormattedMessage id={step.label} />
                        </StepButton>
                    </Step>
                ))}
            </Stepper>
            <Box>{steps.map(renderStep)}</Box>
        </>
    );
};

export const LamaStepper: FC<LamaStepperProps> = (props) => {
    const { steps } = props;

    return (
        <LamaStepperProvider steps={steps}>
            <LamaInnerStepper />
        </LamaStepperProvider>
    );
};
