import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { LamaCollapsibleCard } from 'shared/components';
import { WeeklySumStatistics } from 'features/statistics/components';

export const WeeklySumStatisticsCard: FC = () => {
    return (
        <LamaCollapsibleCard>
            <h3 className="fw-semi-bold mb-15">
                <FormattedMessage id="home.weeklySumStatistics" />
            </h3>
            <WeeklySumStatistics />
        </LamaCollapsibleCard>
    );
};
