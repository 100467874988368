import React, { FC, CSSProperties } from "react";

import CircularProgress from "@material-ui/core/CircularProgress";

interface LamaLoaderProps {
    showLoader?: boolean;
    style?: CSSProperties;
}

export const LamaLoader: FC<LamaLoaderProps> = (props) => {
    const { style, showLoader = true } = props;

    if (!showLoader) {
        return null;
    }

    return (
        <div className="d-flex justify-content-center loader-overlay" style={style}>
            <CircularProgress />
        </div>
    );
}
