import classNames from 'classnames';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';

import { PackageViewModel } from 'features/packs/models';
import { SubscriptionDurationType } from 'features/contracts/models';

import { useRapidModel } from 'shared/packages/rapid-model';

import { LamaCollapsibleCard } from 'shared/components';
import { moneyPipe, LanguageHelper } from 'shared/utilities';
import { ProductResourceNames } from 'features/products/models';

const useStyles = makeStyles((theme) =>
    createStyles({
        body: {
            cursor: 'pointer'
        },
        crossed: {
            display: 'inline-block',
            position: 'relative',
            '&:before': {
                content: '""',
                'border-bottom': `4px solid ${theme.palette.primary.main}`,
                marginTop: '-2px',
                position: 'absolute',
                right: 0,
                top: '50%',
                width: '100%'
            }
        },
        selectedBody: {
            backgroundColor: fade(theme.palette.primary.main, 0.1)
        },
        selectedBorder: {
            border: `0.2rem solid ${theme.palette.primary.main}`
        }
    })
);

interface PackageSelectorItemProps {
    colClasses?: string;
    isSelected?: boolean;
    leaseTimeType?: SubscriptionDurationType;
    pack: PackageViewModel;
    showSelectButton?: boolean;
    systemNamePrefix?: string;
    onPackageSelected?: (pack: PackageViewModel) => void;
}

export const PackageSelectorItem: FC<PackageSelectorItemProps> = (props) => {
    const {
        colClasses,
        isSelected = false,
        leaseTimeType = SubscriptionDurationType.monthly,
        pack: { currentPrice, featureModules = [], description, title, trialPeriodInDays = 0 },
        showSelectButton = true,
        systemNamePrefix,
        onPackageSelected
    } = props;

    const { body, crossed, selectedBorder, selectedBody } = useStyles();
    const { getModelNameTranslation } = useRapidModel();

    const onButtonClicked = () => {
        if (onPackageSelected) {
            onPackageSelected(props.pack);
        }
    };

    const price = currentPrice.priceWithDiscount || currentPrice.priceWithoutTax;
    const trialPeriodInMonths = Math.ceil(trialPeriodInDays / 30);

    const getFeatureModuleEntityName = (entityName?: string) => {
        if (LanguageHelper.camelCase(entityName) === ProductResourceNames.product && systemNamePrefix?.startsWith('service')) {
            return `service${entityName}`;
        }

        return entityName;
    };

    return (
        <LamaCollapsibleCard
            classes={{
                body: classNames(body, 'text-center'),
                root: colClasses,
                block: classNames({ [selectedBorder]: isSelected, [selectedBody]: isSelected })
            }}
            onClick={onButtonClicked}
        >
            <h2 className="text-primary pricing-title">{title}</h2>
            {description && <p>{description}</p>}
            <div className="mb-25">
                {price === 0 ? (
                    <h2 className="amount-title">
                        <FormattedMessage id="shared.free" />
                    </h2>
                ) : (
                    <>
                        <h2 className={classNames('amount-title', { [crossed]: trialPeriodInDays > 0 })}>{moneyPipe(price.toString())}</h2>
                        <p className="text-dark">
                            / <FormattedMessage id={leaseTimeType === SubscriptionDurationType.yearly ? 'shared.year' : 'shared.month'} />
                        </p>
                    </>
                )}
                {trialPeriodInDays > 0 && (
                    <Chip
                        color="primary"
                        label={<FormattedMessage id="package.trialPeriodFreeDuration" values={{ itemCount: trialPeriodInMonths }} />}
                    />
                )}
            </div>
            <ul className="price-detail list-unstyled">
                {featureModules
                    .sort((a, b) => a.order - b.order)
                    .map((featureModule) => (
                        <li key={featureModule.ownerFeatureModule.id}>
                            {featureModule.ownerFeatureModule.maxQuantity}{' '}
                            {getModelNameTranslation(getFeatureModuleEntityName(featureModule.ownerFeatureModule.ownerEntityType?.name), {
                                itemCount: featureModule.ownerFeatureModule.maxQuantity
                            })}
                        </li>
                    ))}
            </ul>
            {showSelectButton && (
                <Button color="primary" className="btn-block text-white w-100" variant="contained" size="large" type="button">
                    <FormattedMessage id="shared.choose" />
                </Button>
            )}
        </LamaCollapsibleCard>
    );
};
