import React, { FC, useMemo } from 'react';
import { RawIntlProvider } from 'react-intl';
import { MuiThemeProvider } from '@material-ui/core/styles';

import { AlertsContainer, LamaAlertsProvider } from 'shared/packages/alerts';

import { langs, TranslationService } from 'lang';
import { primaryTheme } from './themes';
import { UtilityHelper } from 'shared/utilities';
import { LamaDialogProvider } from 'shared/packages/dialogs';

export const RctThemeProvider: FC = props => {
    const currentLocale = 'sl';
    const messages = useMemo(() => UtilityHelper.objectToDotNotation(langs[currentLocale]), [currentLocale]);

    const intl = TranslationService.createIntl(currentLocale, messages);

    return (
        <MuiThemeProvider theme={primaryTheme}>
            <RawIntlProvider value={intl}>
                <LamaDialogProvider >
                    <LamaAlertsProvider>
                        <AlertsContainer />
                        {props.children}
                    </LamaAlertsProvider>
                </LamaDialogProvider>
            </RawIntlProvider>
        </MuiThemeProvider>
    );
};
