import React, { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Label } from 'reactstrap';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';

import { LamaCollapsibleCard, LamaCollapsibleCardProps } from './LamaCollapsibleCard';

export interface LamaCollapsibleCardWithHeaderProps extends LamaCollapsibleCardProps {
    defaulContentCheckboxValue?: boolean;
    showContentCheckbox?: boolean;
    outsideHeaderText?: string;
    outsideHeaderTextTranslate?: string;
}

export const LamaCollapsibleCardWithHeader: FC<LamaCollapsibleCardWithHeaderProps> = props => {
    const { defaulContentCheckboxValue = true, showContentCheckbox = false, outsideHeaderText, outsideHeaderTextTranslate, ...other } = props;
    const [showContent, setShowContent] = useState(props.defaulContentCheckboxValue);

    const toggleShowContent = () => {
        setShowContent(!showContent);
    };

    if (showContentCheckbox || outsideHeaderText || outsideHeaderTextTranslate) {
        return (
            <div>
                <Box ml={-1}>
                    <Label>
                        <Checkbox checked={showContent} onChange={toggleShowContent} color="primary" />
                        {outsideHeaderText}
                        {outsideHeaderTextTranslate && <FormattedMessage id={outsideHeaderTextTranslate} />}
                    </Label>
                </Box>
                {showContent && <LamaCollapsibleCard {...other} />}
            </div>
        );
    }

    return <LamaCollapsibleCard {...other} />;
};
