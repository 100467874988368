import { GraphQlValueType } from "./utilities";

export const GRAPHQL_DEFAULT_PAGE_SIZE = 50;

export interface LamaGraphQlContextState {
    queryPrefixDotNotation?: string;
}

export interface LamaGraphQlValueWithType<TEntity = any, TGraphQlValueType = GraphQlValueType> {
    valueType: TGraphQlValueType;
    value: TEntity;
}

export enum LamaCrudOperationType {
    create = 'create',
    delete = 'delete',
    update = 'update'
}
