import React, { FC } from 'react';

import { SessionContainer } from 'features/authentication/components';

export const TermsOfUse: FC = () => {
    return (
        <SessionContainer hidePromo={true}>
            <div className="terms-of-use typography">
                <h2 className="h2">
                    <u>Pravila in pogoji uporabe za trgovce</u>
                </h2>
                <ol>
                    <li>
                        <h2 className="h2">Pravne informacije</h2>
                        <ol>
                            <li>
                                <p className="p">
                                    Ta dokument določa pravila in pogoje uporabe spletne strani www.prodajko.si (odslej »spletna stran«) in aplikacije
                                    Prodajko (odslej »aplikacija«) na podlagi katerih lahko naročnik postane član Prodajko platforme. Lastnik spletne
                                    strani in aplikacije je FS d.o.o. (odslej »Prodajko«, »mi« ali »nas«), z registriranim naslovom Ulica slovenske
                                    osamosvojitve 2, 2000 Maribor, Slovenija, davčna številka SI 97581208. Storitve ponuja FS d.o.o. Podjetje FS
                                    d.o.o. je ustanovljeno v skladu z zakoni Republike Slovenije. Kontaktirate nas lahko kadarkoli na
                                    info@prodajko.si.
                                </p>
                            </li>
                            <li>
                                <p className="p">
                                    Storitve Prodajko – trgovci so namenjene vsem podjetjem (d.o.o, s.p, zadruge, agencije, društva in ostale pravne
                                    oblike) v nadaljevanju »prodajalec«, ki so registrirana in imajo sedež v Sloveniji. Storitve so plačljive in
                                    razdeljene v več paketov, med katerimi lahko prodajalec izbira med postopkom registracije (izjema je paket
                                    »START«, ki je brezplačen).
                                </p>
                            </li>
                            <li>
                                <p className="p">
                                    V tem dokumentu uporabljeni izrazi imajo naslednji pomen:
                                    <ul>
                                        <li>»uporabnik«: vsak, ki vstopa, uporablja, prenaša in dostopa do spletne strani ali aplikacije</li>
                                        <li>»naročnik«: prodajalec, ki uporablja spletno stran ali aplikacijo</li>
                                        <li>»proizvodi iz druge roke«: rabljeni proizvodi</li>
                                        <li>
                                            »agent objavljanja«: tretja oseba, storitev ali posrednik, ki ponuja objavo vsebine na storitvi v imenu
                                            drugih
                                        </li>
                                    </ul>
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <h2 className="h2">Predmet</h2>
                        <ol>
                            <li>
                                <p className="p">
                                    Določitev pravil in pogojev uporabe, ki urejajo objavo artiklov oziroma izdelkov in obdelavo naročil, ki jih
                                    prodajalec sprejme na platformi Prodajko, katera uporabnikom, kot socialno tržišče, ponuja platformo za kupovanje
                                    in prodajo novih ali rabljenih proizvodov ter deluje v vlogi posrednika pri prodaji.
                                </p>
                            </li>
                            <li>
                                <p className="p">
                                    Ta pravila dopolnjujejo osnovna pravila in pogoje uporabe za navadne uporabnike. Ta dopolnitev velja za podjetja,
                                    združenja in osebe javnega prava ter razne organizacije (d.o.o, s.p, zadruge, agencije, društva in ostale pravne
                                    oblike).
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <h2 className="h2">Pregled vlog</h2>
                        <ol>
                            <li>
                                <p className="p">
                                    Storitev omogoča prodajalcu, da na Prodajko platformi, ki zajema spletno stran in mobilno aplikacijo Prodajko,
                                    objavi in prodaja svoje izdelke. Prodajalec lahko objavi le izdelke, ki ne spadajo med prepovedane (Natančen
                                    seznam prepovedanih izdelkov se nahaja v poglavju »Prepovedani izdelki«). Upoštevati je treba, da je Prodajko le
                                    platforma, ki omogoča hitrejše in lažje nakupe ter deluje kot posredniška storitev. Prodajko ni, v nobenem
                                    primeru, v vlogi kupca oziroma prodajalca.
                                </p>
                            </li>
                            <li>
                                <p className="p">
                                    V primeru, da se uporabnik Prodajko platforme odloči za nakup izdelka (bodisi preko spletne strani ali mobilne
                                    aplikacije) se pogodba o naročilu ali kupoprodajna pogodba izoblikuje izključno med prodajalcem, ki je izdelek
                                    objavil in uporabnikom (»kupec«). Prodajko ni stranka te pogodbe in ni odgovorna za to pogodbo, kot tudi ni
                                    odgovorna za kakršne koli spore, ki bi lahko izhajali iz le-te.
                                </p>
                            </li>
                            <li>
                                <p className="p">Prodajko platforma omogoča naslednje načine plačila oziroma prevzema naročenih izdelkov:</p>
                                <ul>
                                    <li>Osebni prevzem pri prodajalcu oziroma v eni izmed njegovih poslovalnic.</li>
                                    <li>Dostava po pošti in plačilo po prevzemu</li>
                                </ul>
                            </li>
                            <li>
                                <p className="p">
                                    Podatki prodajalca bodo vidni uporabnikom Prodajko platforme na njegovi predstavitveni strani (»Podatki o
                                    trgovini«).
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <h2 className="h2">Uveljavljanje garancije in reklamacij kupljenih izdelkov</h2>
                        <ol>
                            <li>
                                <p className="p">
                                    Prodajko ne prevzema nikakršne odgovornosti iz naslova garancije blaga in/ alireklamacije za izdelke, prodane
                                    preko aplikacije ali spletne strani Prodajko. Naročnik je dolžan objaviti svoje pogoje poslovanja, kateri morajo
                                    zajemati reševanje primerov uveljavljanja garancije in reklamacij. Prevzem se odvija izključno med kupcem in
                                    prodajalcem (»naročnik«), Prodajko zgolj omogoča uporabo aplikacije in deluje kot posrednik med kupcem in
                                    prodajalcem.
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <h2 className="h2">Lastnosti storitve Prodajko - trgovci</h2>
                        <ol>
                            <li>
                                <p className="p">
                                    Prodajko – trgovci omogoča prodajalcu nov večkanalni oglaševalski prostor za pospeševanje prodaje in promocijo
                                    svojih izdelkov. Prodajko platforma mu omogoča objavo in promoviranje izdelkov.
                                </p>
                            </li>
                            <li>
                                <p className="p">Storitev omogoča:</p>
                                <ul>
                                    <li>Objavo podatkov o podjetju/trgovini</li>
                                    <li>Sprejemanje naročil</li>
                                    <li>Vodenje in nadzor nad zalogo izdelkov</li>
                                    <li>Sistem za obveščanje ob prejemu novih sporočil ali naročil znotraj Prodajko platforme</li>
                                    <li>Obdelava naročil</li>
                                    <li>Chat funkcijo za neposreden stik s svojimi kupci</li>
                                    <li>Objava izdelkov</li>
                                    <li>Objava odpiralnega časa</li>
                                    <li>Pregled statistike</li>
                                    <li>Pregled uporabnikov, ki sledijo prodajalcu</li>
                                    <li>
                                        Možnost kontaktiranja prodajalca preko različnih načinov komunikacije (klic, sms, email, socialna omrežja,
                                        chat sistem)
                                    </li>
                                </ul>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <h2 className="h2">Naročniški paketi</h2>
                        <ol>
                            <li>
                                <p className="p">
                                    Funkcionalnosti, ki so naštete v poglavju 5 so razdeljene v različne pakete, katere lahko prodajalec zakupi glede
                                    na njegove potrebe. Paketi se lahko med seboj razlikujejo glede na število ponujenih funkcionalnostih. Vsi paketi
                                    so podrobno predstavljeni na naši spletni strani www.prodajko.si/trgovci/paketi.
                                </p>
                            </li>
                            <li>
                                <p className="p">
                                    Prodajalec za uporabo storitev Prodajko – trgovci plačuje mesečno naročnino. Višina plačila je odvisna od
                                    izbranega paketa oziroma nabora funkcionalnosti, ki jih paket ponuja. Edina izjema je paket “Start”, ki je
                                    brezplačen in je namenjen le osnovni predstavitvi. Prodajko platforma ponuja mesečne, polletne in letne naročnine.
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <h2 className="h2">Postopek registracije in začetek naročnine</h2>
                        <ol>
                            <li>
                                <p className="p">
                                    Podjetje se mora pri postopku registracije strinjati s pravili in pogoji uporabe za trgovce in vnesti naslednje
                                    podatke:
                                    <ul>
                                        <li>Naziv podjetja</li>
                                        <li>Naslov</li>
                                        <li>Kraj in poštna številka</li>
                                        <li>Davčna številka</li>
                                        <li>
                                            Podatki kontaktne osebe:
                                            <ul>
                                                <li>Ime</li>
                                                <li>Priimek</li>
                                                <li>Naslov</li>
                                                <li>Email</li>
                                                <li>Telefonska številka</li>
                                                <ul></ul>
                                            </ul>
                                        </li>
                                    </ul>
                                </p>
                            </li>
                            <li>
                                <p className="p">
                                    Začetek naročnine začne veljati takoj po uspešno zaključeni registraciji in plačilu mesečne naročnine. Po uspešni
                                    registraciji vas bo Prodajko kontaktiral (telefon, email), preveril pravilnost podatkov in vam predstavil dodatne
                                    informacije ter na elektronski naslov boste dobili pogodbo o naročnini. To pogodbo je potrebno podpisati in jo nam
                                    posredovati nazaj na naš email naslov: narocnine@prodajko.si
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <h2 className="h2">Plačevanje storitev</h2>
                        <ol>
                            <li>
                                <p className="p">
                                    Vsak prodajalec mora plačati naročnino za vsak koledarski mesec (izjema so prodajalci, ki uporabljajo paket
                                    Start). Mesečna naročnina se plača sproti, za tekoči koledarski mesec, in sicer do 10. v mesecu za tekoči mesec.
                                </p>
                            </li>
                            <li>
                                <p className="p">
                                    Prodajalec ima možnost plačila naročnine za daljša obdobja v obliki enkratnega plačila. Na takšen način lahko
                                    prodajalec, če se odloči, plača v enkratnem plačilu naročnino za 3, 6 ali 12 mesecev z možnostjo podaljšanja po
                                    poteku le-te.
                                </p>
                            </li>
                            <li>
                                <p className="p">Davek je vključen v končno ceno naročnine.</p>
                            </li>
                            <li>
                                <p className="p">
                                    Načini plačil s katerimi lahko prodajalec poravna stroške naročnine so:
                                    <ul>
                                        <li>
                                            <strong>Kreditne kartice ali PayPal:</strong>
                                            Naročnina se zaračuna z vnaprejšnjimi ponavljajočimi se plačili, ki se začnejo na datum začetka naročnine
                                            in nato mesečno za vsak nadaljnji koledarski mesec. Po uspešnem plačilu se prodajalcu izstavi račun za
                                            plačano naročnino na elektronski naslov, ki ga je vnesel ob registraciji.
                                        </li>
                                        <li>
                                            <strong>Plačilo po predračunu/položnici:</strong>
                                            Prodajalcu se ob uspešni registraciji izstavi predračun, katerega mora poravnati v roku 2 delovnih dni.
                                            Nadaljnje plačevanje mesečnih naročnin poteka preko položnic, katere Prodajko pošlje prodajalcu vsakega 1.
                                            v mesecu za tekoči koledarski mesec.
                                        </li>
                                    </ul>
                                </p>
                            </li>
                            <li>
                                <p className="p">
                                    Ko prodajalcu poteče naročnina, ga Prodajko o tem obvesti in mu naročnino samodejno podaljša. Prodajalec ima vedno
                                    možnost naročnino prekiniti pred samodejnim podaljšanjem.
                                </p>
                            </li>
                            <li>
                                <p className="p">
                                    Za kakršna koli dodatna vprašanja v zvezi z naročnino in plačili smo dosegljivi na elektronski naslov:
                                    narocnine@prodajko.si.
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <h2 className="h2">Cene storitev</h2>
                        <ol>
                            <li>
                                <p className="p">
                                    Cene storitev in paketov so navedene v ceniku oz. na spletni strani www.prodajko.si/trgovci/paketi.
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <h2 className="h2">Prepovedani izdelki in izjeme</h2>
                        <ol>
                            <li>
                                <p className="p">
                                    Na Prodajku je prepovedano prodajati kakršnekoli izdelke, ki so nezakoniti v okviru katerekoli veljavne
                                    zakonodaje, zakona, odloka ali predpisa, na lokalni, državni, in mednarodni ravni.
                                </p>
                            </li>
                            <li>
                                <p className="p">
                                    Primeri prepovedanih izdelkov:
                                    <ol>
                                        <li>Kri, telesne tekočine in deli telesa</li>
                                        <li>Orodje za krajo</li>
                                        <li>Nadzorovane kemične snovi (kot so kadmij, živo srebro, kisline)</li>
                                        <li>Ponarejen denar, znamke ali kovanci</li>
                                        <li>Ponarejeni izdelki</li>
                                        <li>Baze podatkov, ki vsebujejo osebne podatke</li>
                                        <li>Elektronska opreme za nadzor</li>
                                        <li>Prepovedane dobrine</li>
                                        <li>Vladne, uradne in tranzitne uniforme, izkaznice in licence</li>
                                        <li>Nezakonite droge in pripomočki</li>
                                        <li>Vse vrste nezakonitih storitev in izdelkov</li>
                                        <li>Hekerska orodja</li>
                                        <li>Nevarni materiali</li>
                                        <li>Ognjemeti, uničujoče naprave in eksploziv</li>
                                        <li>
                                            Osebni dokumenti, osebne finančne evidence in osebni podatki (v kakršni koli obliki, vključno z mailing
                                            liste).
                                        </li>
                                        <li>Predmete pridobljene z vladno pomočjo</li>
                                        <li>Predmeti, ki spodbujajo ali omogočajo nezakonito dejavnost</li>
                                        <li>Srečke, nagradne igre in avtomati</li>
                                        <li>Nespodoben material in vse vrste pornografije</li>
                                        <li>Vse vrste žaljivo gradivo</li>
                                        <li>Pesticidi</li>
                                        <li>Slike, ki vsebujejo frontalno goloto</li>
                                        <li>Značke in uniforme policije ali drugih varnostnih sil</li>
                                        <li>Zdravila na recept</li>
                                        <li>Iz prodaje poklicani/umaknjeni predmeti</li>
                                        <li>Delnice in druge vrednostne papirje</li>
                                        <li>Ukradena lastnina</li>
                                        <li>Tobačni izdelki (vključno pipe in e cigarete)</li>
                                        <li>Rabljeni kozmetika</li>
                                        <li>
                                            Orožje in z njimi povezani predmeti (kot strelno orožje, deli strelnega orožja in revije, streliva, BB
                                            pištole, solzivec, preklopni noži in orožje borilnih veščin).
                                        </li>
                                    </ol>
                                </p>
                            </li>
                            <li>
                                <p className="p">
                                    Izjeme:
                                    <ul>
                                        <li>
                                            Alkoholne pijače in živila: dovoljeno objavljati zgolj upoštevajoč omejitve Zakona o omejevanju porabe
                                            alkohola (ZOPA), Zakona o zdravstveni ustreznosti živil in izdelkov ter snovi, ki prihajajo v stik z
                                            živili (ZZUZIS) in Zakona o medijih (ZMed).
                                        </li>
                                        <li>
                                            Zdravila brez recepta in medicinski pripomočki: pri objavi zdravil in medicinskih pripomočkov se je
                                            potrebno držati in upoštevati določila Zakona o zdravilih in medicinskih pripomočkih (ZZMP) in Pravilnika
                                            o oglaševanju zdravil in medicinskih pripomočkov.
                                        </li>
                                    </ul>
                                </p>
                            </li>
                            <p className="p">
                                Prodajko si pridržuje pravico, da odstrani kakršnekoli objave, ki jih smatra za neprimerne. Naša politika temelji na
                                veljavni zakonodaji in regulativah, vendar se lahko v določnih primerih odločamo glede na mnenja uporabnikov in lastno
                                mnenje, še posebej kadar gre za nevarne in občutljive predmete.
                            </p>
                        </ol>
                    </li>
                    <li>
                        <h2 className="h2">Pravice in dolžnosti naročnika storitev Prodajko - trgovci</h2>
                        <ol>
                            <li>
                                <p className="p">
                                    Strinjate se, da spletno stran in aplikacijo ter storitve in vsebino uporabljate v skladu z zakonom, moralo in
                                    javnim redom ter v skladu z temi pravili in pogoji uporabe. Dolžni ste storitve in vsebino uporabljati temu
                                    primerno in ne za nezakonite aktivnosti, za izvajanje kaznivih dejanj, dejanj, ki kršijo pravice tretjih oseb
                                    in/ali kršijo Zakon o avtorski in sorodnih pravicah ali Zakon o industrijski lastnini ter katerakoli drugo pravilo
                                    veljavnih predpisov.
                                </p>
                            </li>
                            <li>
                                <p className="p">
                                    Sami ste odgovorni za interakcijo, ki jo vzpostavite z uporabniki in potencialnimi kupci vaših izdelkov in za vso
                                    vsebino, ki jo naložite, oddajate ali prenašate prek spletne strani ali aplikacije. Prav tako zagotavljate, da z
                                    naloženo vsebino ne kršite nobenih pravic tretjih oseb. Soglašate, da Prodajko v nobenem primeru ne odgovarja za
                                    ravnanja drugih uporabnikov. Prodajko prepoveduje uporabo avtomatizirane programske opreme in agentov objavljanja,
                                    posredno ali neposredno, brez izrecnega pisnega dovoljenja Prodajko. Poleg tega, agenti ne smejo objaviti vsebine
                                    v imenu drugih, neposredno ali posredno, ali kako drugače dostopati do storitev z namenom objave vsebine v imenu
                                    drugih, razen z izrecnim pisnim dovoljenjem ali +na podlagi sklenjene licenčne pogodbe sProdajko. Izraz "agent
                                    objavljanja" se nanaša na tretje osebe, storitev ali posrednika, ki ponuja objavo vsebine na storitvi v imenu
                                    drugih.
                                </p>
                            </li>
                            <li>
                                <p className="p">
                                    1.1 Strinjate se, da ne boste prenašali, vnašali, razširjali in omogočali tretjim osebam nobene vrste materiala in
                                    informacij (podatki, vsebine, sporočila, risbe, zvočne datoteke in slike, fotografije, programska oprema, itd), ki
                                    so v nasprotju z zakonom, moralo, javnim redom in temi pravili in pogoji uporabe. Strinjate se, da NE BOSTE:
                                    <ul>
                                        <li>
                                            Vnašali in razširjali vsebine, ki bi lahko bila rasistična, ksenofoba, pornografska ali v nasprotju z
                                            človekovimi pravicami.
                                        </li>
                                        <li>
                                            Vnašali ali razširjali programov ali podatkov (virusov in zlonamerne programske opreme), ki lahko
                                            povzročijo škodo na računalniških sistemih ponudnika dostopa, njegovih dobaviteljev ali drugih uporabnikov
                                            interneta.
                                        </li>
                                        <li>
                                            Širili, pošiljali ali omogočali tretjim osebam nobenih informacij, elementov ali vsebine, ki krši temeljne
                                            pravice in državljanske svoboščine, priznane v mednarodnih pogodbah.
                                        </li>
                                        <li>
                                            Širili, pošiljali ali omogočali tretjim osebam nobenih informacij, elementov ali vsebine, ki pomeni
                                            nezakonito in nepošteno oglaševanje.
                                        </li>
                                        <li>
                                            Vnašali ali razširjali nobenih lažnih, nepravilnih informacij ali vsebine, ki bi prejemnika lahko
                                            zavajale.
                                        </li>
                                        <li>
                                            Širili, pošiljali ali omogočali nobenih informacij, elementov ali vsebin, ki vključujejo kršenje avtorske
                                            pravice in drugih pravic industrijske lastnine, ki pripadajo Prodajko ali tretjim osebam.
                                        </li>
                                        <li>
                                            Pregledovali, zbirali, shranjevali ali dostopali do podatkovne baze Prodajko ali zbirali vse ali del
                                            podatkovne zbirke oglasov in uporabnikov Prodajko.
                                        </li>
                                        <li>
                                            Strinjate se, da Prodajko ne boste oškodovali zaradi morebitnega zahtevka, globe ali kazni, ki lahko
                                            izhajajo iz vaše kršitve katerega od pravil, navedenega v tem dokumentu. Prodajko ima pravico zahtevati
                                            odškodnino za škodo, ki je posledica vašega protipravnega ravnanja
                                        </li>
                                    </ul>
                                </p>
                            </li>
                            <li>
                                <p className="p">
                                    Prodajko posveča veliko pozornosti kontroli podatkov in druge vsebine, ki se nahaja na spletni strani in
                                    aplikaciji. Če kljub temu najdete napako ali nepopolnost, vas prosimo, da nas o tem obvestite na e-naslov
                                    info@prodajko.si . Prodajko bo odgovorili v najkrajšem možnem času.
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <h2 className="h2">Pravice in dolžnosti ponudnika storitev Prodajko - trgovci</h2>
                        <ol>
                            <li>
                                <p className="p">
                                    Prodajko se bo po svojih najboljših močeh trudil in zagotavljal nemoteno delovanje vseh storitev Prodajko –
                                    trgovci, vendar ne odgovarja za nobene posledice delnega ali popolnega izpada ali za kakršnokoli prekinitev
                                    delovanja storitev.
                                </p>
                            </li>
                            <li>
                                <p className="p">
                                    Prodajko sme kadarkoli prekiniti delovanje storitev Prodajko – trgovci, če je to potrebno zaradi izvajanja
                                    vzdrževalnih del ali drugih tehničnih razlogov vendar le ob predhodnem obvestilu vseh prodajalcev na njihov
                                    elektronski naslov.
                                </p>
                            </li>
                            <li>
                                <p className="p">
                                    Prodajko sme, brez predhodnega obvestila prekiniti delovanje storitev Prodajko – trgovci, če je to potrebno zaradi
                                    izvajanja vzdrževalnih del ali drugih tehničnih razlogov le v naslednjih primerih:
                                    <ul>
                                        <li>Nujna in kritična dela, ki zahtevajo takojšnjo vzdrževanje</li>
                                        <li>Kritične varnostne nadgradnje</li>
                                        <li>Vsa dela, ki se izvajajo med 21:00 in 6:00 uro</li>
                                    </ul>
                                </p>
                            </li>
                            <li>
                                <p className="p">
                                    Prodajko iz zgoraj navedenih razlogov naročnikom ni odgovoren ne odškodninsko niti kako drugače in zgoraj navedeni
                                    razlogi ne predstavljajo dejstva na strani Prodajko, ki bi lahko pomenilo, da pogodba ni izpolnjena.
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <h2 className="h2">Obveščanje in snemanje podatkov</h2>
                        <ol>
                            <li>
                                <p className="p">
                                    V kolikor menite, da je bilo vaše z avtorskimi pravicami zaščiteno delo objavljeno na Prodajko brez pooblastila,
                                    lahko pošljete obvestilo o kršitvi avtorskih pravic. Te zahteve lahko predloži samo imetnik avtorskih pravic ali
                                    zastopnik, pooblaščen za ukrepanje v imenu imetnika. Najhitrejši in najlažji način, da obvesti Prodajko o domnevni
                                    kršitvi avtorskih pravic, je prek našega e-mail naslova: info@prodajko.si.
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <h2 className="h2">Ničnost in neučinkovitost klavzul</h2>
                        <ol>
                            <li>
                                <p className="p">
                                    Če je katerakoli določba vključena v sedanjih Pravilih in pogojih uporabe ugotovljenav celoti ali delno kot
                                    neveljavna ali neučinkovita, bo to vplivalo samo na to določbo ali del odločbe, ki se šteje za neveljaven ali
                                    neučinkovit. Ostali del Pravil in pogojev uporabe ostane nedotaknjen oziroma v celoti v veljavi, izključi se samo
                                    ta določba.
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <h2 className="h2">Veljavna zakonodaja in pristojnost</h2>
                        <ol>
                            <li>
                                <p className="p">
                                    Ti pogoji se urejajo in razlagajo v skladu z zakonodajo v Sloveniji. Prodajalec in Prodajko se strinjata, da se
                                    vsak morebitni spor, ki bi lahko nastal med prodajalcem in Prodajkom, pri uporabi aplikacije in spletne strani ter
                                    je predmet teh pogojev rešuje sporazumno. V kolikor spora na ta način ni mogoče rešiti se ga prepusti v reševanje
                                    pristojnemu sodišču v Mariboru.
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <h2 className="h2">Spletna stran, aplikacija in storitve, ki jih zajema ta pravilnik</h2>
                        <ol>
                            <li>
                                <p className="p">
                                    Trenutno vodimo spletno stran www.prodajko.si ("spletna stran"), kot tudi mobilno aplikacijo, skupaj (»storitve«).
                                    Od časa do časa, lahko dodamo nove storitve, ki niso navedene zgoraj, vendar bodo vsebovale povezavo do teh Pravil
                                    in pogojev uporabe in veljali bodo isti pogoji. Ta Pravila in pogoji uporabe zajemajo vašo uporabo Prodajka, kot
                                    tudi aktivnosti, ki jih Prodajko opravlja na vaših vsebinah, vaših osebnih podatkih in drugih podatkih, ki jih
                                    Prodajko zbira, ko dostopate do storitve.
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <h2 className="h2">Informacije javnega značaja</h2>
                        <ol>
                            <li>
                                <p className="p">
                                    Strinjate se, da se lahko vaši podatki (naziv podjetja, naslov, odpiralni čas, aktualna ponudba, akcije in
                                    popusti) objavijo in so na voljo uporabnikom Prodajko platforme. Za objavljeno vsebino in točnost navedenih
                                    podatkov ste sami odgovorni. Če želite urediti, spremeniti ali podatke izbrisati, sledite navodilom na naših
                                    storitvah.
                                </p>
                            </li>
                            <li>
                                <p className="p">
                                    Prav tako soglašate, da Prodajko brezplačno uporabi vaše objavljene izdelke, naziv trgovine (vključno z naloženo
                                    fotografijo, sliko ali druge podatke – tudi ime in lokacija) za reklamne in promocijske namene tudi na drugih
                                    socialnih medijih, Facebook oglasi, novice, objave v revijah, članki ali drugi oglaševalski kanali. Objavo teh
                                    informacij lahko prodajalec prekliče kadarkoli s pisnim zahtevkom, katerega pošlje na elektronski naslov
                                    info@prodajko.si.
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <h2 className="h2">E-mail novice in promocije</h2>
                        <ol>
                            <li>
                                <p className="p">
                                    1.1 S sprejetjem naših Pravil in pogojev uporabe dajete izrecno soglasje, da lahko Prodajko stopa z vami v stik
                                    in/ali vam pošilja komercialne vsebine prek kateregakoli sredstva, vključno z elektronskimi komunikacijami, za
                                    obveščanje o naših izdelkih, storitvah, ponudbah, ali komercialne vsebine, povezane z podjetjem, ki so po presoji
                                    Prodajko lahko za vas zanimive. Če ne želite prejemati reklamnih sporočil, se lahko vedno odjavite po navodilih,
                                    ki so v vsakem takem sporočilu. Da se odjavite od komercialnih sporočil za vse medije, vključno z drugih družabnih
                                    omrežij, se kadarkoli prijavite na spletni strani/aplikaciji in prilagodite svoje uporabniške nastavitve v računu,
                                    tako da počistite ustrezna polja. Upoštevajte, da tudi če se odjavite od prejemanja komercialnih sporočil, lahko
                                    od Prodajka še vedno prejmete administrativna sporočila, kot so potrdila o transakciji, obvestila o dejavnosti
                                    računa (npr. potrditve računa, spremembe gesla, itd.), in vse druge pomembne objave.
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <h2 className="h2">Informacije o piškotkih in povezanih tehnologijah</h2>
                        <ol>
                            <li>
                                <p className="p">
                                    Prodajko storitve lahko vsebujejo piškotke. Piškotke lahko uporabljamo za upravljanje sej naših uporabnikov, za
                                    shranjevanje nastavitev, podatke o sledenju in izbiro jezika. Piškotki se lahko uporabljajo, če se registrirate
                                    pri nas ali ne. "Piškotki" so majhne besedilne datoteke, ki jih spletni strežnik prenesene na trdi disk, nato pa
                                    so shrani na računalnik. Informacije, ki jih piškotek zbira, vsebujejo datum in čas, ko ste nas obiskali,
                                    zgodovino brskanja, nastavitve in uporabniško ime. V nekaterih primerih lahko naši tretjeosebni ponudniki storitev
                                    uporabljajo piškotke na spletni strani. Ne moremo nadzorovati ali dostopati do piškotkov, ki jih uporablja
                                    tretjeosebni ponudniki storitev. Ta Pravila in pogoji uporabe pokrivjo le piškotke, ki se uporabljajo z naše
                                    strani in ne vse piškotke s strani tretjih oseb. Imate možnost, da bodisi sprejmete ali zavrnete uporabo piškotkov
                                    na spletni strani (če ste že registrirani pri nas ali ne), tako da kliknete se/ne strinjam na pojavnem oknu
                                    povezanem z uporabo piškotkov. Običajno lahko nastavite vaš brskalnik, da ne sprejema piškotkov. Vendar pa lahko
                                    zavrnjena uporaba piškotkov omeji dostop do nekaterih funkcij na spletni strani. Na primer, lahko imate težave pri
                                    prijavi ali uporabi nekaterih interaktivnih funkcij spletne strani.
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <h2 className="h2">Predanost, da zavarujemo zbrane osebne podatke</h2>
                        <ol>
                            <li>
                                <p className="p">
                                    Zavezujemo se, da bomo uporabljali le najnaprednejše varnostne ukrepe, potrebne za varnost in zasebnost osebnih
                                    podatkov, Nobena spletna stran ali internet ni popolnoma varna, zato Prodajko ne more zagotoviti, da do
                                    nepooblaščenega dostopa, vdora, izgube podatkov ali druge kršitve ne bo nikoli prišlo. Aplikacije in storitve
                                    uporabljate na lastno odgovornost. Prodajko vas poziva, da varujete svoje osebne podatke, tako da si zapomnite
                                    geslo in ga hranite na varnem mestu.
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <h2 className="h2">Obvestilo o spremembah Pravil in pogojev uporabe</h2>
                        <ol>
                            <li>
                                <p className="p">
                                    Prodajko si pridržuje pravico, da spremeni ta Pravila in pogoje kadarkoli in o tem obvesti vse prodajalce preko
                                    e-pošte ali jih objavi na storitvah. Vse spremembe pravil in pogojev veljajo od objave naprej in vaša nadaljnja
                                    uporaba Prodajko storitev po spremembi bo pomenila, da sprejmete in soglašate s temi spremembi.
                                </p>
                            </li>
                        </ol>
                    </li>
                </ol>
            </div>
        </SessionContainer>
    );
};
