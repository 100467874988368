import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { createStyles, fade, makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { ImportRowErrorViewModel } from 'features/imports/models';

import { ImporterErrorItem } from './ImporterErrorItem';

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            backgroundColor: fade(theme.palette.error.main, 0.2),
            border: `1px solid ${theme.palette.error.main}`,
            marginBottom: theme.spacing(3),
            marginTop: theme.spacing(2),
            padding: theme.spacing(2)
        },
        header: {
            color: theme.palette.error.main,
            marginBottom: theme.spacing(3)
        }
    })
);

interface ImporterErrorsProps {
    errors: ImportRowErrorViewModel[];
}

export const ImporterErrors: FC<ImporterErrorsProps> = (props) => {
    const { errors } = props;

    const classes = useStyles();

    if (!errors?.length) {
        return null;
    }

    return (
        <Box className={classes.root}>
            <Typography className={classes.header} component="h3">
                <strong>
                    <FormattedMessage id="import.errors.header" />
                </strong>
            </Typography>
            {errors.map((item) => (
                <ImporterErrorItem key={`${item.destinationProperty}_${item.errorKey}_${item.rowIndex}`} item={item} />
            ))}
        </Box>
    );
};
