import React, { ChangeEvent } from 'react';
import { RcFormlyField } from '@rc-formly/core';

import { TemplateOptionsExtended } from 'shared/components/forms/models';

import { LamaCheckbox } from 'shared/components/forms/components/LamaCheckbox';

export class LamaCheckboxType extends RcFormlyField<TemplateOptionsExtended> {
    public onChange = (event: ChangeEvent<HTMLInputElement>, value: boolean) => {
        this.handleChange(value);
    };

    render() {
        const { disabled, label, labelComponent, labelTranslate } = this.to || {};

        const value = this.getFieldValue();

        return (
            <LamaCheckbox
                disabled={disabled}
                name={this.getFieldKey()}
                label={label}
                labelTranslate={labelTranslate}
                labelComponent={labelComponent}
                onChange={this.onChange}
                checked={value}
            />
        );
    }
}
