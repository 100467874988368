import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { CategoriesSelector } from './CategoriesSelector';
import { CategoryViewModel } from 'features/categories/models';

const useStyles = makeStyles(() =>
    createStyles({
        dialogPaper: {
            minHeight: '80vh',
            maxHeight: '80vh'
        }
    }));

interface CategoriesSelectorModalProps {
    selectedCategories?: CategoryViewModel[];
    isOpen: boolean;
    onClose: () => void;
    onSelectedCategoryId: (categoryId: string) => void;
}

export const CategoriesSelectorModal: FC<CategoriesSelectorModalProps> = props => {
    const { selectedCategories, isOpen, onClose, onSelectedCategoryId } = props;

    const styles = useStyles();

    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog classes={{ paper: styles.dialogPaper }} fullWidth maxWidth="md" open={isOpen} onClose={handleClose} aria-labelledby="max-width-dialog-title">
            <DialogTitle id="max-width-dialog-title">
                <FormattedMessage id="category.selector.chooseCategory" />
            </DialogTitle>
            <DialogContent>
                <CategoriesSelector selectedCategories={selectedCategories} onSelectedCategoryId={onSelectedCategoryId}  />
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleClose} color="primary">
                    <FormattedMessage id="shared.cancel" />
                </Button>
            </DialogActions>
        </Dialog>
    );
};

