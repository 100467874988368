import { BaseEntity } from 'shared/types';

export enum LocationFormlyTypes {
    placeSelector = 'lm-place-selector'
}

export interface LocationViewModel extends BaseEntity {
    fullAddress: string;
    address?: string;
    streetNumber?: string;
    city?: string;
    postalCode?: string;
    country?: string;
    latitude: number;
    longitude: number;
}
