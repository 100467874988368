import { ModularEntry } from 'shared/packages/react-modular';
import { RouteDefaultData } from 'features/base/models';
import { LamaCrudViewType } from 'shared/types';

import { ContractMe } from './routes';
import { ContractResourceNames } from './models';

export const contractModule: ModularEntry<RouteDefaultData> = {
    name: 'contracts',
    routes: [/*
        {
            header: 'menu.sidebarGroupTitle',
            name: 'contractsMain',
            icon: 'material-icons business',
            titleTranslate: 'contract.sidebarTitle',
            routeProps: {
                path: '/contracts'
            },
            routes: [
                {
                    header: 'menu.sidebarGroupTitle',
                    name: 'contractsList',
                    titleTranslate: 'contract.listSidebarTitle',
                    data: {
                        crudViewType: LamaCrudViewType.list,
                        primaryModelName: ContractResourceNames.contract
                    },
                    routeProps: {
                        component: TenantList,
                        path: '/list'
                    }
                },
                {
                    header: 'menu.sidebarGroupTitle',
                    name: 'contractsCreate',
                    titleTranslate: 'contract.createSidebarTitle',
                    data: {
                        crudViewType: LamaCrudViewType.create,
                        primaryModelName: ContractResourceNames.contract
                    },
                    routeProps: {
                        component: TenantCreateOrUpdate,
                        path: `/${LamaCrudViewType.create}`
                    }
                },
                {
                    header: 'menu.sidebarGroupTitle',
                    name: 'contractsUpdate',
                    titleTranslate: 'contract.updateSidebarTitle',
                    data: {
                        crudViewType: LamaCrudViewType.update,
                        hideOnSidebar: true,
                        primaryModelName: ContractResourceNames.contract
                    },
                    routeProps: {
                        component: TenantCreateOrUpdate,
                        path: `/:id/${LamaCrudViewType.update}`
                    }
                }
            ]
        },*/
        {
            header: 'menu.sidebarGroupTitle',
            name: 'tenantMe',
            icon: 'material-icons business_center',
            titleTranslate: 'packageContract.sidebarMeTitle',
            data: {
                crudViewType: LamaCrudViewType.me,
                primaryModelName: ContractResourceNames.contract
            },
            routeProps: {
                component: ContractMe,
                path: '/me/contract'
            }
        }
    ]
};
