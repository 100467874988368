import { DocumentNode, GraphQLError } from 'graphql';

import { KeyValueObject } from 'shared/types';
import { GraphQlQueryVariables, GraphQlQueryType } from './utilities';

export enum GraphQlErrorTypes {
    conflict = '409',
    databaseUniqueConstrain = 'DATABASE_UNIQUE_CONSTRAINT_EXCEPTION'
}

interface LamaGraphQlErrorOptions {
    graphQLErrors: ReadonlyArray<GraphQLError>;
    message: string;
    messageTranslate?: string;
    messageTranslateParams?: KeyValueObject;
    stack?: string;
}

export class LamaGraphQlError extends Error {
    public graphQLErrors: ReadonlyArray<GraphQLError>;
    public messageTranslate: string;
    public messageTranslateParams: KeyValueObject;
    public stack?: string;

    constructor(options: LamaGraphQlErrorOptions) {
        super(options.message);

        this.graphQLErrors = options.graphQLErrors
        this.messageTranslate = options.messageTranslate || '';
        this.messageTranslateParams = options.messageTranslateParams || {};
        this.stack = options.stack;
    }
}

export interface LamaServerOptions {
    gqlQuery?: DocumentNode
    idPropertyDotNotation?: string;
    loadAdditionalProperties?: string[];
    modelName: string;
    queryType?: GraphQlQueryType;
    variables?: GraphQlQueryVariables | KeyValueObject;
}

export interface PaginationConnectionEdge<TEntity = any> {
    cursor: string;
    node: TEntity;
}

export interface PaginationConnectionPageInfo {
    endCursor: string;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    startCursor: string;
}

export interface PaginationConnection<TEntity = any> {
    edges?: PaginationConnectionEdge[];
    nodes?: TEntity[];
    pageInfo: PaginationConnectionPageInfo;
    totalCount: number;
}

export interface PaginationDecodedCursor {
    __totalCount: number,
    __position: number;
}

export enum SortOperationKind {
    asc = 'ASC',
    desc = 'DESC'
}
