
import {
    LamaFormlyFieldConfig,
    LamaFormlyWrappers,
    LamaFormlyTypes,
    LamaEnumRadioGroupTypeTemplateOptions
} from 'shared/components/forms/models';
import { PackageType } from 'features/packs/models';

import { priceWithoutTaxFormField } from 'features/products/forms';

export const usePackageFormFields = (): LamaFormlyFieldConfig[] => {
    return [
        {
            wrappers: [LamaFormlyWrappers.card, LamaFormlyWrappers.submitButton],
            fieldGroup: [
                {
                    key: 'type',
                    type: LamaFormlyTypes.enumRadioGroup,
                    wrappers: [LamaFormlyWrappers.formGroup],
                    templateOptions: {
                        labelTranslate: 'package.type',
                        enumRadioGroupProps: {
                            enumType: PackageType,
                            translatePrefix: 'package.packageType'
                        },
                        required: true
                    } as LamaEnumRadioGroupTypeTemplateOptions
                },
                {
                    key: 'autoRenew',
                    type: LamaFormlyTypes.checkbox,
                    templateOptions: {
                        labelTranslate: 'package.autoRenew'
                    }
                },
                {
                    key: 'trialPeriodInDays',
                    type: LamaFormlyTypes.input,
                    wrappers: [LamaFormlyWrappers.formGroup],
                    templateOptions: {
                        labelTranslate: 'package.trialPeriodInDays',
                        type: 'number'
                    }
                },
                {
                    key: 'title',
                    type: LamaFormlyTypes.input,
                    wrappers: [LamaFormlyWrappers.formGroup],
                    templateOptions: {
                        labelTranslate: 'package.title',
                        required: true
                    }
                },
                {
                    key: 'systemName',
                    type: LamaFormlyTypes.input,
                    wrappers: [LamaFormlyWrappers.formGroup],
                    templateOptions: {
                        labelTranslate: 'package.systemName'
                    }
                },
                {
                    key: 'description',
                    type: LamaFormlyTypes.editor,
                    wrappers: [LamaFormlyWrappers.formGroup],
                    templateOptions: {
                        labelTranslate: 'shared.description'
                    }
                }
            ]
        },
        priceWithoutTaxFormField
    ];
};
