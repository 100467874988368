import React, { FC } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { LamaGridCellProps } from 'shared/components/grid/types';
import { MediaSizeType } from 'features/media/models';

import { MediaImage } from './MediaImage';

import { UtilityHelper } from 'shared/utilities';

const useStyles = makeStyles((theme) =>
    createStyles({
        image: {
            height: `80px`,
            width: '80px'
        }
    }));

export const PictureCellType: FC<LamaGridCellProps> = props => {
    const { column } = props;
    let {cellValue} = props;

    const styles = useStyles();

    if (UtilityHelper.isEmpty(cellValue)) {
        return <MediaImage classes={{ root: styles.image, image: styles.image }} mediaSizeType={MediaSizeType.thumbnail} />
    }

    if (UtilityHelper.isArray(cellValue)) {
        cellValue = cellValue[0];
    }

    return <MediaImage classes={{ root: styles.image, image: styles.image }} media={cellValue} mediaSizeType={MediaSizeType.thumbnail} />
};
