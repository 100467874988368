import { useContext } from 'react';

import { useLamaGridCreatorInterface } from "./types";

import { LamaGridContext } from './LamaGridProvider';

export const useLamaGridCreator: useLamaGridCreatorInterface = () => {
    const {
        addGridCellType, addGridCellTypes, addGridFilterType, addGridFilterTypes,
        cellTypes, filterTypes, addGridInstance, removeGridInstance
    } = useContext(LamaGridContext);

    const getGridCellType = (cellType: string, gridType?: string) => {
        const foundCellTypes = cellTypes.filter(q => q.cellType === cellType);

        if (foundCellTypes.length > 1) {
            return foundCellTypes.find(q => q.gridType === gridType);
        }
        else {
            return foundCellTypes[0];
        }
    };

    const getGridFilterType = (cellType: string, gridType?: string) => {
        const foundFilterTypes = filterTypes.filter(q => q.cellType === cellType);

        if (foundFilterTypes.length > 1) {
            return foundFilterTypes.find(q => q.gridType === gridType);
        }
        else {
            return foundFilterTypes[0];
        }
    };

    return {
        addGridInstance,
        addGridCellType,
        addGridCellTypes,
        addGridFilterType,
        addGridFilterTypes,
        getGridCellType,
        getGridFilterType,
        removeGridInstance
    };
};
