import React, { FC } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import { MediaPicture } from 'features/media/components';
import { LamaGradient } from 'shared/components';

import { images } from 'assets';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            borderRadius: '5px'
        },
        image: {
            height: 600
        },
        gradient: {
            borderBottomLeftRadius: '5px',
            borderBottomRightRadius: '5px',
            bottom: 0,
            display: 'flex',
            left: 0,
            right: 0,
            top: '50%',
            padding: '30px',
            position: 'absolute'
        },
        text: {
            color: 'white'
        }
    })
);

export const SessionImagePromo: FC = () => {
    const classes = useStyles();

    return (
        <Box position="relative">
            <MediaPicture classes={classes} src={images.promo} />
            <LamaGradient className={classes.gradient} firstColor="rgba(0,0,0,0)" secondColor="#000">
                <Box alignSelf="flex-end">
                    <h2 className={classes.text}>
                        <FormattedMessage id="authentication.promo.header" />
                    </h2>
                    <p className={classes.text}>
                        <FormattedMessage id="authentication.promo.subHeader" />
                    </p>
                    <p className={classes.text}>
                        <FormattedMessage id="authentication.promo.description" />
                    </p>
                </Box>
            </LamaGradient>
        </Box>
    );
};
