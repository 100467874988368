import { RcFormlyConfig } from '@rc-formly/core';

import { LamaFormlyTypes, LamaFormlyWrappers } from './models';

import {
    InputType,
    LamaAdvancedEnumRadioGroupType,
    LamaCheckboxType,
    LamaDatePickerType,
    LamaEditorType,
    LamaEnumRadioGroupType,
    LamaMoneyInputType,
    LamaSelectType,
    LamaMultipleAddLargeType,
    LamaNumberInputType,
    LamaSimpleListType,
    LamaMultipleAddSmallType,
    LamaCheckboxGroupType
} from './types';

import {
    CheckboxFormGroupWrapper,
    LamaCollapsibleCardWrapper,
    LamaDivWrapper,
    LamaRowWrapper,
    FormGroupWrapper,
    SubmitButtonWrapper
} from './wrappers';

import {
    maxLengthValidatorMessage,
    minLengthValidatorMessage,
    requiredValidatorMessage,
} from './validatorMessages';


RcFormlyConfig.addConfig({
    types: [
        {
            component: LamaAdvancedEnumRadioGroupType,
            name: LamaFormlyTypes.advancedEnumRadioGroup
        },
        {
            component: LamaCheckboxGroupType,
            name: LamaFormlyTypes.checkboxGroup
        },
        {
            component: LamaCheckboxType,
            name: LamaFormlyTypes.checkbox
        },
        {
            component: LamaEditorType,
            name: LamaFormlyTypes.editor
        },
        {
            component: LamaEnumRadioGroupType,
            name: LamaFormlyTypes.enumRadioGroup
        },
        {
            component: LamaMoneyInputType,
            name: LamaFormlyTypes.moneyInput,
        },
        {
            component: InputType,
            name: LamaFormlyTypes.input
        },
        {
            component: LamaDatePickerType,
            name: LamaFormlyTypes.datePicker
        },
        {
            component: LamaMultipleAddLargeType,
            name: LamaFormlyTypes.multipleLargeAdd
        },
        {
            component: LamaMultipleAddSmallType,
            name: LamaFormlyTypes.multipleSmallAdd
        },
        {
            component: LamaNumberInputType,
            name: LamaFormlyTypes.numberInput
        },
        {
            component: LamaSimpleListType,
            name: LamaFormlyTypes.simpleList
        },
        {
            component: LamaSelectType,
            name: LamaFormlyTypes.select
        }
    ],
    wrappers: [
        {
            component: CheckboxFormGroupWrapper,
            name: LamaFormlyWrappers.checkboxFormGroup
        },
        {
            component: LamaCollapsibleCardWrapper,
            name: LamaFormlyWrappers.card
        },
        {
            component: LamaDivWrapper,
            name: LamaFormlyWrappers.div
        },
        {
            component: FormGroupWrapper,
            name: LamaFormlyWrappers.formGroup
        },
        {
            component: LamaRowWrapper,
            name: LamaFormlyWrappers.row
        },
        {
            component: SubmitButtonWrapper,
            name: LamaFormlyWrappers.submitButton
        }
    ],
    validatorMessages: [
        maxLengthValidatorMessage,
        minLengthValidatorMessage,
        requiredValidatorMessage
    ]
});

export { LamaAutocomplete, LamaDatePicker, LamaDateRangePicker } from './components'
export { LamaForm } from './LamaForm';
