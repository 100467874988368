import { BaseEntity, KeyValueObject, ContextualColor } from 'shared/types';
import { ProductStatus, ProductType } from 'features/products/models';
import { LamaGridTagColumnAdditionalData, LamaGridEnumColumnAdditionalData } from 'shared/components/grid';

export enum ImportStatus {
    canceled = 'CANCELED',
    error = 'ERROR',
    enqueued = 'ENQUEUED',
    inProgress = 'INPROGRESS',
    none = 'NONE',
    reverting = 'REVERTING',
    reverted = 'REVERTED',
    reviewing = 'REVIEWING',
    started = 'STARTED',
    finished = 'FINISHED',
    finishedWithWarnings = 'FINISHEDWITHWARNINGS'
}

export enum ImportSchemaItemPropertyType {
    boolean = 'boolean',
    category = 'category',
    decimal = 'decimal',
    double = 'double',
    html = 'html',
    link = 'link',
    string = 'string',
    unknown = 'unknown'
}

export enum ImportSchemaItemType {
    category = 'category',
    doNotMap = 'doNotMap',
    field = 'field',
    media = 'media',
    location = 'location',
    identityField = 'identityField',
    titleField = 'titleField'
}

export interface ProductImportViewModel {
    description: string;
    title: string;

    priceWithTax: number;
    priceWithTaxAndDiscount: number;

    address: string;
    city: string;
    country: string;
    postalCode: string;

    latitude: number;
    longitude: number;

    SKU: string;
    vendorUrl: string;

    status: ProductStatus;
    type: ProductType;

    ownerCategoryId: string;
    ownerCategoryTitle: string;

    files: string[];
    onVenues: string[];
}

export interface ImportCancelCommand {
    importId: string;
}

export interface ImportGlobalSettingsViewModel {
    defaultProductStatus?: ProductStatus;
    defaultProductType?: ProductType;

    defaultVenueIds?: string[];
}

export interface ImportMapperPropertyViewModel {
    destinationPropertyName: string;
    sourcePropertyName: string;

    propertyValueType: ImportSchemaItemPropertyType;
    itemType: ImportSchemaItemType;
}

export interface ImportMapperResultPropertyViewModel extends ImportMapperPropertyViewModel {
    required: boolean;
}

export interface ImportMapperLabelPropertyViewModel extends ImportMapperResultPropertyViewModel {
    label: string;
}

export interface ImportRowErrorViewModel {
    destinationProperty?: string;
    errorKey: string;
    rowIndex?: number;

    parameters?: KeyValueObject;
}

export interface ImportSchemaViewModel extends BaseEntity {
    items: ImportMapperResultPropertyViewModel[];
}

export interface Import extends BaseEntity {
    delimiter: string;
    numberOfItems: number;
    stopOnError: boolean;

    defaultProductStatus?: ProductStatus;
    defaultProductType?: ProductType;
    status: ImportStatus;
}

export interface ImportViewModel {
    file: File;
    globalSettings?: ImportGlobalSettingsViewModel;

    mappedCategories?: ImportMapperPropertyViewModel[];
    mappedFields?: ImportMapperPropertyViewModel[];

    bestImportSchemaId?: string;
}

export interface ImportCheckResultViewModel {
    errors?: ImportRowErrorViewModel[];
    exampleProducts?: ProductImportViewModel[];
    notMappedCategories?: ImportMapperResultPropertyViewModel[];
    notMappedFields?: ImportMapperResultPropertyViewModel[];
    notMappedSourcePropertyNames?: string[];
    numberOfFieldDifferences: number;
    numberOfCategoryDifferences: number;
    numberOfFoundProducts: number;
    numberOfRequiredFieldDifferences: number;

    bestImportSchema: ImportSchemaViewModel;
}

export const productStatusGridAdditionalData: LamaGridEnumColumnAdditionalData = {
    enumType: ImportStatus,
    translationPrefix: 'import.importStatus'
};

export const importStatusGridColorAdditionalData: LamaGridTagColumnAdditionalData = {
    ...productStatusGridAdditionalData,
    colorForEnums: new Map<any, ContextualColor>(
        [
            [ImportStatus.finished, 'success'],
            [ImportStatus.finishedWithWarnings, 'warning'],
            [ImportStatus.error, 'error'],
            [ImportStatus.enqueued, 'default'],
            [ImportStatus.canceled, 'warning'],
            [ImportStatus.inProgress, 'default'],
            [ImportStatus.reverted, 'success'],
            [ImportStatus.reverting, 'default'],
            [ImportStatus.reviewing, 'default'],
            [ImportStatus.started, 'default'],
        ]
    )
};

const finishedImportStatuses: ImportStatus[] = [ImportStatus.canceled, ImportStatus.error, ImportStatus.finished, ImportStatus.finishedWithWarnings];

export const isImportFinished = (importStatus: ImportStatus) => finishedImportStatuses.includes(importStatus);
