import React, { createContext, FC } from "react";
import { ApolloProvider } from "@apollo/client";

import { LamaGraphQlContextState } from "shared/graphql/types";

import { apolloClient } from "shared/graphql/client";
import { useLamaApolloClient } from "../hooks/use-lama-apollo-client";

export const LamaGraphQlContext = createContext<LamaGraphQlContextState>({
    queryPrefixDotNotation: ''
});

interface LamaGraphQlProviderProps {
    queryPrefixDotNotation?: string;
}

export const LamaGraphQlProvider: FC<LamaGraphQlProviderProps> = (props) => {
    const { queryPrefixDotNotation } = props;
    //const lamaApolloClient = useLamaApolloClient();

    const contextValue: LamaGraphQlContextState = {
        queryPrefixDotNotation
    };

    return (
        <LamaGraphQlContext.Provider value={contextValue}>
            <ApolloProvider client={apolloClient}>
                {props.children}
            </ApolloProvider>
        </LamaGraphQlContext.Provider>
    );
}
