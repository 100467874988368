export * from './package';
export * from './featureModule';
export * from './PackFormlyTypes';

export enum PackageResourceNames {
    entityType = 'lamaEntityType',
    featureModule = 'lamaFeatureModule',
    package = 'package',
    packageFeatureModule = 'packageFeatureModule'
}
