import { KeyValueObject } from "shared/types";

export enum AlertType {
    error = 'error',
    info = 'info',
    success = 'success',
    warning = 'warning'
}

export interface LamaAlertMessage {
    id?: string;
    alertType?: AlertType;
    autoHideDuration?: number;
    title?: string;
    titleTranslate?: string;
    titleTranslateVariables?: KeyValueObject;
    message?: string;
    messageTranslate?: string;
    messageTranslateVariables?: KeyValueObject;
}

export interface LamaAlertsContextState {
    alerts: LamaAlertMessage[];
    defaultAutoHideDuration: number;
    addAlert: (alert: LamaAlertMessage) => string;
    removeAlert: (alertId: string) => void;
}

export interface useLamaAlertsInterface {
    (): {
        addAlert: (alert: LamaAlertMessage) => string;
        addErrorAlert: (messageTranslate: string, messageTranslateVariables?: KeyValueObject) => string;
        addInfoAlert: (messageTranslate: string, messageTranslateVariables?: KeyValueObject) => string;
        addSuccessAlert: (messageTranslate: string, messageTranslateVariables?: KeyValueObject) => string;
        addWarningAlert: (messageTranslate: string, messageTranslateVariables?: KeyValueObject) => string;
        removeAlert: (alertId: string) => void;
    };
}
