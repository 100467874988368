import React, { FC, ChangeEvent } from 'react';
import { useIntl } from 'react-intl';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

export interface LamaCheckboxProps {
    checked?: boolean;
    disabled?: boolean;
    name: string;
    label?: string;
    labelTranslate?: string;
    labelComponent?: JSX.Element;
    value?: any;
    onChange?: (event: ChangeEvent<HTMLInputElement>, value: boolean) => void;
}

export const LamaCheckbox: FC<LamaCheckboxProps> = (props) => {
    const { disabled, name, labelTranslate, onChange } = props;
    let { checked, label, labelComponent } = props;

    const { formatMessage } = useIntl();

    const handleChange = (name: string) => (evt: ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
            onChange(evt, evt.currentTarget.checked);
        }
    };

    if (labelComponent) {
        label = labelComponent as any;
    } else {
        label = labelTranslate ? formatMessage({ id: labelTranslate }) : label;
    }

    checked = checked || false;

    return (
        <FormControlLabel
            disabled={disabled}
            control={<Checkbox disabled={disabled} checked={checked} onChange={handleChange(name)} color="primary" value={name} />}
            label={label}
        />
    );
};
