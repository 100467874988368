import { ModularEntry } from 'shared/packages/react-modular';
import { AccountResourceNames } from 'features/accounts/models';
import { RouteDefaultData } from 'features/base/models';
import { LamaCrudViewType } from 'shared/types';

import { AccountCreateOrUpdate, AccountList } from './routes';

export const accountModule: ModularEntry<RouteDefaultData> = {
    name: 'accounts',
    routes: [
        {
            header: 'menu.sidebarGroupTitle',
            name: 'accountsMain',
            titleTranslate: 'account.sidebarTitle',
            data: {
                hideOnSidebar: true
            },
            routeProps: {
                path: '/accounts'
            },
            routes: [
                {
                    name: 'accountsList',
                    titleTranslate: 'account.listSidebarTitle',
                    data: {
                        crudViewType: LamaCrudViewType.list,
                        primaryModelName: AccountResourceNames.account
                    },
                    routeProps: {
                        component: AccountList,
                        path: '/list'
                    }
                },
                {
                    name: 'accountsCreate',
                    titleTranslate: 'account.createSidebarTitle',
                    data: {
                        crudViewType: LamaCrudViewType.create,
                        primaryModelName: AccountResourceNames.account
                    },
                    routeProps: {
                        component: AccountCreateOrUpdate,
                        path: `/${LamaCrudViewType.create}`
                    }
                },
                {
                    name: 'accountsUpdate',
                    titleTranslate: 'account.updateSidebarTitle',
                    data: {
                        crudViewType: LamaCrudViewType.update,
                        hideOnSidebar: true,
                        primaryModelName: AccountResourceNames.account
                    },
                    routeProps: {
                        component: AccountCreateOrUpdate,
                        path: `/:id/${LamaCrudViewType.update}`
                    }
                }
            ]
        }
    ]
};
