
import {
    LamaFormlyFieldConfig,
    LamaFormlyWrappers,
    LamaFormlyTypes,
    LamaSelectTypeTemplateOptions,
    LamaEnumRadioGroupTypeTemplateOptions
} from 'shared/components/forms/models';
import { FeatureModuleType, FeatureModuleBuyingType, PackageResourceNames } from 'features/packs/models';

import { SortOperationKind } from 'shared/graphql';
import { priceWithoutTaxFormField } from 'features/products/forms';

export const useFeatureModuleFormFields = (): LamaFormlyFieldConfig[] => {
    return [
        {
            wrappers: [LamaFormlyWrappers.card, LamaFormlyWrappers.submitButton],
            fieldGroup: [
                {
                    key: 'type',
                    type: LamaFormlyTypes.enumRadioGroup,
                    wrappers: [LamaFormlyWrappers.formGroup],
                    templateOptions: {
                        labelTranslate: 'featureModule.type',
                        enumRadioGroupProps: {
                            enumType: FeatureModuleType,
                            translatePrefix: 'featureModule.featureModuleType'
                        },
                        required: true
                    } as LamaEnumRadioGroupTypeTemplateOptions
                },
                {
                    key: 'buyingType',
                    type: LamaFormlyTypes.enumRadioGroup,
                    wrappers: [LamaFormlyWrappers.formGroup],
                    templateOptions: {
                        labelTranslate: 'featureModule.buyingType',
                        enumRadioGroupProps: {
                            enumType: FeatureModuleBuyingType,
                            translatePrefix: 'featureModule.featureModuleBuyingType'
                        },
                        required: true
                    } as LamaEnumRadioGroupTypeTemplateOptions
                },
                {
                    key: 'ownerEntityTypeId',
                    type: LamaFormlyTypes.select,
                    wrappers: [LamaFormlyWrappers.formGroup],
                    templateOptions: {
                        labelTranslate: 'featureModule.ownerEntityTypeId',
                        placeholderTranslate: 'featureModule.chooseOwnerEntityTypeId',
                        lamaSelectProps: {
                            bindLabel: 'title',
                            bindValue: 'id',
                            serverOptions: {
                                modelName: PackageResourceNames.entityType,
                                variables: {
                                    order_by: {
                                        title: SortOperationKind.asc
                                    }
                                }
                            }
                        }
                    } as LamaSelectTypeTemplateOptions
                },
                {
                    key: 'title',
                    type: LamaFormlyTypes.input,
                    wrappers: [LamaFormlyWrappers.formGroup],
                    templateOptions: {
                        labelTranslate: 'featureModule.title',
                        required: true
                    }
                },
                {
                    key: 'systemName',
                    type: LamaFormlyTypes.input,
                    wrappers: [LamaFormlyWrappers.formGroup],
                    templateOptions: {
                        labelTranslate: 'featureModule.systemName'
                    }
                },
                {
                    key: 'maxQuantity',
                    type: LamaFormlyTypes.numberInput,
                    wrappers: [LamaFormlyWrappers.formGroup],
                    templateOptions: {
                        labelTranslate: 'featureModule.maxQuantity'
                    }
                },
                {
                    key: 'description',
                    type: LamaFormlyTypes.editor,
                    wrappers: [LamaFormlyWrappers.formGroup],
                    templateOptions: {
                        labelTranslate: 'shared.description'
                    }
                }
            ]
        },
        priceWithoutTaxFormField
    ];
};
