import React, { FC, useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import Link from '@material-ui/core/Link';

import { useAuth } from 'shared/authentication';

export const UserBlock: FC = () => {
    const { account, logout } = useAuth();
    const [isUserDropdownOpen, toggleUserDropdown] = useState(false);

    const toggleUserDropdownMenu = () => {
        toggleUserDropdown(!isUserDropdownOpen);
    };

    return (
        <div className="top-sidebar">
            <div className="sidebar-user-block">
                <Dropdown isOpen={isUserDropdownOpen} toggle={toggleUserDropdownMenu} className="rct-dropdown">
                    <DropdownToggle tag="div" className="d-flex align-items-center">
                        <div className="user-info">
                            <span className="user-name ml-4">{account?.fullName}</span>
                            <i className="zmdi zmdi-chevron-down dropdown-icon mx-4"></i>
                        </div>
                    </DropdownToggle>
                    <DropdownMenu>
                        <ul className="list-unstyled mb-0">
                            <li className="p-15 border-bottom user-profile-top bg-primary rounded-top">
                                <p className="text-white mb-0 fs-14">{account?.fullName}</p>
                            </li>
                            <li className="border-top">
                                <Link onClick={logout}>
                                    <i className="zmdi zmdi-power text-danger mr-3"></i>
                                    <span>
                                        <FormattedMessage id="shared.logout" />
                                    </span>
                                </Link>
                            </li>
                        </ul>
                    </DropdownMenu>
                </Dropdown>
            </div>
        </div>
    );
};
