import { IRcFormlyProps } from "@rc-formly/core";

import { EntityWithCurrentPrice, ProductResourceNames, Tax } from "features/products/models";
import { LamaFormlyFieldConfig, LamaFormlyWrappers, LamaFormlyTypes, LamaCollapsibleCardWrapperTemplateOptions, LamaSelectTypeTemplateOptions } from "shared/components/forms/models";

import { UtilityHelper } from "shared/utilities";
import { SortOperationKind } from "shared/graphql";

const setPriceWithTax = (formlyProps: IRcFormlyProps<EntityWithCurrentPrice>, currentTaxValue?: number) => {
    const currentPrice = formlyProps.formProps.values.currentPrice;
    currentTaxValue = currentTaxValue || currentPrice?.ownerTax?.value;

    if (UtilityHelper.isNumber(currentPrice?.priceWithoutTax) && UtilityHelper.isNumber(currentTaxValue)) {
        const priceWithTax = currentPrice.priceWithoutTax + currentPrice.priceWithoutTax * currentTaxValue!;

        formlyProps.setFieldValue('currentPrice.priceWithTax', priceWithTax);
    }
};

const setDiscountedPrice = (formlyProps: IRcFormlyProps<EntityWithCurrentPrice>) => {
    const taxValue = formlyProps.formProps.values.currentPrice?.ownerTax?.value;
    const currentPrice = formlyProps.formProps.values.currentPrice;

    if (currentPrice &&
        UtilityHelper.isNumber(taxValue)
    ) {
        const priceWithTax = currentPrice.priceWithoutTax + currentPrice.priceWithoutTax * taxValue!;

        if (currentPrice?.priceWithTaxAndDiscount < priceWithTax) {
            const inverseDiscount = Math.round((priceWithTax * 100) / currentPrice.priceWithTaxAndDiscount);
            const discount = 100 - inverseDiscount;

            formlyProps.setFieldValue('currentPrice.discount', discount);
            formlyProps.setFieldValue(
                'currentPrice.priceWithDiscount',
                currentPrice.priceWithoutTax - (currentPrice.priceWithoutTax * discount) / 100
            );
        }
        else {
            if (currentPrice.discount !== 0) {
                formlyProps.setFieldValue('currentPrice.discount', 0);
            }

            formlyProps.setFieldValue('currentPrice.priceWithDiscount', currentPrice.priceWithoutTax);
            formlyProps.setFieldValue('currentPrice.priceWithTaxAndDiscount', priceWithTax);
        }
    }
};

export const priceWithoutTaxFormField: LamaFormlyFieldConfig = {
    wrappers: [LamaFormlyWrappers.card],
    templateOptions: {
        cardProps: {
            headingTranslate: 'package.price.header'
        }
    } as LamaCollapsibleCardWrapperTemplateOptions,
    fieldGroup: [
        {
            key: 'currentPrice.ownerTaxId',
            type: LamaFormlyTypes.select,
            wrappers: [LamaFormlyWrappers.formGroup],
            templateOptions: {
                labelTranslate: 'price.priceTaxId',
                placeholderTranslate: 'price.choosePrixeTaxId',
                required: true,
                lamaSelectProps: {
                    bindLabel: 'title',
                    bindValue: 'id',
                    defaultValue: 'high',
                    defaultValuePropertyName: 'systemName',
                    serverOptions: {
                        loadAdditionalProperties: ['value'],
                        modelName: ProductResourceNames.tax,
                        variables: {
                            order_by: {
                                value: SortOperationKind.desc
                            }
                        }
                    }
                },
                onChange: (value: string, oldValue: string, formlyProps, priceTax: Tax) => {
                    if (priceTax) {
                        setPriceWithTax(formlyProps, priceTax.value);

                        formlyProps.setFieldValue('currentPrice.ownerTax', priceTax);
                    }
                }
            } as LamaSelectTypeTemplateOptions
        },
        {
            wrappers: [LamaFormlyWrappers.row],
            fieldGroup: [
                {
                    key: 'currentPrice.priceWithoutTax',
                    fieldGroupClassName: 'col-sm-6',
                    type: LamaFormlyTypes.moneyInput,
                    wrappers: [LamaFormlyWrappers.formGroup],
                    templateOptions: {
                        labelTranslate: 'price.priceWithoutTax',
                        required: true,
                        onChange: (value: number, oldValue: number, formlyProps) => {
                            setPriceWithTax(formlyProps);
                            setDiscountedPrice(formlyProps);
                        }
                    }
                },
                {
                    key: 'currentPrice.priceWithTax',
                    fieldGroupClassName: 'col-sm-6',
                    type: LamaFormlyTypes.moneyInput,
                    wrappers: [LamaFormlyWrappers.formGroup],
                    templateOptions: {
                        disabled: true,
                        labelTranslate: 'price.priceWithTax',
                        required: true
                    }
                }
            ]
        },
        {
            wrappers: [LamaFormlyWrappers.row],
            fieldGroup: [
                {
                    key: 'currentPrice.priceWithTaxAndDiscount',
                    fieldGroupClassName: 'col-sm-6',
                    type: LamaFormlyTypes.moneyInput,
                    wrappers: [LamaFormlyWrappers.formGroup],
                    templateOptions: {
                        labelTranslate: 'price.priceWithTaxAndDiscount',
                        onChange: (value: number, oldValue: number, formlyProps) => {
                            setDiscountedPrice(formlyProps);
                        }
                    }
                },
                {
                    key: 'currentPrice.discount',
                    fieldGroupClassName: 'col-sm-6',
                    type: LamaFormlyTypes.numberInput,
                    wrappers: [LamaFormlyWrappers.formGroup],
                    templateOptions: {
                        disabled: true,
                        labelTranslate: 'price.discount'
                    }
                }
            ]
        }
    ]
}
