import React from 'react';
import { RcFormlyField } from "@rc-formly/core";

import { LamaSimpleListTypeTemplateOptions } from 'shared/components/forms/models';

import { LamaSimpleList } from 'shared/components/lists';
import { BaseEntity } from 'shared/types';

export class LamaSimpleListType extends RcFormlyField<LamaSimpleListTypeTemplateOptions> {
    render() {
        const { onEdit, ...otherProps  } = this.to?.simpleListProps! || {};

        const onEditType = (entity: BaseEntity) => {
            if (onEdit) {
                onEdit(entity, this.props.formlyProps);
            }
        };

        return (
            <LamaSimpleList onEdit={onEditType} {...otherProps}  />
        )
    }
}
