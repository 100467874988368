import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/core/styles';

import { useLamaQuery, GraphQlQueryType } from 'shared/graphql';

import { WeeklySumStatisticsQuery, StatisticResourceNames } from 'features/statistics/model';
import { WEEKLY_SUM_STATISTICS } from 'features/statistics/queries';
import { ComparisonResult } from 'shared/types';

import { EmptyStatistcs } from './EmptyStatistics';
import { LamaLoader, IconFromValue } from 'shared/components';

import { numberThousandsPipe } from 'shared/utilities';


interface WeeklySumBoxProps {
    count: number;
    comparison: ComparisonResult;
    labelTranslate: string;
}

interface ComparisonIconProps {
    comparison: ComparisonResult;
}

const ComparisonIcon: FC<ComparisonIconProps> = (props) => {
    const { comparison } = props;
    const theme = useTheme();
    let comparisonIconValue = 'remove';
    let comparisonColor = theme.palette.warning.main;

    if (comparison === ComparisonResult.less) {
        comparisonIconValue = 'arrow_downward';
        comparisonColor = theme.palette.error.main;
    } else if (comparison === ComparisonResult.more) {
        comparisonIconValue = 'arrow_upward';
        comparisonColor = theme.palette.success.main;
    }

    return <IconFromValue iconValue={`material-icons ${comparisonIconValue}`} style={{ color: comparisonColor, fontSize: '2rem' }} />;
};

const WeeklySumBox: FC<WeeklySumBoxProps> = (props) => {
    const { comparison = ComparisonResult.equal, count = 0, labelTranslate } = props;

    return (
        <Box display="flex" alignItems="end" flexDirection="row" mt={3}>
            <ComparisonIcon comparison={comparison} />
            <Box ml={2}>
                <h2 className="mb-0">{numberThousandsPipe(count)}</h2>
                <Typography color="textSecondary" variant="body2" component="span">
                    <FormattedMessage id={labelTranslate} />
                </Typography>
            </Box>
        </Box>
    );
};

export const WeeklySumStatistics: FC = () => {
    const { data, loading } = useLamaQuery<WeeklySumStatisticsQuery>({
        modelName: StatisticResourceNames.statistic,
        queryType: GraphQlQueryType.customList,
        gqlQuery: WEEKLY_SUM_STATISTICS
    });

    return (
        <>
            <LamaLoader showLoader={loading} />
            {data?.weeklySumStatistics ? (
                <Box>
                    <WeeklySumBox
                        comparison={data.weeklySumStatistics.previousProductViewsComparison}
                        count={data.weeklySumStatistics.numberOfProductViews}
                        labelTranslate="statistic.numberOfProductViews"
                    />
                    <WeeklySumBox
                        comparison={data.weeklySumStatistics.previousUniqueViewsComparison}
                        count={data.weeklySumStatistics.numberOfUniqueViews}
                        labelTranslate="statistic.numberOfUniqueCompanyOrProductViews"
                    />
                    <WeeklySumBox
                        comparison={data.weeklySumStatistics.previousContactClicksComparison}
                        count={data.weeklySumStatistics.numberOfContactClicks}
                        labelTranslate="statistic.numberOfContactClicks"
                    />
                </Box>
            ) : (
                <EmptyStatistcs />
            )}
        </>
    );
};
