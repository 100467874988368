import { useWindowDimensionsInterface, WindowDimensionObject } from './types';
import { useEffect, useState } from 'react';

const initialValue: WindowDimensionObject = {
    innerWidth: 0,
    innerHeight: 0,
    outerWidth: 0,
    outerHeight: 0
};

export const useWindowDimensions: useWindowDimensionsInterface = args => {
    const [windowSize, setWindowSize] = useState<WindowDimensionObject>(initialValue);

    const fetchWindowDimensionsAndSave = () => {
        setWindowSize({
            innerWidth: window.innerWidth,
            innerHeight: window.innerHeight,
            outerWidth: window.outerWidth,
            outerHeight: window.outerHeight
        });
    };

    // run on mount
    useEffect(() => {
        // run only once
        fetchWindowDimensionsAndSave();
    }, []);

    // set resize handler once on mount and clean before unmount

    useEffect(() => {
        if (args?.liveMeasure || args?.liveMeasureResize) {
            window.addEventListener('resize', fetchWindowDimensionsAndSave);

            return () => {
                window.removeEventListener('resize', fetchWindowDimensionsAndSave);
            };
        }
    }, [args]);

    return windowSize;
};
