import { useLoadScript } from "@react-google-maps/api";

import { useLamaLoadMapsScriptInterface } from "./types";

import { Environment } from "configs";


const loadScriptOptions = {
    googleMapsApiKey: Environment.googleProjectId,
    language: 'sl',
    libraries: ['places']
};

export const useLamaLoadMapsScript: useLamaLoadMapsScriptInterface = () => {
    const { isLoaded } = useLoadScript(loadScriptOptions);

    return {
        isLoaded
    };
};
