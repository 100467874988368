import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';

import { LamaDialogButtonType, LamaDialogButton } from '../hooks/types';
import { KeyValueObject } from 'shared/types';

interface LamaDialogProps {
    buttons?: LamaDialogButton[];
    headerTextTranslate?: string;
    headerTranslateVariables?: KeyValueObject;
    isOpen: boolean;
    messageTextTranslate?: string;
    messageTranslateVariables?: KeyValueObject;
    onConfirm: () => void;
    onClose: () => void;
}

export const LamaDialog: FC<LamaDialogProps> = (props) => {
    const {
        buttons = [{ buttonType: LamaDialogButtonType.ok, textTranslate: 'shared.ok' }],
        onConfirm,
        onClose,
        isOpen,
        headerTextTranslate,
        headerTranslateVariables,
        messageTextTranslate,
        messageTranslateVariables
    } = props;

    const onButtonClick = (button: LamaDialogButton) => () => {
        if (button.buttonType === LamaDialogButtonType.ok) {
            onConfirm();
        } else {
            onClose();
        }
    };

    return (
        <Dialog open={isOpen} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">
                {headerTextTranslate && <FormattedMessage id={headerTextTranslate} values={headerTranslateVariables} />}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {messageTextTranslate && <FormattedMessage id={messageTextTranslate} values={messageTranslateVariables} />}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {buttons.map((q) => (
                    <Button key={q.textTranslate} autoFocus={q.buttonType === LamaDialogButtonType.ok} onClick={onButtonClick(q)}>
                        <FormattedMessage id={q.textTranslate} />
                    </Button>
                ))}
            </DialogActions>
        </Dialog>
    );
};
