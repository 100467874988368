import React, { FC } from 'react';

import { RouteDefaultData } from 'features/base/models';
import { Venue, VenueResourceNames, VenueStatus } from 'features/venues/models';
import { singleVenueQuery } from 'features/venues/queries';

import { useVenueFormFields } from './useVenueFormFields';
import { useModularRoute } from 'shared/packages/react-modular';

import { LamaCreateUpdateBase } from 'features/base/components';
import { LamaLoader } from 'shared/components';
import { LamaCrudViewType } from 'shared/types';

export const VenueCreateOrUpdateBase: FC = () => {
    const { loading, fields, venues } = useVenueFormFields();
    const { currentRoute } = useModularRoute<RouteDefaultData>();

    const getModelTitle = (entity: Venue) => {
        return entity?.title;
    };

    const getInitialModel = () => {
        return {
            isMainWarehouse: currentRoute?.data?.crudViewType === LamaCrudViewType.create ? venues.length === 0 : false,
            status: VenueStatus.open
        } as Venue;
    };

    if (loading) {
        return <LamaLoader />
    }

    return (
        <LamaCreateUpdateBase
            editGqlQuery={singleVenueQuery}
            getInitialModel={getInitialModel}
            getModelTitle={getModelTitle}
            fields={fields}
            modelName={VenueResourceNames.venue}
        />
    );
};
