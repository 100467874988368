import React, { FC, useEffect, ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { DocumentNode } from 'graphql';

import { RouteDefaultData, LamaCrudRouteParams } from 'features/base/models';
import { LamaCrudViewType } from 'shared/types';

import { useRapidModel } from 'shared/packages/rapid-model';
import { useLamaQuery, GraphQlQueryType } from 'shared/graphql';
import { useModularRoute } from 'shared/packages/react-modular';

import { LamaLoader } from 'shared/components';

import { UtilityHelper } from 'shared/utilities';

interface LamaUpdateWrapperBaseProps {
    editGqlQuery: DocumentNode;
    getModelTitle: (entity: any) => string;
}

export const LamaUpdateWrapperBase: FC<LamaUpdateWrapperBaseProps> = props => {
    const { children, editGqlQuery, getModelTitle } = props;
    const { currentRoute } = useModularRoute<RouteDefaultData>();
    const { id } = useParams<LamaCrudRouteParams>();
    const { currentModel, clearCurrentModel, setCurrentModel } = useRapidModel({
        getModelTitleFunc: getModelTitle
    });
    const isNotUpdateRoute = currentRoute?.data?.crudViewType !== LamaCrudViewType.update;
    const { data, loading } = useLamaQuery({
        modelName: currentRoute?.data?.primaryModelName!,
        gqlQuery: editGqlQuery,
        skip: isNotUpdateRoute,
        queryType: GraphQlQueryType.single,
        variables: {
            id
        }
    });

    useEffect(() => {
        if (UtilityHelper.isNotEmpty(data)) {
            setCurrentModel(data);
        }

        return () => {
            clearCurrentModel();
        };
    }, [data]);

    if (isNotUpdateRoute) {
        return children as ReactElement;
    }

    return (
        <>
            <LamaLoader showLoader={loading} />
            {!loading && currentModel && children}
        </>
    );
};
