import React from 'react';
import { RcFormlyField } from '@rc-formly/core';

import { LamaDatePicker } from 'shared/components/forms/components/LamaDatePicker';
import { TemplateOptionsExtended } from 'shared/components/forms/models';

export class LamaDatePickerType extends RcFormlyField<TemplateOptionsExtended> {
    onChange = (date: Date) => {
        this.handleChange(date);
    };

    render() {
        const { min } = this.to || {};

        const isInvalid = this.hasErrors() && (this.wasFieldTouched() || this.props.formlyProps.formProps.submitCount > 0);
        const value = this.getFieldValue() || '';

        return (
            <LamaDatePicker
                isInvalid={isInvalid}
                name={this.getFieldKey()}
                minDate={min}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                placeholder={this.to?.placeholder}
                value={value}
            />
        );
    }
}
