import { types } from 'typed-graphqlify';
import { GraphQlQueryType, LamaGraphQlQueryHelper } from 'shared/graphql';

import { Price, ProductViewModel, ProductResourceNames, Tax } from 'features/products/models';
import { BaseEntity } from 'shared/types';

import { mediaQueryObject } from 'features/media/queries';

export const priceQueryObject: Price = {
    id: types.string,
    discount: types.number,
    isNegotiable: types.boolean,
    ownerTaxId: types.string,
    priceWithDiscount: types.number,
    priceWithTax: types.number,
    priceWithTaxAndDiscount: types.number,
    priceWithoutTax: types.number,
    ownerTax: {
        value: types.number
    } as Tax
};

export const { gqlQuery: singleProductQuery} = LamaGraphQlQueryHelper.createQueryFromDotNotation<ProductViewModel, BaseEntity>({
    modelName: ProductResourceNames.product as string,
    queryType: GraphQlQueryType.single,
    queryObject: {
        id: types.string,
        description: types.string,
        eanCode: types.string,
        ownerCategoryId: types.string,
        sKU: types.string,
        status: types.custom(),
        title: types.string,
        type: types.custom(),
        url: types.string,
        currentPrice: priceQueryObject,
        files: [{
            order: types.number,
            ownerMediaId: types.string,
            ownerProductId: types.string,
            ownerMedia: mediaQueryObject
        }],
        onVenues: [{
            ownerProductId: types.string,
            ownerVenueId: types.string
        }]
    },
    variables: {
        id: types.string
    }
});
