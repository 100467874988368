import React, { FC, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { types } from 'typed-graphqlify';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { ImportRowErrorViewModel } from 'features/imports/models';
import { ProductResourceNames } from 'features/products/models';
import { VenueResourceNames, Venue } from 'features/venues/models';

import { useImportGlobalSettingsFormFields } from 'features/imports/forms';
import { useImporter } from 'features/imports/hooks';
import { useLamaAlerts } from 'shared/packages/alerts';
import { useLamaQuery } from 'shared/graphql';

import { ImporterBottomButtons } from './ImporterBottomButtons';
import { ImporterErrors } from './ImporterErrors';
import { LamaLoader, LamaForm } from 'shared/components';

import { CrudHelper } from 'features/base/utilities';
import { LamaGrid, LamaGridColumnType, LamaGridColumn } from 'shared/components/grid';

const useStyles = makeStyles((theme) =>
    createStyles({
        header: {
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(2),
            fontSize: '1rem',
            fontWeight: 'bold'
        }
    })
);

const Header: FC = (props) => {
    const { children } = props;
    const { header } = useStyles();

    return <Typography className={header}>{children}</Typography>;
};

export const ImporterStepFive: FC = () => {
    const history = useHistory();
    const [importerErrors, setImporterErrors ] = useState<ImportRowErrorViewModel[]>([]);
    const { formatMessage } = useIntl();
    const { loading, lastImport, lastImportCheckResult, importer } = useImporter();
    const { addSuccessAlert } = useLamaAlerts();
    const { data: venues = [], loading: loadingVenues } = useLamaQuery<Venue[]>({
        modelName: VenueResourceNames.venue,
        queryObject: {
            id: types.string,
            title: types.string
        }
    });

    const gridColumns: LamaGridColumn[] = [
        {
            filterable: false,
            titleTranslate: 'product.title',
            prop: 'title'
        },
        {
            filterable: false,
            titleTranslate: 'shared.description',
            prop: 'description'
        },
        {
            type: LamaGridColumnType.classification,
            titleTranslate: 'product.ownerCategoryId',
            prop: 'ownerCategoryTitle'
        },
        {
            type: LamaGridColumnType.money,
            titleTranslate: 'price.retailPrice',
            prop: 'priceWithTax',
            valueTransformer: (value: number) => {
                if (value === 0) {
                    return formatMessage({ id: 'price.isNegotiable' });
                }

                return value;
            }
        },
        {
            type: LamaGridColumnType.money,
            titleTranslate: 'price.priceWithTaxAndDiscount',
            prop: 'priceWithTaxAndDiscount',
            valueTransformer: (value: number) => {
                if (value === 0) {
                    return formatMessage({ id: 'price.isNegotiable' });
                }

                return value;
            }
        },
    ]

    const fields = useImportGlobalSettingsFormFields({
        venues,
        disabled: true
    });

    const onSubmit = async () => {
        if (!lastImport) {
            return;
        }

        const checkResult = await importer(lastImport);

        if (checkResult) {
            if (checkResult.errors) {
                setImporterErrors(checkResult.errors);
            }
        }
        else {
            const url = CrudHelper.convertUrlToListCrudType(history.location.pathname);

            history.push(url);
            addSuccessAlert('import.success');
        }
    };

    return (
        <Box position="relative">
            <LamaLoader showLoader={loading} />
            <Typography>
                <FormattedMessage id="import.stepFive.summary" />
            </Typography>
            <Header>
                <FormattedMessage id="import.stepFive.headerOne" />
            </Header>
            {!loadingVenues && <LamaForm fields={fields} model={lastImport?.globalSettings}></LamaForm>}
            <Header>
                <FormattedMessage id="import.stepFive.headerTwo" />
            </Header>
            <LamaGrid columns={gridColumns} modelName={ProductResourceNames.product} rows={lastImportCheckResult?.exampleProducts} showPagination={false} />
            <ImporterErrors errors={importerErrors} />
            <Box mt={3}>
                <ImporterBottomButtons onSubmit={onSubmit} />
            </Box>
        </Box>
    );
};
