import React, { FC, CSSProperties, Ref } from "react";

import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

import { LamaGridColumn, LamaGridFilter } from 'shared/components/grid/types';
import { GraphQlQueryObjectParameter } from "shared/graphql";

import { useLamaGridCreator } from "shared/components/grid/hooks";

import { DefaultFilterType } from './filterTypes';

import { UtilityHelper, debounce } from "shared/utilities";

interface LamaGridFiltersProps {
    columns: LamaGridColumn[];
    currentFilters: LamaGridFilter[];
    gridName: string;
    rowRef?: Ref<any>;
    onFilterChange?: (column: LamaGridColumn, changes: GraphQlQueryObjectParameter[]) => void;
}

const rowId = UtilityHelper.createGuid();

export const LamaGridFilters: FC<LamaGridFiltersProps> = (props) => {
    const { columns, currentFilters, gridName, rowRef, onFilterChange } = props;

    const { getGridFilterType } = useLamaGridCreator();

    if (!columns.some(q => q.filterable)) {
        return null;
    }

    const renderColumn = (column: LamaGridColumn) => {
        const { filterable, type, width} = column;
        let gridFilterType = getGridFilterType(type!);

        if (!gridFilterType) {
            gridFilterType = { component: DefaultFilterType } as any;
        }

        const { component: Component } = gridFilterType!;
        const currentChanges = currentFilters.find(q => q.column.id === column.id);

        let styles: CSSProperties = {};

        if (UtilityHelper.isString(width)) {
            styles.width = width;
        }

        const onComponentFilterChange = (changes: GraphQlQueryObjectParameter[]) => {
            if (onFilterChange) {
                onFilterChange(column, changes);
            }
        };

        const debouncedComponentFilterChange = debounce(onComponentFilterChange);

        return (
            <TableCell key={column.id}  style={styles}>
                { filterable && <Component column={column} currentChanges={currentChanges?.filters} onFilterChange={debouncedComponentFilterChange} gridName={gridName}  /> }
            </TableCell>
        );
    };

    return (
        <TableRow ref={rowRef} tabIndex={-1} key={rowId}>
            { columns.map(renderColumn)}
        </TableRow>
    );
};
