import React, { FC, useState, useEffect } from 'react';

import Grid from '@material-ui/core/Grid';

import { Media, MediaResourceNames, MediaGridSizeType } from 'features/media/models';
import { mediaQueryObject } from 'features/media/queries';

import { useLamaQuery, GraphQlQueryType } from 'shared/graphql';
import { useLamaSelection, LamaSelectionProvider } from 'shared/components/selection';

import { LamaLoader } from 'shared/components';

import { UtilityHelper } from 'shared/utilities';
import { MediaChooserItem } from './MediaChooserItem';
import { MediaChooserButton } from './MediaChooserButton';

interface MediaEntityChooserProps {
    currentMediaId?: string;
    gridSize?: MediaGridSizeType;
    onChange: (media: Media | null) => void;
}

const MediaEntityInnerChooser: FC<MediaEntityChooserProps> = props => {
    const { currentMediaId, gridSize, onChange } = props;

    const [selectedMedia, setSelectedMedia] = useState<Media | null>(null);
    const { toggleSelectedItem } = useLamaSelection<Media>();
    const { data: media, loading } = useLamaQuery<Media>({
        skip: selectedMedia !== null || !currentMediaId,
        modelName: MediaResourceNames.media,
        queryType: GraphQlQueryType.single,
        queryObject: mediaQueryObject,
        variables: {
            id: currentMediaId
        }
    });

    useEffect(() => {
        if (currentMediaId && media) {
            setSelectedMedia(media);
            toggleSelectedItem(media);
        }
    }, [currentMediaId, media]);

    const removeEntityMedia = () => {
        toggleSelectedItem(selectedMedia!);
        setSelectedMedia(null);
        onChange(null);
    };

    const onSelectedMediaItems = (mediaItems: Media[]) => {
        if (UtilityHelper.isNotEmpty(mediaItems)) {
            if (mediaItems[0].id !== currentMediaId) {
                setSelectedMedia(mediaItems[0]);

                onChange(mediaItems[0]);
            }

            return;
        }

        removeEntityMedia();
    };

    return (
        <div className="position-relative">
            <LamaLoader showLoader={loading} />
            <Grid container direction="row" spacing={3}>
                <MediaChooserButton gridSize={gridSize} onSelectedMediaItems={onSelectedMediaItems} />
                {selectedMedia && (
                    <MediaChooserItem key={selectedMedia.id} gridSize={gridSize} media={selectedMedia} onRemoveMedia={removeEntityMedia} />
                )}
            </Grid>
        </div>
    );
};

export const MediaEntityChooser: FC<MediaEntityChooserProps> = props => {
    return (
        <LamaSelectionProvider maxNumberOfSelectedItems={1} multiple={false} keyDotNotationProperty="id">
            <MediaEntityInnerChooser {...props} />
        </LamaSelectionProvider>
    );
};
