import React, { ChangeEvent } from 'react';
import { RcFormlyField } from "@rc-formly/core";

import { LamaCheckboxGroupTypeTemplateOptions } from 'shared/components/forms/models';

import { LamaCheckboxGroup } from 'shared/components/forms/components';

export class LamaCheckboxGroupType extends RcFormlyField<LamaCheckboxGroupTypeTemplateOptions> {
    public onChange = (event: ChangeEvent<HTMLInputElement>) => {
        this.handleChange( event.target.value);
    }

    render() {
        const { disabled, checkboxRadioGroupProps } = this.to || {};

        if (!checkboxRadioGroupProps) {
            return null;
        }

        let { options, ...otherProps } = checkboxRadioGroupProps;

        let value = this.getFieldValue<any[]>();

        if (disabled && options?.length) {
            options = options?.map(q => ({
                ...q,
                disabled: true
            }))
        }

        return (
            <LamaCheckboxGroup {...otherProps} options={options} onChange={this.onChange} values={value} />
        );
    }
}
