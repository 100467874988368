import { accountModule } from './accounts';
import { categoryModule } from './categories';
import { contractModule } from './contracts';
import { tenantModule } from './companies';
import { homeModule } from './home';
import { importModule } from './imports';
import { locationModule } from './locations';
import { mediaModule } from './media';
import { packageModule } from './packs';
import { productModule } from './products';
import { venueModule } from './venues';


export const featureModules = [
    homeModule, tenantModule, contractModule, accountModule,
    packageModule, categoryModule, venueModule, productModule,
    importModule, locationModule, mediaModule
];
