import React from 'react';
import { RcFormlyField } from '@rc-formly/core';

import { LamaInput } from 'shared/components/forms/components';

type TypeOfInput = 'text' | 'email' | 'number' | 'password';

export class InputType extends RcFormlyField {
    get type() {
        if (this.to && this.to.type) {
            return this.to.type as TypeOfInput;
        }

        return 'text';
    }

    private isNumber() {
        return this.type === 'number';
    }

    onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value as any;

        if (this.isNumber()) {
            value = parseFloat(value);
        }

        this.handleChange(value);
    };

    render() {
        const { disabled, onInputSubmit, placeholder = '' } = this.to || {};

        const isInvalid = this.hasErrors() && (this.wasFieldTouched() || this.props.formlyProps.formProps.submitCount > 0);
        const autoComplete = this.type === 'password' ? this.getFieldKey() : 'off';

        return (
            <LamaInput
                autoComplete={autoComplete}
                disabled={disabled}
                invalid={isInvalid}
                name={this.getFieldKey()}
                type={this.type}
                onBlur={this.handleBlur}
                onChange={this.onChange}
                onInputSubmit={onInputSubmit}
                placeholder={placeholder}
                value={this.getFieldValue<string>()}
            />
        );
    }
}
