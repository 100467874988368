import React, { FC, ChangeEvent } from "react";
import Pagination from '@material-ui/lab/Pagination';

import { PaginationConnection, PaginationDecodedCursor } from "shared/graphql";

import { Base64Helper } from "shared/utilities";

interface LamaPaginationProps {
    connection: PaginationConnection;
    first?: number;
    last?: number;
    onChange?: (cursor: string) => void;
}

const getPositionFromCursor = (cursor: string) => {
    if (cursor) {
        const decodedCursorJson = Base64Helper.decode(cursor);

        try {
            const paginationDecodedCursor: PaginationDecodedCursor = JSON.parse(decodedCursorJson);

            if (paginationDecodedCursor) {
                return paginationDecodedCursor.__position;
            }
        }
        catch (e) { }
    }

    return 0;
};

export const LamaPagination: FC<LamaPaginationProps> = (props) => {
    const { connection: { totalCount, pageInfo: { startCursor  } }, first, last, onChange } = props;
    const pageSize = first || last || 1;
    const pageCount = Math.ceil(totalCount / pageSize);
    const currentPage = Math.ceil((getPositionFromCursor(startCursor) + 1) / pageSize);

    const constructCursor = (page: number) => {
        const position = page === 1 ? 0 : (page - 1) * pageSize - 1;
        const cursorValue = `{"__totalCount":${totalCount},"__position":${position}}`

        return Base64Helper.encode(cursorValue);
    };

    const handlePageChange = (e: ChangeEvent<any>, value: number) => {
        const cursor = constructCursor(value);

        if (onChange) {
            onChange(cursor);
        }
    };

    return <Pagination count={pageCount} boundaryCount={1} siblingCount={1} page={currentPage} onChange={handlePageChange} color="primary" />
};
