import classNames from 'classnames';
import React, { FC, useState, useEffect, CSSProperties } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

import {
    LamaGridProps,
    LamaGridColumn,
    LamaGridColumnType,
    LamaGridRowEvent,
    LamaGridRowEventType,
    LamaGridAction,
    LamaGridFilter,
    LamaGridSort
} from 'shared/components/grid/types';
import { BaseEntity } from 'shared/types';

import { useDimensions } from 'shared/packages/use-dimensions';
import {
    useLamaQuery,
    useLamaClient,
    GraphQlQueryObjectParameter,
    LamaGraphQlQueryHelper,
    GraphQlQueryVariables,
    GraphQlQueryEqualityOperator,
    LamaGrapQlNamingHelper,
    SortOperationKind,
    GraphQlQueryType,
    PaginationConnection
} from 'shared/graphql';
import { useLamaAlerts } from 'shared/packages/alerts';
import { useLamaDialog } from 'shared/packages/dialogs';
import { useLamaGridCreator } from 'shared/components/grid/hooks';
import { useMountEffect } from 'shared/packages/use-mount-effect';
import { useRapidModel } from 'shared/packages/rapid-model';

import { LamaLoader } from 'shared/components/LamaLoader';
import { LamaGridCellRenderer } from './LamaGridCellRenderer';
import { LamaGridHead } from './LamaGridHead';

import { CrudHelper } from 'features/base/utilities';
import { UtilityHelper } from 'shared/utilities';
import { LamaGridFilters } from './LamaGridFilters';
import { LamaGraphQlValueWithTypeCreator } from 'shared/graphql/utilities';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles({
    container: {
        flexGrow: 1,
        width: '100%'
    },
    root: {
        position: 'relative'
    }
});

export const LamaGrid: FC<LamaGridProps> = (props) => {
    const {
        actions,
        classes: { container: containerCssClases, root: rootCssClasses } = { container: '', root: '' },
        columns = [],
        deleteServerOptions,
        idProperty = 'id',
        idTransformer,
        fullHeight,
        modelName: modelNameFromBaseProps = '',
        onItemDelete,
        onItemDeleteSuccess,
        onItemEdit,
        rows: rowsFromProps,
        showPagination = true,
        titleProperty = 'title',
        titleTransformer
    } = props;
    let { serverOptions } = props;
    let { modelName = '', loadAdditionalProperties = [], gqlQuery, variables } = serverOptions || {};
    const [rootRef, rootDimensions] = useDimensions();
    const [headRef, headDimensions] = useDimensions();
    const [paginationRef, paginationDimension] = useDimensions();
    const [filtersRef, filtersDimensions] = useDimensions();
    const lamaClient = useLamaClient();
    const { addDeleteConfirmationDialog } = useLamaDialog();
    const { addErrorAlert, addSuccessAlert } = useLamaAlerts();
    const { addGridInstance, removeGridInstance } = useLamaGridCreator();
    const history = useHistory();
    const { getModelNameTranslation } = useRapidModel({
        defaultModelName: modelName
    });

    const getDefaultSort = () => {
        const foundCreatedAtColumn = columns.find((q) => q.prop?.endsWith('createdAt'));
        let defaultSort: LamaGridSort = {
            property: 'createdAt',
            sortOperation: SortOperationKind.desc
        };

        if (foundCreatedAtColumn && foundCreatedAtColumn.prop) {
            defaultSort.property = foundCreatedAtColumn.prop;
        }

        return defaultSort;
    };

    const [gridName] = useState(props.gridName || `lama-grid-${UtilityHelper.createGuid()}`);
    const [mappedColumns, setMappedColumns] = useState<LamaGridColumn[]>([]);
    const [filters, setFilters] = useState<LamaGridFilter[]>([]);
    const [sort, setSort] = useState<LamaGridSort>(getDefaultSort());
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [rowsTotalCount, setRowsTotalCount] = useState<number>();
    const [isLoaderShown, setLoaderShown] = useState(false);

    const getQueryVariables = () => {
        let newVariables: GraphQlQueryVariables = {};

        if (UtilityHelper.isNotEmpty(variables)) {
            if (LamaGraphQlQueryHelper.areOnlyWhereVariables(variables!)) {
                newVariables = {
                    where: {
                        ...variables
                    }
                };
            } else {
                newVariables = variables!;
            }
        }

        if (UtilityHelper.isNotEmpty(filters)) {
            newVariables.where = newVariables.where || {};

            filters.forEach((filter) => {
                filter.filters.forEach((insideFilter) => {
                    let property = LamaGrapQlNamingHelper.getFilterPropertyName(
                        `where.${filter.column.prop}`,
                        insideFilter.operator || GraphQlQueryEqualityOperator.equals
                    );

                    newVariables = UtilityHelper.setDotNotationPropertyValue(newVariables, property, () => insideFilter.value);
                });
            });
        }

        if (!newVariables.order_by && sort) {
            newVariables.order_by = UtilityHelper.setDotNotationPropertyValue({}, sort.property, () => sort.sortOperation);
        }

        newVariables.first = LamaGraphQlValueWithTypeCreator.createWithPaginationAmountType(rowsPerPage);

        if (page > 0 && rowsTotalCount) {
            newVariables.after = LamaGraphQlQueryHelper.getCursor(page + 1, rowsPerPage, rowsTotalCount);
        }

        if (UtilityHelper.isEmpty(newVariables)) {
            return variables;
        }

        return newVariables;
    };

    const columnProps = columns?.filter((q) => q.prop).map((q) => q.prop!) || ([] as string[]);
    const { data, loading, refetch } = useLamaQuery<PaginationConnection>({
        modelName: modelName || modelNameFromBaseProps,
        queryType: GraphQlQueryType.paginated,
        gqlQuery,
        dotNotationProperties: ['id', idProperty, deleteServerOptions?.idPropertyDotNotation!, ...loadAdditionalProperties, ...columnProps],
        skip: UtilityHelper.isEmpty(columns) || !modelName,
        variables: getQueryVariables()
    });

    const rows = data?.nodes?.length ? data.nodes : (rowsFromProps || []);

    const styles = useStyles();

    const onDeleteConfirmed = async (entityToDelete: BaseEntity) => {
        setLoaderShown(true);

        const entityName = getModelNameTranslation();
        const entityTitle = getEntityTitle(entityToDelete);

        try {
            let modelId = entityToDelete!.id!;

            if (deleteServerOptions) {
                modelName = deleteServerOptions.modelName;
                modelId = UtilityHelper.getDotNotationPropertyValue(entityToDelete, deleteServerOptions.idPropertyDotNotation!) || modelId;
            }

            const mutationResult = await lamaClient.deleteMutateModel({
                modelName,
                modelId
            });

            if (mutationResult.data) {
                reload();

                if (onItemDeleteSuccess) {
                    onItemDeleteSuccess({
                        index: 0,
                        row: entityToDelete,
                        type: LamaGridRowEventType.deleteSuccess
                    });
                }

                addSuccessAlert('shared.alerts.delete.success', {
                    entityName,
                    entityTitle
                });
            }
        } catch (e) {
            addErrorAlert('shared.alerts.delete.error', {
                entityName,
                entityTitle
            });
        } finally {
            setLoaderShown(false);
        }
    };

    const onFilterChange = (column: LamaGridColumn, changes: GraphQlQueryObjectParameter[]) => {
        const filtersWithoutColumn = filters.filter((q) => q.column.id !== column.id);

        setPage(0);

        if (UtilityHelper.isNotEmpty(changes)) {
            setFilters([
                ...filtersWithoutColumn,
                {
                    column,
                    filters: changes
                }
            ]);
        } else {
            setFilters(filtersWithoutColumn);
        }
    };

    const onPageChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const onRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newRowsPerPage = parseInt(event.target.value);

        setRowsPerPage(newRowsPerPage);
    };

    const onSortChange = (newSort: LamaGridSort | undefined) => {
        setPage(0);

        if (newSort) {
            setSort(newSort);
        } else {
            setSort(getDefaultSort());
        }
    };

    const getEntityId = (row: BaseEntity) => {
        if (!row) {
            return '';
        }

        if (idTransformer) {
            return idTransformer(row);
        } else if (idProperty) {
            return UtilityHelper.getDotNotationPropertyValue(row, idProperty);
        }

        return '';
    };

    const getEntityTitle = (row: BaseEntity) => {
        if (!row) {
            return '';
        }

        if (titleTransformer) {
            return titleTransformer(row);
        } else if (titleProperty) {
            return UtilityHelper.getDotNotationPropertyValue(row, titleProperty);
        }

        return '';
    };

    const reload = () => {
        refetch();
    };

    const toggleLoader = (showLoader?: boolean) => {};

    const triggerAction = (rowEvent: LamaGridRowEvent) => {
        const { row, type } = rowEvent;

        if (type === LamaGridRowEventType.delete) {
            addDeleteConfirmationDialog(getEntityTitle(rowEvent.row), {
                onConfirm: () => {
                    onDeleteConfirmed(rowEvent.row);
                }
            });
        } else if (type === LamaGridRowEventType.edit) {
            if (UtilityHelper.isBoolean(actions?.edit) || !(actions?.del as LamaGridAction).triggerEvent) {
                const entityId = getEntityId(row);
                const editUrl = CrudHelper.convertUrlToEditCrudType(history.location.pathname, entityId);

                history.push(editUrl);
            } else if (onItemEdit) {
                onItemEdit(rowEvent);
            }
        }
    };

    useMountEffect(() => {
        const gridInstanceName = addGridInstance({
            reload,
            toggleLoader,
            triggerAction,
            name: gridName
        });

        return () => {
            removeGridInstance(gridInstanceName);
        };
    });

    useEffect(() => {
        const proccessedColumns = columns.map((q) => {
            let filterable = true;
            let sortable = true;

            if (UtilityHelper.isDefined(q.filterable)) {
                filterable = q.filterable!;
            }
            else if (q.valueTransformer) {
                filterable = false;
                sortable = false;
            } else {
                switch (q.type) {
                    case LamaGridColumnType.actions:
                    case LamaGridColumnType.classification:
                    case LamaGridColumnType.enum:
                    case LamaGridColumnType.enumColor:
                    case LamaGridColumnType.date:
                    case LamaGridColumnType.datetime:
                    case LamaGridColumnType.file:
                    case LamaGridColumnType.files:
                    case LamaGridColumnType.picture:
                    case LamaGridColumnType.pictures:
                        filterable = false;

                        break;
                }
            }

            return {
                ...q,
                id: q.id || UtilityHelper.createGuid(),
                filterable,
                sortable
            } as LamaGridColumn;
        });

        if (actions && (actions.del || actions.edit || actions.show)) {
            proccessedColumns.push({
                id: UtilityHelper.createGuid(),
                additionalData: actions,
                titleTranslate: 'shared.grid.actions',
                type: LamaGridColumnType.actions,
                width: '150px'
            });
        }

        setMappedColumns(proccessedColumns);
    }, [columns]);

    useEffect(() => {
        reload();
    }, [filters, rowsPerPage]);

    useEffect(() => {
        setRowsTotalCount(data?.totalCount);
    }, [data?.totalCount]);

    let tabelContainerStyles: CSSProperties | undefined;

    if (fullHeight) {
        tabelContainerStyles = {
            minHeight: rootDimensions.height - paginationDimension.height || 200
        };
    }

    return (
        <div ref={rootRef} className={classNames(styles.root, rootCssClasses)}>
            <LamaLoader
                showLoader={isLoaderShown || loading}
                style={{ marginTop: headDimensions.height + filtersDimensions.height, marginBottom: paginationDimension.height }}
            />
            {mappedColumns.length && (
                <Box display="flex" flexDirection="column" className="table-responsive h-100">
                    <TableContainer className={classNames(containerCssClases, styles.container)} style={tabelContainerStyles}>
                        <Table stickyHeader size="small">
                            <LamaGridHead headRef={headRef} columns={mappedColumns} currentSort={sort} onSortChange={onSortChange} />
                            <TableBody>
                                <LamaGridFilters
                                    rowRef={filtersRef}
                                    columns={mappedColumns}
                                    currentFilters={filters}
                                    gridName={gridName}
                                    onFilterChange={onFilterChange}
                                />
                                {rows.map((row, rowIndex) => {
                                    return (
                                        <TableRow hover tabIndex={-1} key={row.id}>
                                            {mappedColumns.map((column) => {
                                                const { prop, valueTransformer, width } = column;
                                                let cellValue = prop ? UtilityHelper.getDotNotationPropertyValue(row, prop) : '';

                                                if (valueTransformer) {
                                                    if (UtilityHelper.isArray(cellValue)) {
                                                        cellValue = UtilityHelper.copy(cellValue);
                                                    }

                                                    cellValue = valueTransformer(cellValue, row);
                                                }

                                                let styles: CSSProperties = {};

                                                if (UtilityHelper.isString(width)) {
                                                    styles.width = width;
                                                }

                                                return (
                                                    <TableCell key={column.id} style={styles}>
                                                        <LamaGridCellRenderer
                                                            cellValue={cellValue}
                                                            column={column}
                                                            gridName={gridName}
                                                            row={row}
                                                            rowIndex={rowIndex}
                                                        />
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })}
                                {!rows.length && !loading && (
                                    <TableRow>
                                        <TableCell colSpan={mappedColumns.length}>
                                            <FormattedMessage id="shared.emptyData"></FormattedMessage>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {showPagination && (
                        <TablePagination
                            innerRef={paginationRef}
                            rowsPerPageOptions={[25, 50, 75]}
                            component="div"
                            count={data?.totalCount || 0}
                            labelRowsPerPage={<FormattedMessage id="shared.grid.numberOfItemsPerPage" />}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={onPageChange}
                            onChangeRowsPerPage={onRowsPerPageChange}
                        />
                    )}
                </Box>
            )}
        </div>
    );
};
