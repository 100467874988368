import React, { ChangeEvent } from 'react';
import { RcFormlyField } from "@rc-formly/core";

import { LamaAdvancedEnumRadioGroupTypeTemplateOptions } from 'shared/components/forms/models';

import { LamaAdvancedEnumRadioGroup } from 'shared/components/forms/components';

export class LamaAdvancedEnumRadioGroupType extends RcFormlyField<LamaAdvancedEnumRadioGroupTypeTemplateOptions> {
    public onChange = (event: ChangeEvent<HTMLInputElement>) => {
        this.handleChange( event.target.value);
    }

    render() {
        const { enumRadioGroupProps } = this.to || {};

        if (!enumRadioGroupProps) {
            return null;
        }

        const value = this.getFieldValue();

        return (
            <LamaAdvancedEnumRadioGroup {...enumRadioGroupProps} onChange={this.onChange} value={value} />
        )
    }
}
