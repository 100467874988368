import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { types } from 'typed-graphqlify';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { useLamaQuery } from 'shared/graphql';
import { useImporter } from 'features/imports/hooks';

import { LamaLoader, LamaForm } from 'shared/components';
import { ProductType, ProductStatus } from 'features/products/models';

import { Venue, VenueResourceNames } from 'features/venues/models';
import { ImportGlobalSettingsViewModel } from 'features/imports/models';
import { useLamaStepper } from 'shared/components/stepper';
import { useImportGlobalSettingsFormFields } from 'features/imports/forms';
import { ImporterBottomButtons } from './ImporterBottomButtons';

export const ImporterStepFour: FC = () => {
    const { lastImport, loading, importerCheck } = useImporter();
    const { stepCompleted } = useLamaStepper();
    const { data: venues = [], loading: loadingVenues } = useLamaQuery<Venue[]>({
        modelName: VenueResourceNames.venue,
        queryObject: {
            id: types.string,
            title: types.string
        }
    });

    const initialModel: ImportGlobalSettingsViewModel = {
        defaultProductStatus: ProductStatus.selling,
        defaultProductType: ProductType.new,
        defaultVenueIds: venues.map((q) => q.id!)
    };

    const fields = useImportGlobalSettingsFormFields({
        venues
    });

    const onFormSubmit = async (globalSettings: ImportGlobalSettingsViewModel) => {
        if (!lastImport) {
            return;
        }

        await importerCheck({
            ...lastImport,
            globalSettings
        });

        stepCompleted();
    };

    return (
        <Box position="relative">
            <LamaLoader showLoader={loading || loadingVenues} />
            <Typography>
                <FormattedMessage id="import.stepFour.summary" />
            </Typography>
            <Box mt={2}>
                {!loadingVenues && (
                    <LamaForm fields={fields} model={initialModel} onSubmit={onFormSubmit}>
                        <ImporterBottomButtons />
                    </LamaForm>
                )}
            </Box>
        </Box>
    );
};
