export const MIN_DATE = new Date('0001-01-01T00:00:00Z');

const iso8601 = /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/;
const iso8601Extended = /^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(.[0-9]+)?(Z)?$/;

export class DateHelper {
    static convertDaysToSeconds(numberOfDays: number) {
        return numberOfDays * 24 * 60 * 60;
    }

    static isIso8601(value: string) {
        return iso8601.test(value) || iso8601Extended.test(value);
    }

    static convertDaysToMiliseconds(numberOfDays: number) {
        return this.convertDaysToSeconds(numberOfDays) * 1000;
    }

    static fromTimestamp(timestampInSeconds: number) {
        return new Date(timestampInSeconds * 1000);
    }
}
