import { SelectOption } from "shared/types";
import { IFormlyFieldConfig } from "@rc-formly/core";
import { UtilityHelper } from "shared/utilities";

export class FormHelper {
    static convertEnumToSelectOptions(enumType: any, translatePrefix: string) {
        if (enumType) {
            return Object.keys(enumType).map(q => ({
                label: `${translatePrefix}.${q}`,
                value: enumType[q]
            } as SelectOption));
        }

        return [] as SelectOption[];
    }

    static converEnumValueToTranslationMessage(enumValue: string, translationPrefix: string, enumType?: any, ) {
        if (enumType) {
            for (let key of Object.keys(enumType)) {
                if (enumValue === enumType[key]) {
                    return `${translationPrefix}.${key}`;
                }
            }

            return '';
        }

        return `${translationPrefix}.${enumValue}`;
    }

    static getDotNotationPropertiesFromFields(fields: IFormlyFieldConfig[]) {
        if (UtilityHelper.isEmpty(fields)) {
            return [];
        }

        return fields.reduce((acc, field) => {
            if (field.key) {
                acc.push(field.key);
            }

            if (UtilityHelper.isNotEmpty(field.fieldGroup)) {
                const dotNotationProperties = FormHelper.getDotNotationPropertiesFromFields(field.fieldGroup!);

                acc.push(...dotNotationProperties);
            }

            return acc;
        }, [] as string[])
    }
}
