import React, { FC } from 'react';
import classNames from 'classnames';

import { createStyles, makeStyles } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { useLamaSelection } from '../hooks/use-lama-selection';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';

interface SelectionItemProps {
    item: any;
}

const useStyles = makeStyles(theme => {
    const selectionStyle: CSSProperties = {
        border: `6px solid ${theme.palette.primary.main}`,
        padding: 0
    };

    return createStyles({
        selectionWrapper: {
            padding: '6px',
            position: 'relative',
            '&:hover': selectionStyle
        },
        selected: selectionStyle
    });
});

export const LamaSelectionItem: FC<SelectionItemProps> = props => {
    const { children, item } = props;
    const { isSelected, toggleSelectedItem } = useLamaSelection(item);
    const styles = useStyles();

    const onClick = (evt: React.MouseEvent<HTMLDivElement>) => {
        toggleSelectedItem();
    };

    return (
        <div className={classNames(styles.selectionWrapper, { [styles.selected]: isSelected })} onClick={onClick}>
            <Box position="relative">{children}</Box>
            {isSelected && (
                <Box position="absolute" right={-13} top={-13}>
                    <Checkbox defaultChecked color="primary" />
                </Box>
            )}
        </div>
    );
};
