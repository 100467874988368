import React, { FC } from "react";
import { FormGroup } from "reactstrap";

import { LocationViewModel } from "features/locations/models";

import { LamaPlacesAutocomplete } from "./LamaPlacesAutocomplete";
import { LamaMap } from "./LamaMap";

interface LamaPlaceSelectorProps {
    isInvalid?: boolean;
    value?: LocationViewModel;
    onSelect?: (place: LocationViewModel) => void;
}

export const LamaPlaceSelector: FC<LamaPlaceSelectorProps> = (props) => {
    const { isInvalid, onSelect, value } = props;
    const center = value ? value : undefined;
    const shownMapMarkers = value ? [value] : undefined;

    const onMapSelect = (place: LocationViewModel) => {
        if (onSelect) {
            onSelect(place);
        }
    };

    return (
        <div className="position-relative">
            <FormGroup>
                <LamaPlacesAutocomplete isInvalid={isInvalid} onSelect={onSelect} value={value} />
            </FormGroup>
            <LamaMap center={center} shownMarkers={shownMapMarkers} onPointSelect={onMapSelect} />
        </div>
    )
};
