import React from 'react';
import { FormattedMessage } from "react-intl";
import { RcFormlyArrayField } from "@rc-formly/core";

import Button from "@material-ui/core/Button";
import DeleteIcon from '@material-ui/icons/Delete';
import Grid from "@material-ui/core/Grid";
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';

import { KeyValueObject } from 'shared/types';

import { UtilityHelper } from 'shared/utilities';

export class LamaMultipleAddLargeType extends RcFormlyArrayField {
    addNewValue = () => {
        const propertiesToAdd = this.props.field.fieldArray?.fieldGroup?.map(q => q.key) as string[];

        if (UtilityHelper.isNotEmpty(propertiesToAdd)) {
            let newValue: KeyValueObject = {};

            propertiesToAdd!.forEach((property) => {
                const fieldValue = this.getFieldValue(property);
                const lastPropertyName = UtilityHelper.getDotNotationPropertyLast(property);

                if (lastPropertyName) {
                    newValue[lastPropertyName] = fieldValue;
                }

                this.props.formlyProps.setFieldValue(property, null);
            });

            this.pushValue(newValue);
        }
    }

    renderListItem = (item: any, index: number) => {
        let header = '';
        let subHeader = '';

        if (this.to?.headerProp) {
            header = UtilityHelper.getDotNotationPropertyValue(item, this.to.headerProp);
        }

        if (this.to?.subHeaderProp) {
            const subHeaderText = UtilityHelper.getDotNotationPropertyValue(item, this.to.subHeaderProp);

            if (header) {
                subHeader = subHeaderText;
            }
            else {
                header = subHeaderText;
            }
        }

        return (
            <ListItem key={index}>
                <ListItemText primary={header} secondary={subHeader}  />
                <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="delete" onClick={() => this.removeValue(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
            </ListItem>
        );
    }

    renderItems() {
        const values = this.getFieldValue() as any[];

        if (UtilityHelper.isEmpty(values)) {
            return null;
        }

        return (
            <List>
                { values.map(this.renderListItem) }
            </List>
        )
    }

    render() {
        return (
            <div className="multiple-add-wrapper">
                { this.renderFieldGroup() }
                <Grid container justify="flex-end">
                    <Button variant="contained" color="primary" onClick={this.addNewValue}>
                        <FormattedMessage id="shared.add" />
                    </Button>
                </Grid>
                { this.renderItems() }
            </div>
        );
    }
}
