import React, { FC } from 'react';

import { LamaListRouteBase } from 'features/base/components';

import { ImportResourceNames, importStatusGridColorAdditionalData } from 'features/imports/models';
import { LamaGridProps, LamaGridColumnType } from 'shared/components/grid';
import { ImporterListActionCellType } from './components';

export const ImportList: FC = () => {
    const gridOptions: LamaGridProps = {
        columns: [
            {
                type: LamaGridColumnType.datetime,
                titleTranslate: 'shared.updatedAt',
                prop: 'updatedAt'
            },
            {
                type: LamaGridColumnType.number,
                titleTranslate: 'import.numberOfItems',
                prop: 'numberOfItems'
            },
            {
                additionalData: importStatusGridColorAdditionalData,
                type: LamaGridColumnType.enumColor,
                titleTranslate: 'import.status',
                prop: 'status'
            },
            {
                type: LamaGridColumnType.actions,
                component: ImporterListActionCellType,
                titleTranslate: 'shared.grid.actions'
            }
        ],
        serverOptions: {
            modelName: ImportResourceNames.import
        },
        titleProperty: 'updatedAt'
    };

    return <LamaListRouteBase gridProps={gridOptions} />;
};
