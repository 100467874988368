import { useState, useCallback, useLayoutEffect } from 'react';

import { DimensionObject, useDimensionsInterface } from './types';
import { UtilityHelper } from 'shared/utilities';

const getDimensionObject = (node: HTMLElement): DimensionObject => {
    const rect = node.getBoundingClientRect();

    const top = UtilityHelper.isNumber(rect.x) ? rect.x : rect.top;
    const left = UtilityHelper.isNumber(rect.left) ? rect.y : rect.left;

    return {
        top,
        left,
        width: rect.width,
        height: rect.height,
        x: left,
        y: top,
        right: rect.right,
        bottom: rect.bottom
    };
};

const defaultDimensionObject: DimensionObject = {
    bottom: 0,
    height: 0,
    left: 0,
    right: 0,
    top: 0,
    width: 0,
    x: 0,
    y: 0
};

export const useDimensions: useDimensionsInterface = (args?) => {
    const [dimensions, setDimensions] = useState(defaultDimensionObject);
    const [node, setNode] = useState(null as HTMLElement | null);

    const ref = useCallback(node => {
        setNode(node);
    }, []);

    useLayoutEffect(() => {
        if (node) {
            const measure = () => window.requestAnimationFrame(() => setDimensions(getDimensionObject(node)));

            measure();

            const liveMeasure = args?.liveMeasure;
            const liveMeasureResize = liveMeasure || args?.liveMeasureResize;
            const liveMeasureScrolling = liveMeasure || args?.liveMeasureScrolling;

            if (liveMeasure || liveMeasureResize || liveMeasureScrolling) {
                if (liveMeasureResize) {
                    window.addEventListener('resize', measure);
                }

                if (liveMeasureScrolling) {
                    window.addEventListener('scroll', measure);
                }

                return () => {
                    if (liveMeasureResize) {
                        window.removeEventListener('resize', measure);
                    }

                    if (liveMeasureScrolling) {
                        window.removeEventListener('scroll', measure);
                    }
                };
            }
        }
    }, [node, args]);

    return [ref, dimensions, node];
};
