export enum ComparisonResult {
    equal = 'EQUAL',
    more = 'MORE',
    less = 'LESS'
}

export type KeyValueObject<TValue = any> = { [key: string]: TValue };

export type NumberKeyValueObject<TValue = any> = { [key: number]: TValue };

export interface KeyValuePair<TKey = any, TValue = any> {
    key: TKey;
    value: TValue;
}

export interface SelectOption {
    checked?: boolean;
    disabled?: boolean;
    label: string;
    value: string;
}
