import { format } from 'date-fns';
import React, { FC } from 'react';

import { LamaGridCellProps } from 'shared/components/grid/types';
import { EmptyCell } from './EmptyCell';

export const DataCellType: FC<LamaGridCellProps> = props => {
    const { cellValue } = props;

    if (!cellValue) {
        return <EmptyCell />;
    }

    return <span>{format(cellValue, 'dd.MM.yyy')}</span>;
};
