import React, { FC, useRef, useImperativeHandle, Ref, forwardRef, PropsWithChildren } from 'react';
import { Form } from 'reactstrap';
import { IFormlyFieldConfig, IRcFormlyProps, RcFormlyForm } from '@rc-formly/core';

import { LamaRcFormlyRef } from 'shared/components/forms/models';

interface LamaFormProps {
    enableFieldsReinitialize?: boolean;
    fields: IFormlyFieldConfig[];
    innerRef?: Ref<LamaRcFormlyRef>;
    model?: any;
    onSubmit?(model: any, formlyProps: IRcFormlyProps<any>): void;
}

const initialModel = {};

const LamaInnerForm: FC<LamaFormProps> = props => {
    const { children, enableFieldsReinitialize, model = initialModel, innerRef, ...otherProps } = props;
    const ref = useRef<RcFormlyForm>(null);

    useImperativeHandle(innerRef, () => ({
        getFormlyProps: () => ref.current?.getFormlyProps()
    }));

    return (
        <RcFormlyForm
            {...otherProps}
            enableFieldConfigsReinitialize={enableFieldsReinitialize}
            ref={ref}
            model={model}
            render={(formlyProps, renderFields) => {
                return (
                    <Form onSubmit={formlyProps.handleSubmit}>
                        {renderFields()}
                        {children}
                    </Form>
                );
            }}
        ></RcFormlyForm>
    );
};

const LamaFormWithRef: FC<LamaFormProps> = (props, ref: Ref<LamaRcFormlyRef>) => {
    const { children, ...otheProps } = props;

    return (
        <LamaInnerForm {...otheProps} innerRef={ref}>
            {children}
        </LamaInnerForm>
    );
};

export const LamaForm = forwardRef<LamaRcFormlyRef, Omit<PropsWithChildren<LamaFormProps>, 'innerRef'>>(LamaFormWithRef);
