import { Price } from "features/products/models";
import { BaseEntity } from "shared/types";

export interface LamaEntityType extends BaseEntity {
    name: string;
    title: string;
}

export interface LamaFeatureModule extends BaseEntity {
    description?: string;
    summary?: string;
    systemName?: string;
    title: string;

    currentPrice: Price;
    maxQuantity?: number;
    maxVisibleQuantity?: number;

    buyingType: FeatureModuleBuyingType;
    type: FeatureModuleType;

    ownerEntityType?: LamaEntityType;

    currentPriceId?: string;
    ownerEntityTypeId?: string;
}

export enum FeatureModuleBuyingType {
    addon = 'ADDON',
    partOfPackage = 'PARTOFPACKAGE'
}

export enum FeatureModuleType {
    functionality = 'FUNCTIONALITY',
    quantity = 'QUANTITY'
}
