import React, { FC } from 'react';

import { LamaListRouteBase } from 'features/base/components';

import { PackageResourceNames, FeatureModuleBuyingType, FeatureModuleType } from 'features/packs/models';
import { LamaGridProps, LamaGridColumnType, LamaGridEnumColumnAdditionalData } from 'shared/components/grid';

export const FeatureModuleList: FC = () => {
    const gridOptions: LamaGridProps = {
        columns: [
            {
                titleTranslate: 'featureModule.title',
                prop: 'title'
            },
            {
                titleTranslate: 'featureModule.systemName',
                prop: 'systemName'
            },
            {
                titleTranslate: 'featureModule.ownerEntityTypeId',
                prop: 'ownerEntityType.title'
            },
            {
                additionalData: {
                    enumType: FeatureModuleType,
                    translationPrefix: 'featureModule.featureModuleType',
                } as LamaGridEnumColumnAdditionalData,
                type: LamaGridColumnType.enum,
                titleTranslate: 'featureModule.type',
                prop: 'type'
            },
            {
                additionalData: {
                    enumType: FeatureModuleBuyingType,
                    translationPrefix: 'featureModule.featureModuleBuyingType',
                } as LamaGridEnumColumnAdditionalData,
                type: LamaGridColumnType.enum,
                titleTranslate: 'featureModule.buyingType',
                prop: 'buyingType'
            },
            {
                titleTranslate: 'featureModule.maxQuantity',
                prop: 'maxQuantity'
            },
            {
                type: LamaGridColumnType.number,
                titleTranslate: 'price.priceWithTaxAndDiscount',
                prop: 'currentPrice.priceWithTaxAndDiscount'
            },
            {
                type: LamaGridColumnType.datetime,
                titleTranslate: 'shared.updatedAt',
                prop: 'updatedAt'
            }
        ],
        serverOptions: {
            modelName: PackageResourceNames.featureModule
        },
        titleProperty: 'title'
    };

    return <LamaListRouteBase gridProps={gridOptions} />;
};
