import React, { FC } from 'react';

import { TenantModel, CompanyResourceNames, CompanyModel } from 'features/companies/models';
import { singleMeTenantQuery } from 'features/companies/queries';
import { useCompanyFormFields } from './useCompanyFormFields';

import { LamaCreateUpdateBase } from 'features/base/components';
import { singleMeTenantQueryOptions } from 'features/companies/queries/singleTenantQuery';

export const CompanyCreateOrUpdateBase: FC = () => {
    const fields = useCompanyFormFields();

    const getModelTitle = (entity: CompanyModel) => {
        return entity?.title;
    };

    const getInitialModel = () => {
        return {

        } as CompanyModel;
    };

    const transformBeforeEdit = async (entity: TenantModel) => {
        return entity;
    };

    const transformBeforeSubmit = async (viewModel: TenantModel) => {
        const { companyOfTenant } = viewModel;

        return companyOfTenant;
    };

    return (
        <LamaCreateUpdateBase
            editGqlQuery={singleMeTenantQuery}
            editQueryOptions={singleMeTenantQueryOptions}
            getInitialModel={getInitialModel}
            getModelTitle={getModelTitle}
            fields={fields}
            modelName={CompanyResourceNames.company}
            transformBeforeEdit={transformBeforeEdit}
            transformBeforeSubmit={transformBeforeSubmit}
        />
    );
};
