import React, { FC } from "react";

import { ProductViewModel } from "features/products/models";
import { singleProductQuery } from "features/products/queries";

import { LamaTab } from "shared/components/tabs";
import { ProductCreateOrUpdateBase } from "./components";
import { LamaTabsCreateUpdateBase, LamaUpdateWrapperBase } from "features/base/components";

const tabs: LamaTab[] = [
    {
        component: ProductCreateOrUpdateBase,
        name: 'basic',
        labelTranslate: 'product.tabs.basic'
    }
]

export const ProductCreateOrUpdate: FC = () => {
    const getModelTitle = (entity: ProductViewModel) => {
        return entity?.title || '';
    };

    return (
        <LamaUpdateWrapperBase editGqlQuery={singleProductQuery} getModelTitle={getModelTitle}>
            <LamaTabsCreateUpdateBase tabs={tabs} />
        </LamaUpdateWrapperBase>
    );
}
