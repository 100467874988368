import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { LamaTabsProps, LamaTabs } from 'shared/components/tabs';
import { LamaCrudViewType } from 'shared/types';

import { ContentPage } from 'features/base/components/ContentPage';

import { CrudHelper } from 'features/base/utilities';

export const LamaTabsCreateUpdateBase: FC<LamaTabsProps> = props => {
    let { tabs } = props;
    const { location: { pathname } } = useHistory();

    if (CrudHelper.hasUrlCrudType(pathname, LamaCrudViewType.create)) {
        tabs = tabs.map(q => ({
            ...q,
            disabled: q.link !== '/'
        }));
    }

    return (
        <ContentPage>
            <LamaTabs tabs={tabs} />
        </ContentPage>
    );
};
