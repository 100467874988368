import { GridFilterType, LamaGridColumnType, LamaGridTypes } from "shared/components/grid/types";

import { BoolFilterType } from './BoolFilterType';
import { NumberFilterType } from "./NumberFilterType";
import { DateTimeFilterType } from "./DateTimeFilterType";

export { DefaultFilterType } from './DefaultFilterType';

export const gridFilterTypes: GridFilterType[] = [
    { cellType: LamaGridColumnType.boolean, component: BoolFilterType, gridType: LamaGridTypes.lamaGrid },
    { cellType: LamaGridColumnType.date, component: DateTimeFilterType, gridType: LamaGridTypes.lamaGrid },
    { cellType: LamaGridColumnType.number, component: NumberFilterType, gridType: LamaGridTypes.lamaGrid }
];
