import classNames from 'classnames';
import React, { FC } from "react";

import Grid from "@material-ui/core/Grid";
import Fab from "@material-ui/core/Fab";
import DeleteIcon from '@material-ui/icons/Delete';
import { createStyles, makeStyles } from "@material-ui/core/styles";

import { Media, MediaGridSizeType } from "features/media/models";

import { MediaImage } from "./MediaImage";

interface MediaChooserItemProps {
    gridSize?: MediaGridSizeType;
    media: Media;
    onRemoveMedia?: (media: Media) => void;
}

export const useMediaItemStyles = makeStyles((theme) =>
    createStyles({
        dropzone: {
            border: `1px dashed ${theme.palette.text.secondary}`
        },
        media: {
            borderRadius: '5%',
            position: 'relative'
        },
        mediaHeight: {
            height: 200,
        },
        deleteButton: {
            borderRadius: '50%',
            right: 0,
            position: 'absolute',
            top: 0,
            zIndex: 2
        }
    }));

export const MediaChooserItem: FC<MediaChooserItemProps> = (props) => {
    const { gridSize = 2, media, onRemoveMedia } = props;

    const styles = useMediaItemStyles();

    const onClick = () => {
        if (onRemoveMedia) {
            onRemoveMedia(media);
        }
    };

    return (
        <Grid key={media.id} className={styles.media} item xs={gridSize}>
            <Fab size="small" color="secondary" aria-label="delete" className={styles.deleteButton} onClick={onClick}>
                <DeleteIcon />
            </Fab>
            <MediaImage classes={{ root: classNames(styles.media, styles.mediaHeight) }} media={media} />
        </Grid>
    )
}
