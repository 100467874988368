import React, { FC } from 'react';

import { LamaGridCellProps } from 'shared/components/grid/types';

import { MaterialBadge } from 'shared/components';
import { EmptyCell } from './EmptyCell';

export const ClassificationCellType: FC<LamaGridCellProps> = props => {
    const { cellValue } = props;

    if (!cellValue) {
        return <EmptyCell />
    }

    return (
        <MaterialBadge>
            { cellValue }
        </MaterialBadge>
    );
};
