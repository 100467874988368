import React, { FC, ChangeEvent } from "react";
import { useIntl } from "react-intl";

import { LamaRadioGroup } from "./LamaRadioGroup";

import { FormHelper } from "shared/components/forms/utilities";

export interface LamaEnumRadioGroupProps {
    disabled?: boolean;
    enumType: any;
    row?: boolean;
    translatePrefix: string;
    value?: string;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const LamaEnumRadioGroup: FC<LamaEnumRadioGroupProps> = (props) => {
    const { disabled, enumType, translatePrefix, ...otherProps } = props;
    const { formatMessage } = useIntl();

    const options = FormHelper.convertEnumToSelectOptions(enumType, translatePrefix).map(q => ({
        ...q,
        disabled,
        label: formatMessage({ id: q.label })
    }));

    return (
        <LamaRadioGroup {...otherProps} options={options} />
    )
};
