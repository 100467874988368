import { IMainState } from 'store/reducers';

import { UtilityHelper } from 'shared/utilities';

export const isAuthenticatedSelector = (mainState: IMainState) => UtilityHelper.isNotEmpty(mainState.authentication.tokenResponse?.accessToken);
export const getAccountSelector = (mainState: IMainState) => mainState.authentication.account;
export const getAccessTokenSelector = (mainState: IMainState) => mainState.authentication?.tokenResponse?.accessToken;
export const getCurrentActiveTenantId = (mainState: IMainState) => mainState.authentication.currentActiveTenantId;
export const getRefreshTokenSelector = (mainState: IMainState) => mainState.authentication?.tokenResponse?.refreshToken;
export const hasRefreshTokenSelector = (mainState: IMainState) => UtilityHelper.isNotEmpty(mainState.authentication.tokenResponse?.refreshToken);
