import { PackageViewModel, LamaFeatureModule, FeatureModuleType } from 'features/packs/models';
import { BaseEntity, KeyValueObject } from 'shared/types';
import { Price } from 'features/products/models';

export enum PackageContractFeatureModule {
    core = 'CORE',
}

export enum SubscriptionDurationType {
    halfYearly = 'HALFYEARLY',
    infinite = 'INFINITE',
    monthly = 'MONTHLY',
    quarterly = 'QUARTERLY',
    yearly = 'YEARLY'
}

export enum SubscriptionRenewalType {
    initial = 'INITIAL',
    autoRenewed = 'AUTORENEWED',
    renewalApprovedByUser = 'RENEWALAPPROVEDBYUSER'
}

export enum SubscriptionType {
    free = 'free',
    trial = 'trial',
    paid = 'paid'
}

export interface PackageContractFeatureModuleViewModel {
    autoRenew: boolean;
    currentFeatureModulePrice: Price;
    maxQuantity: number;
    maxVisibleQuantity: number;
    ownerFeatureModule: LamaFeatureModule;
    type: PackageContractFeatureModule;
}

export interface PackageContractConstrainsViewModel {
    currentNumberOfAccounts: number;
    currentNumberOfProducts: number;
    currentNumberOfVenues: number;
}

export interface PackageContractViewModel extends BaseEntity {
    autoRenew: boolean;
    contractFeatureModules: PackageContractFeatureModuleViewModel[];
    currentPackagePrice: Price;
    durationType: SubscriptionDurationType;
    ended: Date;
    ends: Date;
    hasEnded?: boolean;
    renewalType: SubscriptionRenewalType;
    ownerPackage: PackageViewModel;
    starts: Date;
    trialPeriodInDays: number;
    type: SubscriptionType;
}

export interface PackageContractWithConstrainsViewModel extends PackageContractViewModel, PackageContractConstrainsViewModel {
    maxNumberOfAccounts: number;
    maxNumberOfProducts: number;
    maxNumberOfVenues: number;
}
