import { MutationGenerateNameOptions, GraphQlQueryEqualityOperator, GraphQlQueryType } from 'shared/graphql/types';
import { LanguageHelper } from 'shared/utilities';

export class LamaGrapQlNamingHelper {
    public static createListQueryName(modelName: string) {
        const pluralModuleName = LanguageHelper.pluralize(modelName);

        return `all${LanguageHelper.capitalize(pluralModuleName)}`;
    }

    public static createPaginatedQueryName(modelName: string) {
        const pluralModuleName = LanguageHelper.pluralize(modelName);

        return `all${LanguageHelper.capitalize(pluralModuleName)}Paged`;
    }

    public static createSingleQueryName(modelName: string) {
        return LanguageHelper.pluralize(modelName);
    }

    public static createQueryNameFromType(modelName: string, queryType: GraphQlQueryType) {
        if (queryType === GraphQlQueryType.paginated) {
            return LamaGrapQlNamingHelper.createPaginatedQueryName(modelName);
        }
        else if (queryType === GraphQlQueryType.single || queryType === GraphQlQueryType.singleMe) {
            return LamaGrapQlNamingHelper.createSingleQueryName(modelName);
        }
        else if (queryType === GraphQlQueryType.customList) {
            return modelName;
        }

        return LamaGrapQlNamingHelper.createListQueryName(modelName);
    }

    public static createMutationInputTypeName(mutationOptions: MutationGenerateNameOptions) {
        const { crudOperationType, modelName } = mutationOptions;

        const capitalizedModelName = LanguageHelper.capitalize(modelName);
        const capitalizedOperationType = LanguageHelper.capitalize(crudOperationType);

        return `${capitalizedModelName}${capitalizedOperationType}Input`;
    }

    public static createMutationInputName(mutationOptions: MutationGenerateNameOptions) {
        const { crudOperationType } = mutationOptions;
        let modelName = LamaGrapQlNamingHelper.getModelName(mutationOptions);

        const capitalizedOperationType = LanguageHelper.capitalize(crudOperationType);

        return `${modelName}${capitalizedOperationType}Input`;
    }

    public static createMutationName(mutationOptions: MutationGenerateNameOptions) {
        const { crudOperationType } = mutationOptions;
        let modelName = LamaGrapQlNamingHelper.getModelName(mutationOptions);

        const capitalizedModelName = LanguageHelper.capitalize(modelName);

        return `${crudOperationType}${capitalizedModelName}`;
    }

    public static getFilterPropertyName(prop: string, operator: GraphQlQueryEqualityOperator) {
        switch(operator) {
            case GraphQlQueryEqualityOperator.contains:
                return `${prop}_contains`;

            case GraphQlQueryEqualityOperator.higher:
                return `${prop}_gt`;

            case GraphQlQueryEqualityOperator.higherOrEquals:
                return `${prop}_gte`;

            case GraphQlQueryEqualityOperator.lower:
                return `${prop}_lt`;

            case GraphQlQueryEqualityOperator.lowerOrEquals:
                return `${prop}_lte`;

            default:
                return prop;
        }
    }

    private static getModelName(mutationOptions: MutationGenerateNameOptions) {
        const { modelName, isBulk = false } = mutationOptions;

        if (isBulk) {
            return LanguageHelper.pluralize(modelName);
        }

        return modelName;
    }
}
