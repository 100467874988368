import React, { FC, useRef } from 'react';

import { LamaFormlyFieldConfig, LamaFormlyWrappers, LamaFormlyTypes, TemplateOptionsExtended, LamaSelectTypeTemplateOptions } from 'shared/components/forms/models';
import { AccountCreateEditViewModel, AccountResourceNames } from 'features/accounts/models';

import { LamaCreateUpdateBase, CreateUpdateBaseRef, ContentPage } from 'features/base/components';

export const AccountCreateOrUpdate: FC = () => {
    const baseCrudRef = useRef<CreateUpdateBaseRef>(null);

    const fields: LamaFormlyFieldConfig[] = [
        {
            wrappers: [LamaFormlyWrappers.card, LamaFormlyWrappers.submitButton],
            fieldGroup: [
                {
                    key: 'email',
                    type: LamaFormlyTypes.input,
                    wrappers: [LamaFormlyWrappers.formGroup],
                    templateOptions: {
                        labelTranslate: 'account.email',
                        required: true,
                        type: 'email'
                    }
                },
                {
                    key: 'password',
                    type: LamaFormlyTypes.input,
                    wrappers: [LamaFormlyWrappers.formGroup],
                    templateOptions: {
                        labelTranslate: 'account.password',
                        minLength: 6,
                        required: true,
                        type: 'password'
                    }
                },
                {
                    key: 'firstName',
                    type: LamaFormlyTypes.input,
                    wrappers: [LamaFormlyWrappers.formGroup],
                    templateOptions: {
                        labelTranslate: 'account.firstName'
                    }
                },
                {
                    key: 'lastName',
                    type: LamaFormlyTypes.input,
                    wrappers: [LamaFormlyWrappers.formGroup],
                    templateOptions: {
                        labelTranslate: 'account.lastName'
                    }
                },
                {
                    key: 'mobileNumber',
                    type: LamaFormlyTypes.input,
                    wrappers: [LamaFormlyWrappers.formGroup],
                    templateOptions: {
                        labelTranslate: 'account.mobileNumber'
                    }
                },
                {
                    key: 'isEmailVerified',
                    type: LamaFormlyTypes.checkbox,
                    wrappers: [LamaFormlyWrappers.formGroup],
                    templateOptions: {
                        labelTranslate: 'account.isEmailVerified',
                        hideLabel: true
                    } as TemplateOptionsExtended
                },
                {
                    key: 'isMobileNumberVerified',
                    type: LamaFormlyTypes.checkbox,
                    wrappers: [LamaFormlyWrappers.formGroup],
                    templateOptions: {
                        labelTranslate: 'account.isMobileNumberVerified',
                        hideLabel: true
                    } as TemplateOptionsExtended
                },
                {
                    key: 'roleId',
                    type: LamaFormlyTypes.select,
                    wrappers: [LamaFormlyWrappers.formGroup],
                    templateOptions: {
                        labelTranslate: 'account.onRoles',
                        lamaSelectProps: {
                            bindLabel: 'title',
                            bindValue: 'id',
                            serverOptions: {
                                modelName: AccountResourceNames.role
                            }
                        }
                    } as LamaSelectTypeTemplateOptions
                }
            ]
        }
    ];

    const getModelTitle = (entity: AccountCreateEditViewModel) => {
        return entity.fullName || entity.email;
    };

    const getInitialModel = () => {
        return {
            email: '',
            isEmailVerified: false,
            isMobileNumberVerified: false,
            onRoles: []
        } as AccountCreateEditViewModel;
    };

    return (
        <ContentPage>
            <LamaCreateUpdateBase
                ref={baseCrudRef}
                getModelTitle={getModelTitle}
                getInitialModel={getInitialModel}
                fields={fields}
                modelName={AccountResourceNames.account}
            />
        </ContentPage>
    );
};
