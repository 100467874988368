import React, { FC, useContext, useEffect, useState } from 'react';

import { LamaDialogContext, LamaDialogOptions } from '../hooks';

import { UtilityHelper } from 'shared/utilities';
import { LamaDialog } from './LamaDialog';

export const DialogContainer: FC = () => {
    const { defaultAutoHideAfterOk, dialogs, closeDialog } = useContext(LamaDialogContext);
    const [shownDialogId, setShowDialogId] = useState<string>();

    const firstDialogId = dialogs.length ? dialogs[0].id : '';

    useEffect(() => {
        if (firstDialogId) {
            setShowDialogId(firstDialogId);
        }
    }, [firstDialogId]);

    const onDialogClose = (lamaDialogOptions: LamaDialogOptions) => () => {
        closeDialog(lamaDialogOptions.id);

        if (lamaDialogOptions.onClose) {
            lamaDialogOptions.onClose();
        }
    };

    const onDialogConfirm = (lamaDialogOptions: LamaDialogOptions) => () => {
        if (defaultAutoHideAfterOk) {
            closeDialog(lamaDialogOptions.id);
        }

        if (lamaDialogOptions.onConfirm) {
            lamaDialogOptions.onConfirm();
        }
    };

    const renderDialog = (lamaDialogOptions: LamaDialogOptions) => {
        const { id, onClose, onConfirm, ...otherProps } = lamaDialogOptions;

        return (
            <LamaDialog
                key={id}
                {...otherProps}
                isOpen={firstDialogId === id}
                onClose={onDialogClose(lamaDialogOptions)}
                onConfirm={onDialogConfirm(lamaDialogOptions)}
            />
        );
    };

    if (UtilityHelper.isEmpty(dialogs)) {
        return null;
    }

    return <>{dialogs.map(renderDialog)}</>;
};
