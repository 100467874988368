import React, { FC } from 'react';

import { PackageResourceNames, LamaFeatureModule, FeatureModuleType, FeatureModuleBuyingType } from 'features/packs/models';
import { singleFeatureModuleQuery } from 'features/packs/queries';

import { LamaCreateUpdateBase } from 'features/base/components';

import { useFeatureModuleFormFields } from './useFeatureModuleFormFields';

export const FeatureModuleCreateOrUpdateBase: FC = () => {
    const fields = useFeatureModuleFormFields();

    const getModelTitle = (entity: LamaFeatureModule) => {
        return entity?.title;
    };

    const getInitialModel = () => {
        return {
            buyingType: FeatureModuleBuyingType.partOfPackage,
            type: FeatureModuleType.functionality
        } as LamaFeatureModule;
    };

    const transformBeforeEdit = async (entity: LamaFeatureModule) => {
        return entity;
    };

    const transformBeforeSubmit = async (viewModel: LamaFeatureModule) => {
        delete viewModel.currentPrice?.ownerTax;

        return viewModel;
    };

    return (
        <LamaCreateUpdateBase
            editGqlQuery={singleFeatureModuleQuery}
            getInitialModel={getInitialModel}
            getModelTitle={getModelTitle}
            fields={fields}
            modelName={PackageResourceNames.featureModule}
            transformBeforeEdit={transformBeforeEdit}
            transformBeforeSubmit={transformBeforeSubmit}
        />
    );
};
