import React, { FC } from "react";

import { LamaTab } from "shared/components/tabs";

import { TenantCreateOrUpdateBase } from "./components";
import { LamaTabsCreateUpdateBase } from "features/base/components";

const tabs: LamaTab[] = [
    {
        component: TenantCreateOrUpdateBase,
        name: 'basic',
        labelTranslate: 'company.tabs.basic'
    }
]

export const TenantCreateOrUpdate: FC = () => {
    return (
        <LamaTabsCreateUpdateBase tabs={tabs} />
    );
}
