import React, { FC } from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { Media } from 'features/media/models';
import { MediaFileChooser } from './MediaFileChooser';
import { FormattedMessage } from 'react-intl';
import { useLamaSelection } from 'shared/components/selection';

const useStyles = makeStyles(() =>
    createStyles({
        dialogPaper: {
            minHeight: '80vh',
            maxHeight: '80vh'
        },
        dialogContent: {
            display: 'flex'
        }
    }));

interface MediaFileChooserModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSelectedItems: (media: Media[]) => void;
}

export const MediaFileChooserModal: FC<MediaFileChooserModalProps> = props => {
    const { isOpen, onClose, onSelectedItems } = props;

    const { selectedItems } = useLamaSelection<Media>();
    const styles = useStyles();

    const handleClose = () => {
        onClose();
    };

    const handleConfirm = () => {
        onSelectedItems(selectedItems);
    };

    return (
        <Dialog classes={{ paper: styles.dialogPaper  }} fullWidth={true} maxWidth="xl" open={isOpen} onClose={handleClose} aria-labelledby="max-width-dialog-title">
            <DialogTitle id="max-width-dialog-title">
                <FormattedMessage id="media.chooser.chooseImages" />
            </DialogTitle>
            <DialogContent classes={{ root: styles.dialogContent }}>
                <MediaFileChooser />
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleClose} color="primary">
                    <FormattedMessage id="shared.cancel" />
                </Button>
                <Button onClick={handleConfirm} variant="contained" color="primary">
                    <FormattedMessage id="shared.confirm" />
                </Button>
            </DialogActions>
        </Dialog>
    );
};

