import React, { FC, useMemo } from 'react';
import { Pie, PieChart, Cell, ResponsiveContainer } from 'recharts';
import { FormattedMessage } from 'react-intl';

import { useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import { useCurrentContract } from 'features/contracts/hooks';
import { LamaCollapsibleCard, LamaLoader } from 'shared/components';

interface ConstrainChartProps {
    numberOfItems: number;
    maxNumberOfItems: number;
}

interface ChartDataItem {
    color: string;
    value: number;
}

const ConstrainChart: FC<ConstrainChartProps> = (props) => {
    const { maxNumberOfItems, numberOfItems } = props;
    const theme = useTheme();
    const itemsRatio = Math.min(numberOfItems / maxNumberOfItems, 1);

    const data = useMemo(() => {
        let color = theme.palette.success.main;

        if (itemsRatio > 0.75) {
            color = theme.palette.error.main;
        } else if (itemsRatio > 0.5) {
            color = theme.palette.warning.main;
        }

        return [
            {
                color: theme.palette.grey[300],
                value: 1 - itemsRatio
            },
            {
                color,
                value: itemsRatio
            }
        ] as ChartDataItem[];
    }, [maxNumberOfItems, numberOfItems]);

    return (
        <Box height="100%" position="relative">
            <ResponsiveContainer height="100%" width="100%">
                <PieChart>
                    <Pie data={data} dataKey="value" isAnimationActive={false} innerRadius="80%" outerRadius="100%" startAngle={90} endAngle={470}>
                        {data.map((dataItem) => (
                            <Cell key={dataItem.color} fill={dataItem.color} />
                        ))}
                    </Pie>
                </PieChart>
            </ResponsiveContainer>
            <Box display="flex" className="fill-container">
                <Box alignSelf="center" textAlign="center" width="100%">
                    {(itemsRatio * 100).toFixed(0)}%
                </Box>
            </Box>
        </Box>
    );
};

export const PackageConstrainsCard: FC = () => {
    const { currentContract, loading } = useCurrentContract();

    return (
        <LamaCollapsibleCard>
            <LamaLoader showLoader={loading} />
            {!loading && currentContract && (
                <Box display="flex" flexDirection="row">
                    <Box>
                        <h3 className="fw-semi-bold mb-15">
                            <FormattedMessage id="modules.models.package" />:{' '}
                            <span className="text-uppercase">{currentContract?.ownerPackage.title}</span>
                        </h3>
                        <Box mb={1}>
                            <span className="fs-12 d-block text-muted">
                                <span>
                                    <FormattedMessage id="packageContract.currentNumberOfProducts" />
                                </span>
                            </span>
                            <span className="counter-point">
                                {currentContract?.currentNumberOfProducts} / {currentContract?.maxNumberOfProducts}
                            </span>
                        </Box>
                        <Box mb={1}>
                            <span className="fs-12 d-block text-muted">
                                <span>
                                    <FormattedMessage id="packageContract.currentNumberOfAccounts" />
                                </span>
                            </span>
                            <span className="counter-point">
                                {currentContract?.currentNumberOfAccounts} / {currentContract?.maxNumberOfAccounts}
                            </span>
                        </Box>
                        <Box>
                            <span className="fs-12 d-block text-muted">
                                <span>
                                    <FormattedMessage id="packageContract.currentNumberOfVenues" />
                                </span>
                            </span>
                            <span className="counter-point">
                                {currentContract?.currentNumberOfVenues} / {currentContract?.maxNumberOfVenues}
                            </span>
                        </Box>
                    </Box>
                    <Box flexGrow={1} pl={2}>
                        <ConstrainChart
                            numberOfItems={currentContract.currentNumberOfProducts}
                            maxNumberOfItems={currentContract.maxNumberOfProducts}
                        />
                    </Box>
                </Box>
            )}
        </LamaCollapsibleCard>
    );
};
