import React, { FC, useMemo, useCallback, useState, useEffect } from 'react';
import { createEditor, Node, Transforms } from 'slate';
import { withHistory } from 'slate-history';
import { Editable, withReact, Slate } from 'slate-react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import { MarkButton } from './MarkButton';
import { SlateElement } from './SlateElement';
import { SlateLeaf } from './SlateLeaf';
import { Toolbar } from './Toolbar';
import { deserializeHtml, serializeHtml } from './utilities';
import { UtilityHelper } from 'shared/utilities';

import { withHtml } from './withHtml';

export interface LamaEditorProps {
    disabled?: boolean;
    name: string;
    isInvalid?: boolean;
    placeholder?: string;
    value?: string;
    onBlur?: (currentHtml: string) => void;
    onContentChange?: (html: string) => void;
}

const initialValue: Node[] = [
    {
        type: 'paragraph',
        children: [{ text: '' }]
    }
];

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            border: `1px solid ${theme.palette.grey[300]} `
        },
        editable: {
            minHeight: '8rem',
            padding: '1rem'
        }
    })
);

export const LamaEditor: FC<LamaEditorProps> = (props) => {
    const { disabled: readonly, isInvalid, onBlur, placeholder, value = '' } = props;

    const [nodeValues, setNodeValues] = useState<Node[]>(initialValue);
    const renderElement = useCallback((props) => <SlateElement {...props} />, []);
    const renderLeaf = useCallback((props) => <SlateLeaf {...props} />, []);
    const editor = useMemo(() => withHtml(withReact(withHistory(createEditor()))), []);
    const classes = useStyles();

    const onEditorChange = (nodes: Node[]) => {
        setNodeValues(nodes);
    };

    const onEditorBlur = () => {
        if (onBlur) {
            const html = serializeHtml({
                children: nodeValues
            });

            onBlur(html);
        }
    };

    useEffect(() => {
        if (UtilityHelper.isNotEmpty(value)) {
            const newNodes = deserializeHtml(value);

            if (UtilityHelper.isNotEmpty(newNodes)) {
                Transforms.deselect(editor);
                setNodeValues(newNodes);

                return;
            }
        }

        setNodeValues(initialValue);
    }, [value]);

    return (
        <Slate editor={editor} value={nodeValues} onChange={onEditorChange}>
            <Box className={classes.root}>
                <Toolbar>
                    <MarkButton format="bold" icon="format_bold" />
                    <MarkButton format="italic" icon="format_italic" />
                    <MarkButton format="underline" icon="format_underlined" />
                    {/* <MarkButton format="code" icon="code" />
                    <BlockButton format="heading-one" icon="looks_one" />
                    <BlockButton format="heading-two" icon="looks_two" />
                    <BlockButton format="block-quote" icon="format_quote" />
                    <BlockButton format="numbered-list" icon="format_list_numbered" />
                    <BlockButton format="bulleted-list" icon="format_list_bulleted" /> */}
                </Toolbar>
                <Editable
                    className={classes.editable}
                    onBlur={onEditorBlur}
                    readOnly={readonly}
                    renderElement={renderElement}
                    renderLeaf={renderLeaf}
                    placeholder={placeholder}
                />
            </Box>
        </Slate>
    );
};
