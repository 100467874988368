import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import { useLamaStepper } from 'shared/components/stepper';
import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => createStyles({
    submitButton: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4)
    }
}));

interface ImporterBottomButtons {
    buttonLabel?: string;
    onSubmit?(): void;
}

export const ImporterBottomButtons: FC<ImporterBottomButtons> = (props) => {
    const { buttonLabel = 'shared.forward', onSubmit } = props;
    const { back } = useLamaStepper();

    const classes = useStyles();

    const onBackClick = () => {
        back();
    }

    return (
        <Box display="flex">
            <Button variant="contained" color="default" type="button" onClick={onBackClick}>
                <FormattedMessage id="shared.back" />
            </Button>
            <Box ml={3}>
                <Button className={classes.submitButton} variant="contained" color="primary" type="submit" onClick={onSubmit}>
                    <FormattedMessage id={buttonLabel} />
                </Button>
            </Box>
        </Box>
    );
};
