import classNames from 'classnames';
import React, { FC } from 'react';
import { useDropzone } from 'react-dropzone';
import { FormattedMessage } from 'react-intl';

import { createStyles, makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import { IconFromValue } from 'shared/components';

const useStyles = makeStyles((theme) =>
    createStyles({
        dropzone: {
            alignItems: 'center',
            border: `3px dashed ${theme.palette.grey[300]}`,
            cursor: 'pointer',
            display: 'flex',
            flexDirection: 'row',
            height: '100%',
            justifyContent: 'center'
        },
        helperText: {
            alignSelf: 'center'
        },
        icon: {
            fontSize: '2.4rem'
        }
    })
);

interface MediaFileDropzoneProps {
    accept?: string;
    classes?: {
        root?: string;
        text?: string;
    }
    multiple?: boolean;
    onSelectedFiles?: (files: File[]) => void;
}

export const MediaFileDropzone: FC<MediaFileDropzoneProps> = (props) => {
    const { accept = 'image/*', multiple = true, classes: { root, text } = { root: '', text: '' }, onSelectedFiles } = props;

    const { getRootProps, getInputProps } = useDropzone({
        accept,
        multiple,
        onDrop: (acceptedFiles) => {
            if (onSelectedFiles) {
                onSelectedFiles(acceptedFiles);
            }
        }
    });

    const { dropzone, helperText, icon } = useStyles();

    return (
        <div {...getRootProps()} className={classNames(dropzone, root)}>
            <input {...getInputProps()} />
            <Box display="flex" flexDirection="column" alignItems="center">
                <IconFromValue className={icon} iconValue="material-icons arrow_upward" />
                <p className={classNames(helperText, text)}>
                    <FormattedMessage id="shared.dropzone.dragAndDrop" values={{
                        b: (...chunks: string[]) => <b>{chunks}</b>,
                        br: <br />
                    }} />
                </p>
            </Box>
        </div>
    );
};
