export interface AccountFromJwt {
    accountId: string;
    claims: string[];
    fullName: string;
    roles: string[];
    tenantId: string;
    username: string;
}

export interface JwtDecryptedToken {
    exp: number;
    iss: string;
    aud: string[];
    role: string;
    permission: string[];
    nameid: string;
    given_name: string;
    unique_name: string;
}

export enum LamaAuthorizationType {
    assign = 'assign',
    create = 'create',
    delete = 'delete',
    edit = 'edit',
    list = 'list',
    me = 'me',
    view = 'view'
}

export interface PasswordRequestTokenInput {
    clientId: string;
    email: string;
    password: string;
}

export interface RefreshTokenRequestInput {
    clientId: string;
    clientSecret?: string;
    refreshToken: string;
}

export interface TokenResponseViewModel {
    accessToken: string;
    refreshToken: string;

    issued: Date;
    expires: Date;
    expiresIn: number;
}
