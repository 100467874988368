import React, { FC } from 'react';

import { PackageViewModel, PackageType, PackageResourceNames } from 'features/packs/models';
import { singlePackageQuery } from 'features/packs/queries';

import { LamaCreateUpdateBase } from 'features/base/components';

import { usePackageFormFields } from './usePackageFormFields';

export const PackageCreateOrUpdateBase: FC = () => {
    const fields = usePackageFormFields();

    const getModelTitle = (entity: PackageViewModel) => {
        return entity?.title;
    };

    const getInitialModel = () => {
        return {
            autoRenew: false,
            type: PackageType.custom
        } as PackageViewModel;
    };

    const transformBeforeEdit = async (entity: PackageViewModel) => {
        return entity;
    };

    const transformBeforeSubmit = async (viewModel: PackageViewModel) => {
        delete viewModel.currentPrice?.ownerTax;

        return viewModel;
    };

    return (
        <LamaCreateUpdateBase
            editGqlQuery={singlePackageQuery}
            getInitialModel={getInitialModel}
            getModelTitle={getModelTitle}
            fields={fields}
            modelName={PackageResourceNames.package}
            transformBeforeEdit={transformBeforeEdit}
            transformBeforeSubmit={transformBeforeSubmit}
        />
    );
};
