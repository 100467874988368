import { GraphQlValueType } from "shared/graphql/types/utilities";
import { LamaGraphQlValueWithType } from "shared/graphql/types/client";
import { UtilityHelper } from "shared/utilities";

export class LamaGraphQlValueWithTypeCreator {
    public static createWithPaginationAmountType<TEntity = any>(value: TEntity): LamaGraphQlValueWithType<TEntity, GraphQlValueType.paginationAmount> {
        return {
            value,
            valueType: GraphQlValueType.paginationAmount
        };
    }
}

export const isGraphQlValueWithType = (obj: LamaGraphQlValueWithType) => UtilityHelper.isNotEmpty(obj) && UtilityHelper.isNotEmpty(obj.valueType);
