import React, { FC, CSSProperties } from "react";
import Scrollbars from 'react-custom-scrollbars';

import { Logo } from 'features/base/components/Logo';
import { SidebarContent } from "./SidebarContent";
import { UserBlock } from "./UserBlock";

const scrollbarStyle: CSSProperties = {
    height: 'calc(100vh - 60px)'
};

export const AppSidebar: FC = () => {
    return (
        <div className="rct-sidebar background-none">
            <div className="rct-sidebar-content sidebar-overlay-dark">
                <Logo />
                <div className="rct-sidebar-wrap">
                    <Scrollbars
                        className="rct-scroll"
                        autoHide
                        autoHideDuration={100}
                        style={scrollbarStyle}
                    >
                        <UserBlock />
                        <SidebarContent />
                    </Scrollbars>
                </div>
            </div>
        </div>
    );
}
