import React, { FC, useState, useImperativeHandle, forwardRef, Ref, useEffect, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useApolloClient, DocumentNode, ApolloError } from '@apollo/client';
import { useIntl } from 'react-intl';
import { IRcFormlyProps, IFormlyFieldConfig } from '@rc-formly/core';

import { CreateUpdateBaseRef } from './types';
import { RouteDefaultData, LamaCrudRouteParams } from 'features/base/models';
import { LamaRcFormlyRef } from 'shared/components/forms/models';
import { BaseEntity, KeyValueObject, LamaCrudViewType } from 'shared/types';

import { LamaGraphQlQueryHelper, useLamaClient, LamaCrudOperationType, GraphQlQueryType, GraphQlErrorTypes, QueryFromDotNotationOptions, GetQueryDataOptions, LamaGraphQlError } from 'shared/graphql';

import { useModularRoute } from 'shared/packages/react-modular';
import { useLamaAlerts } from 'shared/packages/alerts';

import { LamaForm, LamaLoader } from 'shared/components';

import { useLamaGrid } from 'shared/components/grid';

import { UtilityHelper, Logger, LanguageHelper } from 'shared/utilities';
import { useRapidModel } from 'shared/packages/rapid-model';
import { CrudHelper } from 'features/base/utilities';
import { FormHelper } from 'shared/components/forms/utilities';

interface CreateUpdateBaseProps<
    TEntity extends BaseEntity = any,
    TSubmitEntity extends BaseEntity = TEntity,
    TEditEntity extends BaseEntity = TEntity
> {
    editGqlQuery?: DocumentNode;
    editQueryOptions?: QueryFromDotNotationOptions;
    modelName: string;
    fields: IFormlyFieldConfig[];
    innerRef?: Ref<CreateUpdateBaseRef>;
    isEditMode?: boolean;
    isJoinedModel?: boolean;
    getInitialModel?: () => TEntity;
    getModelTitle: (entity: TEntity) => string;
    loadEditModel?: () => Promise<TEditEntity>;
    transformBeforeEdit?: (entityToEdit: TEntity) => Promise<TEditEntity>;
    transformBeforeSubmit?: (entityToSubmit: TEntity, isEditMode: boolean) => Promise<TSubmitEntity>;
    onSubmitSuccess?: (submittedEntity: TEntity) => void;
}

const serverErrorsTranslationPrefix = 'serverErrors';

const CreateUpdateBaseInner: FC<CreateUpdateBaseProps> = props => {
    const {
        editQueryOptions, fields, modelName, getInitialModel,
        getModelTitle, loadEditModel: loadEditModelFromProps,
        innerRef, isEditMode: isEditModeFromProps,
        isJoinedModel,
        transformBeforeEdit,
        transformBeforeSubmit,
        onSubmitSuccess
    } = props;
    let { editGqlQuery } = props;
    const initialModel = getInitialModel ? getInitialModel() : {};

    const apolloClient = useApolloClient();
    const { formatMessage } = useIntl();
    const history = useHistory();
    const lamaClient = useLamaClient();
    const { addSuccessAlert, addErrorAlert, addWarningAlert } = useLamaAlerts();
    const { currentRoute } = useModularRoute<RouteDefaultData>();
    const { reload: gridReload } = useLamaGrid();
    const { currentModel, getModelNameTranslation } = useRapidModel({
        defaultModelName: modelName,
        getModelTitleFunc: getModelTitle
    });
    const { id } = useParams<LamaCrudRouteParams>();

    const defaultEditMode = UtilityHelper.isBoolean(isEditModeFromProps) ? isEditModeFromProps! : CrudHelper.isEditRoute(currentRoute);
    const isMeRoute = currentRoute?.data?.crudViewType === LamaCrudViewType.me;

    const lamaFormRef = useRef<LamaRcFormlyRef>(null);
    const [isEditMode, setEditMode] = useState(defaultEditMode);
    const [showLoader, setShowLoader] = useState(false);
    const [entityId, setEntityId] = useState(id);

    useImperativeHandle(innerRef, () => ({
        getFormlyProps: () => lamaFormRef.current?.getFormlyProps()!,
        isEditMode: () => {
            return isEditMode;
        },
        triggerEdit: (id: string) => {
            loadEditModel(id);
        },
        triggerEditMode: (modelId: string) => {
            setEntityId(modelId);
            setEditMode(true);
        },
        toggleEditMode: (editMode?: boolean) => {
            const newEditMode = UtilityHelper.isBoolean(editMode) ? editMode! : isEditMode;

            setEditMode(newEditMode);

            if (!newEditMode) {
                lamaFormRef.current?.getFormlyProps()?.resetForm(initialModel);
            }
        },
        triggerEditModeFromValues: (modelValues: any) => {
            setEditMode(true);

            lamaFormRef.current?.getFormlyProps()?.resetForm(modelValues);
        }
    }));

    useEffect(() => {
        if (!isEditMode) {
            if (entityId) {
                setEntityId(undefined);
            }
        } else if (isEditMode && (entityId || isMeRoute)) {
            loadEditModel(entityId);
        }
    }, [isEditMode, entityId]);

    useEffect(() => {
        setEditMode(defaultEditMode);
    }, [defaultEditMode]);

    const entityName = getModelNameTranslation();

    const loadEditModel = async (id?: string) => {
        if (!editGqlQuery && !loadEditModelFromProps && !currentModel) {
            const dotNotationProperties = FormHelper.getDotNotationPropertiesFromFields(fields);

            const editQueryResult = LamaGraphQlQueryHelper.createQueryFromDotNotation({
                modelName,
                dotNotationProperties: ['id', ...dotNotationProperties],
                queryType: GraphQlQueryType.single
            });

            editGqlQuery = editQueryResult.gqlQuery;
        }

        try {
            setShowLoader(true);

            let data: any;

            if (editGqlQuery) {
                let variables: KeyValueObject = {};

                if (!isMeRoute) {
                    variables = { id };
                }

                const queryResult = await apolloClient.query({
                    query: editGqlQuery!,
                    variables
                });

                let queryDataOptions: GetQueryDataOptions = {
                    data: queryResult.data,
                    modelName: editQueryOptions?.modelName || modelName,
                };

                if (editQueryOptions?.queryPrefixDotNotation) {
                    queryDataOptions.queryPrefixDotNotation = editQueryOptions.queryPrefixDotNotation;
                }

                data = LamaGraphQlQueryHelper.getResultFromSingleQueryData<any>(queryDataOptions);
            } else if (loadEditModelFromProps) {
                data = await loadEditModelFromProps();
            }
            else if (currentModel) {
                data = currentModel;
            }

            if (data) {
                if (transformBeforeEdit) {
                    data = await transformBeforeEdit(UtilityHelper.copy(data));
                }

                lamaFormRef.current?.getFormlyProps()?.replaceValues(data);

                setEditMode(true);
            } else {
                addWarningAlert('shared.alerts.query.notFound', {
                    entityName
                });
            }
        } catch (e) {
            Logger.warn(e);
            addErrorAlert('shared.alerts.query.error', {
                entityName
            });
        } finally {
            setShowLoader(false);
        }
    };

    const onSubmit = async (model: any, formlyProps: IRcFormlyProps) => {
        let viewModel = UtilityHelper.copy(model);
        let entityTitle = getModelTitle(viewModel);

        if (transformBeforeSubmit) {
            viewModel = await transformBeforeSubmit(viewModel, isEditMode);

            if (!viewModel) {
                return;
            }
        }

        setShowLoader(true);

        let alertMessagePrefix = isEditMode ? 'update' : 'create';

        try {
            const { data } = await lamaClient.mutateModel<BaseEntity>({
                modelName,
                isJoinedModel,
                crudOperationType: isEditMode ? LamaCrudOperationType.update : LamaCrudOperationType.create,
                variables: viewModel
            });

            if (data) {
                addSuccessAlert(`shared.alerts.${alertMessagePrefix}.success`, {
                    entityName,
                    entityTitle
                });

                gridReload();

                if (currentRoute?.data?.crudViewType === LamaCrudViewType.createOrEdit || !isEditMode) {
                    formlyProps.resetForm();

                    setEditMode(false);
                }

                if (onSubmitSuccess) {
                    onSubmitSuccess(viewModel);
                }
                else {
                    const url = CrudHelper.convertUrlToListCrudType(history.location.pathname);

                    history.push(url);
                }
            }
        } catch (e) {
            Logger.warn(e);

            if (UtilityHelper.isNotEmpty((e as LamaGraphQlError).messageTranslate)) {
                const messageTranslate = (e as LamaGraphQlError).messageTranslate;

                if (messageTranslate) {
                    const messageParams = {
                        ...(e as LamaGraphQlError).messageTranslateParams,
                        entityName,
                        entityTitle
                    };

                    if (messageTranslate.includes('warning')) {
                        addWarningAlert(messageTranslate, messageParams);
                    }
                    else {
                        addErrorAlert(messageTranslate, messageParams);
                    }

                    return;
                }
            }

            addErrorAlert(`shared.alerts.${alertMessagePrefix}.error`, {
                entityName,
                entityTitle
            });
        } finally {
            setShowLoader(false);
        }
    };

    return (
        <>
            <LamaLoader showLoader={showLoader} />
            <LamaForm ref={lamaFormRef} model={initialModel} fields={fields} onSubmit={onSubmit} />
        </>
    );
};

const CreateUpdateBaseWithRef: FC<CreateUpdateBaseProps> = (props, ref: Ref<CreateUpdateBaseRef>) => {
    return <CreateUpdateBaseInner {...props} innerRef={ref} />;
};

export const LamaCreateUpdateBase = forwardRef<CreateUpdateBaseRef, Omit<CreateUpdateBaseProps, 'innerRef'>>(CreateUpdateBaseWithRef);
