import gql from 'graphql-tag';
import { mutation, params, types } from 'typed-graphqlify';

import { LamaCrudOperationType, MutationGenerateOptions, MutationGenerationResult, GetMutationDataOptions } from 'shared/graphql/types';

import { LamaGrapQlNamingHelper } from './LamaGraphQlNamingHelper';
import { UtilityHelper } from 'shared/utilities';

export class LamaGraphQlMutationHelper {
    static getResultFromMutationData<T = any>(mutationDataOptions: GetMutationDataOptions) {
        const { data } = mutationDataOptions;

        if (!data) {
            return data;
        }

        const queryName = LamaGrapQlNamingHelper.createMutationName(mutationDataOptions);

        return data[queryName] as T;
    }
    static generateMutation(options: MutationGenerateOptions) {
        const { isBulk, isJoinedModel, variables } = options;

        const mutationName = LamaGrapQlNamingHelper.createMutationName(options);
        const mutationInputName = LamaGrapQlNamingHelper.createMutationInputName(options);
        const mutationInputVariable = `$${mutationInputName}`;
        let mutationInputTypeName = LamaGrapQlNamingHelper.createMutationInputTypeName(options);

        let mutationReturnObject: any = {};

        if (isJoinedModel) {
            let keys: string[];

            if (UtilityHelper.isArray(variables)) {
                keys = Object.keys(variables[0]);
            } else {
                keys = Object.keys(variables);
            }

            keys.forEach(variableKey => {
                mutationReturnObject[variableKey] = types.string;
            });
        } else {
            mutationReturnObject.id = types.string;
        }

        if (isBulk) {
            mutationReturnObject = [mutationReturnObject];
            mutationInputTypeName = `[${mutationInputTypeName}]`;
        }

        const mutationObject = {
            [mutationName]: params({ [mutationInputName]: mutationInputVariable }, mutationReturnObject)
        };

        const mutationText = mutation(mutationName, params({ [mutationInputVariable]: `${mutationInputTypeName}!` }, mutationObject));

        return {
            gqlQuery: gql`
                ${mutationText}
            `,
            variables: {
                [mutationInputName]: variables
            }
        } as MutationGenerationResult;
    }

    static generateCreateMutation(modelName: string, variables: any) {
        return LamaGraphQlMutationHelper.generateMutation({
            modelName,
            variables,
            crudOperationType: LamaCrudOperationType.create
        });
    }

    static generateDeleteMutation(modelName: string, modelId: string) {
        return LamaGraphQlMutationHelper.generateMutation({
            modelName,
            variables: {
                id: modelId
            },
            crudOperationType: LamaCrudOperationType.delete
        });
    }

    static generateUpdateMutation(modelName: string, variables: any) {
        return LamaGraphQlMutationHelper.generateMutation({
            modelName,
            variables,
            crudOperationType: LamaCrudOperationType.update
        });
    }
}
