import React, { FC } from 'react';

import { PackageResourceNames } from 'features/packs/models';
import { LamaFormlyFieldConfig, LamaFormlyTypes, LamaFormlyWrappers, LamaSelectTypeTemplateOptions } from 'shared/components/forms/models';

import { LamaTrackableEntityCreateUpdateBase } from 'features/base/components';

export const PackageFeatureModuleCreateOrUpdateBase: FC = () => {
    const fields: LamaFormlyFieldConfig[] = [
        {
            wrappers: [LamaFormlyWrappers.card, LamaFormlyWrappers.submitButton],
            fieldGroup:[
                {
                    key: 'order',
                    type: LamaFormlyTypes.numberInput,
                    wrappers: [LamaFormlyWrappers.formGroup],
                    templateOptions: {
                        labelTranslate: 'packageFeatureModule.order',
                        required: true
                    }
                },
                {
                    key: 'ownerFeatureModuleId',
                    type: LamaFormlyTypes.select,
                    wrappers: [LamaFormlyWrappers.formGroup],
                    templateOptions: {
                        labelTranslate: 'packageFeatureModule.ownerFeatureModuleId',
                        placeholderTranslate: 'packageFeatureModule.chooseOwnerFeatureModuleId',
                        required: true,
                        lamaSelectProps: {
                            bindLabel: 'title',
                            bindValue: 'id',
                            serverOptions: {
                                modelName: PackageResourceNames.featureModule,
                            }
                        }
                    } as LamaSelectTypeTemplateOptions
                }
            ]
        }
    ];

    return (
        <LamaTrackableEntityCreateUpdateBase
            fields={fields}
            modelName={PackageResourceNames.packageFeatureModule}
            listHeadingTranslate="package.tabs.featureModules"
            titlePropertyName="ownerFeatureModule.title"
            otherEntityIdPropertyName="ownerFeatureModuleId"
            ownerEntityIdPropertyName="ownerPackageId"
        />
    );
};
