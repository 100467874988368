import { IntlShape } from 'react-intl';
import { IFormlyFieldConfig } from '@rc-formly/core';

import { TranslationService } from 'lang';

export const baseValidationMessage = (value: any, field: IFormlyFieldConfig, callback: (intl: IntlShape, label: string) => string) => {
    if (TranslationService.hasIntl() && field.templateOptions) {
        const intl = TranslationService.getCurrentIntl()!;
        const label = field.templateOptions.labelTranslate
            ? intl.formatMessage({ id: field.templateOptions.labelTranslate })
            : field.templateOptions.label!;

        return callback(intl, label);
    }

    return '';
};
