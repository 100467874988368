import React, { FC } from 'react';
import { LamaAlertMessage, AlertType } from 'shared/packages/alerts/hooks';
import { FormattedMessage } from 'react-intl';

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';

interface LamaAlertProps {
    alertMessage: LamaAlertMessage;
    onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>, alertMessage: LamaAlertMessage) => void;
}

export const LamaAlert: FC<LamaAlertProps> = props => {
    const { alertMessage, onClick } = props;
    const { alertType } = alertMessage;

    const onAlertClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (onClick) {
            onClick(event, alertMessage);
        }
    };

    const getAlertTypeIcon = () => {
        switch (alertType) {
            case AlertType.error:
                return <ErrorIcon />;

            case AlertType.success:
                return <CheckCircleOutlineIcon />;

            case AlertType.warning:
                return <WarningIcon />;

            default:
                return <InfoIcon />;
        }
    };

    const getAlertTypeCssClass = () => {
        switch (alertType) {
            case AlertType.error:
                return 'bg-danger';

            case AlertType.success:
                return 'bg-success';

            case AlertType.warning:
                return 'bg-warning';

            default:
                return 'bg-info';
        }
    };

    return (
        <div className="icon-alert rct-block-content">
            <div className={`alert text-white ${getAlertTypeCssClass()}`} role="alert" onClick={onAlertClick}>
                <span className="alert-addon">
                    { getAlertTypeIcon() }
                </span>
                <p>
                    {(alertMessage.title || alertMessage.titleTranslate) && (
                        <strong>
                            {alertMessage.title}
                            {alertMessage.titleTranslate && (
                                <FormattedMessage id={alertMessage.titleTranslate} values={alertMessage.titleTranslateVariables} />
                            )}
                        </strong>
                    )}
                    {alertMessage.message}
                    {alertMessage.messageTranslate && (
                        <FormattedMessage id={alertMessage.messageTranslate} values={alertMessage.messageTranslateVariables} />
                    )}
                </p>
            </div>
        </div>

    );
};
