import { KeyValueObject } from "shared/types";

export enum LamaDialogType {
    error = 'error',
    info = 'info',
    success = 'success',
    warning = 'warning'
}

export enum LamaDialogButtonType {
    cancel = 'cancel',
    ok = 'ok'
}

export interface LamaDialogButton {
    buttonType: LamaDialogButtonType;
    textTranslate: string;
}

export interface LamaDialogOptions extends LamaDialogCallbackOptions {
    id?: string;
    buttons?: LamaDialogButton[];
    dialogType?: LamaDialogType;
    headerTextTranslate?: string;
    headerTranslateVariables?: KeyValueObject;
    messageTextTranslate?: string;
    messageTranslateVariables?: KeyValueObject;
}

export interface LamaDialogCallbackOptions {
    onConfirm?: () => void;
    onClose?: () => void;
}

export interface LamaDialogContextState {
    dialogs: LamaDialogOptions[];
    defaultAutoHideAfterOk: boolean;
    addDialog: (dialog: LamaDialogOptions) => string;
    closeDialog: (dialogId?: string) => void;
}

export interface useLamaDialogInterface {
    (): {
        addDialog: (dialog: LamaDialogOptions) => string;
        addDeleteConfirmationDialog: (entityName: string, lamaDialog: LamaDialogOptions) => string;
        addErrorDialog: (errorDialog: LamaDialogOptions) => string;
        addInfoDialog: (infoDialog: LamaDialogOptions) => string;
        addSuccessDialog: (successDialog: LamaDialogOptions) => string;
        addWarningDialog: (warningDialog: LamaDialogOptions) => string;
        closeDialog: (dialogId?: string) => void;
    };
}
