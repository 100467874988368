import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import Tooltip from '@material-ui/core/Tooltip';
import FilterListIcon from '@material-ui/icons/FilterList';

import { LamaGridFilterProps } from 'shared/components/grid/types';
import { GraphQlQueryEqualityOperator } from 'shared/graphql';

export const DefaultFilterType: FC<LamaGridFilterProps> = props => {
    const { onFilterChange } = props;

    const onInputChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (event.currentTarget.value) {
            onFilterChange([
                {
                    operator: GraphQlQueryEqualityOperator.contains,
                    value: event.currentTarget.value
                }
            ]);
        } else {
            onFilterChange([]);
        }
    };

    return (
        <FormControl>
            <Input
                onChange={onInputChange}
                startAdornment={
                    <Tooltip arrow={true} placement="bottom" title={<FormattedMessage id="shared.filter" />}>
                        <InputAdornment position="start">
                            <FilterListIcon />
                        </InputAdornment>
                    </Tooltip>
                }
            />
        </FormControl>
    );
};
