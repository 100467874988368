import { Price } from "features/products/models";
import { BaseEntity } from "shared/types";
import { LamaFeatureModule } from "./featureModule";

export enum PackageType {
    custom = 'CUSTOM',
    standard = 'STANDARD'
}

interface PackageFeatureModule {
    order: number;
    ownerFeatureModule: LamaFeatureModule;
}

export interface PackageViewModel extends BaseEntity {
    autoRenew: boolean;
    currentPrice: Price;
    description?: string;
    systemName?: string;
    summary?: string;
    title: string;

    trialPeriodInDays?: number;
    type: PackageType;

    currentPriceId?: string;

    featureModules?: PackageFeatureModule[];
}
