import React, { FC } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';

import { LamaGridCellProps, LamaGridRowEventType, LamaGridActions } from 'shared/components/grid/types';

import { useLamaGrid } from 'shared/components/grid/hooks';

export const ActionsCellType: FC<LamaGridCellProps> = props => {
    const { column: { additionalData}, gridName, row, rowIndex } = props;
    const actions: LamaGridActions = additionalData || { del: false, edit: false };

    const { triggerAction } = useLamaGrid(gridName);

    const deleteItem = () => {
        triggerAction({
            row,
            index: rowIndex,
            type: LamaGridRowEventType.delete
        });
    };

    const editItem = () => {
        triggerAction({
            row,
            index: rowIndex,
            type: LamaGridRowEventType.edit
        });
    };

    return (
        <Box>
            {actions.edit && (
                <IconButton aria-label="edit" onClick={editItem}>
                    <EditIcon />
                </IconButton>
            )}
            {actions.del && (
                <IconButton aria-label="delete" onClick={deleteItem}>
                    <DeleteIcon />
                </IconButton>
            )}
        </Box>
    );
};
