import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { LamaGridFilterProps } from 'shared/components/grid/types';
import { GraphQlQueryEqualityOperator, GraphQlQueryObjectParameter } from 'shared/graphql';
import { UtilityHelper } from 'shared/utilities';

export const BoolFilterType: FC<LamaGridFilterProps> = props => {
    const { currentChanges, onFilterChange } = props;

    const onSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const changes: GraphQlQueryObjectParameter[] = [];

        if (event.target.value === 'true' || event.target.value === 'false') {
            changes.push({
                operator: GraphQlQueryEqualityOperator.equals,
                value: event.target.value === 'true'
            });
        }

        onFilterChange(changes);
    };

    const value = UtilityHelper.isEmpty(currentChanges) ? '' : currentChanges![0].value;

    return (
        <Select value={value} onChange={onSelectChange} displayEmpty>
            <MenuItem value="">
                <i>
                    <FormattedMessage id="shared.empty" />
                </i>
            </MenuItem>
            <MenuItem value="true">
                <FormattedMessage id="shared.yes" />
            </MenuItem>
            <MenuItem value="false">
                <FormattedMessage id="shared.no" />
            </MenuItem>
        </Select>
    );
};
