import { FC } from "react";

import { useMountEffect } from "shared/packages/use-mount-effect";
import { useLamaGridCreator, GridCellType } from "shared/components/grid";

interface LamaGridIncludeCellsProps {
    gridCellTypes: GridCellType[];
}

export const LamaGridIncludeCells: FC<LamaGridIncludeCellsProps> = (props) => {
    const { gridCellTypes } = props;
    const { addGridCellTypes } = useLamaGridCreator();

    useMountEffect(() => {
        addGridCellTypes(gridCellTypes);
    });

    return null;
};
