import 'date-fns';
import React, { FC } from 'react';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
// @ts-ignore
import Drift from 'react-driftjs';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import { LamaGraphQlProvider } from 'shared/graphql';
import { HttpProvider } from 'shared/rest';
import { store, persistor } from 'store';
import { bugsnagClient } from 'shared/utilities';

import { featureModules } from 'features';
import { mediaGridCellTypes } from 'features/media';
import { PackageContractProvider } from 'features/contracts/hooks';

import { App } from 'containers';
import { Environment } from 'configs';
import { ModularIncludes, ModularProvider } from 'shared/packages/react-modular';

import { LamaTenantProvider } from 'shared/authentication';
import { gridCellTypes, gridFilterTypes, LamaGridProvider, LamaGridIncludeCells, LamaGridIncludeFilters } from 'shared/components/grid';

import './lib/reactifyScss';

const combinedGridCellTypes = [...mediaGridCellTypes, ...gridCellTypes];

const ErrorBoundary = bugsnagClient.getPlugin('react');

const MainApp: FC = () => (
    <ErrorBoundary>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <LamaGraphQlProvider queryPrefixDotNotation="admin">
                        <ModularProvider pathPrefix="dashboard">
                            <ModularIncludes modularEntries={featureModules} />
                            <LamaGridProvider>
                                <LamaGridIncludeCells gridCellTypes={combinedGridCellTypes} />
                                <LamaGridIncludeFilters gridFilterTypes={gridFilterTypes} />
                                <Router>
                                    <LamaTenantProvider>
                                        <PackageContractProvider>
                                            <HttpProvider>
                                                <Switch>
                                                    <Route path="/" component={App} />
                                                </Switch>
                                            </HttpProvider>
                                        </PackageContractProvider>
                                    </LamaTenantProvider>
                                </Router>
                            </LamaGridProvider>
                        </ModularProvider>
                    </LamaGraphQlProvider>
                </PersistGate>
            </Provider>
        </MuiPickersUtilsProvider>
        <Drift appId={Environment.driftAppId} />
    </ErrorBoundary>
);

export default MainApp;
