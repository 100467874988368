import { GraphQLError } from "graphql";

import { UtilityHelper } from "shared/utilities";

export const getFirstGrapQLErrorMessage = (graphQlErrors: readonly GraphQLError[], defaultAuthenticationErrorMessage = '', errorPrefix = 'serverErrors') => {
    let authenticationError = defaultAuthenticationErrorMessage;

    if (UtilityHelper.isNotEmpty(graphQlErrors) && graphQlErrors[0].message) {
        authenticationError = `${errorPrefix}.${graphQlErrors[0].message}`;
    }

    return authenticationError;
};
