import React, { FC, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Redirect, Link, useHistory } from 'react-router-dom';
import { FormGroup } from 'reactstrap';
import { IFormlyFieldConfig } from '@rc-formly/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import { defaultAuthenticationErrorMessage, useAuth } from 'shared/authentication';
import { useModular } from 'shared/packages/react-modular';
import { useQueryParams } from 'shared/packages/use-query-params';

import { LamaForm } from 'shared/components';
import { SessionContainer, SessionTitle, RegisterHeaderLink } from 'features/authentication/components';
import { useLamaAlerts } from 'shared/packages/alerts';

interface LoginModel {
    email: string;
    password: string;
}

const Login: FC = () => {
    const { authenticationError, isAuthenticated, isAuthenticating, login } = useAuth();
    const { homeRoutePath } = useModular();
    const { addSuccessAlert } = useLamaAlerts();
    const history = useHistory();
    const { formatMessage } = useIntl();
    const { queryStrings } = useQueryParams();

    const translatedDefaultAuthenticationErrorMesage = formatMessage({ id: defaultAuthenticationErrorMessage });
    const successMessage = queryStrings['success'] ? formatMessage({ id: `authentication.login.success.${queryStrings['success']}`}) : '';

    const fields: IFormlyFieldConfig[] = [
        {
            key: 'email',
            type: 'lm-input',
            wrappers: ['lm-form-group'],
            templateOptions: {
                labelTranslate: 'shared.email',
                required: true,
                type: 'email'
            }
        },
        {
            key: 'password',
            type: 'lm-input',
            wrappers: ['lm-form-group'],
            templateOptions: {
                labelTranslate: 'shared.password',
                minLength: 6,
                required: true,
                type: 'password'
            }
        }
    ];

    useEffect(() => {
        if (successMessage) {
            history.replace('/login');

            addSuccessAlert(successMessage);
        }
    }, [successMessage]);

    const onFormSubmit = (model: LoginModel) => {
        login(model.email, model.password);
    };

    if (isAuthenticated()) {
        return <Redirect to={homeRoutePath} />;
    }

    return (
        <SessionContainer showProgress={isAuthenticating} headerRight={RegisterHeaderLink}>
            <SessionTitle title="authentication.login.subHeader" />
            {authenticationError && (
                <p className="text-danger">
                    <FormattedMessage id={authenticationError} defaultMessage={translatedDefaultAuthenticationErrorMesage} />
                </p>
            )}
            <LamaForm fields={fields} onSubmit={onFormSubmit}>
                <FormGroup className="mb-15">
                    <Button
                        color="primary"
                        disabled={isAuthenticating}
                        className="btn-block text-white w-100"
                        variant="contained"
                        type={'submit'}
                        size="large"
                    >
                        <FormattedMessage id="authentication.login.signIn" />
                    </Button>
                    <Box display="flex" mt={3} justifyContent="space-between">
                        <Link className="text-muted" to="/resend-activation">
                            <FormattedMessage id="authentication.login.activationIssues" />
                        </Link>
                        <Link className="text-muted" to="/forgotten-password">
                            <FormattedMessage id="authentication.login.forgottenPassword" />
                        </Link>
                    </Box>
                </FormGroup>
            </LamaForm>
        </SessionContainer>
    );
};

export default Login;
