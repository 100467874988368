import { IValidatorMessageOption  } from '@rc-formly/core';

import { baseValidationMessage } from './base';

export const minLengthValidatorMessage: IValidatorMessageOption = {
    name: 'minLength',
    message: (value, field) => baseValidationMessage(value, field, (intl, label) => {
        return intl.formatMessage({
            id: 'validation.minLength'
        }, {
            label,
            minLength: field.templateOptions?.minLength
        });
    })
};
