import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';

import { NavMenuItem } from './NavMenuItem';

import { RouteDefaultData } from 'features/base/models';

import { useAuth } from 'shared/authentication';
import { useModular, ModularEntryRoute } from 'shared/packages/react-modular';

import { CrudHelper } from 'features/base/utilities';
import { UtilityHelper } from 'shared/utilities';

export const SidebarContent: FC = () => {
    const { isAuthorized } = useAuth();
    let { groupedRoutes } = useModular<RouteDefaultData>();

    const isRouteAuthorized = (route: ModularEntryRoute<RouteDefaultData>) => {
        const claims = CrudHelper.getClaimsFromRoute(route) || [];

        return isAuthorized(...claims);
    };

    groupedRoutes = groupedRoutes
        .map(q => ({
            ...q,
            routes: q.routes?.filter(q => isRouteAuthorized(q))
        } as ModularEntryRoute<RouteDefaultData>))
        .filter(q => {
            if (UtilityHelper.isEmpty(q.routes) && !q.routeProps?.component) {
                return false;
            }

            return isRouteAuthorized(q);
        });

    const groupByHeader = UtilityHelper.groupBy(groupedRoutes, val => val.header!);
    const groupByHeaderKeys = Object.keys(groupByHeader);
    const routesWithoutHeader = groupedRoutes.filter(q => !q.header);

    return (
        <div className="rct-sidebar-nav">
            <nav className="navigation">
                {groupByHeaderKeys.map((key) => (
                    <List
                        className="rct-mainMenu p-0 m-0 list-unstyled"
                        key={key}
                        subheader={
                            <ListSubheader className="side-title" component="li">
                                <FormattedMessage id={key} />
                            </ListSubheader>
                        }
                    >
                        {groupByHeader[key].map((modularEntryRoute, modularEntryRouteKey) => (
                            <NavMenuItem menuEntry={modularEntryRoute} key={modularEntryRouteKey} />
                        ))}
                    </List>
                ))}
                <List className="rct-mainMenu p-0 m-0 list-unstyled">
                    {routesWithoutHeader.map((route) => (
                        <NavMenuItem menuEntry={route} key={route.name} />
                    ))}
                </List>
            </nav>
        </div>
    );
};
