import React, { FC, CSSProperties, useEffect } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useDispatch, useSelector } from 'react-redux';
import Sidebar from 'react-sidebar';
import Box from '@material-ui/core/Box';

import { useWindowDimensions } from 'shared/packages/use-dimensions';
import { appReducerActionCreators, isMiniSidebarShownSelector, isSidebarShownSelector } from 'features/base/reducers';

import { Header } from './Header';
import { Footer } from './Footer';
import { AppSidebar } from './Sidebar';
import { useLocation } from 'react-router-dom';

const largeScreenSize = 1199;

const getScrollBarStyle = () => {
    return {
        height: 'calc(100vh - 50px)'
    } as CSSProperties;
};

export const RtcAppLayout: FC = props => {
    const dispatch = useDispatch();
    const location = useLocation();
    const isMiniSidebarShown = useSelector(isMiniSidebarShownSelector);
    const isSidebarShown = useSelector(isSidebarShownSelector);
    const { innerWidth } = useWindowDimensions();

    const isSidebarOpen = innerWidth <= largeScreenSize ? isSidebarShown : false;
    const isSidebarDocked = innerWidth > largeScreenSize ? !isSidebarShown : false;
    const sidebarContentClassName = isMiniSidebarShown ? 'app-conrainer-wrapper' : '';

    useEffect(() => {
        if (isSidebarShown) {
            dispatch(appReducerActionCreators.toggleSidebar(false))
        }
    }, [location.pathname]);

    const onSidebarSetOpen = () => {
        dispatch(appReducerActionCreators.toggleSidebar(false));
    };

    return (
        <div className="app">
            <div className="app-main-container">
                <Sidebar
                    sidebar={<AppSidebar />}
                    open={isSidebarOpen}
                    docked={isSidebarDocked}
                    onSetOpen={onSidebarSetOpen}
                    styles={{ content: { overflowY: '' }, sidebar: { zIndex: '5' }, overlay: { zIndex: '4' } }}
                    contentClassName={sidebarContentClassName}
                >
                    <div className="app-container">
                        <div className="rct-app-content">
                            <div className="app-header">
                                <Header />
                            </div>
                            <div className="rct-page">
                                <Scrollbars className="rct-scroll" autoHide autoHideDuration={100} style={getScrollBarStyle()}>
                                    <Box display="flex" flexDirection="column" className="rct-page-content" height="100%">
                                        <Box display="flex" flexGrow="1">
                                            {props.children}
                                        </Box>
                                        <Footer />
                                    </Box>
                                </Scrollbars>
                            </div>
                        </div>
                    </div>
                </Sidebar>
            </div>
        </div>
    );
};
