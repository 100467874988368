export * from './price';
export * from './product';

export enum ProductResourceNames {
    product = 'product',
    tax = 'tax'
}

export const ProductClaims = {
    productReviewer: `${ProductResourceNames.product}.reviewer`
};
