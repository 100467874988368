import React, { FC } from 'react';

import { LamaGridFilterProps } from 'shared/components/grid/types';
import { GraphQlQueryObjectParameter, GraphQlQueryEqualityOperator } from 'shared/graphql';

import { DefaultFilterType } from './DefaultFilterType';

import { UtilityHelper } from 'shared/utilities';

export const NumberFilterType: FC<LamaGridFilterProps> = props => {
    const { onFilterChange, ...otherProps } = props;

    const onDefaultFilterChanged = (changes: GraphQlQueryObjectParameter[]) => {
        if (UtilityHelper.isNotEmpty(changes)) {
            changes[0].value = parseInt(changes[0].value);
            changes[0].operator = GraphQlQueryEqualityOperator.equals;
        }

        onFilterChange(changes);
    };

    return <DefaultFilterType {...otherProps} onFilterChange={onDefaultFilterChanged} />;
};
