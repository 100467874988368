import React, { createContext, FC, useState } from 'react';

import { LamaAlertMessage, LamaAlertsContextState } from './types';

import { UtilityHelper } from 'shared/utilities';

export const LamaAlertsContext = createContext<LamaAlertsContextState>({
    alerts: [],
    defaultAutoHideDuration: 3000,
    addAlert: () => { return ''; },
    removeAlert: () => { return ''; },
});

interface LamaAlertsProviderProps {
    defaultAutoHideDuration?: number;
}

export const LamaAlertsProvider: FC<LamaAlertsProviderProps> = (props) => {
    let { defaultAutoHideDuration } = props;

    if (!UtilityHelper.isNumber(defaultAutoHideDuration)) {
        defaultAutoHideDuration = 6000;
    }

    const [alerts, setAlerts] = useState<LamaAlertMessage[]>([]);

    const addAlert = (alert: LamaAlertMessage) => {
        if (UtilityHelper.isEmpty(alert.id)) {
            alert.id = UtilityHelper.createGuid();
        }

        if (!UtilityHelper.isNumber(alert.autoHideDuration)) {
            alert.autoHideDuration = defaultAutoHideDuration;
        }

        setAlerts([...alerts, alert]);

        return alert.id!;
    };

    const removeAlert = (alertId: string) => {
        setAlerts(alerts.filter(q => q.id !== alertId));
    }

    const contextValue: LamaAlertsContextState = {
        alerts,
        defaultAutoHideDuration: defaultAutoHideDuration!,
        addAlert,
        removeAlert
    }

    return (
        <LamaAlertsContext.Provider value={contextValue}>
            { props.children }
        </LamaAlertsContext.Provider>
    );
}
