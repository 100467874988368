import React, { FC } from 'react';

import { useAuth } from 'shared/authentication/hooks';

import { NotAuthorized } from './NotAuthorized';

interface LamaAuthorizeProps {
    claimToCheck?: string;
    claimsToCheck?: string[];
}

export const LamaAuthorize: FC<LamaAuthorizeProps> = (props) => {
    const { children, claimToCheck, claimsToCheck }  = props;
    const { isAuthorized } = useAuth();

    if (!claimToCheck && !claimsToCheck) {
        return (
            <>
                { children }
            </>
        );
    }
    else if (claimToCheck && isAuthorized(claimToCheck)) {
        return (
            <>
                { children }
            </>
        );
    }
    else if (claimsToCheck && isAuthorized(...claimsToCheck)) {
        return (
            <>
                { children }
            </>
        );
    }

    return (
        <NotAuthorized />
    );
};
