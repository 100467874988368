import React, { FC, useRef, useEffect } from 'react';

import { LocationFormlyTypes } from 'features/locations/models';
import {
    LamaFormlyFieldConfig,
    LamaFormlyWrappers,
    LamaCollapsibleCardWrapperTemplateOptions,
    LamaFormlyTypes
} from 'shared/components/forms/models';
import { RouteDefaultData } from 'features/base/models';
import { TenantModel, TenantCreateUpdateViewModel, CompanyResourceNames } from 'features/companies/models';
import { singleTenantQuery } from 'features/companies/queries';

import { useModularRoute } from 'shared/packages/react-modular';

import { LamaCreateUpdateBase, CreateUpdateBaseRef } from 'features/base/components';

import { CrudHelper } from 'features/base/utilities';

export const TenantCreateOrUpdateBase: FC = () => {
    const baseCrudRef = useRef<CreateUpdateBaseRef>(null);
    const { currentRoute } = useModularRoute<RouteDefaultData>();

    const isEditMode = CrudHelper.isEditRoute(currentRoute);

    useEffect(() => {
        if (isEditMode) {
            baseCrudRef.current?.getFormlyProps().changeFieldConfig('account.password', (field) => {
                field.templateOptions!.required = !isEditMode;

                return field;
            });
        }
    }, [isEditMode])

    const fields: LamaFormlyFieldConfig[] = [
        {
            wrappers: [LamaFormlyWrappers.row, LamaFormlyWrappers.submitButton],
            fieldGroup: [
                {
                    fieldGroupClassName: 'col-sm-6',
                    wrappers: [LamaFormlyWrappers.card],
                    templateOptions: {
                        cardProps: {
                            headingTranslate: 'company.basic.header'
                        }
                    } as LamaCollapsibleCardWrapperTemplateOptions,
                    fieldGroup: [
                        {
                            key: 'tenant.systemName',
                            type: LamaFormlyTypes.input,
                            wrappers: [LamaFormlyWrappers.formGroup],
                            templateOptions: {
                                labelTranslate: 'company.systemName'
                            }
                        },
                        {
                            key: 'tenant.companyOfTenant.title',
                            type: LamaFormlyTypes.input,
                            wrappers: [LamaFormlyWrappers.formGroup],
                            templateOptions: {
                                labelTranslate: 'company.title',
                                required: true
                            }
                        },
                        {
                            key: 'tenant.companyOfTenant.shortTitle',
                            type: LamaFormlyTypes.input,
                            wrappers: [LamaFormlyWrappers.formGroup],
                            templateOptions: {
                                labelTranslate: 'company.shortTitle'
                            }
                        },
                        {
                            key: 'tenant.companyOfTenant.email',
                            type: LamaFormlyTypes.input,
                            wrappers: [LamaFormlyWrappers.formGroup],
                            templateOptions: {
                                labelTranslate: 'company.email',
                                type: 'email'
                            }
                        },
                        {
                            key: 'tenant.companyOfTenant.phone',
                            type: LamaFormlyTypes.input,
                            wrappers: [LamaFormlyWrappers.formGroup],
                            templateOptions: {
                                labelTranslate: 'company.phone'
                            }
                        }
                    ]
                },
                {
                    fieldGroupClassName: 'col-sm-6',
                    wrappers: [LamaFormlyWrappers.div],
                    fieldGroup: [
                        {
                            wrappers: [LamaFormlyWrappers.card],
                            templateOptions: {
                                cardProps: {
                                    headingTranslate: 'company.location.header',
                                    showRequiredInTitle: true
                                }
                            } as LamaCollapsibleCardWrapperTemplateOptions,
                            fieldGroup: [
                                {
                                    key: 'tenant.companyOfTenant.address',
                                    type: LocationFormlyTypes.placeSelector,
                                    wrappers: [LamaFormlyWrappers.formGroup],
                                    templateOptions: {
                                        labelTranslate: 'company.location.header',
                                        hideLabel: true,
                                        required: true
                                    }
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    ];

    const getModelTitle = (entity: TenantCreateUpdateViewModel) => {
        return entity?.tenant?.companyOfTenant?.name || '';
    };

    const getInitialModel = () => {
        return {
            tenant: { companyOfTenant: {} }
        } as TenantCreateUpdateViewModel;
    };

    const transformBeforeEdit = async (entity: TenantModel) => {
        return {
            tenant: entity
        } as TenantCreateUpdateViewModel;
    };

    const transformBeforeSubmit = async (viewModel: TenantCreateUpdateViewModel) => {
        const { tenant } = viewModel;

        return tenant;
    };

    return (
        <LamaCreateUpdateBase
            ref={baseCrudRef}
            editGqlQuery={singleTenantQuery}
            getInitialModel={getInitialModel}
            getModelTitle={getModelTitle}
            fields={fields}
            modelName={CompanyResourceNames.tenant}
            transformBeforeEdit={transformBeforeEdit}
            transformBeforeSubmit={transformBeforeSubmit}
        />
    );
};
