import escapeHtml from 'escape-html';
import { jsx } from 'slate-hyperscript';
import { ReactEditor } from 'slate-react';

import { KeyValueObject } from 'shared/types';
import { Transforms, Editor, Node, Text } from 'slate';

type TextNode =
    | Node
    | {
          bold?: boolean;
          code?: boolean;
          strikethrough?: boolean;
          italic?: boolean;
          underline?: boolean;
      };

const ELEMENT_TAGS: KeyValueObject = {
    //A: (el: HTMLElement) => ({ type: 'link', url: el.getAttribute('href') }),
    BLOCKQUOTE: () => ({ type: 'quote' }),
    H1: () => ({ type: 'heading-one' }),
    H2: () => ({ type: 'heading-two' }),
    H3: () => ({ type: 'heading-three' }),
    H4: () => ({ type: 'heading-four' }),
    H5: () => ({ type: 'heading-five' }),
    H6: () => ({ type: 'heading-six' }),
    //IMG: (el: HTMLElement) => ({ type: 'image', url: el.getAttribute('src') }),
    LI: () => ({ type: 'list-item' }),
    OL: () => ({ type: 'numbered-list' }),
    P: () => ({ type: 'paragraph' }),
    PRE: () => ({ type: 'code' }),
    UL: () => ({ type: 'bulleted-list' })
};

// COMPAT: `B` is omitted here because Google Docs uses `<b>` in weird ways.
const TEXT_TAGS: KeyValueObject = {
    CODE: () => ({ code: true }),
    DEL: () => ({ strikethrough: true }),
    EM: () => ({ italic: true }),
    I: () => ({ italic: true }),
    S: () => ({ strikethrough: true }),
    STRONG: () => ({ bold: true }),
    U: () => ({ underline: true })
};

export const LIST_TYPES = ['numbered-list', 'bulleted-list'];

export const deserializeHtml = (html: string): Node[] => {
    const document = new DOMParser().parseFromString(html, 'text/html');

    const nodes = deserialize(document.body) as Node[];

    if (nodes.length === 1 && !nodes.some((q) => q.type)) {
        return [
            {
                children: nodes,
                type: 'paragraph'
            }
        ];
    }

    return nodes;
};

export const deserialize = (el: HTMLElement | ChildNode): any => {
    if (el.nodeType === 3) {
        return el.textContent;
    } else if (el.nodeType !== 1) {
        return null;
    } else if (el.nodeName === 'BR') {
        return '\n';
    }

    const { nodeName } = el;
    let parent = el;

    if (nodeName === 'PRE' && el.childNodes[0] && el.childNodes[0].nodeName === 'CODE') {
        parent = el.childNodes[0] as any;
    }

    let children = Array.from(parent.childNodes).map(deserialize).flat();

    if (children.length === 0) {
        children = [{ text: '' }];
    }

    if (el.nodeName === 'BODY') {
        return jsx('fragment', {}, children);
    }

    if (nodeName in ELEMENT_TAGS) {
        const attrs = ELEMENT_TAGS[nodeName](el);

        return jsx('element', attrs, children);
    }

    if (nodeName in TEXT_TAGS) {
        const attrs = TEXT_TAGS[nodeName](el);

        return children.map((child) => jsx('text', attrs, child));
    }

    return children;
};

export const isBlockActive = (editor: ReactEditor, format: string) => {
    const matches = Editor.nodes(editor, {
        match: (n) => n.type === format
    });

    return matches;
};

export const isMarkActive = (editor: ReactEditor, format: string) => {
    const marks = Editor.marks(editor);
    return marks ? marks[format] === true : false;
};

export const serializeHtml = (node: Node): string => {
    if (Text.isText(node)) {
        if ((node as TextNode).bold) {
            return `<strong>${escapeHtml(node.text)}</strong>`;
        }

        return escapeHtml(node.text);
    }

    const children = node.children.map((n) => serializeHtml(n)).join('');

    switch (node.type) {
        case 'bulleted-list':
            return `<ul>${children}</ul>`;
        case 'code':
            return `<pre>${children}</pre>`;
        case 'heading-one':
            return `<h1>${children}</h1>`;
        case 'heading-two':
            return `<h2>${children}</h2>`;
        case 'heading-three':
            return `<h3>${children}</h3>`;
        case 'heading-four':
            return `<h4>${children}</h4>`;
        case 'heading-five':
            return `<h5>${children}</h5>`;
        case 'heading-six':
            return `<h6>${children}</h6>`;
        case 'list-item':
            return `<li>${children}</li>`;
        case 'numbered-list':
            return `<ol>${children}</ol>`;
        case 'quote':
            return `<blockquote><p>${children}</p></blockquote>`;
        case 'paragraph':
            return `<p>${children}</p>`;
        case 'link':
            return `<a href="${escapeHtml(node.url as string)}">${children}</a>`;
        default:
            return children;
    }
};

export const toggleMark = (editor: ReactEditor, format: string) => {
    const isActive = isMarkActive(editor, format);

    if (isActive) {
        Editor.removeMark(editor, format);
    } else {
        Editor.addMark(editor, format, true);
    }
};

export const toggleBlock = (editor: ReactEditor, format: string) => {
    const isActive = isBlockActive(editor, format);
    const isList = LIST_TYPES.includes(format);

    Transforms.unwrapNodes(editor, {
        match: (n) => LIST_TYPES.includes(n.type as string),
        split: true
    });

    Transforms.setNodes(editor, {
        type: isActive ? 'paragraph' : isList ? 'list-item' : format
    });

    if (!isActive && isList) {
        const block = { type: format, children: [] };
        Transforms.wrapNodes(editor, block);
    }
};
