import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
import { params, query, types } from 'typed-graphqlify';

import { LamaGrapQlNamingHelper } from './LamaGraphQlNamingHelper';
import { isGraphQlValueWithType } from './LamaGraphQlValueWithType';
import {
    QueryFromDotNotationOptions, GraphQlQueryType, GetQueryDataOptions, QueryFromDotNotationResult,
    GraphQlValueType, graphQlQueryVariableKeys, PaginationConnection, LamaGraphQlValueWithType
} from 'shared/graphql/types';
import { KeyValueObject } from 'shared/types';

import { UtilityHelper, LanguageHelper, Logger, Base64Helper } from 'shared/utilities';

const expand = (dotNotationProperty: string, value: any, initialObject: KeyValueObject = {}) => {
    const items = dotNotationProperty.split('.'); // split on dot notation
    let ref = initialObject; // keep a reference of the new object
    //  loop through all nodes, except the last one
    for (let i = 0; i < items.length - 1; i++) {
        if (!UtilityHelper.isObject(ref[items[i]])) {
            ref[items[i]] = {}; // create a new element inside the reference
        }

        ref = ref[items[i]]; // shift the reference to the newly created object
    }

    if (!UtilityHelper.isObject(ref[items[items.length - 1]])) {
        ref[items[items.length - 1]] = value; // apply the final value
    }

    return initialObject;
};

const generateQueryParams = (variables: KeyValueObject, keyPrefix = '', existingQueryParams: KeyValueObject<string> = {}) =>{
    return Object.keys(variables).reduce((acc, key) => {
        let paramKey = key;
        let graphQlType: string = '';
        let variableValue = variables[key];

        if (UtilityHelper.isObject(variableValue) && isGraphQlValueWithType(variableValue)) {
            graphQlType = (variableValue as LamaGraphQlValueWithType).valueType;
            variableValue = (variableValue as LamaGraphQlValueWithType).value;
        }
        else if (UtilityHelper.isNotEmpty(variableValue) && UtilityHelper.isObject(variableValue) && !UtilityHelper.isArray(variableValue)) {
            let newKeyPrefix = keyPrefix ? `${keyPrefix}${LanguageHelper.capitalize(key)}` : key;

            generateQueryParams(variableValue, newKeyPrefix, acc);

            return acc;
        }
        else {
            graphQlType = LamaGraphQlQueryHelper.getInferredGraphQlType(paramKey, variableValue);
        }

        if (keyPrefix) {
            paramKey = `${keyPrefix}${LanguageHelper.capitalize(paramKey)}`;
        }

        acc[`$${paramKey}`] = graphQlType;

        return acc;
    }, existingQueryParams);
};

const generateFilterParams = (variables: KeyValueObject, existingFilterParams: KeyValueObject = {}, keyPrefix = '') => {
    return Object.keys(variables).reduce((acc, key) => {
        const variableValue = variables![key];

        if (!UtilityHelper.isArray(variableValue) && !isGraphQlValueWithType(variableValue) && UtilityHelper.isObject(variableValue)) {
            let newKeyPrefix = keyPrefix ? `${keyPrefix}${LanguageHelper.capitalize(key)}` : key;

            acc[key] = generateFilterParams(variableValue, acc[key], newKeyPrefix);
        }
        else {
            let paramKey = keyPrefix ? `${keyPrefix}${LanguageHelper.capitalize(key)}` : key;

            acc[key] = `$${paramKey}`;
        }

        return acc;
    }, existingFilterParams);
};

const rewriteVariables = (variables: KeyValueObject, existingRewrittenVariables: KeyValueObject = {}, keyPrefix = '') => {
    return Object.keys(variables).reduce((acc, key) => {
        let variableValue = variables![key];

        if (!UtilityHelper.isArray(variableValue) && !isGraphQlValueWithType(variableValue) && UtilityHelper.isObject(variableValue)) {
            let newKeyPrefix = keyPrefix ? `${keyPrefix}${LanguageHelper.capitalize(key)}` : key;

            rewriteVariables(variableValue, acc, newKeyPrefix);
        }
        else {
            if (isGraphQlValueWithType(variableValue)) {
                variableValue = (variableValue as LamaGraphQlValueWithType).value;
            }

            let paramKey = keyPrefix ? `${keyPrefix}${LanguageHelper.capitalize(key)}` : key;

            acc[paramKey] = variableValue;
        }

        return acc;
    }, existingRewrittenVariables);
};

export class LamaGraphQlQueryHelper {
    static areOnlyWhereVariables(variables: KeyValueObject) {
        if (UtilityHelper.isEmpty(variables)) {
            return true;
        }

        const variableKeys = Object.keys(variables);

        return !graphQlQueryVariableKeys.some(q => variableKeys.includes(q));
    }

    static createQueryFromDotNotation<TQueryObject = any, TVariables = any>(
        queryFromDotNotationOptions: QueryFromDotNotationOptions<TQueryObject, TVariables>) {
        const { modelName, dotNotationProperties, queryType = GraphQlQueryType.list, queryPrefixDotNotation = 'admin' } = queryFromDotNotationOptions;
        let { queryObject = {}, variables } = queryFromDotNotationOptions;
        const isListQuery = queryType === GraphQlQueryType.list || queryType === GraphQlQueryType.paginated;
        const isPagedQuery = queryType === GraphQlQueryType.paginated;
        const queryName = LamaGrapQlNamingHelper.createQueryNameFromType(modelName, queryType);
        let graphQlObjectQuery: KeyValueObject = {};

        if (UtilityHelper.isNotEmpty(dotNotationProperties)) {
            dotNotationProperties!.forEach(dotNotationProperty => {
                if (dotNotationProperty) {
                    queryObject = expand(dotNotationProperty, types.string, queryObject);
                }
            });
        }

        if (isPagedQuery) {
            queryObject = LamaGraphQlQueryHelper.getPaginatedQueryObject(queryObject) as any;
        }

        if (UtilityHelper.isNotEmpty(queryObject)) {
            graphQlObjectQuery[queryName] = UtilityHelper.extend(graphQlObjectQuery[queryName] || {}, queryObject);
        }

        if (queryType === GraphQlQueryType.single) {
            variables = {
                id: 'ID!'
            } as unknown as TVariables;
        }

        if (variables) {
            let parameters = generateFilterParams(variables);

            if (isListQuery && LamaGraphQlQueryHelper.areOnlyWhereVariables(variables)) {
                parameters = { where: parameters };
            }

            graphQlObjectQuery[queryName] = params(parameters, graphQlObjectQuery[queryName]);
        }

        if (queryPrefixDotNotation) {
            const splittedQueryPrefixDotNotation = queryPrefixDotNotation.split('.');

            splittedQueryPrefixDotNotation.reverse().forEach((queryPrefix) => {
                graphQlObjectQuery = { [queryPrefix]: graphQlObjectQuery };
            });
        }

        if (variables) {
            const queryParams = generateQueryParams(variables);

            graphQlObjectQuery = params(queryParams, graphQlObjectQuery);
            variables = rewriteVariables(variables) as unknown as TVariables;
        }

        let gqlQuery: DocumentNode | null;

        try {
            const queryText = query(graphQlObjectQuery);

            gqlQuery = gql`${queryText}`
        }
        catch (e) {
            gqlQuery = null;

            Logger.error(e);
        }

        return {
            gqlQuery,
            variables
        } as QueryFromDotNotationResult;
    }

    static getResultFromSingleQueryData<T = any>(queryDataOptions: GetQueryDataOptions) {
        const { data, dotNotationProperties, mappedDotNotationProperties, modelName, queryPrefixDotNotation } = {
            queryPrefixDotNotation: 'admin',
            ...queryDataOptions
        } as GetQueryDataOptions;

        let innerData = data;

        if (innerData && queryPrefixDotNotation) {
            const splittedQueryPrefixDotNotation = queryPrefixDotNotation.split('.');

            splittedQueryPrefixDotNotation.forEach((queryPrefix) => {
                innerData = innerData[queryPrefix];
            });
        }

        if (!innerData) {
            return null;
        }

        const queryName = LamaGrapQlNamingHelper.createSingleQueryName(modelName);

        innerData = innerData[queryName];

        if (!innerData) {
            return null;
        }

        let proccessedData: any ;

        if (UtilityHelper.isNotEmpty(dotNotationProperties)) {
            proccessedData = dotNotationProperties!.reduce((acc, value) => {
                const property = UtilityHelper.getDotNotationPropertyLast(value);

                acc[property] = UtilityHelper.getDotNotationPropertyValue(innerData, value);

                return acc;
            }, {} as KeyValueObject);
        } else if (UtilityHelper.isNotEmpty(mappedDotNotationProperties)) {
            proccessedData = Object.keys(mappedDotNotationProperties!).reduce((acc, key) => {
                if (UtilityHelper.isString(mappedDotNotationProperties![key])) {
                    acc[key] = UtilityHelper.getDotNotationPropertyValue(innerData, mappedDotNotationProperties![key]);
                }
                else {
                    acc[key] = innerData[key];
                }

                return acc;
            }, {} as KeyValueObject)
        } else {
            proccessedData = innerData;
        }

        return (proccessedData as unknown) as T;
    }

    static getResultFromQueryData<T = any>(queryDataOptions: GetQueryDataOptions) {
        const {
            data, dotNotationProperties, mappedDotNotationProperties, modelName,
            queryType = GraphQlQueryType.list, queryPrefixDotNotation
        } = {
            queryPrefixDotNotation: 'admin',
            ...queryDataOptions
        } as GetQueryDataOptions;
        const isListQuery = queryType === GraphQlQueryType.list;
        let defaultData: T[] = [];
        let innerData = data;

        if (innerData && queryPrefixDotNotation) {
            innerData = innerData[queryPrefixDotNotation];
        }

        if (!innerData) {
            return defaultData;
        }

        const queryName = LamaGrapQlNamingHelper.createQueryNameFromType(modelName, queryType);

        innerData = innerData[queryName];

        if (!innerData) {
            return defaultData;
        }

        const isDataArray = UtilityHelper.isArray(innerData);

        if (!isDataArray && isListQuery) {
            innerData = [innerData];
        }

        let proccessedData: any[];

        if (UtilityHelper.isNotEmpty(dotNotationProperties)) {
            proccessedData = (innerData as any[]).map(obj =>
                dotNotationProperties!.reduce((acc, value) => {
                    const property = UtilityHelper.getDotNotationPropertyLast(value);

                    acc[property] = UtilityHelper.getDotNotationPropertyValue(obj, value);

                    return acc;
                }, {} as KeyValueObject)
            );
        } else if (UtilityHelper.isNotEmpty(mappedDotNotationProperties) && isListQuery) {
            const objectKeys = Object.keys(mappedDotNotationProperties!);

            proccessedData = (innerData as any[]).map(obj =>
                objectKeys.reduce((acc, key) => {
                    if (UtilityHelper.isString(mappedDotNotationProperties![key])) {
                        acc[key] = UtilityHelper.getDotNotationPropertyValue(obj, mappedDotNotationProperties![key]);
                    }
                    else {
                        acc[key] = obj[key];
                    }

                    return acc;
                }, {} as KeyValueObject)
            );
        } else {
            proccessedData = innerData;
        }

        return (proccessedData as unknown) as T[];
    }

    static getInferredGraphQlType(variableName: string, value: any): string {
        if (variableName === 'id') {
            return GraphQlValueType.id;
        }

        if (variableName.endsWith('Id')) {
            return GraphQlValueType.guid;
        }

        if (value === 'DESC' || value === 'ASC') {
            return GraphQlValueType.sortOperationKind;
        }

        if (UtilityHelper.isArray(value) || variableName.endsWith('in')) {
            const variableNameWithoutArray = variableName.replace('_in', '');

            const firstArrayValue = value ? value[0] : null;

            return `[${LamaGraphQlQueryHelper.getInferredGraphQlType(variableNameWithoutArray,firstArrayValue)}]`;
        }

        if (UtilityHelper.isNumber(value)) {
            return GraphQlValueType.integer;
        }

        if (UtilityHelper.isBoolean(value)) {
            return GraphQlValueType.boolean;
        }

        return GraphQlValueType.string;
    }

    static getCursor(page: number, pageSize: number, totalCount: number) {
        const position = page === 1 ? 0 : (page - 1) * pageSize - 1;
        const cursorValue = `{"__totalCount":${totalCount},"__position":${position}}`

        return Base64Helper.encode(cursorValue);
    }

    static getPaginatedQueryObject<TEntity = any>(queryObject: TEntity) {
        const paginatedQueryObject: PaginationConnection<TEntity> = {
            /*edges: [{
                cursor: types.string,
                node: queryObject
            }],*/
            nodes: [queryObject],
            pageInfo: {
                endCursor: types.string,
                hasNextPage: types.boolean,
                hasPreviousPage: types.boolean,
                startCursor: types.string
            },
            totalCount: types.number
        };

        return paginatedQueryObject;
    }
}
