import React, { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';

import Collapse from '@material-ui/core/Collapse';
import Link from '@material-ui/core/Link';
import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
    createStyles({
        titleTransparent: {
            border: 0,
            paddingLeft: 0,
            paddingRight: 0
        }
    })
);

interface LamaCollapsibleCardClasses {
    block?: string;
    body?: string;
    root?: string;
}

export interface LamaCollapsibleCardProps {
    closeable?: boolean;
    classes?: LamaCollapsibleCardClasses;
    collapsible?: boolean;
    heading?: string;
    headingTranslate?: string;
    fullBlock?: boolean;
    transparent?: boolean;
    showRequiredInTitle?: boolean;
    summary?: string;
    summaryTranslate?: string;

    onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export const LamaCollapsibleCard: FC<LamaCollapsibleCardProps> = (props) => {
    const {
        closeable,
        classes: { block: blockClassName, body: bodyClassName, root: rootClassName } = { block: '', body: '', root: '' },
        collapsible,
        children,
        fullBlock,
        heading,
        headingTranslate,
        onClick,
        transparent,
        showRequiredInTitle,
        summary,
        summaryTranslate
    } = props;

    const { titleTransparent } = useStyles();

    const [closed, setClosed] = useState(false);
    const [collapsed, setCollapsed] = useState(true);

    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    };

    const closeCard = () => {
        setClosed(true);
    };

    return (
        <div className={classnames(rootClassName || '', { 'd-block': collapsed })} onClick={onClick}>
            <div className={classnames(`h-100`, blockClassName, { 'd-none': closed, 'rct-block': !transparent })}>
                {(heading || headingTranslate) && (
                    <div className={classnames('rct-block-title', { [titleTransparent]: transparent })}>
                        <h4>
                            {heading}
                            <FormattedMessage id={headingTranslate} />
                            {showRequiredInTitle && <span className="text-danger"> *</span>}
                        </h4>
                        {(collapsible || closeable) && (
                            <div className="contextual-link">
                                {collapsible && (
                                    <Link onClick={toggleCollapsed}>
                                        <i className="ti-minus"></i>
                                    </Link>
                                )}
                                {closeable && (
                                    <Link onClick={closeCard}>
                                        <i className="ti-close"></i>
                                    </Link>
                                )}
                            </div>
                        )}
                    </div>
                )}
                <Collapse in={collapsed} classes={{ container: 'h-100', wrapper: 'h-100', wrapperInner: 'h-100' }} >
                    <div className={classnames('h-100', bodyClassName, { 'rct-block-content': !fullBlock, 'rct-full-block': fullBlock })}>
                        {(summary || summaryTranslate) && (
                            <p className="text-muted">
                                {summary}
                                {summaryTranslate && <FormattedMessage id={summaryTranslate} />}
                            </p>
                        )}
                        {children}
                    </div>
                </Collapse>
            </div>
        </div>
    );
};
