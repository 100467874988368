import React, { FC, useState, Fragment, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import DeleteIcon from '@material-ui/icons/Delete';
import Divider from '@material-ui/core/Divider';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import { useMountEffect } from 'shared/packages/use-mount-effect';
import { useLamaAlerts } from 'shared/packages/alerts';
import { useLamaGridCreator, LamaGridRowEvent } from 'shared/components/grid';
import { useRapidModel } from 'shared/packages/rapid-model';

import { LamaLoader } from 'shared/components/LamaLoader';
import { DeletConfirmationDialog } from 'shared/components/dialogs';

import { useLamaQuery, useLamaClient } from 'shared/graphql';
import { LamaSimpleListProps } from './types';
import { BaseEntity } from 'shared/types';
import { UtilityHelper } from 'shared/utilities';

export const LamaSimpleList: FC<LamaSimpleListProps> = props => {
    const { bindLabel, gridName, hideDelete, deleteServerOptions, secondIdPropertyName } = props;
    let { serverOptions } = props;
    let { loadAdditionalProperties = [], idPropertyDotNotation, modelName, gqlQuery, variables } = serverOptions || {};
    const dotNotationProperties = [
        bindLabel, deleteServerOptions?.idPropertyDotNotation!,
        ...loadAdditionalProperties, idPropertyDotNotation || 'id'
    ];

    const lamaClient = useLamaClient();
    const { addErrorAlert, addSuccessAlert } = useLamaAlerts();
    const { addGridInstance, removeGridInstance } = useLamaGridCreator();
    const { getModelNameTranslation } = useRapidModel({
        defaultModelName: modelName
    });
    const { data, loading, refetch } = useLamaQuery<any[]>({
        dotNotationProperties,
        gqlQuery,
        modelName,
        variables,
        skip: !modelName
    });

    const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [isLoaderShown, setLoaderShown] = useState(false);
    const [selectedItem, setSelectedItem] = useState<BaseEntity | null>(null);

    const selectedItemLabel = UtilityHelper.getDotNotationPropertyValue(selectedItem, bindLabel);

    const reload = () => {
        const newVariables = variables || {};

        refetch({
            ...newVariables
        });
    };

    const toggleLoader = (showLoader?: boolean) => {};
    const triggerAction = (rowEvent: LamaGridRowEvent) => {};

    useMountEffect(() => {
        const gridInstanceName = addGridInstance({
            reload,
            toggleLoader,
            triggerAction,
            name: gridName
        });

        return () => {
            removeGridInstance(gridInstanceName);
        };
    });

    useEffect(() => {
        if (data) {
            refetch(variables);
        }
    }, [variables]);

    const deleteItem = (item: BaseEntity) => {
        setSelectedItem(item);
        setDeleteDialogOpen(true);
    };

    const editItem = (item: BaseEntity) => {
        if (props.onEdit) {
            props?.onEdit(item);
        }
    };

    const onDeleteConfirmationClose = () => {
        setSelectedItem(null);
        setDeleteDialogOpen(false);
    };

    const onDeleteConfirmed = async () => {
        setDeleteDialogOpen(false);
        setLoaderShown(true);

        const entityName = getModelNameTranslation();

        try {
            let modelId = selectedItem!.id!;

            if (deleteServerOptions) {
                modelName = deleteServerOptions.modelName;
                modelId = UtilityHelper.getDotNotationPropertyValue(selectedItem, deleteServerOptions.idPropertyDotNotation!) || modelId;
            }

            const mutationResult = secondIdPropertyName && idPropertyDotNotation
                ? await lamaClient.deleteMutateJoinedModel({
                    modelName,
                    modelIds: {
                        [secondIdPropertyName]: UtilityHelper.getDotNotationPropertyValue(selectedItem, secondIdPropertyName),
                        [idPropertyDotNotation]: UtilityHelper.getDotNotationPropertyValue(selectedItem, idPropertyDotNotation),
                    }
                })
                : await lamaClient.deleteMutateModel({
                    modelName,
                    modelId
                });

            if (mutationResult.data) {
                reload();

                if (props.onDeleteSuccess) {
                    props.onDeleteSuccess(modelId);
                }

                addSuccessAlert('shared.alerts.delete.success', {
                    entityName,
                    entityTitle: selectedItemLabel
                });
            }
        } catch (e) {
            addErrorAlert('shared.alerts.delete.error', {
                entityName,
                entityTitle: selectedItemLabel
            });
        } finally {
            setLoaderShown(false);
        }
    };

    return (
        <>
            <List>
                <Divider />
                <LamaLoader showLoader={loading || isLoaderShown} />
                {UtilityHelper.isNotEmpty(data) ? (
                    data?.map((q, index) => (
                        <Fragment key={q.id || index}>
                            <ListItem>
                                <ListItemText primary={UtilityHelper.getDotNotationPropertyValue(q, bindLabel)} />
                                <IconButton aria-label="edit" onClick={() => editItem(q)}>
                                    <EditIcon />
                                </IconButton>
                                {!hideDelete && (
                                    <IconButton aria-label="delete" onClick={() => deleteItem(q)}>
                                        <DeleteIcon />
                                    </IconButton>
                                )}
                            </ListItem>
                            <Divider />
                        </Fragment>
                    ))
                ) : (
                    <ListItem>
                        <ListItemText>
                            <FormattedMessage id="shared.emptyData" />
                        </ListItemText>
                    </ListItem>
                )}
            </List>
            <DeletConfirmationDialog
                isOpen={isDeleteDialogOpen}
                onClose={onDeleteConfirmationClose}
                onConfirm={onDeleteConfirmed}
                entityTitle={selectedItemLabel}
            />
        </>
    );
};
