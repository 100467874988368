import { types } from 'typed-graphqlify';
import { GraphQlQueryType, LamaGraphQlQueryHelper } from 'shared/graphql';

import { CompanyResourceNames, CompanyModel } from 'features/companies/models';
import { BaseEntity } from 'shared/types';

import { locationQueryObject } from 'features/locations/queries';

export const companyQueryObject: CompanyModel = {
    id: types.string,
    activityType: types.custom(),
    description: types.string,
    displayTitle: types.string,
    email: types.string,
    facebookUrl: types.string,
    linkedInUrl: types.string,
    logoId: types.string,
    youTubeUrl: types.string,
    name: types.string,
    phone: types.string,
    registrationNumber: types.string,
    taxNumber: types.string,
    shortTitle: types.string,
    supportsCardPayment: types.boolean,
    supportsCashPayment: types.boolean,
    supportsPaymentAfterPickup: types.boolean,
    supportsPickup: types.boolean,
    supportsPrePaymentUpn: types.boolean,
    supportsShipment: types.boolean,
    title: types.string,
    validForTax: types.boolean,
    website: types.string,
    address: locationQueryObject
};

export const { gqlQuery: singleCompanyQuery } = LamaGraphQlQueryHelper.createQueryFromDotNotation<CompanyModel, BaseEntity>({
    modelName: CompanyResourceNames.company,
    queryType: GraphQlQueryType.single,
    queryObject: companyQueryObject,
    variables: {
        id: types.string
    }
});
