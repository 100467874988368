import React, { FC } from 'react';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            backgroundColor: theme.palette.grey[100],
            borderBottom: `1px solid ${theme.palette.grey[300]}`
        }
    })
);

export const Toolbar: FC = (props) => {
    const { children } = props;

    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <ButtonGroup>{children}</ButtonGroup>
        </Box>
    );
};
