import { useContext } from 'react';

import { useLamaGridInterface } from './types';
import { LamaGridRowEvent } from 'shared/components/grid/types';

import { LamaGridContext } from './LamaGridProvider';

export const useLamaGrid: useLamaGridInterface = (gridName) => {
    const { lastGrid, grids } = useContext(LamaGridContext);

    const gridInstance = gridName ? grids.find(q => q.name === gridName) : lastGrid;

    const reload = () => {
        gridInstance?.reload();
    };

    const toggleLoader = () => {
        gridInstance?.toggleLoader();
    };

    const triggerAction = (rowEvent: LamaGridRowEvent) => {
        gridInstance?.triggerAction(rowEvent);
    };

    return {
        reload,
        toggleLoader,
        triggerAction
    }
}
