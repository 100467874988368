import merge from 'deepmerge';
import isEqual from "react-fast-compare";
import uuid from 'uuid/v4';

import { KeyValueObject } from 'shared/types';

interface Constructor<T> {
    new (...args: any[]): T;
}

const DEFAULT_GUID = '00000000-0000-0000-0000-000000000000';

export class UtilityHelper {
    static copy<T>(obj: T) {
        return merge<T>({}, obj);
    }

    static createGuid() {
        return uuid();
    }

    static equals(left: any, right: any) {
        return isEqual(left, right);
    }

    static extend<T = any>(...objects: any[]) {
        return merge.all<T>(objects);
    }

    static objectToDotNotation(object: any, prefix = '') {
        return Object.keys(object).reduce((messages, key) => {
            let value = object[key];
            let prefixedKey = prefix ? `${prefix}.${key}` : key;

            if (UtilityHelper.isString(value) || UtilityHelper.isEmpty(value)) {
                messages[prefixedKey] = value;
            } else {
                Object.assign(messages, UtilityHelper.objectToDotNotation(value, prefixedKey));
            }

            return messages;
        }, {} as KeyValueObject);
    }

    static getDotNotationProperties(object: any, prefix = '') {
        const objectDotNotation = UtilityHelper.objectToDotNotation(object, prefix);

        return Object.keys(objectDotNotation);
    }

    static getDotNotationPropertyValue<TPropertyValue = any>(value: any, dotNotationProperty?: string) {
        if (value && UtilityHelper.isString(dotNotationProperty)) {
            const splittedDotNotationProperty = dotNotationProperty!.split('.');

            while (splittedDotNotationProperty.length && value) {
                const currentProperty = splittedDotNotationProperty.shift();

                if (UtilityHelper.isArray(value) && currentProperty) {
                    value = (value as KeyValueObject[]).map(q => UtilityHelper.getDotNotationPropertyValue(q, currentProperty!));

                    break;
                }
                else {
                    value = value[currentProperty!];
                }
            }
        }

        return value as TPropertyValue;
    }

    static getDotNotationPropertyLast(dotNotationProperty: string) {
        if (UtilityHelper.isNotEmpty(dotNotationProperty)) {
            const splittedDotNotationProperty = dotNotationProperty.split('.');

            return splittedDotNotationProperty[splittedDotNotationProperty.length - 1];
        }

        return '';
    }

    static setDotNotationPropertyValue(value: any, dotNotationProperty: string, changeValueFunc: (existingValue: any) => any) {
        if (value && dotNotationProperty) {
            let copiedValue = UtilityHelper.copy(value);
            let originvalValue = copiedValue;
            const splittedDotNotationProperty = dotNotationProperty.split('.');

            do {
                const currentProperty = splittedDotNotationProperty.shift()!;

                if (splittedDotNotationProperty.length) {
                    if (copiedValue[currentProperty]) {
                        copiedValue = copiedValue[currentProperty];
                    } else {
                        copiedValue = copiedValue[currentProperty] = {};
                    }
                } else {
                    copiedValue[currentProperty] = changeValueFunc(copiedValue[currentProperty]);
                }
            } while (splittedDotNotationProperty.length && value);

            return originvalValue;
        }

        return value;
    }

    static groupBy<TValue>(values: TValue[], getKeyFunc: (val: TValue) => string) {
        if (UtilityHelper.isNotEmpty(values)) {
            return values.reduce<KeyValueObject<TValue[]>>((acc, val) => {
                const key = getKeyFunc(val);

                if (UtilityHelper.isString(key)) {
                    if (UtilityHelper.isNotEmpty(acc[key])) {
                        acc[key].push(val);
                    } else {
                        acc[key] = [val];
                    }
                }

                return acc;
            }, {});
        }

        return {};
    }

    static flatten(arr: any[]) {
        if (UtilityHelper.isArray(arr)) {
            let index: number;

            while ((index = arr.findIndex(el => Array.isArray(el))) > -1) {
                arr.splice(index, 1, ...arr[index]);
            }
        }

        return arr;
    }

    static isArray(array: any) {
        return Array.isArray(array) || array instanceof Array;
    }

    static isBoolean(value: any) {
        return typeof value === typeof true;
    }

    static isClass<T>(value: any, constructor: Constructor<T>) {
        return value instanceof constructor;
    }

    static isDefined(value: any) {
        return !this.isUndefined(value);
    }

    static isError(value: any) {
        return value instanceof Error;
    }

    static isEmpty(val: any): boolean {
        // Null and Undefined...
        if (UtilityHelper.isUndefinedOrNull(val)) {
            return true;
        }

        // Booleans..., Numbers...
        if (UtilityHelper.isBoolean(val) || UtilityHelper.isNumber(val)) {
            return false;
        }

        // Strings...
        if (UtilityHelper.isString(val)) {
            return val.length === 0 || val === ' ' || val === DEFAULT_GUID;
        }

        // Functions...
        if (UtilityHelper.isFunction(val)) {
            return false;
        }

        // Arrays...
        if (UtilityHelper.isArray(val)) {
            return val.length === 0 || (val as any[]).every(q => UtilityHelper.isEmpty(q));
        }

        // Errors...
        if (val instanceof Error) {
            return val.message === '';
        }

        // Objects...
        if (val.toString === toString) {
            switch (val.toString()) {
                // Maps, Sets, Files and Errors...
                case '[object File]':
                case '[object Map]':
                case '[object Set]': {
                    return val.size === 0;
                }

                // Plain objects...
                case '[object Object]': {
                    for (var key in val) {
                        if (Object.prototype.hasOwnProperty.call(val, key)) {
                            return false;
                        }
                    }

                    return true;
                }
            }
        }

        // Anything else...
        return false;
    }

    static isNotEmpty(obj: any) {
        return !UtilityHelper.isEmpty(obj);
    }

    static isIterableArray(value: any) {
        if (value == null || this.isUndefined(value)) {
            return false;
        }

        if (typeof value[Symbol.iterator] !== 'function') {
            return false;
        }

        return !this.isString(value);
    }

    static isNumber(value: any) {
        return !isNaN(parseFloat(value)) && isFinite(value);
    }

    static isFile(value: any) {
        return !this.isEmpty(value) && value instanceof File;
    }

    static isFunction(value: any) {
        return value && Object.prototype.toString.call(value) === '[object Function]';
    }

    static isObject(value: any) {
        return value !== null && typeof value === 'object';
    }

    static isSymbol(value: any) {
        return typeof value === 'symbol' || (typeof value === 'object' && Object.prototype.toString.call(value) === '[object Symbol]');
    }

    static isString(value: any) {
        return typeof value === 'string';
    }

    static isUndefined(value: any) {
        return typeof value === 'undefined';
    }

    static isUndefinedOrNull(value: any) {
        return UtilityHelper.isUndefined(value) || value == null;
    }

    static isPropertyInObject(property: any, value: Object) {
        if (this.isObject(value)) {
            return value.hasOwnProperty(property);
        }

        return false;
    }

    static notEquals(left: any, right: any) {
        return !UtilityHelper.equals(left, right);
    }

    static getAsArray<T = any>(value: any): T[] {
        if (UtilityHelper.isNotEmpty(value)) {
            if (UtilityHelper.isArray(value)) {
                return value;
            } else {
                return [value];
            }
        } else {
            return [];
        }
    }

    private static deepEquals(x: any, y: any, leftChain: any[], rightChain: any[]) {
        let p;

        if (isNaN(x) && isNaN(y) && typeof x === 'number' && typeof y === 'number') {
            return true;
        }

        if (x === y) {
            return true;
        }

        if (
            (typeof x === 'function' && typeof y === 'function') ||
            (x instanceof Date && y instanceof Date) ||
            (x instanceof RegExp && y instanceof RegExp) ||
            (x instanceof String && y instanceof String) ||
            (x instanceof Number && y instanceof Number)
        ) {
            return x.toString() === y.toString();
        }

        if (!(x instanceof Object && y instanceof Object)) {
            return false;
        }

        if (x.isPrototypeOf(y) || y.isPrototypeOf(x)) {
            return false;
        }

        if (x.constructor !== y.constructor) {
            return false;
        }

        if (x.prototype !== y.prototype) {
            return false;
        }

        if (leftChain.indexOf(x) > -1 || rightChain.indexOf(y) > -1) {
            return false;
        }

        for (p in y) {
            if (y.hasOwnProperty(p) !== x.hasOwnProperty(p)) {
                return false;
            }

            if (typeof y[p] !== typeof x[p]) {
                return false;
            }
        }

        for (p in x) {
            if (y.hasOwnProperty(p) !== x.hasOwnProperty(p)) {
                return false;
            }

            if (typeof y[p] !== typeof x[p]) {
                return false;
            }

            switch (typeof x[p]) {
                case 'object':
                case 'function':
                    leftChain.push(x);
                    rightChain.push(y);

                    if (!this.deepEquals(x[p], y[p], leftChain, rightChain)) {
                        return false;
                    }

                    leftChain.pop();
                    rightChain.pop();
                    break;

                default:
                    if (x[p] !== y[p]) {
                        return false;
                    }
                    break;
            }
        }

        return true;
    }
}
