
import {
    LamaFormlyFieldConfig,
    LamaFormlyWrappers,
    LamaFormlyTypes,
    LamaCollapsibleCardWrapperTemplateOptions,
    LamaEnumRadioGroupTypeTemplateOptions
} from 'shared/components/forms/models';

import { CompanyActivityType } from 'features/companies/models';
import { LocationFormlyTypes } from 'features/locations/models';
import { MediaFormlyTypes, MediaEntityChooserTypeTemplateOptions } from 'features/media/models';

export const useCompanyFormFields = (): LamaFormlyFieldConfig[] => {
    return [
        {
            wrappers: [LamaFormlyWrappers.row, LamaFormlyWrappers.submitButton],
            fieldGroup: [
                {
                    fieldGroupClassName: 'col-sm-6',
                    wrappers: [LamaFormlyWrappers.div],
                    fieldGroup: [
                        {
                            wrappers: [LamaFormlyWrappers.card],
                            templateOptions: {
                                cardProps: {
                                    headingTranslate: 'company.basic.header'
                                }
                            } as LamaCollapsibleCardWrapperTemplateOptions,
                            fieldGroup: [
                                {
                                    key: 'companyOfTenant.activityType',
                                    type: LamaFormlyTypes.enumRadioGroup,
                                    wrappers: [LamaFormlyWrappers.formGroup],
                                    templateOptions: {
                                        disabled: true,
                                        labelTranslate: 'company.type',
                                        enumRadioGroupProps: {
                                            enumType: CompanyActivityType,
                                            translatePrefix: 'company.activityType'
                                        },
                                        required: true
                                    } as LamaEnumRadioGroupTypeTemplateOptions
                                },
                                {
                                    key: 'companyOfTenant.registrationNumber',
                                    type: LamaFormlyTypes.input,
                                    wrappers: [LamaFormlyWrappers.formGroup],
                                    templateOptions: {
                                        labelTranslate: 'company.registrationNumber',
                                        required: true
                                    }
                                },
                                {
                                    key: 'companyOfTenant.taxNumber',
                                    type: LamaFormlyTypes.input,
                                    wrappers: [LamaFormlyWrappers.formGroup],
                                    templateOptions: {
                                        labelTranslate: 'company.taxNumber',
                                        required: true
                                    }
                                },
                                {
                                    key: 'companyOfTenant.validForTax',
                                    type: LamaFormlyTypes.checkbox,
                                    templateOptions: {
                                        labelTranslate: 'company.validForTax'
                                    }
                                },
                                {
                                    key: 'companyOfTenant.title',
                                    type: LamaFormlyTypes.input,
                                    wrappers: [LamaFormlyWrappers.formGroup],
                                    templateOptions: {
                                        labelTranslate: 'company.title',
                                        required: true
                                    }
                                },
                                {
                                    key: 'companyOfTenant.displayTitle',
                                    type: LamaFormlyTypes.input,
                                    wrappers: [LamaFormlyWrappers.formGroup],
                                    templateOptions: {
                                        labelTranslate: 'company.displayTitle'
                                    }
                                },
                                {
                                    key: 'companyOfTenant.name',
                                    type: LamaFormlyTypes.input,
                                    wrappers: [LamaFormlyWrappers.formGroup],
                                    templateOptions: {
                                        labelTranslate: 'company.name'
                                    }
                                },
                                {
                                    key: 'companyOfTenant.description',
                                    type: LamaFormlyTypes.editor,
                                    wrappers: [LamaFormlyWrappers.formGroup],
                                    templateOptions: {
                                        labelTranslate: 'company.description'
                                    }
                                }
                            ]
                        },
                        {
                            wrappers: [LamaFormlyWrappers.card],
                            templateOptions: {
                                cardProps: {
                                    headingTranslate: 'company.supports.header',
                                    summaryTranslate: 'company.supports.summary'
                                }
                            } as LamaCollapsibleCardWrapperTemplateOptions,
                            fieldGroup: [
                                {
                                    wrappers: [LamaFormlyWrappers.formGroup],
                                    templateOptions: {
                                        labelTranslate: 'company.supportsShipment'
                                    },
                                    fieldGroup: [
                                        {
                                            wrappers: [LamaFormlyWrappers.row],
                                            fieldGroup: [
                                                {
                                                    key: 'companyOfTenant.supportsPickup',
                                                    type: LamaFormlyTypes.checkbox,
                                                    fieldGroupClassName: 'col-sm-6',
                                                    wrappers: [LamaFormlyWrappers.div],
                                                    templateOptions: {
                                                        labelTranslate: 'company.supportsPickup'
                                                    }
                                                },
                                                {
                                                    key: 'companyOfTenant.supportsShipment',
                                                    type: LamaFormlyTypes.checkbox,
                                                    fieldGroupClassName: 'col-sm-6',
                                                    wrappers: [LamaFormlyWrappers.div],
                                                    templateOptions: {
                                                        labelTranslate: 'company.supportsShipment'
                                                    }
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    wrappers: [LamaFormlyWrappers.formGroup],
                                    templateOptions: {
                                        labelTranslate: 'company.paymentOptions'
                                    },
                                    fieldGroup: [
                                        {
                                            wrappers: [LamaFormlyWrappers.row],
                                            fieldGroup: [
                                                {
                                                    key: 'companyOfTenant.supportsCashPayment',
                                                    type: LamaFormlyTypes.checkbox,
                                                    fieldGroupClassName: 'col-sm-6',
                                                    wrappers: [LamaFormlyWrappers.div],
                                                    templateOptions: {
                                                        labelTranslate: 'company.supportsCashPayment'
                                                    }
                                                },
                                                {
                                                    key: 'companyOfTenant.supportsPrePaymentUpn',
                                                    type: LamaFormlyTypes.checkbox,
                                                    fieldGroupClassName: 'col-sm-6',
                                                    wrappers: [LamaFormlyWrappers.div],
                                                    templateOptions: {
                                                        labelTranslate: 'company.supportsPrePaymentUpn'
                                                    }
                                                },
                                                {
                                                    key: 'companyOfTenant.supportsCardPayment',
                                                    type: LamaFormlyTypes.checkbox,
                                                    fieldGroupClassName: 'col-sm-6',
                                                    wrappers: [LamaFormlyWrappers.div],
                                                    templateOptions: {
                                                        labelTranslate: 'company.supportsCardPayment'
                                                    }
                                                },
                                                {
                                                    key: 'companyOfTenant.supportsPaymentAfterPickup',
                                                    type: LamaFormlyTypes.checkbox,
                                                    fieldGroupClassName: 'col-sm-6',
                                                    wrappers: [LamaFormlyWrappers.div],
                                                    templateOptions: {
                                                        labelTranslate: 'company.supportsPaymentAfterPickup'
                                                    }
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    fieldGroupClassName: 'col-sm-6',
                    wrappers: [LamaFormlyWrappers.div],
                    fieldGroup: [
                        {
                            wrappers: [LamaFormlyWrappers.card],
                            templateOptions: {
                                cardProps: {
                                    headingTranslate: 'company.logo.header'
                                }
                            } as LamaCollapsibleCardWrapperTemplateOptions,
                            fieldGroup: [
                                {
                                    key: 'companyOfTenant.logoId',
                                    type: MediaFormlyTypes.mediaSingleChooser,
                                    templateOptions: {
                                        gridSize: 4
                                    } as MediaEntityChooserTypeTemplateOptions
                                }
                            ]
                        },
                        {
                            wrappers: [LamaFormlyWrappers.card],
                            templateOptions: {
                                cardProps: {
                                    headingTranslate: 'company.location.header',
                                }
                            } as LamaCollapsibleCardWrapperTemplateOptions,
                            fieldGroup: [
                                {
                                    key: 'companyOfTenant.address',
                                    type: LocationFormlyTypes.placeSelector,
                                    wrappers: [LamaFormlyWrappers.formGroup],
                                    templateOptions: {
                                        labelTranslate: 'company.address',
                                        required: true
                                    }
                                }
                            ]
                        },
                        {
                            wrappers: [LamaFormlyWrappers.card],
                            templateOptions: {
                                cardProps: {
                                    headingTranslate: 'company.contacting.header',
                                    summaryTranslate: 'company.contacting.summary'
                                }
                            } as LamaCollapsibleCardWrapperTemplateOptions,
                            fieldGroup: [
                                {
                                    key: 'companyOfTenant.phone',
                                    type: LamaFormlyTypes.input,
                                    wrappers: [LamaFormlyWrappers.formGroup],
                                    templateOptions: {
                                        labelTranslate: 'company.phone'
                                    }
                                },
                                {
                                    key: 'companyOfTenant.email',
                                    type: LamaFormlyTypes.input,
                                    wrappers: [LamaFormlyWrappers.formGroup],
                                    templateOptions: {
                                        labelTranslate: 'company.email',
                                        type: 'email'
                                    }
                                },
                                {
                                    key: 'companyOfTenant.website',
                                    type: LamaFormlyTypes.input,
                                    wrappers: [LamaFormlyWrappers.formGroup],
                                    templateOptions: {
                                        labelTranslate: 'company.website',
                                        type: 'url'
                                    }
                                },
                                {
                                    key: 'companyOfTenant.facebookUrl',
                                    type: LamaFormlyTypes.input,
                                    wrappers: [LamaFormlyWrappers.formGroup],
                                    templateOptions: {
                                        labelTranslate: 'company.facebookUrl',
                                        type: 'url'
                                    }
                                },
                                {
                                    key: 'companyOfTenant.linkedInUrl',
                                    type: LamaFormlyTypes.input,
                                    wrappers: [LamaFormlyWrappers.formGroup],
                                    templateOptions: {
                                        labelTranslate: 'company.linkedInUrl',
                                        type: 'url'
                                    }
                                },
                                {
                                    key: 'companyOfTenant.youTubeUrl',
                                    type: LamaFormlyTypes.input,
                                    wrappers: [LamaFormlyWrappers.formGroup],
                                    templateOptions: {
                                        labelTranslate: 'company.youTubeUrl',
                                        type: 'url'
                                    }
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    ];
};
