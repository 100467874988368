import gql from 'graphql-tag';


export const MONTHLY_STATISTICS = gql`
    query {
        admin {
            statistics {
                monthlyStatistics {
                    date
                    numberOfUniqueViews
                    numberOfViews
                }
            }
        }
    }`;
