import React, { FC } from 'react';
import { useFetch } from 'use-http';

import { Media, MediaResourceNames, DEFAULT_MEDIA_PAGINATION_SIZE } from 'features/media/models';
import { mediaQueryObject } from 'features/media/queries';

import { useLamaClient, SortOperationKind, GraphQlQueryType } from 'shared/graphql';
import { useLamaAlerts } from 'shared/packages/alerts';

import { LamaLoader } from 'shared/components';
import { MediaFileDropzone } from './MediaFileDropzone';

import { UtilityHelper } from 'shared/utilities';
import { LamaGraphQlValueWithTypeCreator } from 'shared/graphql/utilities';

interface MediaFileUploadProps {
    maxFileSize?: number;
    onMediaUploadCompleted: () => void;
}

export const MediaFileUpload: FC<MediaFileUploadProps> = props => {
    const { maxFileSize = 5 * 10e5, onMediaUploadCompleted } = props;
    const { addErrorAlert, addWarningAlert, addSuccessAlert } = useLamaAlerts();
    const { addToCache } = useLamaClient();
    const { loading, post, response } = useFetch<Media>();

    const onSelectedFiles = async (files: File[]) => {
        if (UtilityHelper.isEmpty(files)) {
            return;
        }

        const tooBigFiles = files.filter(q => q.size > maxFileSize);

        if (UtilityHelper.isNotEmpty(tooBigFiles)) {
            addWarningAlert(`media.choose.tooBigFile${tooBigFiles.length > 1 ? 's': ''}`, {
                fileNames: tooBigFiles.map(q => q.name).join(', '),
                maxFileSize: Math.floor(maxFileSize / 10e5)
            });

            return;
        }

        let numberOfErrors = 0;
        let newMediaFiles: Media[] = [];

        for (const file of files) {
            const data = new FormData();

            data.append('file', file);
            data.append('title', file.name);

            try {
                await post('api/v3/media', data);

                if (response.ok) {
                    if (response.data) {
                        newMediaFiles.push(response.data);
                    }
                } else {
                    numberOfErrors++;
                }
            } catch (e) {
                numberOfErrors++;
            }
        }

        if (numberOfErrors) {
            if (numberOfErrors === files.length) {
                addErrorAlert('media.choose.uploadError');

                return;
            } else {
                addWarningAlert('media.choose.uploadWarning');
            }
        } else {
            addSuccessAlert('media.choose.uploadSuccess');
        }

        addToCache(
            {
                modelName: MediaResourceNames.media,
                queryObject: mediaQueryObject,
                queryType: GraphQlQueryType.paginated,
                variables: {
                    first: LamaGraphQlValueWithTypeCreator.createWithPaginationAmountType(DEFAULT_MEDIA_PAGINATION_SIZE),
                    order_by: {
                        createdAt: SortOperationKind.desc
                    }
                }
            },
            newMediaFiles
        );

        onMediaUploadCompleted();
    };

    return (
        <>
            <LamaLoader showLoader={loading} />
            <MediaFileDropzone onSelectedFiles={onSelectedFiles} />
        </>
    );
};
