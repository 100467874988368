import appLogo from './img/app-logo.svg';
import  appLogoShort from './img/app-logo-short.png';
import logo  from './img/logo.svg';
import placeholderImage from './img/image-placeholder.jpg';
import promo from  './img/sellaround-promo.jpg';

export const images = {
    appLogo,
    appLogoShort,
    logo,
    placeholderImage,
    promo
};
