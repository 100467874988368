export enum LamaFormlyTypes {
    advancedEnumRadioGroup = 'lm-advanced-enum-radio-group',
    checkbox = 'lm-checkbox',
    checkboxGroup = 'lm-checkbox-group',
    datePicker = 'lm-date-picker',
    editor = 'lm-editor',
    enumRadioGroup = 'lm-enum-radio-group',
    input = 'lm-input',
    numberInput = 'lm-number-input',
    moneyInput = 'lm-money-input',
    multipleLargeAdd = 'lm-multiple-large-add',
    multipleSmallAdd = 'lm-multiple-small-add',
    select = 'lm-select',
    simpleList = 'lm-simple-list'
}
