import { useContext } from 'react';
import { useApolloClient, ApolloError } from '@apollo/client';

import {
    MutateModelOptions,
    useLamaClientInterface,
    MutateModelDeleteOptions,
    OperateModelResult,
    QueryModelOptions,
    MutateJoinedModelDeleteOptions,
    MutateOptions
} from './types';
import { LamaCrudOperationType, GraphQlQueryType, PaginationConnection, GraphQlErrorTypes, LamaGraphQlError } from 'shared/graphql/types';
import { LamaGraphQlMutationHelper, LamaGraphQlQueryHelper, LamaGrapQlNamingHelper } from 'shared/graphql/utilities';
import { LamaGraphQlContext } from 'shared/graphql/providers';

import { UtilityHelper, LanguageHelper } from 'shared/utilities';
import { KeyValueObject } from 'shared/types';
import { useIntl } from 'react-intl';

const serverErrorsTranslationPrefix = 'serverErrors';

export const useLamaClient: useLamaClientInterface = () => {
    const { queryPrefixDotNotation } = useContext(LamaGraphQlContext);
    const apolloClient = useApolloClient();
    const { formatMessage } = useIntl();

    const getMessageFromGraphQlError = (e: ApolloError, entityName?: string) => {
        let messageTranslate = '';
        let messageTranslateParams: KeyValueObject = {
            entityName
        };

        if (UtilityHelper.isNotEmpty(e.graphQLErrors)) {
            const graphQlError = e.graphQLErrors[0];

            switch (graphQlError.extensions?.code) {
                case GraphQlErrorTypes.databaseUniqueConstrain:
                    messageTranslate = graphQlError.message;

                    let { entityName, columnName } = graphQlError.extensions;

                    if (entityName) {
                        entityName = LanguageHelper.camelCase(entityName);

                        if (columnName) {
                            columnName = (columnName as string)
                                .split(',')
                                .map(q => {
                                    const messageId = `${entityName}.${LanguageHelper.camelCase(q)}`;
                                    const serverMessage = formatMessage({ id: messageId });

                                    if (serverMessage === messageId) {
                                        return '';
                                    }

                                    return serverMessage;
                                })
                                .filter(q => q)
                                .join(', ');
                        }

                        entityName = formatMessage({ id: `modules.models.${entityName}` });

                        messageTranslateParams = {
                            columnName,
                            entityName
                        };
                    }

                    break;

                case GraphQlErrorTypes.conflict:
                    messageTranslate = graphQlError.message;

                    if (UtilityHelper.isNotEmpty(messageTranslate) && !messageTranslate.startsWith(serverErrorsTranslationPrefix)) {
                        messageTranslate = `${serverErrorsTranslationPrefix}.${messageTranslate}`;
                    }

                    break;
            }
        }

        return {
            messageTranslate,
            messageTranslateParams
        };
    };

    const queryModel = async (queryOptions: QueryModelOptions) => {
        let { modelName, queryType = GraphQlQueryType.list, variables } = queryOptions;
        const { gqlQuery: query, variables: rewrittenVariables } = LamaGraphQlQueryHelper.createQueryFromDotNotation({
            ...queryOptions,
            queryPrefixDotNotation
        });

        if (queryType === GraphQlQueryType.list) {
            variables = rewrittenVariables;
        }

        let { data, errors } = await apolloClient.query({
            query,
            variables
        });

        if (data) {
            if (queryType === GraphQlQueryType.list) {
                data = LamaGraphQlQueryHelper.getResultFromQueryData({
                    modelName,
                    queryPrefixDotNotation,
                    data
                });
            }
            else {
                data = LamaGraphQlQueryHelper.getResultFromSingleQueryData({
                    modelName,
                    queryPrefixDotNotation,
                    data
                });
            }
        }

        return {
            data,
            errors
        };
    };

    const mutate = async (mutateOptions: MutateOptions): Promise<OperateModelResult> => {
        const { gqlMutation: mutation, variables } = mutateOptions;

        try {
            const mutationResult = await apolloClient.mutate({
                mutation,
                variables
            });

            return mutationResult as any;
        }
        catch (e) {
            const { messageTranslate, messageTranslateParams } = getMessageFromGraphQlError(e);

            throw new LamaGraphQlError({
                graphQLErrors: (e as ApolloError).graphQLErrors,
                messageTranslate,
                messageTranslateParams,
                message: (e as ApolloError).message,
                stack: (e as ApolloError).stack
            })
        }
    };

    const mutateModel = async (mutateOptions: MutateModelOptions): Promise<OperateModelResult> => {
        const { gqlQuery: mutation, variables } = LamaGraphQlMutationHelper.generateMutation(mutateOptions);

        try {
            const mutationResult = await apolloClient.mutate({
                mutation,
                variables
            });

            return {
                data: LamaGraphQlMutationHelper.getResultFromMutationData({
                    ...mutateOptions,
                    data: mutationResult.data
                }),
                errors: mutationResult.errors
            };
        }
        catch (e) {
            const { messageTranslate, messageTranslateParams } = getMessageFromGraphQlError(e);

            throw new LamaGraphQlError({
                graphQLErrors: (e as ApolloError).graphQLErrors,
                messageTranslate,
                messageTranslateParams,
                message: (e as ApolloError).message,
                stack: (e as ApolloError).stack
            })
        }
    };

    const createBulkMutateModel = (mutateOptions: MutateModelOptions) => {
        return mutateModel({
            ...mutateOptions,
            isBulk: true,
            crudOperationType: LamaCrudOperationType.create
        });
    };

    const createMutateModel = (mutateOptions: MutateModelOptions) => {
        return mutateModel({
            ...mutateOptions,
            crudOperationType: LamaCrudOperationType.create
        });
    };

    const deleteMutateModel = (deleteMutateOptions: MutateModelDeleteOptions) => {
        const { modelName, modelId } = deleteMutateOptions;

        return mutateModel({
            modelName,
            variables: {
                id: modelId
            },
            crudOperationType: LamaCrudOperationType.delete
        });
    };

    const deleteMutateJoinedModel = (deleteJoinedMutateOptions: MutateJoinedModelDeleteOptions) => {
        const { modelName, modelIds: variables } = deleteJoinedMutateOptions;

        return mutateModel({
            modelName,
            variables,
            isJoinedModel: true,
            crudOperationType: LamaCrudOperationType.delete
        });
    };

    const addToCache = (queryOptions: QueryModelOptions, newData: any) => {
        if (UtilityHelper.isUndefined(queryOptions.queryPrefixDotNotation) && UtilityHelper.isNotEmpty(queryPrefixDotNotation)) {
            queryOptions.queryPrefixDotNotation = queryPrefixDotNotation;
        }

        const { modelName, queryType = GraphQlQueryType.list } = queryOptions;
        const { gqlQuery: query, variables } = LamaGraphQlQueryHelper.createQueryFromDotNotation(queryOptions);

        let data = apolloClient.readQuery({ query, variables });
        let queryNameDotNotation = LamaGrapQlNamingHelper.createQueryNameFromType(modelName, queryType);

        if (queryPrefixDotNotation) {
            queryNameDotNotation = `${queryPrefixDotNotation}.${queryNameDotNotation}`;
        }

        if (queryType === GraphQlQueryType.list || queryType === GraphQlQueryType.paginated) {
            if (!UtilityHelper.isArray(newData)) {
                newData = [newData];
            }

            if (queryType === GraphQlQueryType.list) {
                data = UtilityHelper.setDotNotationPropertyValue(data, queryNameDotNotation, (currentValue: any[]) => {
                    currentValue = currentValue || [];

                    return [...currentValue, ...newData];
                });
            }
            else {
                data = UtilityHelper.copy(data);
                data = UtilityHelper.setDotNotationPropertyValue(
                    data, queryNameDotNotation, (currentValue: PaginationConnection<any>) => {
                    currentValue.nodes = currentValue.nodes || [];

                    return [...currentValue.nodes, ...newData];
                });
            }
        }

        apolloClient.writeQuery({
            query,
            variables,
            data
        });
    };

    const updateMutateModel = (mutateOptions: MutateModelOptions) => {
        return mutateModel({
            ...mutateOptions,
            crudOperationType: LamaCrudOperationType.update
        });
    };

    return {
        createBulkMutateModel,
        createMutateModel,
        deleteMutateModel,
        deleteMutateJoinedModel,
        mutate,
        mutateModel,
        addToCache,
        queryModel,
        updateMutateModel
    };
};
