import {
    LamaFormlyFieldConfig,
    LamaFormlyTypes,
    LamaFormlyWrappers,
    LamaEnumRadioGroupTypeTemplateOptions,
    LamaCheckboxGroupTypeTemplateOptions,
    LamaSelectTypeTemplateOptions
} from 'shared/components/forms/models';
import { ProductType, ProductNonAdminStatus, ProductResourceNames } from 'features/products/models';
import { Venue } from 'features/venues/models';
import { SelectOption } from 'shared/types';
import { SortOperationKind } from 'shared/graphql';

interface useImportGlobalSettingsFormFieldsOptions {
    disabled?: boolean;
    venues?: Venue[];
}

export const useImportGlobalSettingsFormFields = (options: useImportGlobalSettingsFormFieldsOptions): LamaFormlyFieldConfig[] => {
    const { disabled, venues } = options;
    const fields: LamaFormlyFieldConfig[] = [
        {
            key: 'defaultProductStatus',
            type: LamaFormlyTypes.enumRadioGroup,
            wrappers: [LamaFormlyWrappers.formGroup],
            templateOptions: {
                disabled,
                labelTranslate: 'import.stepFour.defaultProductStatus',
                enumRadioGroupProps: {
                    enumType: ProductNonAdminStatus,
                    translatePrefix: 'product.productStatus'
                },
                required: true
            } as LamaEnumRadioGroupTypeTemplateOptions
        },
        {
            key: 'defaultProductType',
            type: LamaFormlyTypes.enumRadioGroup,
            wrappers: [LamaFormlyWrappers.formGroup],
            templateOptions: {
                disabled,
                labelTranslate: 'import.stepFour.defaultProductType',
                enumRadioGroupProps: {
                    enumType: ProductType,
                    translatePrefix: 'product.productType'
                },
                required: true
            } as LamaEnumRadioGroupTypeTemplateOptions
        },
        {
            key: 'defaultTaxId',
            type: LamaFormlyTypes.select,
            wrappers: [LamaFormlyWrappers.formGroup],
            templateOptions: {
                labelTranslate: 'price.priceTaxId',
                placeholderTranslate: 'price.choosePrixeTaxId',
                required: true,
                lamaSelectProps: {
                    bindLabel: 'title',
                    bindValue: 'id',
                    defaultValue: 'high',
                    defaultValuePropertyName: 'systemName',
                    serverOptions: {
                        loadAdditionalProperties: ['value'],
                        modelName: ProductResourceNames.tax,
                        variables: {
                            order_by: {
                                value: SortOperationKind.desc
                            }
                        }
                    }
                }
            } as LamaSelectTypeTemplateOptions
        }
    ];

    if (venues?.length) {
        fields.splice(1, 0, {
            key: 'defaultVenueIds',
            type: LamaFormlyTypes.checkboxGroup,
            wrappers: [LamaFormlyWrappers.formGroup],
            templateOptions: {
                disabled,
                labelTranslate: 'import.stepFour.defaultVenueIds',
                checkboxRadioGroupProps: {
                    options: venues.map(
                        (q) =>
                            ({
                                label: q.title,
                                value: q.id
                            } as SelectOption)
                    )
                }
            } as LamaCheckboxGroupTypeTemplateOptions
        });
    }

    return fields;
};
