import React, { FC } from 'react';
import { RouteComponentProps, Route, Switch } from 'react-router-dom';

import { AuthorizeRoute, RtcAppLayout } from 'features/base/components';
import { RapidModelProvider } from 'shared/packages/rapid-model';

import { useModular, ModularEntryRoute, useModularRoute } from 'shared/packages/react-modular';
import { UriHelper } from 'shared/utilities';

export const DefaultLayout: FC<RouteComponentProps> = props => {
    const { match } = props;
    const { routes } = useModular();
    const { currentRoute, setCurrentRoute } = useModularRoute();

    const renderRoute = (Component: any, props: RouteComponentProps, route: ModularEntryRoute) => {
        setCurrentRoute(route);

        if (currentRoute?.name === route.name) {
            return (
                <AuthorizeRoute route={currentRoute}>
                    <RapidModelProvider>
                        <Component {...props} />
                    </RapidModelProvider>
                </AuthorizeRoute>
            );
        }

        return null;
    };

    const renderSwitchRoute = (route: ModularEntryRoute) => {
        const { component } = route.routeProps!;

        if (!component) {
            return null;
        }

        const combinedUrl = UriHelper.combineUrl(match.url, route.routeProps?.path as string);

        return <Route key={route.name} path={combinedUrl} exact render={props => renderRoute(component, props, route)} />;
    };

    return (
        <RtcAppLayout>
            <Switch>{routes.map(route => renderSwitchRoute(route))}</Switch>
        </RtcAppLayout>
    );
};
