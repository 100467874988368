import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { LamaGridCellProps } from 'shared/components/grid/types';

export const BooleanCellType: FC<LamaGridCellProps> = props => {
    const { cellValue } = props;
    const messageId = cellValue ? 'shared.yes' : 'shared.no';

    return <FormattedMessage id={messageId}/>;
};
