import React, { FC, useState } from 'react';
// @ts-ignore
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { useIntl } from 'react-intl';
import classNames from 'classnames';

import { useLamaLoadMapsScript } from 'features/locations/hooks';

import { UtilityHelper } from 'shared/utilities';

import 'react-google-places-autocomplete/dist/assets/index.css';

import { LocationViewModel } from 'features/locations/models';

import { useLamaAlerts } from 'shared/packages/alerts';

import { LocationHelper } from 'features/locations/utilities';

export interface LamaPlacesAutocompleteProps {
    disabled?: boolean;
    isInvalid?: boolean;
    placeholder?: string;
    value?: LocationViewModel;
    onSelect?: (place: LocationViewModel) => void;
}

const autocompletionRequest = {
    componentRestrictions: {
        country: ['si']
    }
};

export const LamaPlacesAutocomplete: FC<LamaPlacesAutocompleteProps> = props => {
    const { addWarningAlert } = useLamaAlerts();
    const { isLoaded } = useLamaLoadMapsScript();
    const { formatMessage } = useIntl();
    const [idPrefix] = useState(UtilityHelper.createGuid());

    if (!isLoaded) {
        return null;
    }

    const { disabled, isInvalid, onSelect, value } = props;
    let { placeholder = 'locations.placesAutocomplete.defaultPlaceholder' } = props;

    placeholder = formatMessage({ id: placeholder })

    const onPlaceSelect = async (selectedSuggestion: google.maps.places.AutocompletePrediction) => {
        if (onSelect) {
            try {
                const place = await LocationHelper.getLocationFromAutocompletePrediction(selectedSuggestion);

                if (place) {
                    onSelect(place);
                }
            }
            catch (e) {
                addWarningAlert('locations.placesAutocomplete.geocoderWarning');
            }
        }
    };

    return <GooglePlacesAutocomplete
        autocompletionRequest={autocompletionRequest}
        debounce={300} disabled={disabled}
        idPrefix={idPrefix} inputClassName={classNames('form-control', { 'is-invalid': isInvalid })}
        onSelect={onPlaceSelect} placeholder={placeholder}
        initialValue={value?.fullAddress || ' '}
    />;
};
