import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

interface SessionTitleProps {
    subTitle?: string;
    title: string;
}

export const SessionTitle: FC<SessionTitleProps> = (props) => {
    const { title, subTitle } = props;

    return (
        <div className="session-head mb-30">
            <h2 className="font-weight-bold">
                <FormattedMessage id={title} />
            </h2>
            {subTitle && (
                <p className="mb-0">
                    <FormattedMessage id={subTitle} />
                </p>
            )}
        </div>
    );
};
