import { RcFormlyConfig } from '@rc-formly/core';

import { PackFormlyTypes, PackageResourceNames } from './models';
import { ModularEntry } from 'shared/packages/react-modular';
import { RouteDefaultData } from 'features/base/models';
import { LamaCrudViewType } from 'shared/types';

import { PackageSelectorType } from './components';
import { PackageList, PackageCreateOrUpdate, FeatureModuleCreateOrUpdate, FeatureModuleList } from './routes';

RcFormlyConfig.addConfig({
    types: [
        {
            component: PackageSelectorType,
            name: PackFormlyTypes.packageSelector
        }
    ]
});

export const packageModule: ModularEntry<RouteDefaultData> = {
    name: 'packages',
    routes: [
        {
            header: 'menu.sidebarGroupTitle',
            name: 'packagesMain',
            icon: 'material-icons folder-open',
            titleTranslate: 'package.sidebarTitle',
            routeProps: {
                path: '/packages'
            },
            routes: [
                {
                    header: 'menu.sidebarGroupTitle',
                    name: 'packagesList',
                    titleTranslate: 'package.listSidebarTitle',
                    data: {
                        crudViewType: LamaCrudViewType.manage,
                        primaryModelName: PackageResourceNames.package
                    },
                    routeProps: {
                        component: PackageList,
                        path: '/list'
                    }
                },
                {
                    header: 'menu.sidebarGroupTitle',
                    name: 'packagesCreate',
                    titleTranslate: 'package.createSidebarTitle',
                    data: {
                        crudViewType: LamaCrudViewType.create,
                        primaryModelName: PackageResourceNames.package
                    },
                    routeProps: {
                        component: PackageCreateOrUpdate,
                        path: `/${LamaCrudViewType.create}`
                    }
                },
                {
                    header: 'menu.sidebarGroupTitle',
                    name: 'packagesUpdate',
                    titleTranslate: 'package.updateSidebarTitle',
                    data: {
                        crudViewType: LamaCrudViewType.update,
                        hideOnSidebar: true,
                        primaryModelName: PackageResourceNames.package
                    },
                    routeProps: {
                        component: PackageCreateOrUpdate,
                        path: `/:id/${LamaCrudViewType.update}`
                    }
                }
            ]
        },
        {
            header: 'menu.sidebarGroupTitle',
            name: 'featureModulesMain',
            icon: 'material-icons folder',
            titleTranslate: 'featureModule.sidebarTitle',
            routeProps: {
                path: '/feature-modules'
            },
            routes: [
                {
                    header: 'menu.sidebarGroupTitle',
                    name: 'featureModulesList',
                    titleTranslate: 'featureModule.listSidebarTitle',
                    data: {
                        crudViewType: LamaCrudViewType.manage,
                        primaryModelName: PackageResourceNames.featureModule
                    },
                    routeProps: {
                        component: FeatureModuleList,
                        path: '/list'
                    }
                },
                {
                    header: 'menu.sidebarGroupTitle',
                    name: 'featureModulesCreate',
                    titleTranslate: 'featureModule.createSidebarTitle',
                    data: {
                        crudViewType: LamaCrudViewType.create,
                        primaryModelName: PackageResourceNames.featureModule
                    },
                    routeProps: {
                        component: FeatureModuleCreateOrUpdate,
                        path: `/${LamaCrudViewType.create}`
                    }
                },
                {
                    header: 'menu.sidebarGroupTitle',
                    name: 'featureModulesUpdate',
                    titleTranslate: 'featureModule.updateSidebarTitle',
                    data: {
                        crudViewType: LamaCrudViewType.update,
                        hideOnSidebar: true,
                        primaryModelName: PackageResourceNames.featureModule
                    },
                    routeProps: {
                        component: FeatureModuleCreateOrUpdate,
                        path: `/:id/${LamaCrudViewType.update}`
                    }
                }
            ]
        }
    ]
};

