import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import { useModular } from 'shared/packages/react-modular';

import { images } from 'assets';

export enum LogoSize {
    small = 'small',
    medium = 'mediuma'
}

interface LogoProps {
    route?: string;
    size?: LogoSize;
}

const useStyles = makeStyles((theme) =>
    createStyles({
        siteLogo: {
            backgroundColor: theme.palette.primary.main
        }
    })
);

const AppLogo: FC<LogoProps> = (props) => {
    const { homeRoutePath } = useModular();
    const { size = LogoSize.small, route = homeRoutePath  } = props;

    const classes = useStyles();

    const height = size === LogoSize.medium ? '60px' : '25px';

    return (
        <div className={classNames('site-logo', 'MuiToolbar-regular', classes.siteLogo)}>
            {/* <Link to={homeRoutePath} className="logo-mini">
                <img src={images.appLogoShort} className="mr-15" alt="site logo" width="35" height="35" />
            </Link> */}
            <Link to={route} className="logo-normal">
                <img src={images.appLogo} className="img-fluid" alt="site-logo" style={{ height }} />
            </Link>
        </div>
    );
};

export const Logo = AppLogo;
