import classNames from 'classnames';
import React, { FC, ComponentType } from 'react';
import QueueAnim from 'rc-queue-anim';

import AppBar from '@material-ui/core/AppBar';
import LinearProgress from '@material-ui/core/LinearProgress';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { useTheme, makeStyles, createStyles } from '@material-ui/core/styles';

import { Logo, LogoSize } from 'features/base/components';
import { SessionImagePromo } from './SessionImagePromo';

interface SessionContainerProps {
    classes?: {
        body?: string;
    };
    headerRight?: ComponentType;
    hidePromo?: boolean;
    showProgress?: boolean;
}

const useStyles = makeStyles(() =>
    createStyles({
        body: {
            padding: '2rem 6.25rem',
            position: 'relative'
        }
    })
);


export const SessionContainer: FC<SessionContainerProps> = (props) => {
    const { children, classes: { body } = { body: '' }, headerRight: HeaderRightComponent, hidePromo = false, showProgress = false } = props;

    const theme = useTheme();
    const styles = useStyles();

    return (
        <QueueAnim type="bottom" duration={2000}>
            <div className="rct-session-wrapper" style={{ backgroundColor: theme.palette.primary.main }}>
                {showProgress && <LinearProgress />}
                <div className="container">
                    <AppBar position="static" className="session-header">
                        <Toolbar>
                            <Grid container justify="space-between" alignContent="center">
                                <Box>
                                    <Logo route="/" size={LogoSize.medium} />
                                </Box>
                                {HeaderRightComponent && <HeaderRightComponent />}
                            </Grid>
                        </Toolbar>
                    </AppBar>
                    <div className="session-inner-wrapper">
                        {hidePromo ? (
                            <Box className={classNames('session-body', styles.body, body)}>{children}</Box>
                        ) : (
                            <div className="row">
                                <div className="col-sm-7 col-md-7 col-lg-8">
                                    <Box display="flex" className={classNames('session-body', styles.body, body)} alignItems="center" justifyContent="center">
                                        <Box className="text-center" width="100%">
                                            {children}
                                        </Box>
                                    </Box>
                                </div>
                                <div className="col-sm-5 col-md-5 col-lg-4">
                                    <SessionImagePromo />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </QueueAnim>
    );
};
