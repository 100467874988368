import classNames from 'classnames';
import React, { FC } from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { createStyles, makeStyles } from '@material-ui/core/styles';

interface LamaTabPanelProps {
    className?: string;
    currentValue: string;
    value: string;
}

const useStyles = makeStyles(() =>
    createStyles({
        tabPanel: {
            display: 'flex',
            flexDirection: 'column'
        },
        innerTabPanel: {
            display: 'flex',
            flexGrow: 1,
            padding: '1.5rem',
            position: 'relative',

            '& > *': {
                width: '100%'
            }
        }
    })
);

export const LamaTabPanel: FC<LamaTabPanelProps> = (props) => {
    const { children, value, className, currentValue } = props;

    const { tabPanel, innerTabPanel } = useStyles();

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== currentValue}
            id={`simple-tabpanel-${value}`}
            aria-labelledby={`simple-tab-${value}`}
            className={classNames(tabPanel, className)}
        >
            {value === currentValue && (
                <div className={innerTabPanel}>
                    <Box>{children}</Box>
                </div>
            )}
        </Typography>
    );
};
