import React, { FC } from 'react';
import { Provider } from 'use-http';
import { useHttpOptions } from 'shared/rest/hooks';

import { Environment } from 'configs';

export const HttpProvider: FC = (props) => {
    const { children } = props;

    const { httpOptions } = useHttpOptions();

    return (
        <Provider url={Environment.apiRoot.replace(/\/$/, '')} options={httpOptions}>
            {children}
        </Provider>
    );
};
