import { GridCellType, LamaGridColumnType, LamaGridTypes } from 'shared/components/grid/types';

import { ActionsCellType } from './ActionsCellType';
import { BooleanCellType } from './BooleanCellType';
import { DataCellType } from './DateCellType';
import { DataTimeCellType } from './DateTimeCellType';
import { ClassificationCellType } from './ClassificationCellType';
import { EnumCellType } from './EnumCellType';
import { EnumColorCellType } from './EnumColorCellType';
import { MoneyCellType } from './MoneyCellType';
import { PercentageCellType } from './PercentageCellType';

export { DefaultCellType } from './DefaultCellType';
export { EmptyCell } from './EmptyCell';

export const gridCellTypes: GridCellType[] = [
    { cellType: LamaGridColumnType.actions, component: ActionsCellType, gridType: LamaGridTypes.lamaGrid },
    { cellType: LamaGridColumnType.boolean, component: BooleanCellType, gridType: LamaGridTypes.lamaGrid },
    { cellType: LamaGridColumnType.classification, component: ClassificationCellType, gridType: LamaGridTypes.lamaGrid },
    { cellType: LamaGridColumnType.date, component: DataCellType, gridType: LamaGridTypes.lamaGrid },
    { cellType: LamaGridColumnType.datetime, component: DataTimeCellType, gridType: LamaGridTypes.lamaGrid },
    { cellType: LamaGridColumnType.enum, component: EnumCellType, gridType: LamaGridTypes.lamaGrid },
    { cellType: LamaGridColumnType.enumColor, component: EnumColorCellType, gridType: LamaGridTypes.lamaGrid },
    { cellType: LamaGridColumnType.money, component: MoneyCellType, gridType: LamaGridTypes.lamaGrid },
    { cellType: LamaGridColumnType.percentage, component: PercentageCellType, gridType: LamaGridTypes.lamaGrid }
];
