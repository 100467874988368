import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) =>
    createStyles({
        whiteButton: {
            color: 'white',
            backgroundColor: 'transparent',
            border: '1px solid white',
            '&:hover': {
                backgroundColor: 'white',
                color: theme.palette.primary.main
            }
        }
    })
);

interface SessionHeaderLink {
    buttonText: string;
    url: string;
    text?: string;
}

export const SessionHeaderLink: FC<SessionHeaderLink> = (props) => {
    const { buttonText, url, text } = props;

    const classes = useStyles();

    return (
        <Box display="flex" flexDirection="row" alignItems="center">
            <Box mr={1}>
                <FormattedMessage id={text} />
            </Box>
            <Button className={classes.whiteButton} variant="outlined" component={Link} to={url}>
                <FormattedMessage id={buttonText} />
            </Button>
        </Box>
    );
};
