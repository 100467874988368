import { types } from 'typed-graphqlify';

export const locationQueryObject = {
    fullAddress: types.string,
    latitude: types.number,
    longitude: types.number,
    address: types.string,
    city: types.string,
    country: types.string,
    id: types.string,
    postalCode: types.string,
    streetNumber: types.string
};
