import React, { FC, FormEvent } from 'react';
import { useIntl } from 'react-intl';

import Input from '@material-ui/core/Input';

import { LamaServerOptions } from 'shared/graphql';

import { ReactSelectWrapped } from './ReactSelectWrapped';
import { useSelectStyles } from './lamaSelectStyles';

interface LamaSelectProps {
    onChange?: (event: FormEvent<HTMLInputElement>) => void;
    bindLabel?: string;
    bindValue?: string;
    defaultValue?: string;
    defaultValuePropertyName?: string;
    disabled?: boolean;
    invalid?: boolean;
    multiple?: boolean;
    placeholder?: string;
    placeholderTranslate?: string;
    serverOptions?: LamaServerOptions;
    value?: string | string[];
}

export const LamaSelect: FC<LamaSelectProps> = (props) => {
    const {
        bindLabel,
        bindValue,
        defaultValue,
        defaultValuePropertyName,
        disabled,
        invalid,
        multiple,
        placeholderTranslate,
        serverOptions,
        value
    } = props;
    let { placeholder } = props;

    const intl = useIntl();
    const classes = useSelectStyles();

    const onChange = (event: FormEvent<HTMLInputElement>) => {
        if (props.onChange) {
            props.onChange(event);
        }
    };

    if (placeholderTranslate) {
        placeholder = intl.formatMessage({ id: placeholderTranslate });
    }

    return (
        <Input
            disabled={disabled}
            fullWidth
            className="auto-select-wrap"
            error={invalid}
            inputComponent={ReactSelectWrapped}
            inputProps={{
                bindLabel,
                bindValue,
                defaultValue,
                defaultValuePropertyName,
                disabled,
                multiple,
                serverOptions,
                placeholder,
                value,
                onChange: onChange,
                instanceId: 'react-select-single',
                id: 'react-select-single',
                name: 'react-select-single',
                className: 'react-single-wrap',
                simpleValue: true
            }}
        />
    );
};
