import { types } from "typed-graphqlify";

import { Media } from "features/media/models";

export const mediaQueryObject: Media = {
    id: types.string,
    fullPrefixUrl: types.string,
    extension: types.string,
    hasDifferentSizes: types.boolean,
    prefixUrl: types.string,
    summary: types.string,
    title: types.string,
    type: types.custom()
};
