import { TemplateOptionsExtended } from 'shared/components/forms/models';

export * from './media';
export { MediaFormlyTypes } from './MediaFormlyTypes';

export const DEFAULT_MEDIA_PAGINATION_SIZE = 30;

export enum MediaResourceNames {
    media = 'media'
}

export interface MediaEntityChooserTypeTemplateOptions extends TemplateOptionsExtended {
    gridSize?: 2 | 3 | 4;
}

export interface MediaEntitiesInlineChooserTypeTemplateOptions extends MediaEntityChooserTypeTemplateOptions {
    mediaIdPropertyName?: string;
    mediaPropertyName?: string;
    resourceIdPropertyName: string;
}
