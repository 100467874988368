import { FC } from 'react';
import { ModularEntry } from 'shared/packages/react-modular/types';

import { useMountEffect } from 'shared/packages/use-mount-effect';
import { useModular } from 'shared/packages/react-modular/useModular';

import { UtilityHelper } from 'shared/utilities';

interface ModularIncludesProps {
    modularEntries: ModularEntry[];
}

export const ModularIncludes: FC<ModularIncludesProps> = props => {
    const { modularEntries } = props;
    const { addModules } = useModular();

    useMountEffect(() => {
        if (UtilityHelper.isNotEmpty(modularEntries)) {
            addModules(modularEntries);
        }
    });

    return null;
};
