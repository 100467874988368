import React, { FC } from 'react';

import { LamaListRouteBase } from 'features/base/components';

import { VenueResourceNames } from 'features/venues/models';
import { LamaGridProps, LamaGridColumnType } from 'shared/components/grid';
import { UtilityHelper } from 'shared/utilities';
import { mediaQueryObject } from 'features/media/queries';

export const VenueList: FC = () => {
    const gridOptions: LamaGridProps = {
        columns: [
            {
                type: LamaGridColumnType.picture,
                titleTranslate: 'venue.displayImage.header',
                prop: 'displayImage'
            },
            {
                titleTranslate: 'venue.title',
                prop: 'title'
            },
            {
                titleTranslate: 'venue.place',
                prop: 'place.fullAddress'
            },
            {
                type: LamaGridColumnType.datetime,
                titleTranslate: 'shared.updatedAt',
                prop: 'updatedAt'
            }
        ],
        serverOptions: {
            loadAdditionalProperties: [...UtilityHelper.getDotNotationProperties(mediaQueryObject, 'displayImage')],
            modelName: VenueResourceNames.venue
        },
        titleProperty: 'title'
    };

    return <LamaListRouteBase gridProps={gridOptions} />;
};
