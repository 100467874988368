import gql from 'graphql-tag';


export const WEEKLY_SUM_STATISTICS = gql`
    query {
        admin {
            statistics {
                weeklySumStatistics {
                    previousContactClicksComparison
                    previousProductViewsComparison
                    previousUniqueViewsComparison
                    numberOfContactClicks
                    numberOfProductViews
                    numberOfUniqueViews
                }
            }
        }
    }`;
