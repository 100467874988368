import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button';

export const NotAuthorized: FC = () => {
    return (
        <div className="session-inner-wrapper">
            <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-9 mx-auto">
                    <div className="error-body text-center">
                        <h2 className="oops">
                            <FormattedMessage id="shared.403.header" />
                        </h2>
                        <h2 className="bold mb-0">403</h2>
                        <h2 className="error-msg mb-30">
                            <FormattedMessage id="shared.403.summary" />
                        </h2>
                        <h4 className="mb-50">
                            <FormattedMessage id="shared.403.description" />
                        </h4>
                        <Button component={Link} to="/" variant="contained" className="btn-light btn-lg">
                            <FormattedMessage id="shared.403.button" />
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};
