import React from 'react';
import { RcFormlyField } from "@rc-formly/core";

import { LamaSelect } from 'shared/components/forms/components';
import { UtilityHelper } from 'shared/utilities';

import { LamaSelectTypeTemplateOptions } from 'shared/components/forms/models';

export class LamaSelectType extends RcFormlyField<LamaSelectTypeTemplateOptions> {
    onChange = (event: React.FormEvent<HTMLInputElement>) => {
        let value = event.currentTarget.value;
        const { bindValue } = this.to?.lamaSelectProps || {};

        if (bindValue) {
            value = UtilityHelper.getDotNotationPropertyValue(value, bindValue);
        }

        this.handleChange(value, event.currentTarget.value);
    }

    render() {
        const { disabled, lamaSelectProps = {}, placeholder, placeholderTranslate } = this.to || {};

        const isInvalid = this.hasErrors() && (this.wasFieldTouched() || this.wasFormSubmitted());

        return (
            <LamaSelect
                {...lamaSelectProps}
                disabled={disabled}
                onChange={this.onChange}
                invalid={isInvalid}
                placeholder={placeholder}
                placeholderTranslate={placeholderTranslate}
                value={this.getFieldValue()}  />
        )
    }
}
