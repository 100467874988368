import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { ImporterProvider } from 'features/imports/hooks';

import { ContentPage } from 'features/base/components';
import { LamaCollapsibleCard } from 'shared/components';
import { LamaStepper, StepItem } from 'shared/components/stepper';
import { ImporterStepOne, ImporterStepTwo, ImporterStepThree, ImporterStepFour, ImporterStepFive } from './components';

const steps: StepItem[] = [
    {
        component: ImporterStepOne,
        label: 'import.stepOne.label'
    },
    {
        component: ImporterStepTwo,
        label: 'import.stepTwo.label'
    },
    {
        component: ImporterStepThree,
        label: 'import.stepThree.label'
    },
    {
        component: ImporterStepFour,
        label: 'import.stepFour.label'
    },
    {
        component: ImporterStepFive,
        label: 'import.stepFive.label'
    }
];

export const ImportCreate: FC = () => {
    return (
        <ContentPage>
            <LamaCollapsibleCard>
                <div className="page-title">
                    <h2>
                        <FormattedMessage id="import.moduleQuickImport" />
                    </h2>
                </div>
                <ImporterProvider>
                    <LamaStepper steps={steps} />
                </ImporterProvider>
            </LamaCollapsibleCard>
        </ContentPage>
    );
};
