import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';

import { DeleteConfirmationDialogProps } from './types';

export const DeletConfirmationDialog: FC<DeleteConfirmationDialogProps> = props => {
    const { onConfirm, onClose, isOpen, entityTitle } = props;

    return (
        <Dialog open={isOpen} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">
                <FormattedMessage id="shared.dialogs.delete.title" />
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <FormattedMessage id="shared.dialogs.delete.message" values={{ entityTitle }} />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    <FormattedMessage id="shared.cancel" />
                </Button>
                <Button onClick={onConfirm} className="btn-danger text-white" autoFocus>
                    <FormattedMessage id="shared.delete" />
                </Button>
            </DialogActions>
        </Dialog>
    );
};
