import { ComponentType } from 'react';

import { KeyValueObject, LamaCrudViewType } from 'shared/types';

export interface AdminModuleType {
    id: string;
    systemName: string;
    title: string;
}

export interface AdminVisitModule {
    id: string;
    icon?: string;
    showCategories: boolean;
    typeOfModule: AdminModuleType;
}

export interface AdminVisitModuleTranslation {
    id: string;
    title: string;
    ownerLanguageId: string;
    ownerModule: AdminVisitModule;
}

export enum VisitModuleContentType {
    category = 'category',
    coupons = 'coupons',
    event = 'event',
    guidedTours = 'guidedTours',
    pointOfInterest = 'pointOfInterest',
    localProvider = 'localProvider',
    transport = 'transport'
}

export interface VisitModuleComponent {
    additionalData?: KeyValueObject
    component: ComponentType;
    moduleContentType: VisitModuleContentType;
    path?: string;
    primaryModelName: string;
    titlePosfix?: string;
    titlePosfixTranslate?: string;
    viewType: LamaCrudViewType;
}
