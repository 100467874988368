import { useEffect, useRef } from 'react';

import { useUpdateEffectInterface } from './types';

export const useUpdateEffect: useUpdateEffectInterface = (effect, deps) => {
    const isInitialMount = useRef(true);

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            effect();
        }
    }, deps);
};
