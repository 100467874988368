import React, { FC } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { LamaServerOptions } from 'shared/graphql';

import { LamaAutocompleteInput } from './LamaAutocompleteInput';
import { UtilityHelper } from 'shared/utilities';

interface LamaAutoCompleteProps {
    bindLabel?: string;
    bindValue?: string;
    defaultValue?: string;
    defaultValuePropertyName?: string;
    disabled?: boolean;
    invalid?: boolean;
    options?: any[];
    multiple?: boolean;
    placeholder?: string;
    placeholderTranslate?: string;
    serverOptions?: LamaServerOptions;
    value?: any;
    isOptionDisabled?: (option: any) => boolean;
    onChange?: (event: React.ChangeEvent<any>, value: any) => void;
}

export const LamaAutocomplete: FC<LamaAutoCompleteProps> = (props) => {
    const { bindLabel = 'label', bindValue = 'value', isOptionDisabled, onChange, placeholder, placeholderTranslate } = props;
    let { options = [], value = null } = props;

    const getOptionLabel = (option: any) => {
        if (bindLabel) {
            return UtilityHelper.getDotNotationPropertyValue(option, bindLabel);
        }

        return option;
    };

    const getOptionValue = (option: any) => {
        if (bindValue) {
            return value === UtilityHelper.getDotNotationPropertyValue(option, bindValue);
        }

        return option === value;
    };


    const onAutoCompleteChange = (event: React.ChangeEvent<any>, value: any) => {
        if (onChange) {
            onChange(event, value);
        }
    };

    return (
        <Autocomplete
            options={options}
            getOptionDisabled={isOptionDisabled}
            getOptionLabel={getOptionLabel}
            getOptionSelected={getOptionValue}
            value={value as string}
            onChange={onAutoCompleteChange}
            renderInput={(inputProps) => (
                <LamaAutocompleteInput {...inputProps} placeholder={placeholder} placeholderTranslate={placeholderTranslate} />
            )}
        />
    );
};
