import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { MonthlyStatisticsGraph } from 'features/statistics/components';
import { LamaCollapsibleCard } from 'shared/components';

export const MonthlyStatisticsCard: FC = () => {
    return (
        <LamaCollapsibleCard>
            <h3 className="fw-semi-bold mb-15">
                <FormattedMessage id="home.monthlyStatistics" />
            </h3>
            <MonthlyStatisticsGraph />
        </LamaCollapsibleCard>
    );
};
