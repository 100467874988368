export const siLang = {
    account: {
        email: 'Elektronski naslov',
        firstName: 'Ime',
        isEmailVerified: 'Je elektronski račun potrjena',
        isMobileNumberVerified: 'Je mobilna številka potrjena',
        lastName: 'Priimek',
        mobileNumber: 'Mobilna številka',
        onRoles: 'Vloge',
        password: 'Geslo',
        sidebarTitle: 'Uporabniški računi',
        listSidebarTitle: 'Uporabniški računi - Seznam',
        createSidebarTitle: 'Uporabniški računi - Ustvari',
        updateSidebarTitle: 'Uporabniški računi - Uredi'
    },
    authentication: {
        forgottenPassword: {
            error: 'Zgodila se je napaka pri pozabljenem geslu, prosimo poizkusite kasneje!',
            subHeader: 'Pozabljeno geslo',
            resetPassword: 'Ponastavi geslo',
            success: 'Na vaš E-mail so bila uspešno poslana navodila za ponastavitev gesla!'
        },
        loginLink: {
            button: 'Prijavite se',
            text: 'Ste že registrirani?'
        },
        login: {
            activationIssues: 'Težava z aktivacijo računa?',
            error: 'Zgodila se je napaka pri prijavi, prosimo poizkusite kasneje!',
            forgottenPassword: 'Pozabljeno geslo',
            signIn: 'Prijava',
            subHeader: 'Vstop v nadzorno ploščo',
            success: {
                emailActivation: 'Vaš uporabniški račun je bil uspešno aktiviran, sedaj se lahko prijavite!'
            }
        },
        promo: {
            header: 'ZAČNITE PRODAJATI V DIGITALNEM SVETU',
            subHeader: 'Več opcij, več moči, večja prodaja',
            description: 'Izkoristite potencialne spletnega in mobilnega trga. Naša platforma vam nudi vsa digitalna orodja, ki jih potrebujete.'
        },
        resendActivationEmail: {
            error: 'Zgodila se je napaka pri ponovnem pošiljanju aktivacijskega E-maila, prosimo poizkusite kasneje!',
            subHeader: 'Ponovno pošlji potrditveni e-mail',
            resetPassword: 'Pošlji ponovno',
            success: 'Na vaš E-mail so bila uspešno ponovno poslana navodila za aktivacijo vašega računa.'
        },
        register: {
            agreeWithPrivacyPolicy: 'Prebral/-a sem {link}',
            agreeWithPrivacyPolicyFull: 'Prebral/-a sem politko zasebnosti',
            agreeWithTermsOfUse: 'Strinjam se s {link} platforme Prodajko',
            agreeWithTermsOfUseFull: 'Strinjam se s pravili in pogoji poslovanja platforme Prodajko',
            basicHeader: 'Podatki podjetja',
            error: 'Zgodila se je napaka pri registraciji, prosimo poizkusite kasneje!',
            subHeader: 'Registriraj podjetje',
            submitButton: 'Ustvari račun',
            success: 'Registracija je bila uspešna! Na vašem E-mailu boste našli navodila za aktivacijo vašega računa.',
            userHeader: 'Kontaktna oseba'
        },
        registerLink: {
            button: 'Registracija',
            text: 'Še nimate odprtega računa'
        },
        resetPassword: {
            button: 'Shrani spremembe',
            error: 'Zgodila se je napaka pri ponastavitvi gesla, prosimo poizkusite kasneje!',
            passwordConfirm: 'Potrdi geslo',
            subHeader: 'Ponastavi geslo',
            success: 'Ponastavitev gesla je bila uspešna! Sedaj se lahko prijavite z novim geslom.'
        },
        withPrivacyPolicy: 'politko zasebnosti',
        withTermsOfUse: 'pravili in pogoji poslovanja'
    },
    base: {
        contentStatus: {
            active: 'Aktivno',
            draft: 'Osnutek',
            inactive: 'Neaktivno'
        },
        header: {
            showMyCompany: 'Prikaži mojo trgovino'
        },
        title: 'Prodajko nadzorna plošča'
    },
    content: {
        listItems: 'Seznam vsebine'
    },
    company: {
        activityType: {
            hasStores: 'Ukvarjamo se s prodajo in imamo tudi fizične poslovalnice',
            servicesOnly: 'Naše podjetje nudi storitve',
            webOnly: 'Prodajamo izključno preko spleta'
        },
        address: 'Naslov',
        basic: {
            header: 'Osnovni podatki podjetja'
        },
        contactPerson: {
            email: 'Elektronski naslov',
            firstName: 'Ime',
            lastName: 'Priimek',
            password: 'Geslo',
            phone: 'Telefon'
        },
        contacting: {
            header: 'Možnost kontakta',
            summary: 'Vpišite vse možnosti, ki jih želite uporabnikom ponuditi na voljo, da lahko stopijo v stik z vami.'
        },
        createdAt: 'Datum vnosa',
        description: 'Opis',
        displayTitle: 'Prikazno ime',
        email: 'Kontaktni E-mail',
        name: 'Ime podjetja v spletnem naslovu (primer ime-podjetja.prodajko.si)',
        facebookUrl: 'Facebook URL',
        linkedInUrl: 'LinkedIn URL',
        youTubeUrl: 'YouTube URL',
        location: {
            header: 'Sedež podjetja'
        },
        logo: {
            header: 'Logotip podjetja'
        },
        paymentOptions: 'Možnosti plačila',
        phone: 'Kontaktna telefonska številka',
        registrationNumber: 'Matična številka',
        taxNumber: 'Davčna številka',
        title: 'Naziv podjetja',
        type: 'S čim se ukvarja vaše podjetje',
        shortTitle: 'Kratko ime podjetja',
        supports: {
            header: 'Možnost prevzema in plačil',
            summary: 'Tukaj lahko označite, katere možnosti plačila in dostave nudite. Ta informacija bo prikazana uporabnikom našega portala pri vsakem izdelku, ki ga boste objavili.'
        },
        supportsPickup: 'Osebni prevzem',
        supportsShipment: 'Dostava',
        supportsCashPayment: 'Gotovinsko plačilo',
        supportsCardPayment: 'Plačilo z kartico',
        supportsPaymentAfterPickup: 'Plačilo po prevzemu',
        supportsPrePaymentUpn: 'Predplačilo (UPN)',
        systemName: 'Sistemsko (unikatno) ime podjetja',
        validForTax: 'Podjetje je davčni zavezanec',
        website: 'Spletna stran',
        tabs: {
            basic: 'Podjetje'
        },
        account: {
            header: 'Dostopni podatki za nadzorno ploščo ponudnika',
            email: 'Elektronski naslov ponudnika (služi kot uporabniško ime za prijavo)',
            password: 'Geslo ponudnika (služi kot geslo za prijavo)'
        },
        sidebarHeaderTitle: 'Podjetja',
        sidebarTitle: 'Podjetja',
        listSidebarTitle: 'Podjetja - Seznam',
        createSidebarTitle: 'Podjetja - Ustvari',
        updateSidebarTitle: 'Podjetja - Uredi'
    },
    category: {
        categories: 'Kategorije',
        chooseModuleId: 'Izberite modul iz seznama...',
        title: 'Naziv',
        sidebarTitle: 'Kategorije',
        listSidebarTitle: 'Kategorije - Seznam',
        ownerParentCategoryId: 'Starš kategorije',
        selector: {
            chooseCategory: 'Izberite kategorijo'
        }
    },
    featureModule: {
        buyingType: 'Način nakupa modula',
        chooseOwnerEntityTypeId: 'Izberite tip entitete iz seznama...',
        maxQuantity: 'Kvantiteta modula',
        ownerEntityTypeId: 'Tip entitete',
        systemName: 'Sistemsko ime',
        type: 'Tip modula',
        title: 'Naziv',
        sidebarTitle: 'Moduli',
        listSidebarTitle: 'Moduli - Seznam',
        createSidebarTitle: 'Moduli - Ustvari',
        updateSidebarTitle: 'Moduli - Uredi',
        featureModuleBuyingType: {
            addon: 'Dodatek',
            partOfPackage: 'Del paketa'
        },
        featureModuleType: {
            functionality: 'Funkcionalnost',
            quantity: 'Kvantiteta'
        },
        tabs: {
            basic: 'Osnovni podatki'
        }
    },
    import: {
        cancelDialog: {
            header: 'Prekliči uvoz',
            message: 'Ali resnično želite preklicati uvoz?'
        },
        revertDialog: {
            header: 'Razveljavi uvoz',
            message: 'Ali resnično želite razveljaviti uvoz?'
        },
        stepOne: {
            label: 'Nalaganje datoteke',
            summary: 'To je orodje, ki vam omogoča, da avtomatično uvozite (ali posodobite) vaše oglase v nadzorno ploščo. {br}Trenutno podprte datoteke za uvoz: <b>CSV</b>, <b>XML</b>',
            linkOne: 'Če želite uporabiti <b>Prodajko.si</b> uvozno shemo jo lahko prenesete TUKAJ.',
            linkTwo: 'Navodila za uporabno <b>Prodajko.si</b> uvozne sheme se nahajajo TUKAJ.',
            linkThree: 'Če želite uporabiti <b>lastno uvozno shemo</b> se navodila nahajajo TUKAJ.'
        },
        stepTwo: {
            label: 'Vezava podatkov',
            summary: 'Naložili ste CSV datoteko, ki nima standardne Prodajko uvozne sheme. Da se lahko izvede pravilen uvoz produktov je potrebno, da polja iz CSV datoteke ustrezno povežete na polja v Prodajko sistemu. V kolikor določenega stolpca ne želite uvažati oziroma ne najdete primerne vezave ga označite z “Ne uvozi tega polja”.',
            propertyFromImport: 'Podatek iz vaše uvozne datoteke',
            ourProperty: 'Prodajko polje',
            stillRequiredFieldsNotMappedWarning: 'Pozabili ste še povezati naslednja obvezna polja: {requiredFields}!'
        },
        stepThree: {
            label: 'Mapiranje kategorij',
            requestCategory: 'V kolikor ne najdete ustrezne kategorije iz našega seznama, nam lahko pošljete <mailto>zahtevo za dodajanje nove kategorije.</mailto>',
            summary: 'Vaša struktura oziroma poimenovanje kategorij se ne sklada v celoti s strukturo, ki se uporablja v sistemu Prodajko. Da lahko vsem uporabnikom in vašim kupcem zagotovimo enotno in pregledno uporabniško izkušnjo morate v tem koraku vaša poimenovanja kategorij ustrezno povezati z našimi. Ta korak izvedete samo enkrat.',
            stillCategoriesNotMappedWarning: 'Pozabili ste še povezati naslednje kategorije: {requiredCategories}!'
        },
        stepFour: {
            label: 'Globalne nastavitve',
            summary: 'Tukaj ustrezno izberite globalne nastavitve za oglase, ki jih uvažate. Te nastavitve bodo uporabile na vseh oglasih iz tega uvoza oz. na tistih oglasih, pri katerih teh informacij niste določili.',
            defaultProductStatus: 'Status oglasa',
            defaultProductType: 'Tip oglasa',
            defaultVenueIds: 'Na voljo v poslovalnicah'
        },
        stepFive: {
            label: 'Povzetek',
            summary: 'Spodaj je predstavljen hiter povzetek nastavitev. Preden sprožite uvoz vas prosimo, da pregledate povzetek. Ko boste zagnali uvoz se bo le-ta izvajal v ozadju in boste o koncu uvoza obveščeni tukaj na nadzorni plošči v obliki obvestila in na elektronski naslov se vam bo poslalo poročilo o izvedbi uvoza.',
            headerOne: 'Povzetek globalnih nastavitev',
            headerTwo: 'Primer nekaj oglasov, ki se bodo uvozili. Ta seznam prikazuje le nekaj prvih oglasov iz uvoza za hiter pregled pravilnosti podatkov.'
        },
        errors: {
            header: 'V uvozni datoteki so bile najdene naslednje napake:'
        },
        importStatus: {
            canceled: 'Prekinjen s strani uporabnika',
            error: 'Prišlo je do napake',
            enqueued: 'V čakalni vrsti',
            inProgress: 'Poteka',
            reverted: 'Razveljavljeno',
            reverting: 'V procesu razveljanja',
            reviewing: 'V pregledu',
            started: 'Začetek',
            finished: 'Zaključeno',
            finishedWithWarnings: 'Zaključeno z opozorili'
        },
        fields: {
            Title: 'Naziv',
            Description: 'Opis',
            VendorUrl: 'Vezava produkta na zunanji vir',
            SKU: 'SKU',
            OwnerCategoryId: 'Kategorija',
            PriceWithTax: 'Maloprodajna cena z davkom',
            PriceWithTaxAndDiscount: 'Znižana cena z davkom',
            City: 'Kraj',
            Address: 'Ulica',
            PostalCode: 'Poštna številka',
            Country: 'Država',
            Latitude: 'Zemljepisna širina',
            Longitude: 'Zemljepisna dolžina',
            Files: 'Slike',
            Files_0: 'Slika 1',
            Files_1: 'Slika 2',
            Files_2: 'Slika 3',
            Files_3: 'Slika 4',
            Files_4: 'Slika 5',
            Files_5: 'Slika 6',
            Files_6: 'Slika 7',
            Files_7: 'Slika 8',
            Files_8: 'Slika 9',
            Files_9: 'Slika 10'
        },
        cancel: 'Prekliči uvoz',
        cancelSuccess: 'Uvoz je bil uspešno preklican.',
        chooseFieldFromYourImport: 'Izberite polje iz vašega uvoza...',
        numberOfItems: 'Št. oglasov',
        moduleQuickImport: 'Modul za hiter uvoz izdelkov / storitev',
        revert: 'Razveljavi uvoz',
        revertSuccess: 'Uvoz je bil uspešno razveljavljen',
        sidebarTitle: 'Uvoz izdelkov',
        listSidebarTitle: 'Uvoz - Seznam',
        createSidebarTitle: 'Uvozi izdelke',
        rowError: 'Napaka v vrstici {rowIndex}!',
        success: 'Uvoz vaših izdelkov/storitev je v teku, lahko nadaljujete z delom.',
        status: 'Status',
        warnings: {
            categoryNameMissing: 'Kategorija "{categoryName}" še ni bila povezana s Prodajko kategorijo!',
            requiredProperyMissing: 'Manjka obvezno polje "{destinationProperty}"!'
        }
    },
    locations: {
        placesAutocomplete: {
            defaultPlaceholder: 'Vpiši iskano lokacijo...',
            geocoderWarning: 'Zgodila se je napaka pri pridobivanju koordinat iz izbrane lokacije'
        }
    },
    home: {
        monthlyStatistics: 'Mesečna statistika',
        weeklySumStatistics: 'Skupna statistika zadnjih 7 dni',
        sidebarTitle: 'Domov'
    },
    media: {
        chooser: {
            chooseImages: 'Izberite slike',
            upload: 'Naloži',
            select: 'Izberi',
            selectSuccess: 'Slike so bile uspešno dodane na entiteto!'
        },
        choose: {
            tooBigFile: 'Datoteka "{fileNames}" ne sme biti večja od {maxFileSize} MB!',
            tooBigFiles: 'Datoteke "{fileNames}" ne smejo biti večje od {maxFileSize} MB!',
            uploadError: 'Zgodila se je napaka pri nalaganju slik! Prosimo poizkusite pozneje.',
            uploadSuccess: 'Slike so bile uspešno naložene!',
            uploadWarning: 'Nekatere slike se niso pravilno naložile!'
        },
        sidebarTitle: 'Slike'
    },
    menu: {
        sidebarGroupTitle: 'Meni'
    },
    modules: {
        models: {
            account: 'Uporabniški račun',
            category: 'Kategorija',
            categoryRecursive: 'Kategorija',
            company: 'Podjetje',
            import: 'Uvoz',
            lamaFeatureModule: 'Modul',
            media: 'Slika',
            package: 'Paket',
            packageContract: 'Naročnina',
            packageFeatureModule: 'Modul v paketu',
            product: 'Izdelek',
            statistics: 'Statistika',
            tenant: 'Naročnik',
            venue: 'Poslovalnica'
        },
        pluralModels: {
            account: '{itemCount, plural, one {Uporabniški račun} two {Uporabniška računa} other {Uporabniški računi}}',
            product: '{itemCount, plural, one {Izdelek} two {Izdelka} three {Izdelki} four {Izdelki} other {Izdelkov}}',
            serviceProduct: '{itemCount, plural, one {Storitev} two {Storitvi} three {Storitve} four {Storitve} other {Storitev}}',
            venue: '{itemCount, plural, one {Poslovalnica} two {Poslovalnici} three {Poslovalnice} four {Poslovalnice} other {Poslovalnic}}'
        }
    },
    package: {
        autoRenew: 'Privzeto naj podaljšuje naročnino',
        choosePackage: 'Izberite paket',
        chooseSuitablePackage: 'Izberite paket, ki je najbolj primeren za vas',
        noHiddenFeesTop: 'Vsi paketi so za prve trgovce na voljo brezplačno za obdobje 2 mesecev!',
        noHiddenFeesBottom: 'Brez skritih stroškov ali obveznosti.',
        monthly: 'na mesec',
        monthlyLease: 'Mesečni zakup',
        systemName: 'Sistemsko ime',
        title: 'Naziv',
        trialPeriodInDays: 'Čas preizkusa v dnevih',
        trialPeriodFreeDuration: '{itemCount, plural, one {# mesec brezplačno} two {# meseca brezplačno} three {# mesece brezplačno} four {# mesece brezplačno} other {# mesecov brezplačno} }',
        type: 'Tip paketa',
        yearly: 'na leto',
        yearlyLease: 'Letni zakup (4 mesece brezplačno!)',
        sidebarTitle: 'Paketi',
        listSidebarTitle: 'Paketi - Seznam',
        createSidebarTitle: 'Paketi - Ustvari',
        updateSidebarTitle: 'Paketi - Uredi',
        basic: {
            header: 'Osnovni podatki paketa'
        },
        packageType: {
            custom: 'Po meri',
            standard: 'Standarden'
        },
        price: {
            header: 'Cena'
        },
        tabs: {
            basic: 'Osnovni podatki',
            featureModules: 'Moduli'
        }
    },
    packageFeatureModule: {
        chooseOwnerFeatureModuleId: 'Izberite modul iz seznama...',
        ownerFeatureModuleId: 'Modul',
        order: 'Vrstni red'
    },
    packageContract: {
        currentNumberOfAccounts: 'Št. aktivnih uporabniški računov',
        currentNumberOfProducts: 'Število aktivnih izdelkov',
        currentNumberOfVenues: 'Število aktivnih poslovalnic',
        currentPackage: 'Vaš trenutno izbran paket',
        moreInfo: 'Več info',
        sidebarMeTitle: 'Naročnina'
    },
    price: {
        choosePrixeTaxId: 'Izberite davek iz seznama...',
        discount: 'Popust',
        isNegotiable: 'Cena po dogovoru',
        priceTaxId: 'Davek',
        priceWithoutTax: 'Cena brez davka',
        priceWithTax: 'Maloprodajna cena z davkom',
        priceWithTaxAndDiscount: 'Znižana cena z davkom',
        retailPrice: 'Maloprodajna cena'
    },
    product: {
        displayImage: 'Prikazna slika',
        hasDiscount: 'Izdelek ima popust',
        ownerCategoryId: 'Kategorija',
        type: 'Tip',
        sKU: 'SKU',
        status: 'Status',
        url: 'Vpišite / kopirajte URL povezavo do tega izdelka na vaši spletni strani',
        title: 'Naziv',
        sidebarTitle: 'Izdelki/Storitve',
        listSidebarTitle: 'Izdelki/Storitve - Seznam',
        createSidebarTitle: 'Izdelki/Storitve - Ustvari',
        updateSidebarTitle: 'Izdelki/Storitve - Uredi',
        basic: {
            header: 'Osnovni podatki izdelka'
        },
        categories: {
            header: 'Kategorija',

        },
        media: {
            header: 'Slike'
        },
        price: {
            header: 'Cena'
        },
        link: {
            header: 'Vezava produkta na zunanji vir',
            description: 'Vpišite / kopirajte URL povezavo do tega izdelka na vaši spletni strani'
        },
        venues: {
            header: 'Dosegljivost izdelka',
            description: 'V katerih poslovalnicah je na voljo ta izdelek?'
        },
        productStatus: {
            acceptsPreorders: 'Sprejemamo prednaročila',
            archived: 'Arhiviran',
            deleted: 'Izbrisan',
            none: 'NONE',
            notActive: 'Ni na voljo',
            notInStock: 'Ni na zalogi',
            removedViolationOfTerms: 'Izdelek je bil odstranjen zaradi kršenja pogojev uporabe',
            underReview: 'V pregledu',
            selling: 'Na zalogi',
            violationOfTerms: 'Izdelek krši pogoje uporabe'
        },
        productType: {
            new: 'Nov',
            refurbished: 'Obnovljen',
            used: 'Rabljen'
        },
        tabs: {
            basic: 'Osnovni podatki'
        }
    },
    statistic: {
        notEnoughData: 'Ni dovolj podatkov za prikaz statistike',
        numberOfViews: 'Število ogledov',
        numberOfContactClicks: 'Zabeleženih klikov na kontaktne gumbe',
        numberOfUniqueCompanyOrProductViews: 'Unikatnih obiskovalcev vaše trgovine',
        numberOfUniqueViews: 'Število obiskovalcev',
        numberOfProductViews: 'Ogledov vaših oglasov'
    },
    tenants: {
        account: {
            header: 'Dostopni podatki za nadzorno ploščo naročnika',
            email: 'Elektronski naslov naročnika (služi kot uporabniško ime za prijavo)',
            password: 'Geslo naročnika (služi kot geslo za prijavo)'
        },
        tabs: {
            modules: 'Moduli'
        },
        chooseTenant: 'Izberite naročnika iz seznama...',
        sidebarTitle: 'Naročniki',
        sidebarMeTitle: 'Podjetje',
        listSidebarTitle: 'Naročniki - Seznam',
        createSidebarTitle: 'Naročniki - Ustvari',
        updateSidebarTitle: 'Naročniki - Uredi'
    },
    venue: {
        email: 'E-mail',
        isMainWarehouse: 'Je glavna poslovalnica',
        phoneNumber: 'Telefon',
        status: 'Status',
        place: 'Lokacija',
        title: 'Naziv poslovalnice',
        website: 'Spletna stran',
        sidebarTitle: 'Poslovalnice',
        listSidebarTitle: 'Poslovalnice - Seznam',
        createSidebarTitle: 'Poslovalnice - Ustvari',
        updateSidebarTitle: 'Poslovalnice - Uredi',
        basic: {
            header: 'Osnovni podatki poslovalnice'
        },
        contacting: {
            header: 'Možnost kontakta',
            summary: 'Vpišite kontaktne podatke poslovalnice.'
        },
        displayImage: {
            header: 'Slika zunanjosti poslovalnice'
        },
        location: {
            header: 'Lokacija poslovalnice'
        },
        venueStatus: {
            closed: 'Poslovalnica je zaprta',
            open: 'Poslovalnica obratuje'
        },
        tabs: {
            basic: 'Osnovni podatki'
        }
    },
    validation: {
        file: {
            wrongType: 'Datoteka je v napačnem formatu!'
        },
        maxLength: 'Polje "{label}" ima lahko največ {maxLength} znakov!',
        minLength: 'Polje "{label}" mora imeti najmanj {minLength} znakov!',
        required: 'Polje "{label}" je obvezno!'
    },
    serverErrors: {
        account: {
            warnings: {
                alreadyVerified: 'Uporabnik z vnešenim e-mailom je že aktiviran',
                notVerified: 'Vaš uporabniški račun še ni aktiviran. Na vpisan email naslov ste prejeli aktivacijski e-mail v katerem morate potrditi registracijo. V primeru, da email sporočila niste prejeli uporabite spodnji link "Težave z aktivacijo računa?"',
                forgottenPasswordTooManyRequests: 'Preveč zahtev za pozabljeno geslo v časovnem obdobju. Prosimo počakajte par minut in poizkusite znova!',
                ACCOUNT_WAS_NOT_FOUND: 'Uporabnik z vnešenim e-mailom ni bil najden!',
                USER_WRONG_PASSWORD: 'Uporabnik z vnešenim geslom ni bil najden!'
            }
        },
        import: {
            warnings: {
                alreadyRunning: 'Naenkrat je lahko aktiven samo 1 uvoz'
            }
        },
        media: {
            errors: {
                uploadFailed: 'Pri nalaganju datoteke se je zgodila napaka. Prosimo počakajte par minut in poizkusite znova!'
            }
        },
        packageContract: {
            warnings: {
                activeContractNotFound: 'Naročnina ni bila najdena! Prosimo kontaktirajte administratorja na info@prodajko.si',
                maxQuantityReached: 'Dosegli ste maksimalno dovoljeno število dovoljenih entit tipa "{entityName}". Kontaktirajte nas na podpora@prodajko.si za povečanje paketa.'
            }
        },
        venue: {
            warnings: {
                cannotChangeDirectlyMainWarehouseStatus: 'Status glavne poslovalnice je možno samo spremenit, da jo označite na drugi poslovalnici!',
                firstShouldBeMainWarehouse: 'Prva poslovalnica mora biti glavna poslovalnica!'
            }
        }
    },
    sidebar: {
        categoriesHeader: 'Kategorije',
        moduleHeader: 'Moduli'
    },
    shared: {
        '403': {
            button: 'Pojdi na domačo stran',
            header: 'Oops...',
            summary: 'Oprostite, do zahtevane vsebine nimate dostopa',
            description: 'Za dodatne informacije se obrnite na skrbnike plaftorme'
        },
        alerts: {
            delete: {
                error: 'Pri brisanju entitete "{entityName}" z nazivom "{entityTitle}" se je zgodila napaka. Prosimo poizkusite kasneje!',
                success: 'Entiteta "{entityName}" z nazivom "{entityTitle}" je bila uspešno izbrisana!'
            },
            create: {
                error: 'Pri ustvarjanju entitete "{entityName}" z nazivom "{entityTitle}" se je zgodila napaka. Prosimo poizkusite kasneje!',
                success: 'Entiteta "{entityName}" z nazivom "{entityTitle}" je bila uspešno ustvarjena!'
            },
            update: {
                error: 'Pri posodabljanju entitete "{entityName}" z nazivom "{entityTitle}" se je zgodila napaka. Prosimo poizkusite kasneje!',
                success: 'Entiteta "{entityName}" z nazivom "{entityTitle}" je bila uspešno posodobljena!'
            },
            query: {
                error: 'Zgodila se je napaka pri pridobivanju entitete "{entityName}". Prosimo poizkusite pozneje!',
                notFound: 'Iskana entiteta "{entityName}" ni bila najdena!'
            }
        },
        dialogs: {
            delete: {
                title: 'Potrditev izbrisa',
                message: 'Ali ste prepričani, da želite izbrisati entiteto "{entityTitle}"?'
            }
        },
        dropzone: {
            dragAndDrop: 'Povlecite vašo <b>datoteko</b> v ta okvir ali pa kliknite tukaj.'
        },
        grid: {
            actions: 'Akcije',
            numberOfItemsPerPage: 'Število elementov na stran'
        },
        serverErrors: {
            uniqueConstrain: 'Polje "{columnName}" na entiteti "{entityName}" mora biti unikatno!',
            generic: 'Zgodila se je napaka! Poizkusite pozneje.'
        },
        add: 'Dodaj',
        addNewEntry: 'Dodaj nov vnos',
        addTranslations: 'Dodaj prevode',
        back: 'Nazaj',
        beginning: 'Začetek',
        cancel: 'Prekliči',
        choose: 'Izberi',
        confirm: 'Potrdi',
        create: 'Ustvari',
        createdAt: 'Datum vnosa',
        days: '{itemCount, plural, one {dan} two {dni} three {dni} four {dnevi} other {dni}}',
        delete: 'Izbriši',
        description: 'Opis',
        edit: 'Uredi',
        entry: 'Vnos',
        email: 'E-mail',
        empty: '/',
        emptyData: 'Ni podatkov',
        forward: 'Naprej',
        free: 'Brezplačno',
        filter: 'Filter',
        id: 'Identifikator',
        list: 'Seznam',
        logout: 'Odjava',
        no: 'Ne',
        month: 'mesec',
        ok: 'Ok',
        password: 'Geslo',
        translations: 'Prevodi',
        updatedAt: 'Zadnja posodobitev',
        saveItem: 'Shrani vnos',
        year: 'leto',
        yes: 'Da',
        tabs: {
            basic: 'Vsebinski del',
            pictures: 'Slike',
            translations: 'Prevodi'
        }
    }
};
