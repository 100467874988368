import React from 'react';
import { RcFormlyField } from '@rc-formly/core';

import { PackageSelector } from './PackageSelector';
import { PackageViewModel, PackageSelectorTypeTemplateOptions } from 'features/packs/models';

export class PackageSelectorType extends RcFormlyField<PackageSelectorTypeTemplateOptions> {
    onPackageChange = (pack: PackageViewModel) => {
        this.handleChange(pack.id);
    };

    render() {
        const { packageSelectorProps: { systemNamePrefix } = { systemNamePrefix: '' }  } = this.to || { };
        const fieldValue = this.getFieldValue();

        return <PackageSelector selectedPackageId={fieldValue} systemNamePrefix={systemNamePrefix} onPackageChange={this.onPackageChange} />;
    }
}
