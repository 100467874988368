import React, { FC } from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';

import { LocationViewModel } from 'features/locations/models';
import { useLamaLoadMapsScript } from 'features/locations/hooks';

import { LamaLoader } from 'shared/components';

import { LocationHelper } from 'features/locations/utilities';

interface LamaMapProps {
    center?: LocationViewModel;
    height?: string;
    mapOptions?: google.maps.MapOptions;
    shownMarkers?: LocationViewModel[];
    zoom?: number;
    onPointSelect?: (place: LocationViewModel) => void;
}

export const LamaMap: FC<LamaMapProps> = props => {
    const {
        center = { latitude: 46.554649, longitude: 15.645881 } as LocationViewModel,
        height = '400px',
        mapOptions = { disableDefaultUI: true } as google.maps.MapOptions,
        onPointSelect,
        shownMarkers,
        zoom = 10
    } = props;

    const { isLoaded } = useLamaLoadMapsScript();
    const { children } = props;

    if (!isLoaded) {
        return (
            <div style={{ height }}>
                <LamaLoader />
            </div>
        );
    }

    const onMapDblClick = async (evt: google.maps.MouseEvent) => {
        if (onPointSelect) {
            const newSelectedPoint = await LocationHelper.convertFromLatLngToLocation(evt.latLng);

            onPointSelect(newSelectedPoint);
        }
    };

    const latLng = LocationHelper.convertFromLocationToGoogleLatLng(center);
    const markerPositions = shownMarkers?.map(q => LocationHelper.convertFromLocationToGoogleLatLng(q));

    return (
        <GoogleMap center={latLng} mapContainerStyle={{ height }} options={mapOptions} onClick={onMapDblClick} zoom={zoom}>
            {markerPositions && markerPositions.map((q, index) => <Marker key={index} position={q} />)}
            {children}
        </GoogleMap>
    );
};
