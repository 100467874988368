import classNames from 'classnames';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { ContentPage } from 'features/base/components';
import { useCurrentContract } from 'features/contracts/hooks';
import { LamaLoader, LamaCollapsibleCard } from 'shared/components';
import { PackageSelectorItem } from 'features/packs/components';
import { makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
    createStyles({
        paragraph: {
            fontSize: '1rem',
            marginBottom: '1.5rem'
        },
        trialHeader: {
            fontSize: '3rem',
            textAlign: 'center'
        }
    })
);

export const ContractMe: FC = () => {
    const { paragraph, trialHeader } = useStyles();
    const { loading, currentContract } = useCurrentContract();

    const numberOfTrialDays = 60;

    return (
        <ContentPage>
            <LamaLoader showLoader={loading} />
            <div className="row align-items-start">
                <div className="col-md-4">
                    <LamaCollapsibleCard headingTranslate="packageContract.currentPackage" classes={{ body: 'pl-4 pr-4' }}>
                        {currentContract ? <PackageSelectorItem pack={currentContract?.ownerPackage} showSelectButton={false} /> : <div></div>}
                    </LamaCollapsibleCard>
                </div>
                <div className="col-md-8">
                    <LamaCollapsibleCard headingTranslate="packageContract.moreInfo">
                        <h1 className={classNames('text-muted', trialHeader)}>
                            {numberOfTrialDays} <FormattedMessage id="shared.days" values={{ itemCount: numberOfTrialDays }} />
                        </h1>
                        <p className={paragraph}>
                            Do poteka vašega brezplačnega testnega obdobja. V tem obdobju bomo sproti dodajali vedno več funkcionalnosti, ki vam bodo
                            seveda brezplačno na razpolago za testiranje.
                        </p>

                        <p className={paragraph}>
                            V kolikor boste zadovoljni z rezultati naše storitve vas bomo proti koncu testnega obdobja kontaktirali in lahko
                            podaljšate sodelovanje z nami. Seveda bomo imeli pripravljene posebne ugodnosti in akcije za prve naročnike Prodajko
                            platforme.
                        </p>

                        <p className={paragraph}>
                            V kolikor boste mnenja, da vam nismo uspeli pomagati boste lahko vaš uporabniški račun pri nas zaprli brez kakršnih koli
                            skritih stroškov ali obveznosti.
                        </p>

                        <p className={paragraph}>
                            V kolikor imate kakšna dodatna vprašanja smo vam vedno na voljo preko:
                            <b> 041 815 531</b> ali nam pišite na
                            <b>
                                <a href="mailto:podpora@prodajko.si"> podpora@prodajko.si</a>.
                            </b>
                        </p>

                        <p className={paragraph}>Želimo vam veliko uspešnih prodaj, ekipa Prodajko.</p>
                    </LamaCollapsibleCard>
                </div>
            </div>
        </ContentPage>
    );
};
