import React from 'react';
import { FormFeedback, FormGroup, Label } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { RcFormlyWrapper } from '@rc-formly/core';

export class CheckboxFormGroupWrapper extends RcFormlyWrapper {
    render() {
        const { hideLabel, labelTranslate, required } = this.to || {};
        const { fieldGroupClassName = 'mb-0' } = this.props.parentField as any;

        const error = this.getFieldError();
        const hasError = error && (this.wasFieldTouched() || this.wasFormSubmitted());

        return (
            <FormGroup className={fieldGroupClassName}>
                <div>
                    {
                        labelTranslate && !hideLabel && <Label>
                            <FormattedMessage id={labelTranslate}></FormattedMessage>
                        </Label>
                    }
                    {
                        required && !hideLabel && <span className="text-danger"> *</span>
                    }
                </div>
                { this.props.children }
                {
                    hasError && <FormFeedback valid={false} style={{ display: 'block' }}>
                        { error }
                    </FormFeedback>
                }
            </FormGroup>
        )
    }
}
