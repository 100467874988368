import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import { ImportRowErrorViewModel } from 'features/imports/models';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

interface ImporterErorrItemProps {
    item: ImportRowErrorViewModel;
}

export const ImporterErrorItem: FC<ImporterErorrItemProps> = (props) => {
    const { item } = props;

    const { formatMessage } = useIntl();

    let header = '';
    let subHeader = '';

    if (item.rowIndex) {
        header = formatMessage(
            { id: 'import.rowError' },
            {
                rowIndex: item.rowIndex
            }
        );

        if (item.destinationProperty && item.errorKey) {
            const propertyTranslation = formatMessage({ id: `import.fields.${item.destinationProperty}` });

            subHeader = formatMessage(
                { id: item.errorKey },
                {
                    ...(item.parameters || {}),
                    destinationProperty: propertyTranslation
                }
            );
        } else {
        }
    } else {
        header = formatMessage({ id: item.errorKey }, item.parameters);
    }

    return (
        <Box mb={2}>
            <Typography variant="subtitle1">
                <strong>{header}</strong>
            </Typography>
            {subHeader && <Typography variant="body2">{subHeader}</Typography>}
        </Box>
    );
};
