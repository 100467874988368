import React, { FC } from 'react';
import classNames from 'classnames';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
    createStyles({
        imageWrapper: {
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat',
            overflow: 'hidden'
        },
        image: {
            minHeight: 0,
            height: 200,
            opacity: 0,
            width: '100%'
        }
    })
);

interface MediaPictureProps {
    classes?: {
        root?: string;
        image?: string;
    };
    height?: number | string;
    src: string;
    title?: string;
}

export const MediaPicture: FC<MediaPictureProps> = (props) => {
    const { classes: { image, root } = { image: '', root: '' }, src, title } = props;

    const classes = useStyles();

    return (
        <div className={classNames(classes.imageWrapper, root)} style={{ backgroundImage: `url(${src})` }}>
            <img className={classNames(classes.image, image)} src={src} alt={title} />
        </div>
    );
};
