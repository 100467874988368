import React, { FC } from 'react';

import { ModularEntryRoute } from 'shared/packages/react-modular';
import { RouteDefaultData } from 'features/base/models';

import { LamaAuthorize } from 'shared/authentication/components';

import { CrudHelper } from 'features/base/utilities';

interface AuthorizeRouteProps {
    route: ModularEntryRoute<RouteDefaultData>;
}

export const AuthorizeRoute: FC<AuthorizeRouteProps> = props => {
    const { route, children } = props;

    const claims = CrudHelper.getClaimsFromRoute(route);

    return <LamaAuthorize claimsToCheck={claims}>{children}</LamaAuthorize>;
};
