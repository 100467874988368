import { ImmerReducer, createActionCreators, createReducerFunction } from 'immer-reducer';

import { IAppState } from './state';
import { UtilityHelper } from 'shared/utilities';

const initialState: IAppState = {
    isMiniSidebarShown: false,
    isPageLoaderShown: false,
    isSidebarShown: false
};

class AppReducer extends ImmerReducer<IAppState> {
    toggleMiniSidebar(isShown?: boolean) {
        if (UtilityHelper.isBoolean(isShown)) {
            this.draftState.isMiniSidebarShown = isShown!;
        }
        else {
            this.draftState.isMiniSidebarShown = !this.draftState.isMiniSidebarShown;
        }

        this.draftState.isSidebarShown = true;
    }

    toggleSidebar(isShown?: boolean) {
        if (UtilityHelper.isBoolean(isShown)) {
            this.draftState.isSidebarShown = isShown!;
        }
        else {
            this.draftState.isSidebarShown = !this.draftState.isSidebarShown;
        }
    }

    hidePageLoader() {
        this.draftState.isPageLoaderShown = false;
    }

    showPageLoader() {
        this.draftState.isPageLoaderShown = true;
    }

    togglePageLoader(isShown?: boolean) {
        if (UtilityHelper.isBoolean(isShown)) {
            this.draftState.isPageLoaderShown = isShown!;
        }
        else {
            this.draftState.isPageLoaderShown = !this.draftState.isPageLoaderShown;
        }
    }
}

export const appReducerActionCreators = createActionCreators(AppReducer);
export const appReducerFunction = createReducerFunction(AppReducer, initialState);
