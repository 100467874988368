import React, { FC, useState } from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { MediaFileDropzone } from 'features/media/components';
import { LamaLoader } from 'shared/components';
import { useImporter } from 'features/imports/hooks';
import { useLamaStepper } from 'shared/components/stepper';
import { ImportRowErrorViewModel } from 'features/imports/models';
import { ImporterErrors } from './ImporterErrors';

const useStyles = makeStyles((theme) =>
    createStyles({
        dropzone: {
            height: '300px',
            marginTop: theme.spacing(2)
        }
    })
);

const LinkTypography: FC<MessageDescriptor> = (props) => {
    const { id } = props;

    return (
        <Box mb={1}>
            <Typography>
                <FormattedMessage
                    id={id}
                    values={{
                        b: (...chunks: string[]) => <b>{chunks}</b>,
                        br: <br />
                    }}
                />
            </Typography>
        </Box>
    );
};

export const ImporterStepOne: FC = () => {
    const [importerErrors, setImporterErrors ] = useState<ImportRowErrorViewModel[]>([]);

    const { loading, importerCheck } = useImporter();
    const { activeStep, setStep, stepCompleted } = useLamaStepper();

    const classes = useStyles();

    const onDropzoneSelectedFiles = async (files: File[]) => {
        setImporterErrors([]);

        const importCheckResult = await importerCheck({ file: files[0] });

        if (importCheckResult.errors?.length) {
            setImporterErrors(importCheckResult.errors);
        }
        else {
            if (importCheckResult.numberOfRequiredFieldDifferences !== 0) {
                stepCompleted();
            }
            else if (importCheckResult.numberOfCategoryDifferences !== 0) {
                setStep(activeStep + 2, { [activeStep + 1]: true });
            }
            else {
                setStep(activeStep + 3, {
                    [activeStep + 1]: true,
                    [activeStep + 2]: true
                });
            }
        }
    };

    return (
        <Box position="relative">
            <LamaLoader showLoader={loading} />
            <LinkTypography id="import.stepOne.summary" />
            <MediaFileDropzone accept=".csv,.xml" multiple={false} classes={{ root: classes.dropzone }} onSelectedFiles={onDropzoneSelectedFiles} />
            <ImporterErrors errors={importerErrors} />
            <Box mt={2}>
                <LinkTypography id="import.stepOne.linkOne" />
                <LinkTypography id="import.stepOne.linkTwo" />
                <LinkTypography id="import.stepOne.linkThree" />
            </Box>
        </Box>
    );
};
