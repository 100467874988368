import { ModularEntry } from 'shared/packages/react-modular';
import { RouteDefaultData } from 'features/base/models';
import { LamaCrudViewType } from 'shared/types';

import { VenueCreateOrUpdate,  VenueList } from './routes';
import { VenueResourceNames } from './models';

export const venueModule: ModularEntry<RouteDefaultData> = {
    name: 'venues',
    routes: [
        {
            header: 'menu.sidebarGroupTitle',
            name: 'venuesMain',
            icon: 'zmdi zmdi-store',
            titleTranslate: 'venue.sidebarTitle',
            routeProps: {
                path: '/venues'
            },
            routes: [
                {
                    header: 'menu.sidebarGroupTitle',
                    name: 'venuesList',
                    titleTranslate: 'venue.listSidebarTitle',
                    data: {
                        crudViewType: LamaCrudViewType.list,
                        primaryModelName: VenueResourceNames.venue
                    },
                    routeProps: {
                        component: VenueList,
                        path: '/list'
                    }
                },
                {
                    header: 'menu.sidebarGroupTitle',
                    name: 'venuesCreate',
                    titleTranslate: 'venue.createSidebarTitle',
                    data: {
                        crudViewType: LamaCrudViewType.create,
                        primaryModelName: VenueResourceNames.venue
                    },
                    routeProps: {
                        component: VenueCreateOrUpdate,
                        path: `/${LamaCrudViewType.create}`
                    }
                },
                {
                    header: 'menu.sidebarGroupTitle',
                    name: 'venuesUpdate',
                    titleTranslate: 'venue.updateSidebarTitle',
                    data: {
                        crudViewType: LamaCrudViewType.update,
                        hideOnSidebar: true,
                        primaryModelName: VenueResourceNames.venue
                    },
                    routeProps: {
                        component: VenueCreateOrUpdate,
                        path: `/:id/${LamaCrudViewType.update}`
                    }
                }
            ]
        }
    ]
};
