import { useRef, EffectCallback, DependencyList, useEffect } from 'react';

import { useDeepEffectInterface } from './types';

import { UtilityHelper } from 'shared/utilities';

const useDeepCompareMemoize = (value: any) => {
    const ref = useRef()

    if (UtilityHelper.notEquals(value, ref.current)) {
      ref.current = value
    }

    return ref.current;
  }

export const useDeepEffect: useDeepEffectInterface = (effect: EffectCallback, deps?: DependencyList) => {
    useEffect(effect, useDeepCompareMemoize(deps));
};
