import React, { FC } from 'react';

interface LamaGradientProps {
    direction?: 'topBottom' | 'bottomTop';
    className?: string;
    firstColor: string;
    secondColor: string;
}

export const LamaGradient: FC<LamaGradientProps> = (props) => {
    const { children, className, direction = 'topBottom', firstColor, secondColor } = props;

    return <div className={className} style={{ background: `linear-gradient(180deg, ${firstColor} 0%, ${secondColor} 100%)` }}>{children}</div>;
};
