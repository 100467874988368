import { DocumentNode } from 'graphql';
import React, { FC, useRef } from 'react';
import { useParams } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';

import { LamaCrudRouteParams } from 'features/base/models';
import { LamaFormlyFieldConfig } from 'shared/components/forms/models';
import { LamaServerOptions } from 'shared/graphql';
import { CreateUpdateBaseRef } from './types';

import { LamaCreateUpdateBase } from './LamaCreateUpdateBase';
import { LamaCollapsibleCard, LamaSimpleList } from 'shared/components';
import { FormHelper } from 'shared/components/forms/utilities';

interface LamaTrackableEntityCreateUpdateBaseProps {
    editGqlQuery?: DocumentNode;
    fields: LamaFormlyFieldConfig[];
    listHeadingTranslate: string;
    modelName: string;
    otherEntityIdPropertyName: string;
    ownerEntityIdPropertyName: string;
    titlePropertyName: string;
}

export const LamaTrackableEntityCreateUpdateBase: FC<LamaTrackableEntityCreateUpdateBaseProps> = (props) => {
    const { editGqlQuery, fields, listHeadingTranslate, modelName, otherEntityIdPropertyName, ownerEntityIdPropertyName, titlePropertyName } = props;

    const { id: ownerEntityId } = useParams<LamaCrudRouteParams>();

    const baseCrudRef = useRef<CreateUpdateBaseRef>(null);

    const listServerOptions: LamaServerOptions = {
        modelName,
        idPropertyDotNotation: ownerEntityIdPropertyName,
        loadAdditionalProperties: [titlePropertyName, ...FormHelper.getDotNotationPropertiesFromFields(fields)],
        variables: {
            where: {
                [ownerEntityIdPropertyName]: ownerEntityId
            }
        }
    };

    const getInitialModel = () => {
        return {
            [ownerEntityIdPropertyName]: ownerEntityId
        };
    };

    const getModelTitle = () => {
        return '';
    };

    const transformBeforeSubmit = (entityViewModel: any) => {
        const firstTitlePropertyName = titlePropertyName.split('.')[0];

        delete entityViewModel[firstTitlePropertyName];

        return entityViewModel;
    };

    const onSubmitSuccess = () => {
        if (baseCrudRef.current?.isEditMode()) {
            baseCrudRef.current.getFormlyProps().resetForm(getInitialModel());
            baseCrudRef.current.toggleEditMode();
        }
    };

    const onItemEdit = (entity: any) => {
        //baseCrudRef.current?.triggerEditMode(entity.id!);
        baseCrudRef.current?.triggerEditModeFromValues(entity);
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={9}>
                <LamaCreateUpdateBase
                    ref={baseCrudRef}
                    editGqlQuery={editGqlQuery}
                    getInitialModel={getInitialModel}
                    getModelTitle={getModelTitle}
                    fields={fields}
                    isEditMode={false}
                    isJoinedModel={true}
                    modelName={modelName}
                    transformBeforeSubmit={transformBeforeSubmit}
                    onSubmitSuccess={onSubmitSuccess}
                />
            </Grid>
            <Grid item xs={3}>
                <LamaCollapsibleCard headingTranslate={listHeadingTranslate}>
                    <LamaSimpleList bindLabel={titlePropertyName} onEdit={onItemEdit} serverOptions={listServerOptions} secondIdPropertyName={otherEntityIdPropertyName} />
                </LamaCollapsibleCard>
            </Grid>
        </Grid>
    );
};
