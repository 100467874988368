import React from 'react';
import { RcFormlyField } from '@rc-formly/core';
import { CategoriesSelectorToggle } from './CategoriesSelectorToggle';

export class CategoriesSelectorToggleType extends RcFormlyField {
    onSelectedCategoryId = (categoryId: string) => {
        this.handleChange(categoryId);
    };

    render() {
        const fieldValue = this.getFieldValue();

        return <CategoriesSelectorToggle categoryId={fieldValue} onSelectedCategoryId={this.onSelectedCategoryId} />;
    }
}
