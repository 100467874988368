import React, { createContext, FC, useState } from 'react';

import { ModularContextState, ModularEntry, ModularEntryRoute } from './types';
import { UtilityHelper, UriHelper } from 'shared/utilities';

interface ModularProviderProps {
    pathPrefix?: string;
}

interface ModularProviderState {
    modules: ModularEntry[];
    groupedRoutes: ModularEntryRoute[];
    routes: ModularEntryRoute[];
}

export const ModularContext = createContext<ModularContextState>({
    groupedRoutes: [],
    modules: [],
    pathPrefix: '',
    routes: [],
    addModule: () => { },
    addModules: () => { },
    hasModule: () => { return false; },
    removeModule: () => {},
    setCurrentRoute: () => { }
});

const proccessRoutes = (routes: ModularEntryRoute[], parentRoute?: ModularEntryRoute) => {
    return routes
        .filter(q => UtilityHelper.isNotEmpty(q.routeProps)).map(q => {
            let path = q.routeProps?.path;

            if (path && parentRoute?.routeProps?.path) {
                path = UriHelper.combineUrl(parentRoute?.routeProps?.path as string, path as string);
            }

            return {
                ...q,
                parent: parentRoute,
                routeProps: {
                    ...q.routeProps,
                    path:  path
                }
            } as ModularEntryRoute;
        })
        .map(route => {
            if (UtilityHelper.isNotEmpty(route.routes)) {
                return {
                    ...route,
                    routes: getRoutesFromModularEntryRoutes(route.routes!, route)
                } as ModularEntryRoute;
            }

            return route;
        });
}

const getAllRoutesFromModularEntries = (modules: ModularEntry[], pathPrefix?: string) =>
    modules.map(q => getRoutesFromModularEntry(q)).reduce((acc, val) => acc.concat(proccessRoutes(val)), []);

const getAllGroupedRoutesFromModularEntries = (modules: ModularEntry[], pathPrefix?: string) => {
    let groupedRoutes = modules
        .filter(q => UtilityHelper.isNotEmpty(q.routes))
        .map(q => q.routes)
        .reduce((acc, val) => acc.concat(val), []);

    if (pathPrefix && UtilityHelper.isNotEmpty(groupedRoutes)) {
        groupedRoutes = groupedRoutes.map(q => {
            if (q.routeProps) {
                return {
                    ...q,
                    routeProps: {
                        ...q.routeProps,
                        path: UriHelper.combineUrl('/', pathPrefix, q.routeProps.path as string)
                    }
                };
            }

            return q;
        });
    }

    return proccessRoutes(groupedRoutes);
};

const getRoutesFromModularEntry = (modularEntry: ModularEntry) => {
    let routes: ModularEntryRoute[] = [];

    if (UtilityHelper.isNotEmpty(modularEntry.routes)) {
        routes = getRoutesFromModularEntryRoutes(modularEntry.routes);
    }

    return routes;
};

const getRoutesFromModularEntryRoutes = (routes: ModularEntryRoute[], parentRoute?: ModularEntryRoute) => {
    const allRoutes = routes.filter(q => UtilityHelper.isNotEmpty(q.routeProps)).map(q => {
        let path = q.routeProps?.path;

        if (path && parentRoute?.routeProps?.path) {
            path = UriHelper.combineUrl(parentRoute?.routeProps?.path as string, path as string);
        }

        return {
            ...q,
            parent: parentRoute,
            routeProps: {
                ...q.routeProps,
                path:  path
            }
        } as ModularEntryRoute;
    });

    routes.forEach(route => {
        if (UtilityHelper.isNotEmpty(route.routes)) {
            const subRoutes = getRoutesFromModularEntryRoutes(route.routes!, route);

            allRoutes.push(...subRoutes);
        }
    });

    return allRoutes;
};

export const ModularProvider: FC<ModularProviderProps> = (props) => {
    const [currentRoute, setCurrentRoute] = useState<ModularEntryRoute>();
    const [{ groupedRoutes, modules, routes }, setModules] = useState({
        groupedRoutes: [],
        modules: [],
        routes: []
    } as ModularProviderState);

    const { pathPrefix } = props;

    const addModule = (modularEntry: ModularEntry) => {
        addModules([modularEntry]);
    };

    const addModules = (modularEntries: ModularEntry[]) => {
        const uniqueModuleEntries = modularEntries.reduce((entries, entry) => {
            if (!entries.some(q => q.name === entry.name) && !hasModule(entry.name)) {
                entries.push(entry);
            }

            return entries;
        }, [] as ModularEntry[]);

        const newRoutes = getAllRoutesFromModularEntries(uniqueModuleEntries, pathPrefix);
        const newGroupedRoutes = getAllGroupedRoutesFromModularEntries(uniqueModuleEntries, pathPrefix)

        setModules({
            modules: [...modules, ...uniqueModuleEntries],
            groupedRoutes: [...groupedRoutes, ...newGroupedRoutes],
            routes: [...routes, ...newRoutes]
        });
    };

    const hasModule = (moduleName: string) => {
        return modules.some(q => q.name === moduleName)
    };

    const removeModule = (moduleName: string) => {
        if (hasModule(moduleName)) {
            const filteredModuleEntries = modules.filter(q => q.name !== moduleName);

            const newRoutes = getAllRoutesFromModularEntries(filteredModuleEntries, pathPrefix);
            const newGroupedRoutes = getAllGroupedRoutesFromModularEntries(filteredModuleEntries, pathPrefix)

            setModules({
                modules: filteredModuleEntries,
                groupedRoutes: newGroupedRoutes,
                routes: newRoutes
            });
        }
    }

    const contextValue: ModularContextState = {
        currentRoute,
        groupedRoutes,
        modules,
        pathPrefix,
        routes,
        addModules,
        addModule,
        hasModule,
        removeModule,
        setCurrentRoute
    };

    return (
        <ModularContext.Provider value={contextValue}>
            { props.children }
        </ModularContext.Provider>
    );
};
