import React from 'react';
import { RcFormlyField } from "@rc-formly/core";

import { LamaPlaceSelector } from 'features/locations/components/LamaPlaceSelector';

export class LamaPlaceSelectorType extends RcFormlyField {
    render() {
        const value = this.getFieldValue();
        const isInvalid = this.hasErrors() && (this.wasFieldTouched() || this.props.formlyProps.formProps.submitCount > 0);

        return (
            <LamaPlaceSelector isInvalid={isInvalid} onSelect={this.handleChange} value={value} />
        );
    }
}
