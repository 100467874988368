import React, { FC } from 'react';

import { CompanyCreateOrUpdateBase } from './components';
import { ContentPage } from 'features/base/components';

export const CompanyCreateOrUpdate: FC = () => {
    return (
        <ContentPage>
            <CompanyCreateOrUpdateBase />
        </ContentPage>
    );
};
