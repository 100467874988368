import React, { FC } from 'react';
import { ContentPage } from 'features/base/components';

import { MonthlyStatisticsCard, PackageConstrainsCard, WeeklySumStatisticsCard } from './components';

export const Home: FC = (props) => {
    return (
        <ContentPage>
            <div className="row align-items-start">
                <div className="col-lg-4 col-md-5 col-sm-6">
                    <PackageConstrainsCard />
                    <WeeklySumStatisticsCard />
                </div>
                <div className="col-lg-8 col-md-7 col-sm-6">
                    <MonthlyStatisticsCard />
                </div>
            </div>
        </ContentPage>
    );
};
