import React, { FC, useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";

import { LamaCrudRouteParams } from "features/base/models";
import { Media, MediaGridSizeType } from "features/media/models";
import { useLamaSelection, LamaSelectionProvider } from 'shared/components/selection';
import { useWidth } from "shared/packages/use-width";

import { DeletConfirmationDialog } from "shared/components";

import { UtilityHelper } from "shared/utilities";
import { MediaChooserItem } from './MediaChooserItem';
import { MediaChooserButton } from './MediaChooserButton';

const getNumberOfGridCols = (breakpoint: Breakpoint) => {
    switch (breakpoint) {
        case 'xl':
            return 4;

        case 'lg':
            return 4;

        case "md":
            return 4;

        case 'md':
            return 6;

        case "sm":
            return 6;

        case 'xs':
            return 12;

        default:
            return 12;
    }
}

interface MediaEntitiesInlineInnerChooserProps {
    entityMedia?: any[];
    gridSize?: MediaGridSizeType;
    mediaIdPropertyName?: string;
    mediaPropertyName?: string;
    onSelectedItems?: (entityMedia: any[]) => void;
    resourceIdPropertyName: string;
    useOrder?: boolean;
}

const MediaEntitiesInlineInnerChooser: FC<MediaEntitiesInlineInnerChooserProps> = (props) => {
    const {
        entityMedia = [], mediaIdPropertyName = 'ownerMediaId',
        mediaPropertyName = 'ownerMedia', onSelectedItems,
        resourceIdPropertyName, useOrder = true
    } = props;
    let { gridSize } = props;
    const { id } = useParams<LamaCrudRouteParams>();
    const currentBreakpoint = useWidth();

    const { addSelectedItems, maxNumberOfSelectedItems, toggleSelectedItem, selectedItems } = useLamaSelection<Media>();

    const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [selectedMediaItem, setSelectedMediaItem] = useState<Media | null>(null);

    if (UtilityHelper.isEmpty(gridSize)) {
        gridSize = getNumberOfGridCols(currentBreakpoint) as MediaGridSizeType;
    }

    const selectedItemLabel = selectedMediaItem?.title;

    const hasData = UtilityHelper.isNotEmpty(entityMedia);

    useEffect(() => {
        if (hasData && UtilityHelper.isEmpty(selectedItems)) {
            const alreadySelectedItems = entityMedia!.map(q => UtilityHelper.getDotNotationPropertyValue<Media>(q, mediaPropertyName));

            addSelectedItems(alreadySelectedItems);
        }
    }, [hasData])

    const onSelectedMediaItems = async(mediaItems: Media[]) => {
        if (UtilityHelper.isEmpty(mediaItems)) {
            return;
        }

        if (UtilityHelper.isNotEmpty(mediaItems)) {
            if (mediaItems.length > maxNumberOfSelectedItems) {
                mediaItems = mediaItems.slice(-maxNumberOfSelectedItems);
            }

            const variables = mediaItems.map((q, index) => {
                let joinedValue: any = {
                    [mediaIdPropertyName]: q.id,
                    [resourceIdPropertyName]: id,
                    [mediaPropertyName]: q
                };

                if (useOrder) {
                    joinedValue.order = entityMedia!.length + index;
                }

                return joinedValue;
            });

            if (onSelectedItems) {
                onSelectedItems(variables);
            }
        }
    };

    const removeEntityMedia = (entityMedia: any) => () => {
        const media = UtilityHelper.getDotNotationPropertyValue<Media>(entityMedia, mediaPropertyName);

        setSelectedMediaItem(media);
        setDeleteDialogOpen(true);
    };


    const onDeleteConfirmationClose = () => {
        setDeleteDialogOpen(false);
        setSelectedMediaItem(null);
    };

    const onDeleteConfirmed = async () => {
        setDeleteDialogOpen(false);
        setSelectedMediaItem(null);
        toggleSelectedItem(selectedMediaItem!);

        if (onSelectedItems) {
            onSelectedItems(entityMedia?.filter(q => UtilityHelper.getDotNotationPropertyValue(q, mediaIdPropertyName) !== selectedMediaItem?.id));
        }
    };

    const renderMediaItem = (entityMedia: any) => {
        const media = UtilityHelper.getDotNotationPropertyValue<Media>(entityMedia, mediaPropertyName);

        if (!media) {
            return null;
        }

        return (
            <MediaChooserItem key={media.id} gridSize={gridSize} media={media} onRemoveMedia={removeEntityMedia(entityMedia)} />
        );
    };

    return (
        <div className="position-relative">
            <Grid container direction="row" spacing={3}>
                <MediaChooserButton gridSize={gridSize} onSelectedMediaItems={onSelectedMediaItems} />
                { entityMedia && entityMedia.map(renderMediaItem) }
            </Grid>
            <DeletConfirmationDialog
                isOpen={isDeleteDialogOpen}
                onClose={onDeleteConfirmationClose}
                onConfirm={onDeleteConfirmed}
                entityTitle={selectedItemLabel}
            />
        </div>
    );
};

interface MediaEntitiesInlineChooserProps extends MediaEntitiesInlineInnerChooserProps {
    maxNumberOfSelectedItems?: number;
}

export const MediaEntitiesInlineChooser: FC<MediaEntitiesInlineChooserProps> = (props) => {
    const { maxNumberOfSelectedItems = 10, ...otherProps } = props;

    return (
        <LamaSelectionProvider maxNumberOfSelectedItems={maxNumberOfSelectedItems} multiple={true} keyDotNotationProperty="id">
            <MediaEntitiesInlineInnerChooser {...otherProps} />
        </LamaSelectionProvider>
    )
};
