import { useContext } from 'react';

import { KeyValueObject } from 'shared/types';
import { useLamaAlertsInterface, AlertType } from './types';

import { LamaAlertsContext } from './LamaAlertsProvider';


export const useLamaAlerts: useLamaAlertsInterface = () => {
    const { addAlert, removeAlert } = useContext(LamaAlertsContext);

    const addErrorAlert = (messageTranslate: string, messageTranslateVariables?: KeyValueObject) => {
        return addAlert({
            messageTranslate,
            messageTranslateVariables,
            alertType: AlertType.error
        });
    };

    const addInfoAlert = (messageTranslate: string, messageTranslateVariables?: KeyValueObject) => {
        return addAlert({
            messageTranslate,
            messageTranslateVariables,
            alertType: AlertType.info
        });
    };

    const addSuccessAlert = (messageTranslate: string, messageTranslateVariables?: KeyValueObject) => {
        return addAlert({
            messageTranslate,
            messageTranslateVariables,
            alertType: AlertType.success
        });
    };

    const addWarningAlert = (messageTranslate: string, messageTranslateVariables?: KeyValueObject) => {
        return addAlert({
            messageTranslate,
            messageTranslateVariables,
            alertType: AlertType.warning
        });
    };

    return {
        addAlert,
        addErrorAlert,
        addInfoAlert,
        addSuccessAlert,
        addWarningAlert,
        removeAlert
    }
}
