import { types } from 'typed-graphqlify';
import { GraphQlQueryType, LamaGraphQlQueryHelper, QueryFromDotNotationOptions } from 'shared/graphql';

import { CompanyResourceNames, TenantModel } from 'features/companies/models';
import { BaseEntity } from 'shared/types';

import { companyQueryObject } from './singleCompanyQuery';

export const singleMeTenantQueryOptions: QueryFromDotNotationOptions<TenantModel, BaseEntity> = {
    modelName: CompanyResourceNames.tenant as string,
    queryType: GraphQlQueryType.singleMe,
    queryObject: {
        id: types.string,
        systemName: types.string,
        companyOfTenant: companyQueryObject
    },
    queryPrefixDotNotation: 'admin.me'
};

export const { gqlQuery: singleMeTenantQuery} = LamaGraphQlQueryHelper.createQueryFromDotNotation<TenantModel, BaseEntity>(singleMeTenantQueryOptions);

export const { gqlQuery: singleTenantQuery} = LamaGraphQlQueryHelper.createQueryFromDotNotation<TenantModel, BaseEntity>({
    modelName: CompanyResourceNames.tenant as string,
    queryType: GraphQlQueryType.single,
    queryObject: {
        id: types.string,
        systemName: types.string,
        companyOfTenant: companyQueryObject
    },
    variables: {
        id: types.string
    }
});
