import { BaseEntity } from 'shared/types';
import { LocationViewModel } from 'features/locations/models';

export enum VenueStatus {
    closed = 'CLOSED',
    open = 'OPEN'
}

export interface Venue extends BaseEntity {
    description: string;
    name?: string;
    title: string;
    summary?: string;

    email?: string;
    phoneNumber?: string;
    website?: string;

    isMainWarehouse: boolean;

    status: VenueStatus;

    displayImageId?: string;

    place: LocationViewModel
}
