import { ComponentType } from 'react';

import { BaseEntity, KeyValueObject } from 'shared/types';
import { LamaServerOptions, GraphQlQueryObjectParameter, SortOperationKind } from 'shared/graphql';

export enum LamaGridTypes {
    lamaGrid = 'LamaGrid',
    lamaSimpleList = 'LamaSimpleList'
}

export enum LamaGridColumnType {
    actions = 'action',
    boolean = 'boolean',
    buttons = 'buttons',
    classification = 'classification',
    custom = 'custom',
    date = 'date',
    datetime = 'datetime',
    enum = 'enum',
    enumColor = 'enumColor',
    email = 'email',
    file = 'file',
    files = 'files',
    list = 'list',
    number= 'number',
    money = 'money',
    percentage = 'percentage',
    picture = 'picture',
    pictures = 'pictures',
    text = 'text',
    translated = 'translated'
}

export enum LamaGridRowEventType {
    delete = 'delete',
    deleteSuccess = 'deleteSuccess',
    edit = 'edit',
    history = 'history',
    view = 'views'
}

export interface GridCellType<TRowEntity extends BaseEntity = any> {
    gridType?: string;
    cellType: LamaGridColumnType;
    component: ComponentType<LamaGridCellProps<TRowEntity>>;
}

export interface GridFilterType<TRowEntity extends BaseEntity = any> {
    gridType?: string;
    cellType: LamaGridColumnType;
    component: ComponentType<LamaGridFilterProps<TRowEntity>>;
}

export interface LamaGridRowEvent<TRowEntity extends BaseEntity = any> {
    row: TRowEntity;
    index: number;
    type: LamaGridRowEventType;
}

export interface LamaGridTagColumnAdditionalData<TEnum = KeyValueObject>  extends LamaGridEnumColumnAdditionalData<TEnum> {
    colorForEnums: Map<TEnum, any>;
}

export interface LamaGridEnumColumnAdditionalData<TEnum = KeyValueObject> {
    enumType: TEnum;
    translationPrefix: string;
}

export interface LamaGridColumn<TRowEntity extends BaseEntity = any, TAdditionalData = any> {
    id?: string;
    additionalData?: TAdditionalData;
    component?: ComponentType<LamaGridCellProps<TRowEntity>>;
    filterable?: boolean;
    prop?: string;
    sortable?: boolean;
    title?: string;
    titleTranslate?: string;
    type?: LamaGridColumnType;
    width?: string;
    valueTransformer?: (propertyValue: any, row: TRowEntity) => any;
}

export interface LamaGridCellProps<TRowEntity extends BaseEntity = any, TAdditionalData = any> {
    column: LamaGridColumn<TRowEntity, TAdditionalData>;
    cellValue: any;
    gridName: string;
    row: TRowEntity;
    rowIndex: number;
}

export interface LamaGridFilterProps<TRowEntity extends BaseEntity = any, TAdditionalData = any> {
    column: LamaGridColumn<TRowEntity, TAdditionalData>;
    currentChanges?: GraphQlQueryObjectParameter[];
    gridName: string;
    onFilterChange: (changes: GraphQlQueryObjectParameter[]) => void;
}

export interface LamaGridAction {
    triggerEvent?: boolean;
    triggerDefault?: boolean;
    show: boolean;
}

export interface LamaGridActions {
    del?: LamaGridAction | boolean;
    edit?: LamaGridAction | boolean;
    show?: LamaGridAction | boolean;
}

export interface LamaGridFilter {
    column: LamaGridColumn;
    filters: GraphQlQueryObjectParameter[];
}

export interface LamaGridSort {
    property: string;
    sortOperation: SortOperationKind
}

export interface LamaGridCssClasses {
    root?: string;
    container?: string;
}

export interface LamaGridProps<TRowEntity extends BaseEntity = any> {
    actions?: LamaGridActions;
    classes?: LamaGridCssClasses;
    columns: LamaGridColumn<TRowEntity>[];
    fullHeight?: boolean;
    gridName?: string;
    modelName?: string;
    deleteServerOptions?: LamaServerOptions;
    idProperty?: string;
    idTransformer?: (entity: TRowEntity) => string;
    rows?: any[];
    serverOptions?: LamaServerOptions;
    showPagination?: boolean;
    onItemDelete?: (rowEvent: LamaGridRowEvent<TRowEntity>) => void;
    onItemDeleteSuccess?: (rowEvent: LamaGridRowEvent<TRowEntity>) => void;
    onItemEdit?: (rowEvent: LamaGridRowEvent<TRowEntity>) => void;
    titleProperty?: string;
    titleTransformer?: (entity: TRowEntity) => string;
}
