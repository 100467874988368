import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache, from, DefaultOptions } from '@apollo/client';
import { fromPromise } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { setContext } from 'apollo-link-context';
import jwtDecode from 'jwt-decode';

import { EmptyFunction } from 'shared/types';
import { REFRESH_TOKEN } from 'shared/authentication/mutations';
import { RefreshTokenRequestInput, TokenResponseViewModel, JwtDecryptedToken } from 'shared/authentication/models';
import {
    getRefreshTokenSelector,
    hasRefreshTokenSelector,
    getAccessTokenSelector,
    authenticationReducerActionCreators,
    getCurrentActiveTenantId
} from 'shared/authentication/reducers';

import { store } from 'store';

import { UtilityHelper, Logger, DateHelper } from 'shared/utilities';
import { Environment } from 'configs';
import { parseISO, isValid, getYear } from 'date-fns';

const url = new URL('graphql', Environment.apiRoot);

const httpLink = createHttpLink({
    uri: url.href
});

const authLink = (setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const mainState = store.getState();
    const token = getAccessTokenSelector(mainState);
    const currentActiveTenantId = getCurrentActiveTenantId(mainState);

    if (currentActiveTenantId) {
        headers = {
            ...headers,
            'Tenant-Id': currentActiveTenantId
        };
    }

    if (UtilityHelper.isEmpty(token)) {
        return {
            headers
        };
    }

    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: `Bearer ${token}`
        }
    };
}) as unknown) as ApolloLink;

let isRefreshing = false;
let pendingRequests: EmptyFunction[] = [];

const resolvePendingRequests = () => {
    pendingRequests.map(callback => callback());
    pendingRequests = [];
};

const getNewRefreshToken = async () => {
    const refreshToken = getRefreshTokenSelector(store.getState())!;
    const refreshTokenRequestInput: RefreshTokenRequestInput = {
        refreshToken,
        clientId: Environment.clientId
    };

    try {
        const mutateResult = await apolloClient.mutate<
            { refreshToken: TokenResponseViewModel },
            { refreshTokenRequestInput: RefreshTokenRequestInput }
        >({
            mutation: REFRESH_TOKEN,
            variables: {
                refreshTokenRequestInput
            }
        });

        if (mutateResult.data) {
            const decodedToken = jwtDecode<JwtDecryptedToken>(mutateResult.data.refreshToken.accessToken);

            store.dispatch(authenticationReducerActionCreators.loggedIn(mutateResult.data.refreshToken!, decodedToken));
            resolvePendingRequests();

            return mutateResult.data.refreshToken.accessToken;
        } else {
            Logger.warn('Issue with refreshing token...');
        }
    } catch (e) {
        pendingRequests = [];

        store.dispatch(authenticationReducerActionCreators.logout());
    } finally {
        isRefreshing = false;
    }
};

const errorLink = (onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
        for (let err of graphQLErrors) {
            switch (err.extensions?.code) {
                case 'AUTH_NOT_AUTHENTICATED':
                    // error code is set to UNAUTHENTICATED
                    // when AuthenticationError thrown in resolver
                    let reduxState = store.getState();
                    const hasToken = hasRefreshTokenSelector(reduxState);

                    if (hasToken) {
                        let forward$;

                        if (!isRefreshing) {
                            isRefreshing = true;

                            forward$ = fromPromise(getNewRefreshToken());
                        } else {
                            // Will only emit once the Promise is resolved
                            forward$ = fromPromise(
                                new Promise(resolve => {
                                    pendingRequests.push(() => resolve(true));
                                })
                            );
                        }

                        return forward$.flatMap(() => forward(operation));
                    }

                    break;

                case 'AUTH_NOT_AUTHORIZED':
                    break;
            }
        }
    }

    if (networkError) {
        console.log(`[Network error]: ${networkError}`);
        // if you would also like to retry automatically on
        // network errors, we recommend that you use
        // apollo-link-retry
    }
}) as unknown) as ApolloLink;

const convertToDate = (body: any) => {
    if (UtilityHelper.isEmpty(body)) {
        return
    }

    if (UtilityHelper.isArray(body)) {
        for (let i = 0; i < (body as any[]).length; i++) {
            convertToDate(body[i]);
        }
    }

    for (const key of Object.keys(body)) {
        const value = body[key];

        if (DateHelper.isIso8601(value)) {
            const parsedDate = parseISO(value);

            if (isValid(parsedDate) && getYear(parsedDate) > 2) {
                body[key] = parsedDate;
            }
            else {
                body[key] = null;
            }
        }
        else if (value === '0001-01-01T00:00:00.0000000Z') {
            body[key] = null;
        }
        else if (UtilityHelper.isObject(value)) {
            convertToDate(value);
        }
    }

    return body;
}

const bodyTransformerLink = new ApolloLink((operation, forward) => {
    return forward(operation).map((data) => {
        return convertToDate(data);
    });
});

const defaultOptions: DefaultOptions = {
    watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all'
    },
    query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all'
    }
};

export const apolloClient = new ApolloClient({
    //defaultOptions,
    link: from([errorLink, authLink, bodyTransformerLink, httpLink]),
    cache: new InMemoryCache({
        addTypename: false
    })
});
