import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { KeyboardDatePicker } from '@material-ui/pickers';

interface LamaDataPickerProps {
    format?: string;
    isInvalid?: boolean;
    maxDate?: Date;
    minDate?: Date;
    name?: string;
    label?: string;
    labelTranslate?: string;
    placeholder?: string;
    placeholderTranslate?: string;
    value?: Date;
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
    onChange?: (date?: Date | null) => void;
}

export const LamaDatePicker: FC<LamaDataPickerProps> = props => {
    const { name, format = 'dd.MM.yyyy', isInvalid, maxDate, minDate, labelTranslate, onBlur, onChange, value } = props;
    let { label } = props;

    const { formatMessage } = useIntl();

    const handleDateChange = (date: Date | null) => {
        if (onChange) {
            onChange(date);
        }
    };

    label = labelTranslate ? formatMessage({ id: labelTranslate }) : label;

    return (
        <KeyboardDatePicker
            autoOk
            disableToolbar
            error={isInvalid}
            id={name}
            variant="inline"
            format={format}
            margin="normal"
            maxDate={maxDate}
            minDate={minDate}
            label={label}
            value={value || null}
            placeholder={format}
            onBlur={onBlur}
            onChange={handleDateChange}
        />
    );
};
