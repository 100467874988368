import React, { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { types } from 'typed-graphqlify';

import Box from '@material-ui/core/Box';

import { PackageViewModel, PackageResourceNames } from 'features/packs/models';
import { SubscriptionDurationType } from 'features/contracts/models';
import { useLamaQuery, SortOperationKind } from 'shared/graphql';

import { LamaLoader } from 'shared/components';
import { PackageSelectorItem } from './PackageSelectorItem';
import { PackageSelectLeaseTime } from './PackageSelectLeaseTime';

interface PackageSelectorProps {
    selectedPackage?: PackageViewModel;
    selectedPackageId?: string;
    systemNamePrefix?: string;
    showLeaseDuration?: boolean;
    onPackageChange?: (newSelectedPackage: PackageViewModel) => void;
}

export const PackageSelector: FC<PackageSelectorProps> = (props) => {
    const { selectedPackageId, showLeaseDuration = false, selectedPackage, systemNamePrefix, onPackageChange } = props;

    const [choosenPackageLeaseTimeType, setChoosenPackageLeaseTimeType] = useState(SubscriptionDurationType.monthly);

    let { data, loading } = useLamaQuery<PackageViewModel[], PackageViewModel>({
        clone: true,
        modelName: PackageResourceNames.package,
        queryObject: {
            id: types.string,
            systemName: types.string,
            description: types.string,
            trialPeriodInDays: types.number,
            title: types.string,
            currentPrice: {
                priceWithoutTax: types.number,
                priceWithDiscount: types.number
            },
            featureModules: [
                {
                    order: types.number,
                    ownerFeatureModule: {
                        id: types.string,
                        maxQuantity: types.number,
                        ownerEntityType: {
                            name: types.string
                        }
                    }
                }
            ]
        } as PackageViewModel,
        queryPrefixDotNotation: '',
        variables: {
            order_by: {
                currentPrice: {
                    priceWithoutTax: SortOperationKind.asc
                }
            }
        }
    });

    if (systemNamePrefix) {
        data = data?.filter((q) => q.currentPrice.priceWithoutTax === 0 || !q.systemName || q.systemName.startsWith(systemNamePrefix));
    }

    const onPackageSelected = (pack: PackageViewModel) => {
        if (onPackageChange) {
            onPackageChange(pack);
        }
    };

    const onPackageLeaseTimeChange = (packageLeaseTimeType: SubscriptionDurationType) => {
        setChoosenPackageLeaseTimeType(packageLeaseTimeType);
    };

    return (
        <>
            {showLeaseDuration && (
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <h3 className="mb-0">
                        <FormattedMessage id="package.choosePackage" />
                    </h3>
                    <PackageSelectLeaseTime onPackageLeaseTimeChange={onPackageLeaseTimeChange} value={choosenPackageLeaseTimeType} />
                </Box>
            )}
            <h2 className="mb-3">
                <FormattedMessage id="package.chooseSuitablePackage" />
            </h2>
            <h2 className="text-center font-weight-bolder">
                <FormattedMessage id="package.noHiddenFeesTop" />
            </h2>
            <h2 className="text-center font-weight-bolder">
                <FormattedMessage id="package.noHiddenFeesBottom" />
            </h2>
            <div className="price-list position-relative">
                <div className="row">
                    {data?.map((q) => (
                        <div className="col-md-3" key={q.id}>
                            <PackageSelectorItem
                                colClasses="mb-3 mt-3"
                                isSelected={q.id === selectedPackageId}
                                leaseTimeType={choosenPackageLeaseTimeType}
                                pack={q}
                                systemNamePrefix={systemNamePrefix}
                                onPackageSelected={onPackageSelected}
                            />
                        </div>
                    ))}
                </div>
                <LamaLoader showLoader={loading} />
            </div>
        </>
    );
};
