import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { IFormlyFieldConfig } from '@rc-formly/core';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';

import { BaseResourceNames } from 'features/base/models';
import { LamaFormlyTypes, LamaSelectTypeTemplateOptions } from 'shared/components/forms/models';

import { appReducerActionCreators } from 'features/base/reducers';
import { useCurrentTenant } from 'features/companies/hooks';

import { useLamaTenant } from 'shared/authentication';

import { LamaForm } from 'shared/components';

interface HeaderTenantSelectModel {
    currentActiveTenantId?: string;
}

export const Header: FC = () => {
    const { canChangeActiveTenant, currentActiveTenantId, changeActiveTenant } = useLamaTenant();
    const { loading: loadingTenant, companyDomainHostUrl } = useCurrentTenant();
    const dispatch = useDispatch();

    const onToggleSidebar = () => {
        dispatch(appReducerActionCreators.toggleSidebar());
    };

    const model: HeaderTenantSelectModel = {
        currentActiveTenantId
    };

    const fields: IFormlyFieldConfig[] = [
        {
            key: 'currentActiveTenantId',
            type: LamaFormlyTypes.select,
            templateOptions: {
                placeholderTranslate: 'tenants.chooseTenant',
                lamaSelectProps: {
                    bindLabel: 'companyOfTenant.title',
                    bindValue: 'id',
                    serverOptions: {
                        modelName: BaseResourceNames.tenant
                    }
                },
                onChange: (newValue) => {
                    changeActiveTenant(newValue);
                }
            } as LamaSelectTypeTemplateOptions
        }
    ];

    return (
        <AppBar position="static" className="rct-header">
            <Toolbar className="d-flex justify-content-between w-100 pl-0">
                <ul className="list-inline mb-0 navbar-left">
                    <li className="list-inline-item" onClick={onToggleSidebar}>
                        <Tooltip title="Sidebar Toggle" placement="bottom">
                            <IconButton color="inherit" aria-label="Menu" className="humburger p-0" size="small">
                                <MenuIcon />
                            </IconButton>
                        </Tooltip>
                    </li>
                </ul>
                <ul className="list-inline mb-0 navbar-right">
                    {!loadingTenant && companyDomainHostUrl && (
                        <li className="list-inline-item">
                            <Button
                                href={companyDomainHostUrl}
                                color="primary"
                                variant="contained"
                                target="_blank"
                            >
                                <FormattedMessage id="base.header.showMyCompany" />
                            </Button>
                        </li>
                    )}
                    {canChangeActiveTenant() && (
                        <li className="list-inline-item ml-2">
                            <Box width={200}>
                                <LamaForm model={model} fields={fields} />
                            </Box>
                        </li>
                    )}
                </ul>
            </Toolbar>
        </AppBar>
    );
};
