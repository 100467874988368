import { useContext } from 'react';
import { useLamaStepperInterface } from './types';
import { LamaStepperContext } from 'shared/components/stepper/providers';
import { UtilityHelper } from 'shared/utilities';
import { NumberKeyValueObject } from 'shared/types';

export const useLamaStepper: useLamaStepperInterface = () => {
    const {
        activeStep, completedSteps, disabledSteps,
        setActiveStep, setCompletedSteps, setDisabledSteps, steps
    } = useContext(LamaStepperContext);

    const numberOfCompletedSteps = () => Object.keys(completedSteps).length;

    const totalSteps = () => steps.length;

    const isLastStep = () => activeStep === totalSteps() - 1;

    const isStepCompleted = (stepIndex: number) => UtilityHelper.isDefined(completedSteps[stepIndex]);

    const isStepDisabled = (stepIndex: number) => UtilityHelper.isDefined(disabledSteps[stepIndex]);

    const allStepsCompleted = () => numberOfCompletedSteps() === totalSteps();

    const back = () => {
        setStep(activeStep - 1);
    }

    const setStep = (newStepIndex: number, newDisabledSteps?: NumberKeyValueObject) => {
        if (newStepIndex >= totalSteps()) {
            return;
        }

        const completedSteps = Array.from<number>({ length: newStepIndex }).reduce((acc, val, i) => {
            acc[i] = true;

            return acc;
        }, {} as NumberKeyValueObject<boolean>);

        setActiveStep(newStepIndex);
        setCompletedSteps(completedSteps);

        if (newDisabledSteps) {
            setDisabledSteps(newDisabledSteps);
        }
        else if (newStepIndex === totalSteps() - 1) {
            setDisabledSteps(completedSteps);
        }
    };

    const stepCompleted = () => {
        setStep(activeStep + 1);
    };

    return {
        activeStep,
        allStepsCompleted,
        back,
        isLastStep,
        isStepCompleted,
        isStepDisabled,
        setStep,
        stepCompleted,
        steps
    };
};
