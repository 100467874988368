import React, { FC } from "react";
import Tab, { TabProps } from "@material-ui/core/Tab";
import { useIntl } from "react-intl";

interface LinkTabProps extends TabProps {
    labelTranslate?: string;
}

export const LamaLinkTab: FC<LinkTabProps> = (props) => {
    let { label, labelTranslate, ...otherProps } = props;

    const { formatMessage } = useIntl();

    label = labelTranslate ? formatMessage({ id: labelTranslate }) : label;

    return (
        <Tab {...otherProps} label={label}  />
    )
};
