import classNames from 'classnames';
import React from 'react';
import { RcFormlyWrapper } from '@rc-formly/core';
import { LamaFormlyFieldConfig } from 'shared/components/forms/models';

export class LamaRowWrapper extends RcFormlyWrapper {
    render() {
        const fieldGroupClassName = (this.props.parentField as LamaFormlyFieldConfig)?.fieldGroupClassName;

        return (
            <div className={classNames('row align-items-start', fieldGroupClassName)}>
                {this.props.children}
            </div>
        )
    }
}
