import React, { FC, useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { types } from 'typed-graphqlify';

import { useLamaQuery } from 'shared/graphql';
import { CategoryResourceNames, CategoryViewModel } from '../models';

import { UtilityHelper } from 'shared/utilities';
import { LamaLoader } from 'shared/components';
import { CategoriesSelectorModal } from './CategoriesSelectorModal';
import Box from '@material-ui/core/Box';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';

interface CategoriesSelectorToggleProps {
    categoryId?: string;
    onSelectedCategoryId: (categoryId: string) => void;
}

export const CategoriesSelectorToggle: FC<CategoriesSelectorToggleProps> = (props) => {
    const { categoryId, onSelectedCategoryId } = props;

    const [showCategorySelector, setShowCategorySelector] = useState(false);
    const [selectedCategories, setSelectedCategories] = useState<CategoryViewModel[]>([]);
    const { loading, data } = useLamaQuery<CategoryViewModel[]>({
        modelName: CategoryResourceNames.category as string,
        queryObject: {
            id: types.string,
            ownerParentCategoryId: types.string,
            title: types.string
        },
        skip: UtilityHelper.isEmpty(categoryId)
    });

    useEffect(() => {
        if (categoryId && UtilityHelper.isNotEmpty(data)) {
            let parentCategoryId: string | undefined = categoryId;
            const newSelectedCategories: CategoryViewModel[] = [];

            while (parentCategoryId) {
                const foundCategory = data?.find((q) => q.id === parentCategoryId);

                if (foundCategory) {
                    newSelectedCategories.unshift(foundCategory);
                }

                parentCategoryId = foundCategory?.ownerParentCategoryId;
            }

            setSelectedCategories(newSelectedCategories);
        } else if (!loading && UtilityHelper.isNotEmpty(selectedCategories)) {
            setSelectedCategories([]);
        }
    }, [loading, categoryId]);

    const onModalClose = () => {
        setShowCategorySelector(false);
    };

    const openModal = () => {
        setShowCategorySelector(true);
    };

    const onModalSelectedCategoryId = (categoryId: string) => {
        onModalClose();
        onSelectedCategoryId(categoryId);
    };

    const buttonText = categoryId ? 'shared.edit' : 'shared.choose';

    return (
        <>
            <Paper variant="outlined">
                <Box display="flex" justifyContent="space-between" p={2}>
                    {selectedCategories.length > 0 && (
                        <Breadcrumbs aria-label="breadcrumb">
                            {selectedCategories.map((q) => (
                                <Typography key={q.id} color="textPrimary">
                                    {q.title}
                                </Typography>
                            ))}
                        </Breadcrumbs>
                    )}
                    <Button variant="outlined" onClick={openModal}>
                        <FormattedMessage id={buttonText} />
                    </Button>
                </Box>
                <LamaLoader showLoader={loading} />
            </Paper>
            <CategoriesSelectorModal
                selectedCategories={selectedCategories}
                isOpen={showCategorySelector}
                onClose={onModalClose}
                onSelectedCategoryId={onModalSelectedCategoryId}
            />
        </>
    );
};
