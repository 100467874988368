import React, { FC, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Redirect, useHistory } from 'react-router-dom';
import { FormGroup } from 'reactstrap';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { IFormlyFieldConfig } from '@rc-formly/core';

import { TenantRegisterViewModel } from 'features/authentication/models';
import { TENANT_REGISTER } from 'features/authentication/mutations';
import {
    LamaFormlyFieldConfig,
    LamaFormlyWrappers,
    LamaFormlyTypes,
    LamaCollapsibleCardWrapperTemplateOptions,
    LamaEnumRadioGroupTypeTemplateOptions
} from 'shared/components/forms/models';
import { KeyValueObject } from 'shared/types';

import { useLamaClient, LamaGraphQlError } from 'shared/graphql';
import { CompanyActivityType } from 'features/companies/models/company';
import { LocationFormlyTypes } from 'features/locations/models';
import { PackFormlyTypes, PackageSelectorTypeTemplateOptions } from 'features/packs/models';

import { useAuth } from 'shared/authentication';
import { useLamaAlerts } from 'shared/packages/alerts';
import { useModular } from 'shared/packages/react-modular';

import { LamaForm, LamaLoader } from 'shared/components';
import { SessionContainer, LoginHeaderLink } from 'features/authentication/components';
import { SubscriptionDurationType } from 'features/contracts/models';

import { UriHelper, UtilityHelper } from 'shared/utilities';
import { Environment } from 'configs';

const defaultErrorMessage = 'authentication.register.error';

const useStyles = makeStyles(() =>
    createStyles({
        body: {
            padding: '2rem'
        }
    })
);

export const Register: FC = () => {
    const { addSuccessAlert, addWarningAlert } = useLamaAlerts();
    const { isAuthenticated } = useAuth();
    const lamaClient = useLamaClient();
    const history = useHistory();
    const { homeRoutePath } = useModular();
    const { formatMessage } = useIntl();

    const [serverErrorMessage, setServerErrorMessage] = useState<string>();
    const [serverErrorMessageParams, setServerErrorMessageParams] = useState<KeyValueObject>();
    const [showLoader, setShowLoader] = useState(false);

    const classes = useStyles();

    const fields: LamaFormlyFieldConfig[] = [
        {
            wrappers: [LamaFormlyWrappers.row],
            fieldGroup: [
                {
                    fieldGroupClassName: 'col-sm-6',
                    wrappers: [LamaFormlyWrappers.div],
                    fieldGroup: [
                        {
                            wrappers: [LamaFormlyWrappers.card],
                            templateOptions: {
                                cardProps: {
                                    headingTranslate: 'authentication.register.basicHeader',
                                    transparent: true
                                }
                            } as LamaCollapsibleCardWrapperTemplateOptions,
                            fieldGroup: [
                                {
                                    key: 'company.type',
                                    type: LamaFormlyTypes.enumRadioGroup,
                                    wrappers: [LamaFormlyWrappers.formGroup],
                                    templateOptions: {
                                        labelTranslate: 'company.type',
                                        enumRadioGroupProps: {
                                            enumType: CompanyActivityType,
                                            row: false,
                                            translatePrefix: 'company.activityType'
                                        },
                                        onChange: (newValue: CompanyActivityType, oldValue: CompanyActivityType, formlyProps) => {
                                            formlyProps.setFieldValue('ownerPackageId', undefined);
                                            formlyProps.changeFieldConfig(
                                                'ownerPackageId',
                                                (fieldConfig: IFormlyFieldConfig<PackageSelectorTypeTemplateOptions>) => {
                                                    if (newValue === CompanyActivityType.servicesOnly) {
                                                        fieldConfig.templateOptions!.packageSelectorProps = {
                                                            systemNamePrefix: 'service'
                                                        };
                                                    } else {
                                                        fieldConfig.templateOptions!.packageSelectorProps = {
                                                            systemNamePrefix: 'main'
                                                        };
                                                    }

                                                    return fieldConfig;
                                                }
                                            );
                                        },
                                        required: true
                                    } as LamaEnumRadioGroupTypeTemplateOptions
                                },
                                {
                                    key: 'company.registrationNumber',
                                    type: LamaFormlyTypes.input,
                                    wrappers: [LamaFormlyWrappers.formGroup],
                                    templateOptions: {
                                        labelTranslate: 'company.registrationNumber',
                                        required: true
                                    }
                                },
                                {
                                    key: 'company.taxNumber',
                                    type: LamaFormlyTypes.input,
                                    wrappers: [LamaFormlyWrappers.formGroup],
                                    templateOptions: {
                                        labelTranslate: 'company.taxNumber',
                                        required: true
                                    }
                                },
                                {
                                    key: 'company.validForTax',
                                    type: LamaFormlyTypes.checkbox,
                                    templateOptions: {
                                        labelTranslate: 'company.validForTax'
                                    }
                                },
                                {
                                    key: 'company.title',
                                    type: LamaFormlyTypes.input,
                                    wrappers: [LamaFormlyWrappers.formGroup],
                                    templateOptions: {
                                        labelTranslate: 'company.title',
                                        required: true
                                    }
                                },
                                {
                                    key: 'company.address',
                                    type: LocationFormlyTypes.placeSelector,
                                    wrappers: [LamaFormlyWrappers.formGroup],
                                    templateOptions: {
                                        labelTranslate: 'company.location.header',
                                        required: true
                                    }
                                }
                            ]
                        }
                    ]
                },
                {
                    fieldGroupClassName: 'col-sm-6',
                    wrappers: [LamaFormlyWrappers.div],
                    fieldGroup: [
                        {
                            wrappers: [LamaFormlyWrappers.card],
                            templateOptions: {
                                cardProps: {
                                    headingTranslate: 'authentication.register.userHeader',
                                    transparent: true
                                }
                            } as LamaCollapsibleCardWrapperTemplateOptions,
                            fieldGroup: [
                                {
                                    wrappers: [LamaFormlyWrappers.row],
                                    fieldGroup: [
                                        {
                                            key: 'contactPerson.firstName',
                                            fieldGroupClassName: 'col-sm-6',
                                            type: LamaFormlyTypes.input,
                                            wrappers: [LamaFormlyWrappers.formGroup],
                                            templateOptions: {
                                                labelTranslate: 'company.contactPerson.firstName',
                                                required: true
                                            }
                                        },
                                        {
                                            key: 'contactPerson.lastName',
                                            fieldGroupClassName: 'col-sm-6',
                                            type: LamaFormlyTypes.input,
                                            wrappers: [LamaFormlyWrappers.formGroup],
                                            templateOptions: {
                                                labelTranslate: 'company.contactPerson.lastName',
                                                required: true
                                            }
                                        }
                                    ]
                                },
                                {
                                    key: 'contactPerson.email',
                                    type: LamaFormlyTypes.input,
                                    wrappers: [LamaFormlyWrappers.formGroup],
                                    templateOptions: {
                                        labelTranslate: 'company.contactPerson.email',
                                        required: true,
                                        type: 'email'
                                    }
                                },
                                {
                                    key: 'contactPerson.password',
                                    type: LamaFormlyTypes.input,
                                    wrappers: [LamaFormlyWrappers.formGroup],
                                    templateOptions: {
                                        labelTranslate: 'company.contactPerson.password',
                                        minLength: 6,
                                        required: true,
                                        type: 'password'
                                    }
                                },
                                {
                                    key: 'contactPerson.phone',
                                    type: LamaFormlyTypes.input,
                                    wrappers: [LamaFormlyWrappers.formGroup],
                                    templateOptions: {
                                        labelTranslate: 'company.contactPerson.phone',
                                        required: true
                                    }
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            key: 'ownerPackageId',
            type: PackFormlyTypes.packageSelector,
            wrappers: [LamaFormlyWrappers.formGroup],
            templateOptions: {
                labelTranslate: 'package.choosePackage',
                packageSelectorProps: {
                    systemNamePrefix: 'main'
                },
                hideLabel: true,
                required: true
            } as PackageSelectorTypeTemplateOptions
        },
        {
            key: 'agreeWithPrivacyPolicy',
            type: LamaFormlyTypes.checkbox,
            wrappers: [LamaFormlyWrappers.formGroup],
            fieldGroupClassName: 'mb-0',
            templateOptions: {
                labelComponent: (
                    <FormattedMessage
                        id="authentication.register.agreeWithPrivacyPolicy"
                        values={{
                            link: (
                                <Link href={UriHelper.combineUrl(Environment.domainHost, '/pravila-in-pogoji')} target="_blank">
                                    <FormattedMessage id="authentication.withPrivacyPolicy" />
                                </Link>
                            )
                        }}
                    />
                ),
                labelTranslate: 'authentication.register.agreeWithPrivacyPolicyFull',
                hideLabel: true,
                required: true
            }
        },
        {
            key: 'agreeWithTermsOfUse',
            type: LamaFormlyTypes.checkbox,
            wrappers: [LamaFormlyWrappers.formGroup],
            templateOptions: {
                labelComponent: (
                    <FormattedMessage
                        id="authentication.register.agreeWithTermsOfUse"
                        values={{
                            link: (
                                <Link href="/terms-of-use" target="_blank">
                                    <FormattedMessage id="authentication.withTermsOfUse" />
                                </Link>
                            )
                        }}
                    />
                ),
                labelTranslate: 'authentication.register.agreeWithTermsOfUseFull',
                hideLabel: true,
                required: true
            }
        }
    ];

    const translatedDefaultErrorMesage = formatMessage({ id: defaultErrorMessage });

    const onFormSubmit = async (values: TenantRegisterViewModel) => {
        setServerErrorMessage('');
        setShowLoader(true);

        const viewModel = UtilityHelper.copy(values);

        delete viewModel.agreeWithPrivacyPolicy;
        delete viewModel.agreeWithTermsOfUse;

        try {
            const mutateResult = await lamaClient.mutate<boolean, { tenantRegisterInput: TenantRegisterViewModel }>({
                gqlMutation: TENANT_REGISTER,
                variables: {
                    tenantRegisterInput: viewModel
                }
            });

            if (mutateResult.data) {
                addSuccessAlert('authentication.register.success');

                history.push('/login');
            }
        } catch (e) {
            const errorMessage = (e as LamaGraphQlError).messageTranslate || defaultErrorMessage;
            const errorMessageParams = (e as LamaGraphQlError).messageTranslateParams;

            setServerErrorMessage(errorMessage);
            setServerErrorMessageParams(errorMessageParams);
            setShowLoader(false);

            addWarningAlert(errorMessage, errorMessageParams)
        }
    };

    if (isAuthenticated()) {
        return <Redirect to={homeRoutePath} />;
    }

    const model = {
        durationType: SubscriptionDurationType.monthly,
        company: {
            validForTax: false
        }
    } as TenantRegisterViewModel;

    return (
        <SessionContainer classes={{ body: classes.body }} showProgress={showLoader} headerRight={LoginHeaderLink} hidePromo={true}>
            <h1>
                <FormattedMessage id="authentication.register.subHeader" />
            </h1>
            <LamaForm fields={fields} model={model} onSubmit={onFormSubmit}>
                <LamaLoader showLoader={showLoader} />
                {serverErrorMessage && (
                    <p className="text-danger">
                        <FormattedMessage id={serverErrorMessage} values={serverErrorMessageParams} defaultMessage={translatedDefaultErrorMesage} />
                    </p>
                )}
                <FormGroup className="mb-15">
                    <Button color="primary" disabled={showLoader} className="text-white" variant="contained" type={'submit'} size="large">
                        <FormattedMessage id="authentication.register.submitButton" />
                    </Button>
                </FormGroup>
            </LamaForm>
        </SessionContainer>
    );
};
