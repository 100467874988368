import { useIntl } from 'react-intl';

import { useRapidModelInterface, RapidModelOptions, GetModelNameTranslationOptions } from "./types";
import { RapidModelContext } from './RapidModelProvider';
import { useContext } from 'react';
import { LanguageHelper, UtilityHelper } from 'shared/utilities';

export const useRapidModel: useRapidModelInterface = (options?: RapidModelOptions<any>) => {
    const { defaultModelName } = options || {};
    const { currentModel, modelTitleFunc, setCurrentModel } = useContext(RapidModelContext);
    const intl = useIntl();

    const clearCurrentModel = () => {
        setCurrentModel(undefined);
    };

    const getModelNameTranslation = (modelName?: string, options?: GetModelNameTranslationOptions) => {
        if (!modelName) {
            modelName = defaultModelName;
        }

        modelName = LanguageHelper.camelCase(modelName);

        if (UtilityHelper.isNumber(options?.itemCount)) {
            return intl.formatMessage({
                id: `modules.pluralModels.${modelName}`

            }, {
                itemCount: options!.itemCount
            });
        }

        return intl.formatMessage({
            id: `modules.models.${modelName}`
        });
    };

    const getModelTitle = () => {
        if (modelTitleFunc && currentModel) {
            return modelTitleFunc(currentModel);
        }

        return '';
    };

    return {
        clearCurrentModel,
        currentModel,
        getModelTitle,
        getModelNameTranslation,
        setCurrentModel
    };
};
