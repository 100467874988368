import { useContext } from 'react';

import { useLamaDialogInterface, LamaDialogType, LamaDialogOptions, LamaDialogButtonType } from './types';

import { LamaDialogContext } from './LamaDialogProvider';

export const useLamaDialog: useLamaDialogInterface = () => {
    const { addDialog, closeDialog } = useContext(LamaDialogContext);

    const addErrorDialog = (lamaDialog: LamaDialogOptions) => {
        return addDialog({
            dialogType: LamaDialogType.error,
            ...lamaDialog
        });
    };

    const addDeleteConfirmationDialog = (entityTitle: string, lamaDialog: LamaDialogOptions) => {
        return addDialog({
            headerTextTranslate: 'shared.dialogs.delete.title',
            messageTextTranslate: 'shared.dialogs.delete.message',
            messageTranslateVariables: {
                entityTitle
            },
            dialogType: LamaDialogType.warning,
            buttons: [
                {
                    buttonType: LamaDialogButtonType.cancel,
                    textTranslate: 'shared.cancel'
                },
                {
                    buttonType: LamaDialogButtonType.ok,
                    textTranslate: 'shared.delete'
                }
            ],
            ...lamaDialog
        });
    };

    const addInfoDialog = (lamaDialog: LamaDialogOptions) => {
        return addDialog({
            dialogType: LamaDialogType.info,
            ...lamaDialog
        });
    };

    const addSuccessDialog = (lamaDialog: LamaDialogOptions) => {
        return addDialog({
            dialogType: LamaDialogType.error,
            ...lamaDialog
        });
    };

    const addWarningDialog = (lamaDialog: LamaDialogOptions) => {
        return addDialog({
            buttons: [
                {
                    buttonType: LamaDialogButtonType.cancel,
                    textTranslate: 'shared.cancel'
                },
                {
                    buttonType: LamaDialogButtonType.ok,
                    textTranslate: 'shared.ok'
                }
            ],
            ...lamaDialog
        });
    };

    return {
        addDeleteConfirmationDialog,
        addDialog,
        addErrorDialog,
        addInfoDialog,
        addSuccessDialog,
        addWarningDialog,
        closeDialog
    }
}
