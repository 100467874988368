import React, { FC } from 'react';

import { Media, MediaSizeType } from 'features/media/models';
import { images } from 'assets';
import { MediaPicture } from './MediaPicture';

interface MediaImageProps {
    classes?: {
        root?: string;
        image?: string;
    };
    media?: Media;
    mediaSizeType?: MediaSizeType;
}

const mediaSizeTypeSuffixes = new Map<MediaSizeType, string>([
    [MediaSizeType.thumbnail, 'thumb'],
    [MediaSizeType.medium, 'medium'],
    [MediaSizeType.large, 'large']
]);

export const MediaImage: FC<MediaImageProps> = props => {
    const { classes, media, mediaSizeType = MediaSizeType.medium } = props;
    let mediaSrc: string;
    let mediaTitle: string;

    if (media) {
        const { fullPrefixUrl, extension, hasDifferentSizes, prefixUrl, title } = media;

        const mediaSizeTypeSuffix = mediaSizeTypeSuffixes.get(mediaSizeType) || mediaSizeTypeSuffixes.get(MediaSizeType.medium);

        mediaSrc = hasDifferentSizes ? `${fullPrefixUrl}_${mediaSizeTypeSuffix}${extension}` : prefixUrl;
        mediaTitle = title;
    } else {
        mediaSrc = images.placeholderImage;
        mediaTitle = 'placeholder';
    }

    return (
        <MediaPicture classes={classes} src={mediaSrc} title={mediaTitle}  />
    );
};
