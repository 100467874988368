import React, { createContext, FC, useState } from 'react';
import { LamaGridContextState, LamaGridInstance, LamaGridInsertInstance } from './types';
import { GridCellType, GridFilterType } from 'shared/components/grid/types';
import { UtilityHelper } from 'shared/utilities';

export const LamaGridContext = createContext<LamaGridContextState>({
    cellTypes: [],
    filterTypes: [],
    grids: [],
    lastGrid: null,
    addGridInstance : () => '',
    addGridCellType: () => { },
    addGridCellTypes: () => { },
    addGridFilterType: () => { },
    addGridFilterTypes: () => { },
    removeGridInstance: () => { },
});

export const LamaGridProvider: FC = (props) => {
    const [cellTypes, setCellTypes] = useState<GridCellType[]>([]);
    const [filterTypes, setFilterTypes] = useState<GridFilterType[]>([]);
    const [grids, setGrids] = useState<LamaGridInstance[]>([]);
    const lastGrid = grids ? grids[grids.length - 1] : null;

    const addGridCellType = (gridCellType: GridCellType) => {
        addGridCellTypes([gridCellType]);
    };

    const addGridCellTypes = (gridCellTypes: GridCellType[]) => {
        const uniqueGridCellTypes = gridCellTypes.reduce((cellTypes, cellType) => {
            if (!hasCellType(cellType, cellTypes) && !hasCellType(cellType)) {
                cellTypes.push(cellType);
            }

            return cellTypes;
        }, [] as GridCellType[]);

        setCellTypes([...cellTypes, ...uniqueGridCellTypes]);
    };

    const addGridFilterType = (gridFilterType: GridFilterType) => {
        addGridFilterTypes([gridFilterType]);
    };

    const addGridFilterTypes = (gridFilterTypes: GridFilterType[]) => {
        const uniqueGridFilterTypes = gridFilterTypes.reduce((filterTypes, filterType) => {
            if (!hasFilterType(filterType, filterTypes) && !hasFilterType(filterType)) {
                filterTypes.push(filterType);
            }

            return filterTypes;
        }, [] as GridFilterType[]);

        setFilterTypes([...filterTypes, ...uniqueGridFilterTypes]);
    };

    const addGridInstance = (gridInstance: LamaGridInsertInstance) => {
        gridInstance.name = gridInstance.name || UtilityHelper.createGuid();

        setGrids([...grids, gridInstance as LamaGridInstance]);

        return gridInstance.name;
    };

    const hasCellType = (gridCellType: GridCellType, cellTypesToCheck?: GridCellType[]) => {
        if (!cellTypesToCheck) {
            cellTypesToCheck = cellTypes;
        }

        return cellTypesToCheck.some(q => q.cellType === gridCellType.cellType && q.gridType === gridCellType.gridType);
    };

    const hasFilterType = (gridFilterType: GridFilterType, cellFiltersToCheck?: GridFilterType[]) => {
        if (!cellFiltersToCheck) {
            cellFiltersToCheck = filterTypes;
        }

        return cellFiltersToCheck.some(q => q.cellType === gridFilterType.cellType && q.gridType === gridFilterType.gridType);
    };

    const removeGridInstance = (gridName: string) => {
        setGrids(grids.filter(q => q.name !== gridName));
    };

    const contextValue: LamaGridContextState = {
        addGridInstance,
        addGridCellType,
        addGridCellTypes,
        addGridFilterType,
        addGridFilterTypes,
        cellTypes,
        filterTypes,
        grids,
        lastGrid,
        removeGridInstance
    };

    return (
        <LamaGridContext.Provider value={contextValue}>
            { props.children }
        </LamaGridContext.Provider>
    );
};
