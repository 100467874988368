import { types } from 'typed-graphqlify';
import { GraphQlQueryType, LamaGraphQlQueryHelper, QueryFromDotNotationOptions } from 'shared/graphql';

import { PackageContractViewModel, ContractResourceNames } from 'features/contracts/models';
import { lamaFeatureWithOwnerEntityQueryObject, packageQueryObject } from 'features/packs/queries';
import { priceQueryObject } from 'features/products/queries';
import { BaseEntity } from 'shared/types';

export const singleMeContractQueryOptions: QueryFromDotNotationOptions<PackageContractViewModel, BaseEntity> = {
    modelName: ContractResourceNames.contract as string,
    queryType: GraphQlQueryType.singleMe,
    queryObject: {
        id: types.string,
        autoRenew: types.boolean,
        contractFeatureModules: [{
            autoRenew: types.boolean,
            currentFeatureModulePrice: priceQueryObject,
            maxQuantity: types.number,
            maxVisibleQuantity: types.number,
            ownerFeatureModule: lamaFeatureWithOwnerEntityQueryObject,
            type: types.custom()
        }],
        currentPackagePrice: priceQueryObject,
        ownerPackage: packageQueryObject,
        durationType: types.custom(),
        ended: types.custom(),
        ends: types.custom(),
        hasEnded: types.boolean,
        renewalType: types.custom(),
        starts: types.custom(),
        trialPeriodInDays: types.number,
        type: types.custom()
    },
    queryPrefixDotNotation: 'admin.me'
};

export const { gqlQuery: singleMeContractQuery} = LamaGraphQlQueryHelper.createQueryFromDotNotation<PackageContractViewModel>(singleMeContractQueryOptions);

