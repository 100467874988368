import React, { FC, ChangeEvent } from 'react';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';

import { SelectOption } from 'shared/types';

interface LamaRadioGroupProps {
    options: SelectOption[];
    row?: boolean;
    value?: string;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const LamaRadioGroup: FC<LamaRadioGroupProps> = (props) => {
    const { row = true, options, onChange, value = '' } = props;

    return (
        <RadioGroup aria-label="position" name="position" value={value} onChange={onChange} row={row}>
            {options.map((option) => (
                <FormControlLabel
                    disabled={option.disabled}
                    key={option.value}
                    value={option.value}
                    control={<Radio color="primary" />}
                    label={option.label}
                />
            ))}
        </RadioGroup>
    );
};
