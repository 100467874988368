import React, { FC } from 'react';

import { LamaListRouteBase } from 'features/base/components';

import { ProductResourceNames, productStatusGridColorAdditionalData, ProductMediaViewModel } from 'features/products/models';
import { LamaGridProps, LamaGridColumnType } from 'shared/components/grid';
import { UtilityHelper } from 'shared/utilities';
import { mediaQueryObject } from 'features/media/queries';
import { useIntl } from 'react-intl';

export const ProductList: FC = () => {
    const { formatMessage } = useIntl();
    const gridOptions: LamaGridProps = {
        columns: [
            {
                type: LamaGridColumnType.picture,
                titleTranslate: 'product.displayImage',
                prop: 'files',
                valueTransformer: (values: ProductMediaViewModel[]) => {
                    if (UtilityHelper.isNotEmpty(values)) {
                        return values.sort((a, b) => a.order - b.order)[0].ownerMedia as any;
                    }

                    return values;
                }
            },
            {
                titleTranslate: 'product.title',
                prop: 'title'
            },
            {
                titleTranslate: 'product.sKU',
                prop: 'sKU'
            },
            {
                type: LamaGridColumnType.percentage,
                titleTranslate: 'price.discount',
                prop: 'currentPrice.discount'
            },
            {
                type: LamaGridColumnType.money,
                titleTranslate: 'price.retailPrice',
                prop: 'currentPrice.priceWithTaxAndDiscount',
                valueTransformer: (value: number) => {
                    if (value === 0) {
                        return formatMessage({ id: 'price.isNegotiable' });
                    }

                    return value;
                }
            },
            {
                type: LamaGridColumnType.classification,
                titleTranslate: 'product.ownerCategoryId',
                prop: 'ownerCategory.title'
            },
            {
                additionalData: productStatusGridColorAdditionalData,
                type: LamaGridColumnType.enumColor,
                titleTranslate: 'product.status',
                prop: 'status'
            },
            {
                type: LamaGridColumnType.datetime,
                titleTranslate: 'shared.updatedAt',
                prop: 'updatedAt'
            }
        ],
        serverOptions: {
            loadAdditionalProperties: ['files.order', ...UtilityHelper.getDotNotationProperties(mediaQueryObject, 'files.ownerMedia')],
            modelName: ProductResourceNames.product
        },
        titleProperty: 'title'
    };

    return <LamaListRouteBase gridProps={gridOptions} />;
};
