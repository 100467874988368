import { RcFormlyConfig } from '@rc-formly/core';

import { LocationFormlyTypes } from './models';
import { ModularEntry } from "shared/packages/react-modular";

import { LamaPlaceSelectorType } from './components';

RcFormlyConfig.addConfig({
    types: [
        {
            component: LamaPlaceSelectorType,
            name: LocationFormlyTypes.placeSelector
        }
    ]
})

export const locationModule: ModularEntry = {
    name: 'locations',
    routes: []
};
