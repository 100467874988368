import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useHistory } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import { LamaListRouteBaseProps } from './types';
import { RouteDefaultData } from 'features/base/models';
import { LamaCrudViewType } from 'shared/types';

import { useAuth } from 'shared/authentication';
import { useModularRoute } from 'shared/packages/react-modular';

import { ContentPage } from 'features/base/components/ContentPage';
import { LamaGrid } from 'shared/components/grid';
import { LamaCollapsibleCard } from 'shared/components';

import { CrudHelper } from 'features/base/utilities';


const useStyles = makeStyles({
    gridRoot: {
        flexGrow: 1
    }
});

export const LamaListRouteBase: FC<LamaListRouteBaseProps> = props => {
    let { createSectionComponent: CreateSectionComponent, gridProps } = props;
    const { isAuthorized } = useAuth();
    const { currentRoute } = useModularRoute<RouteDefaultData>();
    const styles = useStyles();

    if (!gridProps.actions) {
        const deleteClaim = CrudHelper.getClaimFromModel(currentRoute?.data?.primaryModelName!, LamaCrudViewType.delete);
        const editClaim = CrudHelper.getClaimFromModel(currentRoute?.data?.primaryModelName!, LamaCrudViewType.update);

        gridProps.actions = {
            del: isAuthorized(deleteClaim),
            edit: isAuthorized(editClaim)
        };
    }

    const history = useHistory();

    const createClaim = CrudHelper.getClaimFromModel(currentRoute?.data?.primaryModelName!, LamaCrudViewType.create);
    const createPath = history.location.pathname.replace(LamaCrudViewType.list, LamaCrudViewType.create);
    const canShowCreate = isAuthorized(createClaim);

    return (
        <ContentPage>
            <LamaCollapsibleCard classes={{ body: 'd-flex flex-column' }}>
                {canShowCreate && (
                    <Box mb={3}>
                        {CreateSectionComponent ? (
                            <CreateSectionComponent />
                        ) : (
                            <Grid container justify="flex-end">
                                <Link to={createPath}>
                                    <Button variant="contained" color="primary" type="button">
                                        <FormattedMessage id="shared.addNewEntry" /> +
                                    </Button>
                                </Link>
                            </Grid>
                        )}
                    </Box>
                )}
                <LamaGrid {...gridProps} fullHeight={true} classes={{ root: styles.gridRoot }} />
            </LamaCollapsibleCard>
        </ContentPage>
    );
};
