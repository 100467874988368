import React, { FC } from "react";

import { LamaGridCellProps } from 'shared/components/grid/types';

export const DefaultCellType: FC<LamaGridCellProps> = (props) => {
    const { cellValue } = props;

    return (
        <span>
            {cellValue}
        </span>
    )
}
