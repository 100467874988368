import { BaseEntity } from 'shared/types';

export type MediaGridSizeType = 1 | 2 | 3 | 4;

export enum MediaType {
    audio = 'audio',
    file = 'file',
    picture = 'picture',
    video = 'video'
}

export enum MediaSizeType {
    none = 'none',
    thumbnail = 'thumbnail',
    medium = 'medium',
    large = 'large',
    original = 'original'
}

export interface Media extends BaseEntity {
    summary: string;
    title: string;

    fullPrefixUrl: string;
    prefixUrl: string;
    extension: string;

    hasDifferentSizes: boolean;

    type: MediaType;
}

export interface MediaSize extends BaseEntity {
    height: number;
    width: number;

    url: string;

    type: MediaSizeType;

    ownerMediaId: string;
}
