import React, { FC, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Redirect, useHistory } from 'react-router-dom';
import { FormGroup } from 'reactstrap';
import { IFormlyFieldConfig } from '@rc-formly/core';
import Button from '@material-ui/core/Button';

import { RESET_PASSWORD } from 'features/authentication/mutations';

import { useAuth } from 'shared/authentication';
import { useLamaAlerts } from 'shared/packages/alerts';
import { useModular } from 'shared/packages/react-modular';
import { useQueryParams } from 'shared/packages/use-query-params';

import { LamaForm } from 'shared/components';
import { SessionContainer, SessionTitle } from 'features/authentication/components';

import { useLamaClient, LamaGraphQlError } from 'shared/graphql';

interface ResetPasswordFormModel {
    password: string;
    passwordConfirm: string;
}

interface ResetPasswordViewModel {
    hash: string;
    password: string;
}

const defaultErrorMessage = 'authentication.resetPassword.error';

export const ResetPassword: FC = () => {
    const { addSuccessAlert } = useLamaAlerts();
    const { isAuthenticated } = useAuth();
    const lamaClient = useLamaClient();
    const { homeRoutePath } = useModular();
    let history = useHistory();
    const { queryStrings } = useQueryParams();
    const { formatMessage } = useIntl();

    const [serverErrorMessage, setServerErrorMessage] = useState<string>();
    const [showLoader, setShowLoader] = useState(false)

    const fields: IFormlyFieldConfig[] = [
        {
            key: 'password',
            type: 'lm-input',
            wrappers: ['lm-form-group'],
            templateOptions: {
                labelTranslate: 'shared.password',
                minLength: 6,
                required: true,
                type: 'password'
            }
        }
    ];

    const translatedDefaultErrorMesage = formatMessage({ id: defaultErrorMessage });

    const onFormSubmit = async (values: ResetPasswordFormModel) => {
        setServerErrorMessage('');
        setShowLoader(true);

        try {
            const mutateResult = await lamaClient.mutate<
                boolean,
                { resetPasswordInput: ResetPasswordViewModel }
            >({
                gqlMutation: RESET_PASSWORD,
                variables: {
                    resetPasswordInput: {
                        hash: queryStrings['hash'],
                        password: values.password
                    }
                }
            });

            if (mutateResult.data) {
                addSuccessAlert('authentication.resetPassword.success');
                history.push('/login');
            }
        }
        catch (e) {
            let error = (e as LamaGraphQlError).messageTranslate || defaultErrorMessage;

            setShowLoader(false);
            setServerErrorMessage(error);
        }
    };

    if (isAuthenticated()) {
        return <Redirect to={homeRoutePath} />;
    }

    return (
        <SessionContainer showProgress={showLoader}>
            <SessionTitle title="authentication.resetPassword.subHeader" />
            {serverErrorMessage && (
                <p className="text-danger">
                    <FormattedMessage id={serverErrorMessage} defaultMessage={translatedDefaultErrorMesage} />
                </p>
            )}
            <LamaForm fields={fields} onSubmit={onFormSubmit}>
                <FormGroup className="mb-15">
                    <Button
                        color="primary"
                        disabled={showLoader}
                        className="btn-block text-white w-100"
                        variant="contained"
                        type={'submit'}
                        size="large"
                    >
                        <FormattedMessage id="authentication.resetPassword.button" />
                    </Button>
                </FormGroup>
            </LamaForm>
        </SessionContainer>
    );
};
