import gql from 'graphql-tag';

export const LOGIN = gql`
    mutation login($passwordRequestTokenInput: PasswordRequestTokenInput!) {
        login(passwordRequestTokenInput: $passwordRequestTokenInput) {
            accessToken,
            expires,
            expiresIn,
            issued,
            refreshToken
        }
    }`;
