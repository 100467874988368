
import { useEffect, useContext } from 'react';

import { PackageContractContext } from './PackageContractProvider';
import { useCurrentContractInterface } from './types';
import { useLamaQuery, GraphQlQueryType } from 'shared/graphql';

import { ContractResourceNames, PackageContractViewModel, PackageContractWithConstrainsViewModel } from 'features/contracts/models';
import {
    singleMeContractQuery,
    singleMeContractQueryOptions,
    singleMeContractConstrainsQueryOptions,
    singleMeContractContstrainsQuery
} from 'features/contracts/queries';
import { UtilityHelper, LanguageHelper } from 'shared/utilities';
import { FeatureModuleType } from 'features/packs/models';
import { KeyValueObject } from 'shared/types';


export const useCurrentContract: useCurrentContractInterface = () => {
    const { currentContract, setCurrentContract } = useContext(PackageContractContext)

    const hasCurrentContract = UtilityHelper.isNotEmpty(currentContract);
    const { data, loading } = useLamaQuery<PackageContractViewModel>({
        modelName: ContractResourceNames.contract,
        queryType: GraphQlQueryType.singleMe,
        gqlQuery: singleMeContractQuery,
        queryPrefixDotNotation: singleMeContractQueryOptions.queryPrefixDotNotation,
        skip: hasCurrentContract,
        map: (result: PackageContractViewModel) => {
            if (result?.ownerPackage) {
                result.ownerPackage.featureModules = result.contractFeatureModules.map((q, order) => ({
                    order,
                    ownerFeatureModule: {
                        ...q.ownerFeatureModule,
                        currentPrice: q.currentFeatureModulePrice,
                        maxQuantity: q.maxQuantity,
                        maxVisibleQuantity: q.maxVisibleQuantity
                    }
                }));
            }

            return result;
        }
    });

    const { data: dataContract, loading: loadingConstrans } = useLamaQuery<PackageContractWithConstrainsViewModel>({
        modelName: singleMeContractConstrainsQueryOptions.modelName,
        queryType: singleMeContractConstrainsQueryOptions.queryType,
        gqlQuery: singleMeContractContstrainsQuery,
        queryPrefixDotNotation: singleMeContractQueryOptions.queryPrefixDotNotation,
        skip: hasCurrentContract || !data,
        map: (result: PackageContractWithConstrainsViewModel) => {
            let packageContractWithConstrainsViewModel: PackageContractWithConstrainsViewModel =  {
                ...data,
                ...result
            };

            if (data?.contractFeatureModules) {
                data.contractFeatureModules.forEach((featureModule) => {
                    if (featureModule.ownerFeatureModule?.type === FeatureModuleType.quantity && featureModule.ownerFeatureModule?.ownerEntityType?.name) {
                        const modelName = LanguageHelper.capitalize(featureModule.ownerFeatureModule?.ownerEntityType?.name);
                        const quantityPropertyName =  `maxNumberOf${modelName}s`;

                        (packageContractWithConstrainsViewModel as KeyValueObject)[quantityPropertyName] = (packageContractWithConstrainsViewModel as KeyValueObject)[quantityPropertyName] as number || 0;
                        (packageContractWithConstrainsViewModel as KeyValueObject)[quantityPropertyName] += featureModule.maxQuantity;
                    }
                });
            }

            return packageContractWithConstrainsViewModel;
        }
    });

    useEffect(() => {
        if (!currentContract && dataContract) {
            setCurrentContract(dataContract);
        }
    }, [currentContract, dataContract]);

    return {
        currentContract,
        loading: loading || loadingConstrans
    };
};
