import React, { FC } from 'react';

import { ProductResourceNames, ProductViewModel, ProductStatus, ProductType, ProductVenueViewModel, ProductCreateUpdateViewModel } from 'features/products/models';
import { singleProductQuery } from 'features/products/queries';

import { LamaCreateUpdateBase } from 'features/base/components';

import { useProductFormFields } from './useProductFormFields';
import { LamaLoader } from 'shared/components';
import { UtilityHelper } from 'shared/utilities';

export const ProductCreateOrUpdateBase: FC = () => {
    const { loading, fields, venues } = useProductFormFields();

    const getModelTitle = (createUpdateViewModel: ProductCreateUpdateViewModel) => {
        return createUpdateViewModel?.entity.title;
    };

    const getInitialModel = () => {
        let product = {
            entity: {
                type: ProductType.new,
                status: ProductStatus.selling
            },
            hasDiscount: false
        } as ProductCreateUpdateViewModel;

        if (venues.length) {
            product.entity.onVenues = venues.map(q => ({
                ownerVenueId: q.id
            } as ProductVenueViewModel));
        }

        return product;
    };

    const transformBeforeEdit = async (entity: ProductViewModel) => {
        const { discount = 0 } = entity.currentPrice || {  };

        if (UtilityHelper.isNotEmpty(entity.files)) {
            entity.files = entity.files!.sort((a, b) => a.order - b.order);
        }

        return {
            entity,
            hasDiscount: discount > 0
        } as ProductCreateUpdateViewModel;
    };

    const transformBeforeSubmit = async (viewModel: ProductCreateUpdateViewModel) => {
        delete viewModel.entity.currentPrice?.ownerTax;

        viewModel.entity.files = viewModel.entity.files?.map(q => {
            delete q.ownerMedia;

            return q;
        });

        return viewModel.entity;
    };

    if (loading) {
        return <LamaLoader />
    }

    return (
        <LamaCreateUpdateBase
            editGqlQuery={singleProductQuery}
            getInitialModel={getInitialModel}
            getModelTitle={getModelTitle}
            fields={fields}
            modelName={ProductResourceNames.product}
            transformBeforeEdit={transformBeforeEdit}
            transformBeforeSubmit={transformBeforeSubmit}
        />
    );
};
