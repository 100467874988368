import classNames from 'classnames';
import React, { FC, useState, Ref, forwardRef, useImperativeHandle } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';

import { useQueryParams } from 'shared/packages/use-query-params';

import { LamaTabsProps, LamaTab, LamaTabsQueryParams, LamaTabsRef } from './types';
import { LamaTabPanel } from './LamaTabPanel';
import { LamaLinkTab } from './LamaLinkTab';

import { UtilityHelper } from 'shared/utilities';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper
    }
}));

const LamaInnerTabs: FC<LamaTabsProps> = props => {
    const { classes: propClasses, innerRef, initial, tabs = [], useQueryStrings = true } = props;

    const history = useHistory();
    const classes = useStyles();
    const { addQueryStrings, queryStrings } = useQueryParams<LamaTabsQueryParams>();

    let initialTab = initial || (UtilityHelper.isNotEmpty(tabs) ? tabs[0].name : '');
    const firstTab = initialTab;

    if (queryStrings.tab && useQueryStrings) {
        initialTab = queryStrings.tab;
    }

    const [value, setValue] = useState(initialTab);

    const activeTab = tabs.some(q => q.link === value || q.name === value) ? value : firstTab;

    useImperativeHandle(innerRef, () => ({
        moveToTabName: (tabName) => {
            setValue(tabName);
        }
    }));

    const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
        const foundTab = tabs.find(q => q.link === newValue || q.name === newValue);

        if (foundTab && useQueryStrings) {
            const { link } = foundTab;

            if (link || queryStrings.tab) {
                const newPath = addQueryStrings({ tab: link });

                history.push(newPath);
            }
        }

        setValue(newValue);
    };

    const renderTabPanel = (tab: LamaTab) => {
        const { component } = tab;

        return (
            <LamaTabPanel key={tab.name} className={propClasses?.container} currentValue={activeTab} value={tab.link || tab.name}>
                { component }
            </LamaTabPanel>
        );
    };

    return (
        <div className={classNames(classes.root, propClasses?.root)}>
            <Tabs value={activeTab} onChange={handleChange} className={propClasses?.tabs} textColor="primary" indicatorColor="primary">
                {tabs.map(q => (
                    <LamaLinkTab key={q.name} disabled={q.disabled} value={q.link || q.name} label={q.label} labelTranslate={q.labelTranslate} />
                ))}
            </Tabs>
            {tabs.map(renderTabPanel)}
        </div>
    );
};

const LamaInnerTabsWithRef: FC<LamaTabsProps> = (props, ref: Ref<LamaTabsRef>) => {
    return <LamaInnerTabs {...props} innerRef={ref} />;
};

export const LamaTabs = forwardRef<LamaTabsRef, Omit<LamaTabsProps, 'innerRef'>>(LamaInnerTabsWithRef);
