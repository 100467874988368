import { createPipe } from 'imask';

export const numberPipeMaskOptions = {
    mask: Number,
    scale: 2,
    thousandsSeparator: '',
    radix: ',',
    normalizeZeros: false,
    padFractionalZeros: false
} as IMask.MaskedNumber;

export const numberPipe = createPipe(numberPipeMaskOptions);

const numberThousandsPipeMaskOptions = {
    mask: Number,
    scale: 0,
    thousandsSeparator: '.',
    normalizeZeros: false,
    padFractionalZeros: false
} as IMask.MaskedNumber;


const thousandsPipe = createPipe(numberThousandsPipeMaskOptions);

export const numberThousandsPipe = (value?: number | string) => thousandsPipe(value?.toString() || '');
