import { IRcFormlyProps } from '@rc-formly/core';
import { types } from 'typed-graphqlify';

import {
    LamaFormlyFieldConfig,
    LamaFormlyWrappers,
    LamaFormlyTypes,
    LamaCollapsibleCardWrapperTemplateOptions,
    LamaSelectTypeTemplateOptions,
    LamaEnumRadioGroupTypeTemplateOptions,
    LamaAdvancedEnumRadioGroupTypeTemplateOptions,
    LamaCheckboxGroupTypeTemplateOptions
} from 'shared/components/forms/models';
import { ProductResourceNames, Tax, ProductViewModel, ProductCreateUpdateViewModel, ProductStatus, ProductType, ProductClaims } from 'features/products/models';
import { SortOperationKind, useLamaQuery } from 'shared/graphql';
import { SelectOption } from 'shared/types';

import { CategoryFormlyTypes } from 'features/categories/models';
import { MediaFormlyTypes, MediaEntitiesInlineChooserTypeTemplateOptions } from 'features/media/models';
import { VenueResourceNames, Venue } from 'features/venues/models';

import { useAuth } from 'shared/authentication';
import { useRapidModel } from 'shared/packages/rapid-model';

import { UtilityHelper, NumberHelper } from 'shared/utilities';

interface useProductFormFieldsProps {
    (): {
        fields: LamaFormlyFieldConfig[];
        loading?: boolean;
        venues: Venue[]
    };
}

const calculatePriceWithoutTax = (valueWithTax: number, taxValue: number) => {
    if (taxValue > 0 && taxValue < 1) {
        taxValue *= 100;
    }

    return NumberHelper.roundToDecimals((valueWithTax * (100 - taxValue)) / 100, 2)
}

export const useProductFormFields: useProductFormFieldsProps = () => {
    const { isAuthorized } = useAuth();
    const { currentModel  } = useRapidModel<ProductViewModel>();
    const { data = [], loading } = useLamaQuery<Venue[]>({
        modelName: VenueResourceNames.venue,
        queryObject: {
            id: types.string,
            title: types.string
        }
    });

    const hasProductReviewClaims = isAuthorized(ProductClaims.productReviewer);

    const setPriceWithoutTax = (formlyProps: IRcFormlyProps<ProductCreateUpdateViewModel>, currentTaxValue?: number) => {
        const currentPrice = formlyProps.formProps.values.entity.currentPrice;
        currentTaxValue = currentTaxValue || currentPrice?.ownerTax?.value;

        if (currentPrice && UtilityHelper.isNumber(currentTaxValue)) {
            formlyProps.setFieldValue('entity.currentPrice.priceWithoutTax', calculatePriceWithoutTax(currentPrice.priceWithTax, currentTaxValue!));
        }
    };

    const setDiscountedPrice = (formlyProps: IRcFormlyProps<ProductCreateUpdateViewModel>, currentTaxValue?: number) => {
        const taxValue = currentTaxValue || formlyProps.formProps.values.entity.currentPrice?.ownerTax?.value;
        const currentPrice = formlyProps.formProps.values.entity.currentPrice;

        if (currentPrice &&
            UtilityHelper.isNumber(taxValue)
        ) {
            const priceWithoutTax = calculatePriceWithoutTax(currentPrice.priceWithTax, taxValue!);

            if (currentPrice?.priceWithTaxAndDiscount < currentPrice.priceWithTax) {
                const inverseDiscount = Math.round(currentPrice.priceWithTaxAndDiscount / currentPrice.priceWithTax  * 100 );
                const discount = Math.ceil(100 - inverseDiscount);

                if (currentPrice.discount !== discount) {
                    formlyProps.setFieldValue('entity.currentPrice.discount', discount);
                    formlyProps.setFieldValue(
                        'entity.currentPrice.priceWithDiscount',
                        NumberHelper.roundToDecimals(priceWithoutTax - (priceWithoutTax * discount) / 100, 2)
                    );
                }
            }
            else {
                if (currentPrice.discount !== 0) {
                    formlyProps.setFieldValue('entity.currentPrice.discount', 0);
                }

                formlyProps.setFieldValue('entity.currentPrice.priceWithDiscount', priceWithoutTax);
                formlyProps.setFieldValue('entity.currentPrice.priceWithTaxAndDiscount', currentPrice.priceWithTax);
            }
        }
    };

    const rightSide: LamaFormlyFieldConfig = {
        fieldGroupClassName: 'col-sm-6',
        wrappers: [LamaFormlyWrappers.div],
        fieldGroup: [
            {
                wrappers: [LamaFormlyWrappers.card],
                templateOptions: {
                    cardProps: {
                        headingTranslate: 'product.categories.header'
                    }
                } as LamaCollapsibleCardWrapperTemplateOptions,
                fieldGroup: [
                    {
                        key: 'entity.ownerCategoryId',
                        type: CategoryFormlyTypes.categorySelector,
                        wrappers: [LamaFormlyWrappers.formGroup],
                        templateOptions: {
                            labelTranslate: 'product.ownerCategoryId',
                            required: true
                        }
                    }
                ]
            },
            {
                wrappers: [LamaFormlyWrappers.card],
                templateOptions: {
                    cardProps: {
                        headingTranslate: 'product.media.header',
                        showRequiredInTitle: true
                    }
                } as LamaCollapsibleCardWrapperTemplateOptions,
                fieldGroup: [
                    {
                        key: 'entity.files',
                        type: MediaFormlyTypes.mediaEntitiesChooser,
                        wrappers: [LamaFormlyWrappers.formGroup],
                        templateOptions: {
                            labelTranslate: 'product.media.header',
                            hideLabel: true,
                            //gridSize: 4,
                            resourceIdPropertyName: 'ownerProductId',
                            required: true
                        } as MediaEntitiesInlineChooserTypeTemplateOptions
                    }
                ]
            },
            {
                wrappers: [LamaFormlyWrappers.card],
                templateOptions: {
                    cardProps: {
                        headingTranslate: 'product.link.header'
                    }
                } as LamaCollapsibleCardWrapperTemplateOptions,
                fieldGroup: [
                    {
                        key: 'entity.url',
                        type: LamaFormlyTypes.input,
                        wrappers: [LamaFormlyWrappers.formGroup],
                        templateOptions: {
                            labelTranslate: 'product.url',
                            type: 'url'
                        }
                    }
                ]
            }
        ]
    };

    if (UtilityHelper.isNotEmpty(data)) {
        rightSide.fieldGroup?.push({
            wrappers: [LamaFormlyWrappers.card],
            templateOptions: {
                cardProps: {
                    headingTranslate: 'product.venues.header',
                    summaryTranslate: 'product.venues.description'
                }
            } as LamaCollapsibleCardWrapperTemplateOptions,
            fieldGroup: [
                {
                    key: 'entity.onVenues',
                    type: LamaFormlyTypes.checkboxGroup,
                    wrappers: [LamaFormlyWrappers.formGroup],
                    templateOptions: {
                        checkboxRadioGroupProps: {
                            options: data.map(q => ({
                                label: q.title,
                                value: q.id
                            } as SelectOption)),
                            valueDotNotationProperty: 'ownerVenueId'
                        }
                    } as LamaCheckboxGroupTypeTemplateOptions
                }
            ]
        })
    }

    return {
        loading,
        venues: data,
        fields: [
            {
                wrappers: [LamaFormlyWrappers.row, LamaFormlyWrappers.submitButton],
                fieldGroup: [
                    {
                        fieldGroupClassName: 'col-sm-6',
                        wrappers: [LamaFormlyWrappers.div],
                        fieldGroup: [
                            {
                                wrappers: [LamaFormlyWrappers.card],
                                templateOptions: {
                                    cardProps: {
                                        headingTranslate: 'product.basic.header'
                                    }
                                } as LamaCollapsibleCardWrapperTemplateOptions,
                                fieldGroup: [
                                    {
                                        key: 'entity.status',
                                        type: LamaFormlyTypes.advancedEnumRadioGroup,
                                        wrappers: [LamaFormlyWrappers.formGroup],
                                        templateOptions: {
                                            labelTranslate: 'product.status',
                                            enumRadioGroupProps: {
                                                enumType: ProductStatus,
                                                options: [
                                                    {
                                                        value: ProductStatus.selling
                                                    },
                                                    {
                                                        value: ProductStatus.acceptsPreorders
                                                    },
                                                    {
                                                        value: ProductStatus.notActive
                                                    },
                                                    {
                                                        value: ProductStatus.notInStock
                                                    },
                                                    {
                                                        disabled: !hasProductReviewClaims,
                                                        value: ProductStatus.underReview
                                                    },
                                                    {
                                                        disabled: !hasProductReviewClaims,
                                                        value: ProductStatus.violationOfTerms
                                                    }
                                                ],
                                                translatePrefix: 'product.productStatus'
                                            },
                                            required: true
                                        } as LamaAdvancedEnumRadioGroupTypeTemplateOptions
                                    },
                                    {
                                        key: 'entity.type',
                                        type: LamaFormlyTypes.enumRadioGroup,
                                        wrappers: [LamaFormlyWrappers.formGroup],
                                        templateOptions: {
                                            labelTranslate: 'product.type',
                                            enumRadioGroupProps: {
                                                enumType: ProductType,
                                                translatePrefix: 'product.productType'
                                            },
                                            required: true
                                        } as LamaEnumRadioGroupTypeTemplateOptions
                                    },
                                    {
                                        key: 'entity.title',
                                        type: LamaFormlyTypes.input,
                                        wrappers: [LamaFormlyWrappers.formGroup],
                                        templateOptions: {
                                            labelTranslate: 'product.title',
                                            minLength: 5,
                                            required: true
                                        }
                                    },
                                    {
                                        key: 'entity.description',
                                        type: LamaFormlyTypes.editor,
                                        wrappers: [LamaFormlyWrappers.formGroup],
                                        templateOptions: {
                                            minLength: 10,
                                            labelTranslate: 'shared.description',
                                            required: true
                                        }
                                    },
                                    {
                                        key: 'entity.sKU',
                                        type: LamaFormlyTypes.input,
                                        wrappers: [LamaFormlyWrappers.formGroup],
                                        templateOptions: {
                                            labelTranslate: 'product.sKU'
                                        }
                                    }
                                ]
                            },
                            {
                                wrappers: [LamaFormlyWrappers.card],
                                templateOptions: {
                                    cardProps: {
                                        headingTranslate: 'product.price.header'
                                    }
                                } as LamaCollapsibleCardWrapperTemplateOptions,
                                fieldGroup: [
                                    {
                                        key: 'entity.currentPrice.isNegotiable',
                                        type: LamaFormlyTypes.checkbox,
                                        wrappers: [LamaFormlyWrappers.checkboxFormGroup],
                                        templateOptions: {
                                            labelTranslate: 'price.isNegotiable',
                                            hideLabel: true,
                                            onChange: (newValue: boolean, oldValue: boolean, formlyProps: IRcFormlyProps<ProductCreateUpdateViewModel>) => {
                                                if (UtilityHelper.isDefined(oldValue) || newValue === true) {
                                                    if (newValue) {
                                                        formlyProps.setFieldValue('entity.currentPrice.discount', 0);
                                                        formlyProps.setFieldValue('entity.currentPrice.priceWithoutTax', 0);
                                                        formlyProps.setFieldValue('entity.currentPrice.priceWithDiscount', 0);
                                                        formlyProps.setFieldValue('entity.currentPrice.priceWithTax', 0);
                                                        formlyProps.setFieldValue('entity.currentPrice.priceWithTaxAndDiscount', 0);
                                                    }

                                                    formlyProps.changeFieldConfig('hasDiscount', q => {
                                                        q.hide = newValue;
                                                        return q;
                                                    });
                                                    formlyProps.changeFieldConfig('priceWrapper', q => {
                                                        q.hide = newValue;
                                                        return q;
                                                    });

                                                    if (formlyProps.formProps.values.hasDiscount) {
                                                        formlyProps.changeFieldConfig('discountPriceWrapper', q => {
                                                            q.hide = newValue;
                                                            return q;
                                                        });
                                                    }
                                                }
                                            }
                                        }
                                    },
                                    {
                                        key: 'priceWrapper',
                                        wrappers: [LamaFormlyWrappers.row],
                                        fieldGroup: [
                                            /*{
                                            key: 'entity.currentPrice.priceWithoutTax',
                                            fieldGroupClassName: 'col-sm-6',
                                            type: LamaFormlyTypes.moneyInput,
                                            wrappers: [LamaFormlyWrappers.formGroup],
                                            templateOptions: {
                                                labelTranslate: 'price.priceWithoutTax',
                                                required: true,
                                                onChange: (value: number, oldValue: number, formlyProps) => {
                                                    setPriceWithTax(formlyProps);
                                                    setDiscountedPrice(formlyProps);
                                                }
                                            }
                                        },*/
                                            {
                                                key: 'entity.currentPrice.ownerTaxId',
                                                fieldGroupClassName: 'col-sm-6',
                                                type: LamaFormlyTypes.select,
                                                wrappers: [LamaFormlyWrappers.formGroup],
                                                templateOptions: {
                                                    labelTranslate: 'price.priceTaxId',
                                                    placeholderTranslate: 'price.choosePrixeTaxId',
                                                    required: true,
                                                    lamaSelectProps: {
                                                        bindLabel: 'title',
                                                        bindValue: 'id',
                                                        defaultValue: 'high',
                                                        defaultValuePropertyName: 'systemName',
                                                        serverOptions: {
                                                            loadAdditionalProperties: ['value'],
                                                            modelName: ProductResourceNames.tax,
                                                            variables: {
                                                                order_by: {
                                                                    value: SortOperationKind.desc
                                                                }
                                                            }
                                                        }
                                                    },
                                                    onChange: (value: string, oldValue: string, formlyProps, priceTax: Tax) => {
                                                        if (value && priceTax) {
                                                            setPriceWithoutTax(formlyProps, priceTax.value);
                                                            setDiscountedPrice(formlyProps, priceTax.value);

                                                            formlyProps.setFieldValue('entity.currentPrice.ownerTax', priceTax);
                                                        }
                                                    }
                                                } as LamaSelectTypeTemplateOptions
                                            },
                                            {
                                                key: 'entity.currentPrice.priceWithTax',
                                                fieldGroupClassName: 'col-sm-6',
                                                type: LamaFormlyTypes.moneyInput,
                                                wrappers: [LamaFormlyWrappers.formGroup],
                                                templateOptions: {
                                                    labelTranslate: 'price.priceWithTax',
                                                    required: true,
                                                    onChange: (value: string, oldValue: string, formlyProps) => {
                                                        if (!UtilityHelper.isString(value)) {
                                                            setPriceWithoutTax(formlyProps);
                                                            setDiscountedPrice(formlyProps);
                                                        }
                                                    }
                                                }
                                            }
                                        ]
                                    },
                                    {
                                        key: 'hasDiscount',
                                        type: LamaFormlyTypes.checkbox,
                                        wrappers: [LamaFormlyWrappers.checkboxFormGroup],
                                        templateOptions: {
                                            labelTranslate: 'product.hasDiscount',
                                            hideLabel: true,
                                            onChange: (newValue: boolean, oldValue: boolean, formlyProps: IRcFormlyProps<ProductCreateUpdateViewModel>) => {
                                                formlyProps.changeFieldConfig('discountPriceWrapper', q => {
                                                    q.hide = !newValue;
                                                    return q;
                                                });

                                                if (!newValue) {
                                                    formlyProps.setFieldValue('entity.currentPrice.discount', undefined);
                                                    formlyProps.setFieldValue(
                                                        'entity.currentPrice.priceWithTaxAndDiscount',
                                                        formlyProps.formProps.values.entity.currentPrice?.priceWithTax);
                                                    formlyProps.setFieldValue(
                                                        'entity.currentPrice.priceWithDiscount',
                                                        formlyProps.formProps.values.entity.currentPrice?.priceWithoutTax);
                                                }
                                            }
                                        }
                                    },
                                    {
                                        key: 'discountPriceWrapper',
                                        wrappers: [LamaFormlyWrappers.row],
                                        hide: !currentModel?.currentPrice?.discount,
                                        fieldGroup: [
                                            {
                                                key: 'entity.currentPrice.priceWithTaxAndDiscount',
                                                fieldGroupClassName: 'col-sm-6',
                                                type: LamaFormlyTypes.moneyInput,
                                                wrappers: [LamaFormlyWrappers.formGroup],
                                                templateOptions: {
                                                    labelTranslate: 'price.priceWithTaxAndDiscount',
                                                    onChange: (value: number, oldValue: number, formlyProps) => {
                                                        if (!UtilityHelper.isString(value)) {
                                                            setDiscountedPrice(formlyProps);
                                                        }
                                                    }
                                                }
                                            },
                                            {
                                                key: 'entity.currentPrice.discount',
                                                fieldGroupClassName: 'col-sm-6',
                                                type: LamaFormlyTypes.numberInput,
                                                wrappers: [LamaFormlyWrappers.formGroup],
                                                templateOptions: {
                                                    disabled: true,
                                                    labelTranslate: 'price.discount'
                                                }
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    },
                    rightSide
                ]
            }
        ]
    };
};
