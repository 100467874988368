import React, { FC, useState } from 'react';
import { Input, InputGroup, InputProps, InputGroupAddon } from 'reactstrap';
import { useIntl } from 'react-intl';

import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Box from '@material-ui/core/Box';

export interface LamaInputProps extends InputProps {
    onInputSubmit?: (keyboardEvent: React.KeyboardEvent<HTMLInputElement>) => void;
}

export const LamaInput: FC<LamaInputProps> = (props) => {
    let { onInputSubmit, onKeyUp, placeholder, type, value = '', ...otherProps } = props;

    const { formatMessage } = useIntl();
    const [showPassword, setShowPassword] = useState(false);

    if (placeholder) {
        placeholder = formatMessage({ id: placeholder });
    }

    const onInputKeyUp = (keyboardEvent: React.KeyboardEvent<HTMLInputElement>) => {
        if (keyboardEvent.keyCode === 13 && onInputSubmit) {
            onInputSubmit(keyboardEvent);
        }

        if (onKeyUp) {
            onKeyUp(keyboardEvent);
        }
    };

    const onPasswordShowToggle = (evt: React.MouseEvent) => {
        evt.preventDefault();

        setShowPassword(!showPassword);
    };

    if (value === null) {
        value = '';
    }
    else {
        value = value.toString();
    }

    if (type === 'password') {
        return (
            <InputGroup>
                <Input {...otherProps} placeholder={placeholder} onKeyUp={onInputKeyUp} type={showPassword ? 'text': 'password'} value={value?.toString()} />
                <InputGroupAddon addonType="append">
                    <Box display="flex" ml={1} alignItems="center">
                        <IconButton aria-label="toggle password visibility" size="small" onClick={onPasswordShowToggle} edge="end">
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </Box>
                </InputGroupAddon>
            </InputGroup>
        );
    }

    return <Input {...otherProps} placeholder={placeholder} onKeyUp={onInputKeyUp} type={type} value={value} />;
};
