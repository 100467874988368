import React, { createContext, FC, useState } from 'react';

import { SelectionContextState } from './types';
import { UtilityHelper } from 'shared/utilities';

export const LamaSelectionContext = createContext<SelectionContextState>({
    maxNumberOfSelectedItems: 1,
    multiple: false,
    selectedItems: [],
    addSelectedItems: () => { },
    hasSelectedItem: () => { return false; },
    toggleSelectedItem: () => {},
    resetSelections: () => {}
});

interface SelectionProviderProps {
    keyDotNotationProperty?: string;
    maxNumberOfSelectedItems?: number;
    multiple?: boolean;
}

export const LamaSelectionProvider: FC<SelectionProviderProps> = props => {
    const { children, keyDotNotationProperty, multiple = false } = props;
    let { maxNumberOfSelectedItems = 1 } = props;

    if (!multiple) {
        maxNumberOfSelectedItems = 1;
    }

    const [selectedItems, setSelectedItems] = useState<any[]>([]);

    const addSelectedItems = (newSelectedItems: any[]) => {
        const uniqueSelectedItems = newSelectedItems.reduce((items, entry) => {
            if (!hasSelectedItem(entry, items) && !hasSelectedItem(entry)) {
                items.push(entry);
            }

            return items;
        }, [] as any[]);

        checkMaxNumberOfItemsBeforeSet([...selectedItems, ...uniqueSelectedItems]);
    };

    const toggleSelectedItem = (selectedItem: any) => {
        let newSelectedItems: any[];

        if (hasSelectedItem(selectedItem)) {
            if (keyDotNotationProperty) {
                const selectedItemKey = UtilityHelper.getDotNotationPropertyValue(selectedItem, keyDotNotationProperty);

                newSelectedItems = selectedItems.filter(q => UtilityHelper.getDotNotationPropertyValue(q, keyDotNotationProperty) !== selectedItemKey);
            }
            else {
                newSelectedItems = selectedItems.filter(q => q !== selectedItem);
            }
        }
        else {
            newSelectedItems = [...selectedItems, selectedItem];
        }

        checkMaxNumberOfItemsBeforeSet(newSelectedItems);
    };

    const hasSelectedItem = (selectedItem: any, compareToItems?: any[]) => {
        if (!compareToItems) {
            compareToItems = selectedItems;
        }

        if (keyDotNotationProperty) {
            return compareToItems.some(
                q =>
                    UtilityHelper.getDotNotationPropertyValue(selectedItem, keyDotNotationProperty) ===
                    UtilityHelper.getDotNotationPropertyValue(q, keyDotNotationProperty)
            );
        }
        else {
            return compareToItems.some(q => q === selectedItem);
        }
    };

    const checkMaxNumberOfItemsBeforeSet = (newSelectedItems: any[]) => {
        if (newSelectedItems.length > maxNumberOfSelectedItems) {
            newSelectedItems = newSelectedItems.slice(-maxNumberOfSelectedItems);
        }

        setSelectedItems(newSelectedItems);
    };

    const resetSelections = () => {
        setSelectedItems([]);
    };

    const contextValue: SelectionContextState = {
        addSelectedItems,
        hasSelectedItem,
        maxNumberOfSelectedItems,
        multiple,
        resetSelections,
        selectedItems,
        toggleSelectedItem
    };

    return <LamaSelectionContext.Provider value={contextValue}>{children}</LamaSelectionContext.Provider>;
};
