import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import Box from '@material-ui/core/Box';
import { createStyles, makeStyles } from '@material-ui/core/styles'
import InboxIcon from '@material-ui/icons/Inbox';

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            height: '200px'
        },
        icon: {
            fontSize: '50px'
        }
    }));

export const NoData: FC = () => {
    const classes = useStyles();

    return (
        <Box display="flex" flexDirection="column" className={classes.root} alignItems="center" justifyContent="center">
            <Box mb={1}>
                <InboxIcon className={classes.icon} />
            </Box>

            <FormattedMessage id="shared.emptyData" />
        </Box>
    )
};
