import { useContext } from 'react';
import { useModularInterface } from './types';

import { ModularContext } from './ModularProvider';

export const useModular: useModularInterface = () => {
    const {
        addModule, addModules, hasModule, removeModule, setCurrentRoute,
        currentRoute, groupedRoutes, modules, pathPrefix, routes
    } = useContext(ModularContext);

    let homeRoutePath = pathPrefix || '/';

    if (!homeRoutePath.startsWith('/')) {
        homeRoutePath = `/${homeRoutePath}`;
    }

    return {
        addModule,
        addModules,
        hasModule,
        removeModule,
        setCurrentRoute,
        currentRoute,
        groupedRoutes,
        modules,
        homeRoutePath,
        routes
    };
};
