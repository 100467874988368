export const debounce = <F extends (...args: any[]) => any>(func: F, waitFor = 300) => {
    let timeout: NodeJS.Timer;

    return (...args: Parameters<F>): Promise<ReturnType<F>> =>
        new Promise(resolve => {
            if (timeout) {
                clearTimeout(timeout);
            }

            timeout = setTimeout(() => resolve(func(...args)), waitFor);
        });
};

export const emptyFunction = () => {};
