import pluralize from 'pluralize';

import { UtilityHelper } from "./UtilityHelper";
import { KeyValueObject } from "shared/types";

export class LanguageHelper {
    public static capitalize(text: string, format?: string) {
        if (!text) {
            return text;
        }

        format = format || 'first';

        if (format === 'first') {
            // Capitalize the first letter of a sentence
            return text.charAt(0).toUpperCase() + text.slice(1);
        }
        else {
            const words = text.split(' ');
            const result = new Array<string>();

            words.forEach((word) => {
                if (word.length === 2 && format === 'team') {
                    // Uppercase team abbreviations like FC, CD, SD
                    result.push(word.toUpperCase());
                }
                else {
                    result.push(word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
                }
            });

            return result.join(' ');
        }
    }

    public static camelCase<T>(obj: T) {
        if (obj instanceof Array) {
            const build: any[] = [];

            for (const key in obj) {
                let value = obj[key];

                if (UtilityHelper.isObject(value)) {
                    value = LanguageHelper.camelCase(value);
                }

                build.push(value);
            }

            return build as unknown as T;
        } else if (UtilityHelper.isObject(obj)) {
            let build: KeyValueObject = {};

            Object.keys(obj).forEach(key => {
                if ((obj as KeyValueObject).hasOwnProperty(key)) {
                    const destKey = (key.charAt(0).toLowerCase() + key.slice(1) || key).toString();
                    let value = (obj as KeyValueObject)[key];

                    if (value !== null && UtilityHelper.isObject(value)) {
                        value = this.camelCase(value);
                    }

                    build[destKey] = value;
                }
            });

            return build as T;
        }
        else if (UtilityHelper.isString(obj)) {
            return ((obj as unknown as string).charAt(0).toLowerCase()
                + (obj as unknown as string).slice(1) || (obj as unknown as string)).toString() as unknown as T;
        }

        return obj;
    }

    public static pluralize(value: string) {
        return pluralize(value);
    }
}
