import classNames from 'classnames';
import React, { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { Media, MediaGridSizeType } from 'features/media/models';

import { useMediaItemStyles } from './MediaChooserItem';
import { MediaFileChooserModal } from './MediaFileChooser';

const useStyles = makeStyles((theme) =>
    createStyles({
        dropzone: {
            border: `1px dashed ${theme.palette.text.secondary}`,
            height: '200px'
        }
    })
);

interface MediaChooseButtonProps {
    gridSize?: MediaGridSizeType;
    onSelectedMediaItems: (media: Media[]) => void;
}

export const MediaChooserButton: FC<MediaChooseButtonProps> = (props) => {
    const { gridSize = 2, onSelectedMediaItems } = props;

    const [showMediaChooser, setShowMediaChooser] = useState(false);

    const onMediaFileChooserClose = () => {
        setShowMediaChooser(false);
    };

    const openMediaFileChooser = () => {
        setShowMediaChooser(true);
    };

    const onSelectedItems = (mediaItems: Media[]) => {
        setShowMediaChooser(false);

        onSelectedMediaItems(mediaItems);
    };

    const styles = useStyles();
    const mediaStyles = useMediaItemStyles();

    return (
        <>
            <Grid item xs={gridSize} onClick={openMediaFileChooser}>
                <Grid className={classNames(styles.dropzone, mediaStyles.media)} container justify="center" alignItems="center">
                    <Button variant="contained">
                        <FormattedMessage id="media.chooser.chooseImages" />
                    </Button>
                </Grid>
            </Grid>
            <MediaFileChooserModal isOpen={showMediaChooser} onClose={onMediaFileChooserClose} onSelectedItems={onSelectedItems} />
        </>
    );
};
