import React, { FC, ChangeEvent } from 'react';
import { SelectOption } from 'shared/types';
import { LamaCheckbox } from './LamaCheckbox';
import { UtilityHelper } from 'shared/utilities';

export interface LamaCheckboxGroupProps {
    classes?: {
        checkboxWrapper?: string;
    };
    options: SelectOption[];
    values?: any[];
    labelDotNotationProperty?: string;
    valueDotNotationProperty?: string;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const LamaCheckboxGroup: FC<LamaCheckboxGroupProps> = (props) => {
    const {
        classes: { checkboxWrapper = 'col-md-6' } = {},
        options,
        values = [],
        labelDotNotationProperty,
        valueDotNotationProperty,
        onChange
    } = props;

    const areValuesEmpty = UtilityHelper.isEmpty(values);

    const onCheckboxChange = (option: SelectOption) => (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
        if (onChange) {
            let newValues = UtilityHelper.copy(values);

            if (checked) {
                if (valueDotNotationProperty) {
                    newValues.push({
                        [valueDotNotationProperty]: option.value
                    });
                } else {
                    newValues.push(option.value);
                }
            } else {
                newValues = values.filter((q) => UtilityHelper.getDotNotationPropertyValue(q, valueDotNotationProperty) !== option.value);
            }

            onChange({
                target: {
                    value: newValues as any
                }
            } as ChangeEvent<HTMLInputElement>);
        }
    };

    const renderOption = (option: SelectOption) => {
        const { disabled, label, value } = option;
        let { checked } = option;

        if (!areValuesEmpty) {
            checked = values!.some((q) => UtilityHelper.getDotNotationPropertyValue(q, valueDotNotationProperty!) === value);
        }

        return (
            <div className={checkboxWrapper} key={value}>
                <LamaCheckbox
                    checked={checked}
                    disabled={disabled}
                    label={label}
                    name={value}
                    onChange={onCheckboxChange(option)}
                    value={value}
                />
            </div>
        );
    };

    return <div className="row">{options.map(renderOption)}</div>;
};
