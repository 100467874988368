import { ModularEntry } from 'shared/packages/react-modular';
import { RouteDefaultData } from 'features/base/models';
import { LamaCrudViewType } from 'shared/types';

import { ImportCreate, ImportList } from './routes';
import { ImportResourceNames } from './models';

export const importModule: ModularEntry<RouteDefaultData> = {
    name: 'imports',
    routes: [
        {
            header: 'menu.sidebarGroupTitle',
            name: 'importsMain',
            icon: 'material-icons arrow_upward',
            titleTranslate: 'import.sidebarTitle',
            data: {
                hideOnSidebar: true
            },
            routeProps: {
                path: '/imports'
            },
            routes: [
                {
                    header: 'menu.sidebarGroupTitle',
                    name: 'importsList',
                    titleTranslate: 'import.listSidebarTitle',
                    data: {
                        crudViewType: LamaCrudViewType.list,
                        primaryModelName: ImportResourceNames.import
                    },
                    routeProps: {
                        component: ImportList,
                        path: '/list'
                    }
                },
                {
                    header: 'menu.sidebarGroupTitle',
                    name: 'importsCreate',
                    titleTranslate: 'import.createSidebarTitle',
                    data: {
                        crudViewType: LamaCrudViewType.create,
                        primaryModelName: ImportResourceNames.import
                    },
                    routeProps: {
                        component: ImportCreate,
                        path: `/${LamaCrudViewType.create}`
                    }
                }
            ]
        }
    ]
};
