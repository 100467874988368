import React, { FC } from "react";
import { useIntl } from "react-intl";

import { LamaEnumRadioGroupProps } from "./LamaEnumRadioGroup";
import { LamaRadioGroup } from "./LamaRadioGroup";

import { FormHelper } from "shared/components/forms/utilities";
import { SelectOption } from "shared/types";


export interface LamaAdvancedEnumRadioGroupProps extends LamaEnumRadioGroupProps {
    options: SelectOption[];
}

export const LamaAdvancedEnumRadioGroup: FC<LamaAdvancedEnumRadioGroupProps> = (props) => {
    const { enumType, options, translatePrefix, ...otherProps } = props;
    const { formatMessage } = useIntl();

    const enumOptions = options.map(q => {
        const labelTranslation = FormHelper.converEnumValueToTranslationMessage(q.label || q.value, translatePrefix, enumType);

        return {
            disabled: q.disabled,
            label: formatMessage({ id: labelTranslation }),
            value: q.value
        } as SelectOption;
    });

    return (
        <LamaRadioGroup {...otherProps} options={enumOptions} />
    )
};
