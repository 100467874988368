import classNames from 'classnames';
import React, { FC } from 'react';
import { Badge } from 'reactstrap';

import { createStyles, makeStyles } from '@material-ui/core/styles';
import { ContextualColor } from 'shared/types';

const useStyles = makeStyles(theme =>
    createStyles({
        primary: {
            backgroundColor: theme.palette.primary.main
        }
    })
);

interface MaterialBadgeProps {
    color?: ContextualColor;
}

export const MaterialBadge: FC<MaterialBadgeProps> = props => {
    const { color = 'primary', children } = props;
    const classes = useStyles();

    const getCorrectCssClass = () => {
        switch (color) {
            case 'error':
                return 'bg-danger';

            case 'success':
                return 'bg-success';

            case 'warning':
                return 'bg-warning';

            default:
                return classes.primary;
        }
    };

    return <Badge className={classNames(getCorrectCssClass(), 'px-4')}>{children}</Badge>;
};
