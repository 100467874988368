import { useContext } from 'react';
import { useIntl } from 'react-intl';
import useFetch from 'use-http';

import { useImporterInterface } from './types';
import { ImportViewModel, ImportCheckResultViewModel, ImportRowErrorViewModel } from 'features/imports/models';
import { ImporterContext } from './ImporterProvider';
import { FormDataHelper } from 'shared/utilities';
import { ServiceResult } from 'shared/types';

export const useImporter: useImporterInterface = () => {
    const { lastImport, lastImportCheckResult, setLastImport, setLastImportCheckResult } = useContext(ImporterContext);
    const { loading, post, response } = useFetch();
    const { formatMessage } = useIntl();

    const convertServiceResultToCheckResult = (importCheckResult: ServiceResult) => {
        const errorKey = importCheckResult?.statusKey ? `serverErrors.${importCheckResult.statusKey}` : 'shared.serverErrors.generic';

        return {
            errors: [
                {
                    errorKey,
                    parameters: {
                        entityName: formatMessage({ id: 'modules.models.product' })
                    }
                } as ImportRowErrorViewModel
            ]
        } as ImportCheckResultViewModel;
    };

    const importer = async (checkViewModel: ImportViewModel) => {
        const bestImportSchemaId = checkViewModel.bestImportSchemaId || lastImportCheckResult?.bestImportSchema.id;
        const lastImport: ImportViewModel = {
            ...checkViewModel,
            bestImportSchemaId
        };

        setLastImport(lastImport);

        const formData = FormDataHelper.convertObjectToFormData(lastImport);

        const successViewModel = await post('api/v3/imports', formData);

        if (!response.ok) {
            return convertServiceResultToCheckResult(successViewModel);
        };
    };

    const importerCheck = async (checkViewModel: ImportViewModel): Promise<ImportCheckResultViewModel> => {
        setLastImport(checkViewModel);

        const formData = FormDataHelper.convertObjectToFormData(checkViewModel);

        const importCheckResult = await post('api/v3/imports/check', formData);

        if (response.status !== 200) {
            return convertServiceResultToCheckResult(importCheckResult);
        }

        setLastImportCheckResult(importCheckResult);

        return importCheckResult;
    };

    return {
        lastImport,
        lastImportCheckResult,
        loading,
        importer,
        importerCheck,
        setLastImport
    };
};
