import { Editor, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';
import { deserialize } from './utilities';

export const withHtml = (editor: Editor & ReactEditor) => {
    const { insertData, isInline, isVoid } = editor;

    editor.isInline = (element) => {
        return element.type === 'link' ? true : isInline(element);
    };

    editor.isVoid = (element) => {
        return element.type === 'image' ? true : isVoid(element);
    };

    editor.insertData = (data) => {
        let html = data.getData('text/html');

        if (html) {
            html = html.replace(/(\r\n|\n|\r)/gm, '');

            const parsed = new DOMParser().parseFromString(html, 'text/html');
            const fragment = deserialize(parsed.body);

            Transforms.insertFragment(editor, fragment);

            return;
        }

        insertData(data);
    };

    return editor;
};
