import React, { FC } from 'react';

import { LamaGridCellProps } from 'shared/components/grid/types';
import { DefaultCellType } from './DefaultCellType';
import { EmptyCell } from './EmptyCell';

import { UtilityHelper, moneyPipe } from 'shared/utilities';

export const MoneyCellType: FC<LamaGridCellProps> = props => {
    const { cellValue } = props;

    if (UtilityHelper.isUndefinedOrNull(cellValue)) {
        return <EmptyCell />;
    }

    if (UtilityHelper.isString(cellValue)) {
        return <DefaultCellType {...props} />
    }

    return <span>{moneyPipe((cellValue as number).toString())}</span>;
};
