import React, { FC, useState, useMemo, MouseEvent, Fragment } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { types } from 'typed-graphqlify';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import { LamaLoader, IconFromValue } from 'shared/components';

import { useLamaQuery, QueryFromDotNotationOptions, GraphQlQueryVariables, SortOperationKind } from 'shared/graphql';
import { CategoryResourceNames, CategoryViewModel } from 'features/categories/models';

import { UtilityHelper } from 'shared/utilities';

interface CategoriesSelectorProps {
    selectedCategories?: CategoryViewModel[];
    onSelectedCategoryId(categoryId: string): void;
}

const createCategoryQuery = (ownerParentCategoryId?: string): QueryFromDotNotationOptions<Partial<CategoryViewModel>> => {
    let variables: GraphQlQueryVariables<CategoryViewModel> = {
        order_by: {
            title: SortOperationKind.asc
        }
    };

    if (ownerParentCategoryId) {
        variables.where = {
            ownerParentCategoryId
        };
    }

    return {
        modelName: ownerParentCategoryId ? CategoryResourceNames.category : (CategoryResourceNames.categoryRecursive as string),
        queryObject: {
            id: types.string,
            childCategories: [
                {
                    id: types.string
                }
            ] as CategoryViewModel[],
            imageResource: types.string,
            title: types.string
        },
        variables
    };
};

const useStyles = makeStyles(() =>
    createStyles({
        card: {
            cursor: 'pointer'
        },
        icon: {
            height: '1.6rem'
        },
        listItem: {
            cursor: 'pointer'
        }
    })
);

export const CategoriesSelector: FC<CategoriesSelectorProps> = (props) => {
    const { selectedCategories: selectedCategoriesFromProps = [], onSelectedCategoryId } = props;
    const [selectedCategories, setSelectedCategories] = useState<CategoryViewModel[]>(selectedCategoriesFromProps);

    const lastSelectedCategory = selectedCategories[selectedCategories.length - 1];
    const lastSelectedCategoryId = lastSelectedCategory?.ownerParentCategoryId || lastSelectedCategory?.id;

    const { card, icon, listItem } = useStyles();
    const { formatMessage } = useIntl();
    const queryObject = useMemo(() => createCategoryQuery(lastSelectedCategoryId), [lastSelectedCategoryId]);

    const { data, loading } = useLamaQuery<CategoryViewModel[]>(queryObject);

    const categorySelected = (category: CategoryViewModel) => () => {
        if (UtilityHelper.isEmpty(category.childCategories) && onSelectedCategoryId) {
            onSelectedCategoryId(category.id);
        } else {
            setSelectedCategories([...selectedCategories, category]);
        }
    };

    const revertLastSelectedCategory = () => {
        setSelectedCategories(selectedCategories.slice(0, -2));
    };

    const resetCategorySelectionTo = (categoryId?: string) => (evt: MouseEvent) => {
        evt.preventDefault();

        if (categoryId) {
            const index = selectedCategories.findIndex((q) => q.id === categoryId);

            setSelectedCategories(selectedCategories.slice(0, index));
        } else {
            setSelectedCategories([]);
        }
    };

    const isEmpty = UtilityHelper.isEmpty(data);

    return (
        <>
            {selectedCategories.length > 0 && (
                <>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link color="inherit" href="" onClick={resetCategorySelectionTo()}>
                            <FormattedMessage id="shared.beginning" />
                        </Link>
                        {selectedCategories.map((q, index) =>
                            index === selectedCategories.length - 1 ? (
                                <Typography key={q.id} color="textPrimary">
                                    {q.title}
                                </Typography>
                            ) : (
                                <Link key={q.id} color="inherit" href="/" onClick={resetCategorySelectionTo(q.id)}>
                                    {q.title}
                                </Link>
                            )
                        )}
                    </Breadcrumbs>
                    <List>
                        <ListItem className={listItem} selected={false} onClick={revertLastSelectedCategory}>
                            <ListItemIcon>
                                <ArrowBackIcon />
                            </ListItemIcon>
                            <ListItemText primary={formatMessage({ id: 'shared.back' })} />
                        </ListItem>
                        <Divider component="li" variant="inset" />
                        {data?.map((q) => (
                            <Fragment key={q.id}>
                                <ListItem className={listItem} selected={lastSelectedCategory?.id === q.id} onClick={categorySelected(q)}>
                                    {q.imageResource && (
                                        <ListItemIcon>
                                            <IconFromValue iconValue={q.imageResource} />
                                        </ListItemIcon>
                                    )}
                                    <ListItemText primary={q.title} />
                                </ListItem>
                                <Divider component="li" />
                            </Fragment>
                        ))}
                        {isEmpty && !loading && (
                            <ListItem>
                                <ListItemText primary={formatMessage({ id: 'shared.empty' })} />
                            </ListItem>
                        )}
                    </List>
                </>
            )}
            {!selectedCategories.length && (
                <Grid container>
                    <div className="row">
                        {data?.map((q) => (
                            <div key={q.id} className="col-lg-4 col-sm-6 mb-4">
                                <Card className={card} onClick={categorySelected(q)}>
                                    <CardContent>
                                        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                                            <Typography gutterBottom>
                                                <IconFromValue className={icon} iconValue={q.imageResource} />
                                            </Typography>
                                            <Typography variant="body2" component="p">
                                                {q.title}
                                            </Typography>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </div>
                        ))}
                    </div>
                </Grid>
            )}
            <LamaLoader showLoader={loading} />
        </>
    );
};
