import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { format } from 'date-fns';
import { LineChart, Tooltip, XAxis, CartesianGrid, Line, ResponsiveContainer, YAxis, Legend } from 'recharts';
import { useTheme } from '@material-ui/core/styles';

import { useLamaQuery, GraphQlQueryType } from 'shared/graphql';

import { MONTHLY_STATISTICS } from 'features/statistics/queries';
import { StatisticResourceNames, StatisticsByDateItemViewModel, MonthlyStatisticsQuery } from 'features/statistics/model';

import { LamaLoader } from 'shared/components';

import { EmptyStatistcs } from './EmptyStatistics';

export const MonthlyStatisticsGraph: FC = () => {
    const { data, loading } = useLamaQuery<MonthlyStatisticsQuery>({
        modelName: StatisticResourceNames.statistic,
        queryType: GraphQlQueryType.customList,
        gqlQuery: MONTHLY_STATISTICS
    });
    const { formatMessage } = useIntl();
    const theme = useTheme();

    const xAxisLabel = (item: StatisticsByDateItemViewModel) => {
        return format(item.date, 'dd.MM.yyyy');
    };

    return (
        <>
            <LamaLoader showLoader={loading} />
            {data?.monthlyStatistics?.length ? (
                <ResponsiveContainer width="100%" height={400}>
                    <LineChart data={data?.monthlyStatistics}>
                        <XAxis dataKey={xAxisLabel} />
                        <CartesianGrid vertical={false} />
                        <YAxis allowDecimals={false} />
                        <Tooltip cursor={false} />
                        <Legend />
                        <Line
                            name={formatMessage({ id: 'statistic.numberOfViews' })}
                            type="monotone"
                            dataKey="numberOfViews"
                            stroke={theme.palette.success.main}
                            activeDot={{ r: 8 }}
                        />
                        <Line
                            name={formatMessage({ id: 'statistic.numberOfUniqueViews' })}
                            type="monotone"
                            dataKey="numberOfUniqueViews"
                            stroke={theme.palette.primary.main}
                            activeDot={{ r: 8 }}
                        />
                    </LineChart>
                </ResponsiveContainer>
            ) : (
                <EmptyStatistcs />
            )}
        </>
    );
};
