import classNames from 'classnames';
import React, { FC } from 'react';
import Icon from '@material-ui/core/Icon';
import { makeStyles, createStyles } from '@material-ui/core/styles';

interface IconFromValueProps {
    className?: string;
    iconValue: string;
    style?: React.CSSProperties
}

const useStyles = makeStyles(() =>
    createStyles({
        icon: {
            fontSize: '1.2rem'
        }
    })
);

const fontAwesomeIcons = 'fa';
const ionicons = 'ion';
const materialIcons = 'material-icons';
const materialFontIcons = 'zmdi';

export const IconFromValue: FC<IconFromValueProps> = props => {
    const { className, style } = props;
    let { iconValue = '' } = props;

    const classes = useStyles();

    if (iconValue.startsWith(materialIcons)) {
        iconValue = iconValue.replace(`${materialIcons} `, '');

        return <Icon className={classNames(classes.icon, className)} style={style}>{iconValue}</Icon>;
    }

    if (iconValue.startsWith(ionicons)) {
        return <i className={classNames(classes.icon, className, iconValue)} style={style}></i>
     }

    if (iconValue.startsWith(materialFontIcons)) {
       return <i className={classNames(classes.icon, className, iconValue)} style={style}></i>
    }

    if (iconValue.startsWith(fontAwesomeIcons)) {
        return <i className={classNames(classes.icon, fontAwesomeIcons, className, iconValue)} style={style}></i>
    }

    return null;
};
