import React from 'react';
import { FormattedMessage } from 'react-intl';
import { RcFormlyWrapper } from '@rc-formly/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

export class SubmitButtonWrapper extends RcFormlyWrapper {
    render() {
        const buttonLabel = this.to?.buttonLabel || 'shared.saveItem';

        return (
            <>
                <Box mb={3}>
                    <Grid container justify="flex-end">
                        <Button variant="contained" color="primary" type="submit">
                            <FormattedMessage id={buttonLabel} />
                        </Button>
                    </Grid>
                </Box>
                {this.props.children}
            </>
        );
    }
}
