import React, { FC } from 'react';
import { useSlate } from 'slate-react';
import Button from '@material-ui/core/Button';

import { isMarkActive, toggleMark } from './utilities';
import { IconFromValue } from 'shared/components';

interface MarkButtonProps {
    icon: string;
    format: string;
}

export const MarkButton: FC<MarkButtonProps> = (props) => {
    const { format, icon } = props;
    const editor = useSlate();

    const onButtonClick = (evt: React.MouseEvent) => {
        evt.preventDefault();
        toggleMark(editor, format);
    };

    if (isMarkActive(editor, format)) {
    }

    return (
        <Button onClick={onButtonClick}>
            <IconFromValue iconValue={`material-icons ${icon}`} />
        </Button>
    );
};
