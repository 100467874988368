import React, { createContext, FC } from "react";
import { useSelector, useDispatch } from "react-redux";

import { LamaTenantProviderContextState } from "./types";

import {
    getAccountSelector, getCurrentActiveTenantId, authenticationReducerActionCreators
} from "shared/authentication/reducers";

export const LamaTenantContext = createContext<LamaTenantProviderContextState>({
    changeActiveTenant: () => {}
});

export const LamaTenantProvider: FC = (props) => {
    const { children } = props;
    const dispatch = useDispatch();
    let currentAccount = useSelector(getAccountSelector);
    let currentActiveTenantId = useSelector(getCurrentActiveTenantId);

    currentActiveTenantId = currentActiveTenantId || currentAccount?.tenantId;

    const changeActiveTenant = (newTenantId: string) => {
        dispatch(authenticationReducerActionCreators.changeCurrentActiveTenantId(newTenantId));
    };

    const contextValue: LamaTenantProviderContextState = {
        currentActiveTenantId,
        changeActiveTenant
    };

    return (
        <LamaTenantContext.Provider value={contextValue}>
            { children }
        </LamaTenantContext.Provider>
    )
};
