import { useAuth } from 'shared/authentication/hooks';
import { useLamaTenantInterface } from "./types";
import { useHistory } from 'react-router-dom';
import { useContext } from 'react';

import { LamaTenantContext } from './LamaTenantProvider';
import { useModular } from 'shared/packages/react-modular';

const tenantsCreateClaim = 'tenants.list';

export const useLamaTenant: useLamaTenantInterface = () => {
    const { account: { tenantId = '' } = { }, isAuthorized } = useAuth();
    const { homeRoutePath } = useModular();
    const { changeActiveTenant: changeActiveTenantContext, currentActiveTenantId } = useContext(LamaTenantContext);
    const { push  } = useHistory();

    const canChangeActiveTenant = () => {
        return isAuthorized(tenantsCreateClaim);
    };

    const changeActiveTenant = (newTenantId: string) => {
        changeActiveTenantContext(newTenantId);

        push(homeRoutePath);
    }

    return {
        canChangeActiveTenant,
        changeActiveTenant,
        currentActiveTenantId,
        isSameActiveAndUserTenant: currentActiveTenantId === tenantId
    }
};
