import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import Box from '@material-ui/core/Box';
import Switch from '@material-ui/core/Switch';

import { SubscriptionDurationType } from 'features/contracts/models';

interface PackageSelectLeaseTimeProps {
    onPackageLeaseTimeChange?: (packageLeaseTimeType: SubscriptionDurationType) => void;
    value?: SubscriptionDurationType
}

export const PackageSelectLeaseTime: FC<PackageSelectLeaseTimeProps> = (props) => {
    const { onPackageLeaseTimeChange, value = SubscriptionDurationType.yearly } = props;

    const onSwitchChange = (e: React.ChangeEvent, checked: boolean) => {
        if (onPackageLeaseTimeChange) {
            onPackageLeaseTimeChange(checked ? SubscriptionDurationType.yearly : SubscriptionDurationType.monthly);
        }
    };

    const isSwitchChecked = value === SubscriptionDurationType.yearly;

    return (
        <Box display="flex" alignItems="center">
            <Box mr={2}>
                <div>
                    <FormattedMessage id="package.monthlyLease" />
                </div>
            </Box>
            <Switch checked={isSwitchChecked} color="primary" onChange={onSwitchChange} />
            <Box ml={2}>
                <div>
                    <FormattedMessage id="package.yearlyLease" />
                </div>
            </Box>
        </Box>
    );
};
