import React, { FC } from 'react';

import { LamaGridCellProps } from 'shared/components/grid/types';
import { EmptyCell } from './EmptyCell';

import { UtilityHelper } from 'shared/utilities';

export const PercentageCellType: FC<LamaGridCellProps> = props => {
    const { cellValue } = props;

    if (!UtilityHelper.isNumber(cellValue)) {
        return <EmptyCell />;
    }

    return <span>{cellValue}%</span>;
};
