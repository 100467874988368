import React from 'react';
import { RcFormlyWrapper } from '@rc-formly/core';
import { LamaFormlyFieldConfig } from 'shared/components/forms/models';

export class LamaDivWrapper extends RcFormlyWrapper {

    render() {
        const fieldGroupClassName = (this.props.parentField as LamaFormlyFieldConfig)?.fieldGroupClassName;

        return (
            <div className={fieldGroupClassName}>
                {this.props.children}
            </div>
        )
    }
}
