import React, { createContext, FC, useState } from 'react';

import { LamaDialogContextState, LamaDialogOptions, LamaDialogButton, LamaDialogButtonType } from './types';

import { DialogContainer } from '../components';

import { UtilityHelper } from 'shared/utilities';

const lamaDialogOkButton: LamaDialogButton = {
    buttonType: LamaDialogButtonType.ok,
    textTranslate: 'shared.ok'
};
const lamaDialogCancelButton: LamaDialogButton = {
    buttonType: LamaDialogButtonType.cancel,
    textTranslate: 'shared.cancel'
};

export const LamaDialogContext = createContext<LamaDialogContextState>({
    dialogs: [],
    defaultAutoHideAfterOk: true,
    addDialog: () => {
        return '';
    },
    closeDialog: () => {}
});

interface LamaDialogProviderProps {
    defaultAutoHideAfterOk?: boolean;
}

export const LamaDialogProvider: FC<LamaDialogProviderProps> = (props) => {
    let { defaultAutoHideAfterOk = true } = props;

    const [dialogs, setDialogs] = useState<LamaDialogOptions[]>([]);

    const addDialog = (dialog: LamaDialogOptions) => {
        if (UtilityHelper.isEmpty(dialog.id)) {
            dialog.id = UtilityHelper.createGuid();
        }

        setDialogs([...dialogs, dialog]);

        return dialog.id!;
    };

    const closeDialog = () => {
        setDialogs([]);
    };

    const contextValue: LamaDialogContextState = {
        dialogs,
        defaultAutoHideAfterOk,
        addDialog,
        closeDialog
    };

    return (
        <LamaDialogContext.Provider value={contextValue}>
            <DialogContainer />
            {props.children}
        </LamaDialogContext.Provider>
    );
};
