import React, { FC } from "react";

import { LamaTab } from "shared/components/tabs";

import { PackageCreateOrUpdateBase, PackageFeatureModuleCreateOrUpdateBase } from "./components";
import { LamaTabsCreateUpdateBase } from "features/base/components";

const tabs: LamaTab[] = [
    {
        component: PackageCreateOrUpdateBase,
        name: 'basic',
        labelTranslate: 'package.tabs.basic'
    },
    {
        component: PackageFeatureModuleCreateOrUpdateBase,
        name: 'featureModules',
        labelTranslate: 'package.tabs.featureModules',
        link: 'feature-modules',
    }
]

export const PackageCreateOrUpdate: FC = () => {
    return (
        <LamaTabsCreateUpdateBase tabs={tabs} />
    );
}
