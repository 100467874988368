import React from 'react';
import { RcFormlyField } from '@rc-formly/core';

import { Media, MediaEntitiesInlineChooserTypeTemplateOptions } from 'features/media/models';

import { MediaEntitiesInlineChooser } from './MediaEntitiesInlineChooser';

export class MediaEntitiesInlineChooserType extends RcFormlyField<MediaEntitiesInlineChooserTypeTemplateOptions> {
    mediaChanged = (media: Media[]) => {
        this.handleChange(media ? media : []);
    };

    render() {
        const { gridSize, mediaIdPropertyName, mediaPropertyName, resourceIdPropertyName } = this.to || {};
        const value = this.getFieldValue();

        return (
            <MediaEntitiesInlineChooser
                entityMedia={value}
                mediaIdPropertyName={mediaIdPropertyName}
                mediaPropertyName={mediaPropertyName}
                resourceIdPropertyName={resourceIdPropertyName!}
                gridSize={gridSize}
                onSelectedItems={this.mediaChanged}
                useOrder={true}
            />
        );
    }
}
