import { BaseEntity } from 'shared/types';
import { LocationViewModel } from 'features/locations/models';

export enum CompanyActivityType {
    hasStores = 'HASSTORES',
    servicesOnly = 'SERVICESONLY',
    webOnly = 'WEBONLY'
}

export interface CompanyModel extends BaseEntity {
    displayTitle?: string;
    name: string;
    title: string;
    shortTitle?: string;
    summary?: string;
    description?: string;

    bankAccountNumber?: string;
    bic?: string;
    registrationNumber: string;
    taxNumber: string;
    validForTax: boolean;

    email: string;
    phone: string;
    website: string;

    facebookUrl?: string;
    linkedInUrl?: string;
    youTubeUrl?: string;

    supportsPickup?: boolean;
    supportsShipment?: boolean;

    supportsCashPayment?: boolean;
    supportsCardPayment?: boolean;
    supportsPaymentAfterPickup?: boolean;
    supportsPrePaymentUpn?: boolean;

    activityType: CompanyActivityType;

    logoId?: string;

    address: LocationViewModel;
}
