import React, { FC } from "react";

import { LamaGridCellProps } from "shared/components/grid/types";
import { useLamaGridCreator } from "shared/components/grid/hooks";

import { EmptyCell, DefaultCellType } from "./cellTypes";

import { UtilityHelper } from "shared/utilities";

export const LamaGridCellRenderer: FC<LamaGridCellProps> = (props) => {
    const { column, cellValue, gridName, row, rowIndex } = props;
    const { component: Component, type } = column;
    const { getGridCellType } = useLamaGridCreator();

    if (Component) {
        return (
            <Component cellValue={cellValue} column={column} gridName={gridName} row={row} rowIndex={rowIndex} />
        );
    }

    if (type) {
        const cellType = getGridCellType(type);

        if (cellType) {
            const { component: CellTypeComponent } = cellType;

            return (
                <CellTypeComponent cellValue={cellValue} column={column} gridName={gridName} row={row} rowIndex={rowIndex} />
            );
        }
    }

    const isEmpty = UtilityHelper.isEmpty(cellValue);

    if (isEmpty) {
        return <EmptyCell />;
    }

    return <DefaultCellType {...props} />
};
