import React, { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import Collapse from '@material-ui/core/Collapse';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import List from '@material-ui/core/List';
import Link from '@material-ui/core/Link';

import { ModularEntryRoute } from 'shared/packages/react-modular';

import { UtilityHelper } from 'shared/utilities';

import { RouteDefaultData } from 'features/base/models';
import { IconFromValue } from 'shared/components';

interface NavMenuItemProps {
    menuEntry: ModularEntryRoute<RouteDefaultData>;
}

interface NavListItemProps extends NavMenuItemProps {
    isOpen?: boolean;
    onToggle?: (isToggled: boolean, menuEntry: ModularEntryRoute) => void;
}

const NavListItem: FC<NavListItemProps> = props => {
    const {
        isOpen,
        onToggle,
        menuEntry: { data, icon, title, titleTranslate, routeProps, routes }
    } = props;
    const hasAnySubRoutes = UtilityHelper.isNotEmpty(routes);
    const hasComponent = UtilityHelper.isNotEmpty(routeProps?.component);
    const path = routeProps ? (routeProps.path! as string) : '';

    if (data?.hideOnSidebar) {
        return null;
    }

    const onListItemClick = () => {
        if (onToggle) {
            onToggle(!isOpen, props.menuEntry);
        }
    };

    const renderInsideLink = () => {
        return (
            <>
                {icon && (
                    <ListItemIcon className="menu-icon">
                        <IconFromValue iconValue={icon} />
                    </ListItemIcon>
                )}
                <span className="menu">
                    {title}
                    {titleTranslate && <FormattedMessage id={titleTranslate} />}
                </span>
            </>
        );
    };

    return (
        <ListItem button component="li" className={classNames({ 'list-item': hasAnySubRoutes }, { 'item-active': isOpen })} onClick={onListItemClick}>
            {path && hasComponent ? (
                <NavLink activeClassName="item-active" to={path}>
                    {renderInsideLink()}
                </NavLink>
            ) : (
                <Link>{renderInsideLink()}</Link>
            )}
        </ListItem>
    );
};

export const NavMenuItem: FC<NavMenuItemProps> = props => {
    const { menuEntry } = props;
    const [isSubmenuOpen, setSubmenuOpen] = useState(false);

    if (!menuEntry || menuEntry.data?.hideOnSidebar) {
        return null;
    }

    if (UtilityHelper.isNotEmpty(menuEntry.routes)) {
        return (
            <>
                <NavListItem menuEntry={menuEntry} isOpen={isSubmenuOpen} onToggle={setSubmenuOpen} />
                <Collapse in={isSubmenuOpen} timeout="auto" className="sub-menu">
                    <List className="list-unstyled py-0">
                        {menuEntry.routes!.map((menuEntryRoute, key) => (
                            <NavListItem key={key} menuEntry={menuEntryRoute} />
                        ))}
                    </List>
                </Collapse>
            </>
        );
    }

    return <NavListItem menuEntry={menuEntry} />;
};
