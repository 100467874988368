import { useLocation } from 'react-router-dom';
import { stringify, parse } from 'query-string';

import { useQueryParamsInterface, AddQueryStringOptions } from './types';
import { KeyValueObject } from 'shared/types';

export const useQueryParams: useQueryParamsInterface = () => {
    const { pathname, search } = useLocation();

    const addQueryStrings = (queryStrings: KeyValueObject, options?: AddQueryStringOptions) => {
        let queryString = stringify(queryStrings, { arrayFormat: 'comma', skipNull: true });

        queryString = queryString ? `?${queryString}` : '';

        return pathname + queryString;
    };

    return {
        addQueryStrings,
        queryStrings: parse(search, { arrayFormat: 'comma', parseBooleans: true, parseNumbers: true }) as any || {}
    };
}
