import React, { FC, useState, useEffect } from 'react';
import Select, { ValueType, OptionsType } from 'react-select';

import { InputBaseComponentProps } from '@material-ui/core/InputBase';


import { LamaServerOptions, useLamaClient, GRAPHQL_DEFAULT_PAGE_SIZE } from 'shared/graphql';
import { useDeepEffect } from 'shared/packages/use-deep-effect';

import { Logger, UtilityHelper } from 'shared/utilities';
import { SelectOption, SimpleEntity } from 'shared/types';


interface FilterOption<TOption = any> {
    label: string;
    value: string;
    data: TOption;
}

export interface ReactSelectWrappedProps extends InputBaseComponentProps {
    bindLabel?: string;
    bindValue?: string;
    defaultValuePropertyName?: string;
    disabled?: boolean;
    multiple?: boolean;
    serverOptions?: LamaServerOptions;
}


export const ReactSelectWrapped: FC<ReactSelectWrappedProps> = props => {
    const {
        bindLabel, bindValue, className, defaultValue, defaultValuePropertyName,
        disabled, name, multiple = false,
        onChange: onInputChange, serverOptions, placeholder,
        value
    } = props;
    const [, setLoading] = useState(false);
    const [selectedOption, setSelectedOption] = useState<ValueType<any>>(null);
    const [options, setOptions] = useState<OptionsType<any>>([]);
    const lamaClient = useLamaClient();

    const setSelectOptionFromValueIfDifferent = (optionsToCheck?: OptionsType<any>) => {
        optionsToCheck = optionsToCheck || options;

        if (UtilityHelper.isEmpty(value)) {
            if (UtilityHelper.isNotEmpty(defaultValue) && defaultValuePropertyName) {
                const newSelectedOption = optionsToCheck.find(q => UtilityHelper.getDotNotationPropertyValue(q, defaultValuePropertyName) === defaultValue);

                if (newSelectedOption) {
                    onSelectChange(newSelectedOption);

                    return;
                }
            }

            setSelectedOption(null)
        }
        else {
            const newSelectedOption = optionsToCheck.find(q => UtilityHelper.getDotNotationPropertyValue(q, bindValue!) === value);

            setSelectedOption(newSelectedOption);
        }
    };

    const loadOptions = async () => {
        if (serverOptions && bindLabel && bindValue) {
            try {
                setLoading(true);

                const { loadAdditionalProperties = [], modelName, queryType, variables } = serverOptions;
                const dotNotationProperties = [bindLabel, bindValue, ...loadAdditionalProperties];

                if (defaultValuePropertyName) {
                    dotNotationProperties.push(defaultValuePropertyName);
                }

                const result = await lamaClient.queryModel<any[]>({
                    modelName,
                    variables,
                    queryType,
                    dotNotationProperties
                });

                if (result.data) {
                    setOptions(result.data || []);
                    setSelectOptionFromValueIfDifferent(result.data);
                }
            }
            catch (e) {
                Logger.log(e);
            }
            finally {
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        const foundValue = options.find(q => UtilityHelper.getDotNotationPropertyValue(q, bindValue!) === value);

        if (!foundValue && (value || defaultValue)) {
            loadOptions();
        }
        else {
            setSelectOptionFromValueIfDifferent();
        }
    }, [value]);

    useDeepEffect(() => {
        loadOptions();
    }, [serverOptions, bindLabel, bindValue]);

    const onSelectChange = (model: ValueType<SelectOption>) => {
        if (onInputChange) {
            setSelectedOption(model);

            onInputChange({
                currentTarget: {
                    value: model,
                    name
                },
                target: {
                    value: model,
                    name
                }
            } as any);
        }
    };

    const onSelectInputChange = () => {
        if (options.length > GRAPHQL_DEFAULT_PAGE_SIZE) {
            // TODO: create graphql filter for this usercase.
        }
    };

    const filterOptions = (option: FilterOption<SimpleEntity>, rawInput: string) => {
        if (rawInput) {
            let label: string;

            if (bindLabel) {
                label = UtilityHelper.getDotNotationPropertyValue(option.data, bindLabel);
            }
            else {
                label = option.label;
            }

            if (label) {
                return label.toLowerCase().includes(rawInput.toLowerCase()) || false;
            }
        }

        return true;
    };

    const formatOptionLabel = (option: any) => {
        if (bindLabel) {
            return UtilityHelper.getDotNotationPropertyValue(option, bindLabel);
        }

        return option;
    };

    const getOptionValue = (option: any) => {
        if (bindValue) {
            return UtilityHelper.getDotNotationPropertyValue(option, bindValue);
        }

        return option;
    };

    return (
        <Select
            //components={{ Option, ClearIndicator: ClearIcon,  }}
            isDisabled={disabled}
            filterOption={filterOptions}
            isMulti={multiple}
            className={className}
            placeholder={placeholder}
            onChange={onSelectChange}
            onInputChange={onSelectInputChange}
            formatOptionLabel={formatOptionLabel}
            getOptionValue={getOptionValue}
            options={options}
            value={selectedOption}
        />
    );
};
