import { stringify } from 'query-string';

import { UtilityHelper } from 'shared/utilities/UtilityHelper';
import { KeyValueObject } from 'shared/types';

export class UriHelper {
    static addQueryParams(baseUrl: string, queryParams: KeyValueObject) {
        let queryString = stringify(queryParams, { arrayFormat: 'comma', skipNull: true });

        queryString = queryString ? `?${queryString}` : '';

        return baseUrl + queryString;
    }

    static combineUrl(baseUrl: string, ...paths: string[]) {
        let combinedUrl = baseUrl;

        if (UtilityHelper.isNotEmpty(paths)) {
            paths.forEach((path) => {
                if (UtilityHelper.isNotEmpty(path) && path !== '/') {
                    if (!combinedUrl.endsWith('/')) {
                        combinedUrl += '/';
                    }

                    if (path.startsWith('/')) {
                        path = path.slice(1);
                    }

                    combinedUrl += path;
                }
            })
        }

        return combinedUrl;
    }

    static isSameUrl(firstUrl: string, secondUrl: string) {
        return firstUrl === secondUrl;
    }

    static safeUrl(title: string) {
        if (!title) {
            return '';
        }

        title = title.trim();

        let maxlen = 80,
            len = title.length,
            prevdash = false,
            sb = new Array<string>(len),
            c: string,
            char: number;

        for (let i = 0; i < len; i++) {
            c = title[i];
            char = c.charCodeAt(0);

            if ((c >= 'a' && c <= 'z') || (c >= '0' && c <= '9')) {
                sb.push(c);
                prevdash = false;
            }
            else if (c >= 'A' && c <= 'Z') {
                // tricky way to convert to lowercase
                sb.push(String.fromCharCode(char | 32));
                prevdash = false;
            }
            else if (c === ' ' || c === ',' || c === '.' || c === '/' ||
                c === '\\' || c === '-' || c === '_' || c === '=') {

                if (!prevdash && sb.length > 0) {
                    sb.push('-');
                    prevdash = true;
                }
            }
            else if (char >= 128) {
                let prevlen = sb.length;

                sb.push(this.remapInternationalCharToAscii(c));

                if (prevlen !== sb.length) {
                    prevdash = false;
                }
            }

            if (i === maxlen) {
                break;
            }
        }

        if (prevdash) {
            return sb.join('').substr(0, sb.length - 1);
        }
        else {
            return sb.join('');
        }
    }

    private static remapInternationalCharToAscii(c: string) {
        let s = c.toLowerCase();

        if ('àåáâäãåą'.indexOf(s) !== -1) {
            return 'a';
        }
        else if ('èéêëę'.indexOf(s) !== -1) {
            return 'e';
        }
        else if ('ìíîïı'.indexOf(s) !== -1) {
            return 'i';
        }
        else if ('òóôõöøőð'.indexOf(s) !== -1) {
            return 'o';
        }
        else if ('ùúûüŭů'.indexOf(s) !== -1) {
            return 'u';
        }
        else if ('çćčĉ'.indexOf(s) !== -1) {
            return 'c';
        }
        else if ('żźž'.indexOf(s) !== -1) {
            return 'z';
        }
        else if ('śşšŝ'.indexOf(s) !== -1) {
            return 's';
        }
        else if ('ñń'.indexOf(s) !== -1) {
            return 'n';
        }
        else if ('ýÿ'.indexOf(s) !== -1) {
            return 'y';
        }
        else if ('ğĝ'.indexOf(s) !== -1) {
            return 'g';
        }
        else if (c === 'ř') {
            return 'r';
        }
        else if (c === 'ł') {
            return 'l';
        }
        else if (c === 'đ') {
            return 'd';
        }
        else if (c === 'ß') {
            return 'ss';
        }
        else if (c === 'Þ') {
            return 'th';
        }
        else if (c === 'ĥ') {
            return 'h';
        }
        else if (c === 'ĵ') {
            return 'j';
        }
        else {
            return '';
        }
    }
}
