import React, { FC } from 'react';

import { LamaGridFilterProps } from 'shared/components/grid/types';
import { GraphQlQueryEqualityOperator, GraphQlQueryObjectParameter } from 'shared/graphql';

import { LamaDateRangePicker } from 'shared/components/forms';

import { UtilityHelper } from 'shared/utilities';

export const DateTimeFilterType: FC<LamaGridFilterProps> = props => {
    const { currentChanges, onFilterChange } = props;

    return <LamaDateRangePicker variant="inline" autoOk  />;
}
