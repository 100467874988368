import React, { createContext, FC, useState } from "react";

import { RapidModelContextState, RapidModelTitleFunc } from "./types";

export const RapidModelContext = createContext<RapidModelContextState>({
    setCurrentModel: () => { },
    setModelTitleFunc: () => { }
});

export const RapidModelProvider: FC = (props) => {
    const { children } = props;
    const [currentModel, setCurrentModel] = useState();
    const [modelTitleFunc, setModelTitleFunc] = useState<RapidModelTitleFunc>();

    const contextValue: RapidModelContextState = {
        currentModel,
        setCurrentModel,
        modelTitleFunc,
        setModelTitleFunc
    };

    return (
        <RapidModelContext.Provider value={contextValue}>
            { children }
        </RapidModelContext.Provider>
    )
}
