import { types } from 'typed-graphqlify';
import { GraphQlQueryType, LamaGraphQlQueryHelper } from 'shared/graphql';

import { VenueResourceNames, Venue } from 'features/venues/models';
import { BaseEntity } from 'shared/types';

import { locationQueryObject } from 'features/locations/queries';

export const { gqlQuery: singleVenueQuery} = LamaGraphQlQueryHelper.createQueryFromDotNotation<Venue, BaseEntity>({
    modelName: VenueResourceNames.venue,
    queryType: GraphQlQueryType.single,
    queryObject: {
        id: types.string,
        description: types.string,
        displayImageId: types.string,
        email: types.string,
        isMainWarehouse: types.boolean,
        place: locationQueryObject,
        phoneNumber: types.string,
        status: types.custom(),
        title: types.string,
        website: types.string,
    },
    variables: {
        id: types.string
    }
});
