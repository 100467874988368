import { types } from 'typed-graphqlify';
import { GraphQlQueryType, LamaGraphQlQueryHelper } from 'shared/graphql';

import { PackageResourceNames, PackageViewModel } from 'features/packs/models';
import { priceQueryObject } from 'features/products/queries';
import { BaseEntity } from 'shared/types';

export const packageQueryObject: PackageViewModel = {
    id: types.string,
    autoRenew: types.boolean,
    description: types.string,
    systemName: types.string,
    title: types.string,
    trialPeriodInDays: types.number,
    type: types.custom(),
    currentPrice: priceQueryObject
};

export const { gqlQuery: singlePackageQuery} = LamaGraphQlQueryHelper.createQueryFromDotNotation<PackageViewModel, BaseEntity>({
    modelName: PackageResourceNames.package as string,
    queryType: GraphQlQueryType.single,
    queryObject: packageQueryObject,
    variables: {
        id: types.string
    }
});
