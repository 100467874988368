import { BaseEntity, ContextualColor } from 'shared/types';
import { Media } from 'features/media/models';
import { Venue } from 'features/venues/models';
import { Price } from './price';
import { LamaGridTagColumnAdditionalData, LamaGridEnumColumnAdditionalData } from 'shared/components/grid';

export enum ProductNonAdminStatus {
    acceptsPreorders = 'ACCEPTSPREORDERS',
    notActive = 'NOTACTIVE',
    notInStock = 'NOTINSTOCK',
    selling = 'SELLING',
}

export enum ProductStatus {
    acceptsPreorders = 'ACCEPTSPREORDERS',
    archived = 'ARCHIVED',
    deleted = 'DELETED',
    none = 'NONE',
    notActive = 'NOTACTIVE',
    notInStock = 'NOTINSTOCK',
    removedViolationOfTerms = 'REMOVEDVIOLATIONOFTERMS',
    underReview = 'UNDERREVIEW',
    selling = 'SELLING',
    violationOfTerms = 'VIOLATIONOFTERMS'
}

export const productStatusGridAdditionalData: LamaGridEnumColumnAdditionalData = {
    enumType: ProductStatus,
    translationPrefix: 'product.productStatus'
};

export const productStatusGridColorAdditionalData: LamaGridTagColumnAdditionalData = {
    ...productStatusGridAdditionalData,
    colorForEnums: new Map<any, ContextualColor>(
        [
            [ProductStatus.selling, 'success'],
            [ProductStatus.acceptsPreorders, 'success'],
            [ProductStatus.notInStock, 'warning'],
            [ProductStatus.underReview, 'warning'],
            [ProductStatus.notActive, 'error'],
            [ProductStatus.removedViolationOfTerms, 'error']
        ]
    )
};

export enum ProductType {
    new = 'NEW',
    refurbished = 'REFURBISHED',
    used = 'USED'
}

export interface ProductMediaViewModel {
    order: number;

    ownerMediaId: string;
    ownerProductId: string;

    ownerMedia?: Media;
}

export interface ProductVenueViewModel {
    ownerProductId: string;
    ownerVenueId: string;

    ownerVenue?: Venue;
}

export interface ProductViewModel extends BaseEntity {
    description: string;
    summary?: string;
    title: string;

    url: string;

    sKU: string;
    eanCode: string;

    status: ProductStatus;
    type: ProductType;

    currentPriceId?: string;
    ownerCategoryId: string;

    currentPrice?: Price;

    files?: ProductMediaViewModel[];
    onVenues?: ProductVenueViewModel[];
}

export interface ProductCreateUpdateViewModel {
    hasDiscount?: boolean;
    entity: ProductViewModel;
}
