import { RouteDefaultData, createOrEditViewTypes } from 'features/base/models';
import { LamaCrudViewType } from 'shared/types';
import { ModularEntryRoute } from 'shared/packages/react-modular';

import { LanguageHelper, UriHelper } from 'shared/utilities';

export class CrudHelper {
    static getClaimFromModel(modelName: string, crudViewType: LamaCrudViewType) {
        const pluralModelName = LanguageHelper.pluralize(modelName);

        return `${pluralModelName}.${crudViewType}`;
    }

    static getClaimsFromRoute(route: ModularEntryRoute<RouteDefaultData>) {
        if (route.data?.crudViewType && route.data.primaryModelName) {
            const pluralModelName = LanguageHelper.pluralize(route.data.primaryModelName);
            const crudTypes: LamaCrudViewType[] = [];

            if (route.data.crudViewType === LamaCrudViewType.createOrEdit) {
                crudTypes.push(LamaCrudViewType.create, LamaCrudViewType.update);
            } else {
                crudTypes.push(route.data.crudViewType);
            }

            return crudTypes.map(q => `${pluralModelName}.${q}`);
        }

        return undefined;
    }

    static hasUrlCrudType(url: string, crudViewType: LamaCrudViewType) {
        if (url) {
            return url.includes(`/${crudViewType}`);
        }

        return false;
    }

    static isEditRoute(route?: ModularEntryRoute<RouteDefaultData>) {
        return route?.data?.isEdit
            || route?.data?.crudViewType === LamaCrudViewType.update
            || route?.data?.crudViewType === LamaCrudViewType.me;
    }

    static convertUrlToEditCrudType(currentUrl: string, modelId: string) {
        const simpleReplaceCrudTypes = [LamaCrudViewType.create, LamaCrudViewType.list];

        for (let crudViewType of simpleReplaceCrudTypes) {
            const crudViewTypePrefix = `/${crudViewType}`;

            if (currentUrl.endsWith(crudViewTypePrefix) || currentUrl.includes(`${crudViewType}`)) {
                let splittedCurrentUrl = currentUrl.split('/').filter(q => q);
                const crudViewIndex = splittedCurrentUrl.indexOf(crudViewType);

                if (crudViewIndex !== -1) {
                    splittedCurrentUrl = splittedCurrentUrl.slice(0, crudViewIndex);
                }
                else {
                    splittedCurrentUrl = splittedCurrentUrl.filter(q => q && q !== crudViewType);
                }

                splittedCurrentUrl.push(`${modelId}/${LamaCrudViewType.update}`);

                return `/${splittedCurrentUrl.join('/')}`;
            }
        }

        return currentUrl;
    }

    static convertUrlToListCrudType(currentUrl: string) {
        if (!currentUrl || currentUrl.includes(`/${LamaCrudViewType.list}`)) {
            return currentUrl;
        }

        for (let crudViewType of createOrEditViewTypes) {
            const crudViewTypePrefix = `/${crudViewType}`;

            if (currentUrl.endsWith(crudViewTypePrefix)  || currentUrl.includes(`${crudViewTypePrefix}/`)) {
                let splittedUrl = currentUrl.split('/');

                if (crudViewType === LamaCrudViewType.create) {
                    const crudViewIndex = splittedUrl.indexOf(crudViewType);

                    splittedUrl = splittedUrl.slice(0, crudViewIndex);
                    splittedUrl.push(LamaCrudViewType.list);

                    return splittedUrl.join('/');
                }
                else {
                    let numberOfItemsToRemove = 1;

                    if (crudViewType === LamaCrudViewType.update) {
                        numberOfItemsToRemove = 2;
                    }

                    return UriHelper.combineUrl('/', ...splittedUrl.slice(0, -numberOfItemsToRemove), LamaCrudViewType.list);
                }
            }
        }

        return currentUrl;
    }
}
