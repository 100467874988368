import { getYear } from 'date-fns';

import { images } from 'assets';

const AppConfig = {
    appLogo: images.logo,          // App Logo
    brandName: 'Reactify',                                    // Brand Name
    navCollapsed: false,                                      // Sidebar collapse
    darkMode: false,                                          // Dark Mode
    boxLayout: false,                                         // Box Layout
    rtlLayout: false,                                         // RTL Layout
    miniSidebar: false,                                       // Mini Sidebar
    isDarkSidenav: true,                                   // Set true to dark sidebar
    enableThemeOptions: true,                              // Enable Theme Options
    locale: {
       languageId: 'english',
       locale: 'en',
       name: 'English',
       icon: 'en',
    },
    copyRightText: `Prodajko © ${getYear(new Date())} made with <3 by FS računalniške storitve d.o.o.`,      // Copy Right Text
    // light theme colors
    themeColors: {
       'primary': '#5d92f4',
       'secondary': '#677080',
       'success': '#00D014',
       'danger': '#FF3739',
       'warning': '#FFB70F',
       'info': '#00D0BD',
       'dark': '#464D69',
       'default': '#FAFAFA',
       'greyLighten': '#A5A7B2',
       'grey': '#677080',
       'white': '#FFFFFF',
       'purple': '#896BD6',
       'yellow': '#D46B08'
    },
    // dark theme colors
    darkThemeColors: {
       darkBgColor: '#424242'
    }
 }

 export default AppConfig;
