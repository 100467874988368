import { ContextualColor } from 'shared/types';
import { LamaGridEnumColumnAdditionalData, LamaGridTagColumnAdditionalData } from 'shared/components/grid/types';

export enum ContentStatus {
    active = 'ACTIVE',
    draft = 'DRAFT',
    inactive = 'INACTIVE'
}

export const contentStatusGridAdditionalData: LamaGridEnumColumnAdditionalData = {
    enumType: ContentStatus,
    translationPrefix: 'base.contentStatus'
};

export const contentStatusGridColorAdditionalData: LamaGridTagColumnAdditionalData = {
    ...contentStatusGridAdditionalData,
    colorForEnums: new Map<any, ContextualColor>(
        [
            [ContentStatus.active, 'success'],
            [ContentStatus.draft, 'warning'],
            [ContentStatus.inactive, 'error']
        ]
    )
};
