import classNames from 'classnames';
import React, { useState, useEffect, FC } from 'react';

import { KeyboardDatePicker, DatePickerProps } from '@material-ui/pickers';
import { useUtils } from '@material-ui/pickers';
import { createStyles, fade, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => {
    const focusedRangeColor = fade(theme.palette.primary.main, 0.3);
    const focusedRangeGradient = `linear-gradient(to right, ${focusedRangeColor}, ${focusedRangeColor})`;
    const transparentRangeGradient = `linear-gradient(to right, rgba(0,0,0,0.0), rgba(0,0,0,0.0))`;

    return createStyles({
        dateRangePickerDialog: {
            '& .MuiPickersCalendar-transitionContainer': {
                minHeight: 218,
                marginTop: 10
            }
        },
        day: {
            width: 40,
            height: 36,
            fontSize: theme.typography.caption.fontSize,
            margin: 0,
            color: theme.palette.text.primary,
            fontWeight: theme.typography.fontWeightMedium,
            padding: 0,
            transition: 'none',
            '&::after': {
                borderRadius: '100%',
                bottom: 0,
                boxSizing: 'border-box',
                content: '""',
                height: 36,
                width: 36,
                left: 0,
                margin: 'auto',
                position: 'absolute',
                right: 0,
                top: 0,
                transform: 'scale(0)',
                zIndex: 2
            },
            '&:hover': {
                backgroundColor: 'transparent',
                color: theme.palette.text.primary,
                '&::after': {
                    backgroundColor: theme.palette.background.paper,
                    border: `2px solid ${theme.palette.primary.main}`,
                    bottom: -2,
                    left: -2,
                    height: 36,
                    width: 36,
                    right: -2,
                    top: -2,
                    boxSizing: 'content-box',
                    transform: 'scale(1)'
                }
            },
            '& > .MuiIconButton-label': {
                zIndex: 3
            }
        },
        hidden: {
            opacity: 0,
            pointerEvents: 'none'
        },
        current: {
            color: theme.palette.primary.main,
            fontWeight: 600
        },
        focusedRange: {
            color: theme.palette.primary.contrastText,
            background: `${focusedRangeGradient} no-repeat 0/20px 40px, ${focusedRangeGradient} no-repeat 20px 0/20px 40px`,
            fontWeight: theme.typography.fontWeightMedium,
            width: 40,
            marginRight: 0,
            marginLeft: 0,
            borderRadius: 0
        },
        dayDisabled: {
            pointerEvents: 'none',
            color: theme.palette.text.hint
        },
        beginCap: {
            '&::after': {
                transform: 'scale(1)',
                backgroundColor: theme.palette.primary.main
            }
        },
        endCap: {
            '&::after': {
                transform: 'scale(1)',
                backgroundColor: theme.palette.primary.main
            }
        },
        focusedFirst: {
            background: `${transparentRangeGradient} no-repeat 0/20px 40px,${focusedRangeGradient} no-repeat 20px 0/20px 40px`
        },
        focusedLast: {
            background: `${focusedRangeGradient} no-repeat 0/20px 40px,${transparentRangeGradient} no-repeat 20px 0/20px 40px`
        }
    });
});

type LamaDateRangePickerChangeEvent = { begin: Date, end?: Date };

interface LamaDateRangePickerProps extends Omit<DatePickerProps, 'value' | 'onChange' | 'labelFunc'> {
    value?: Date[];
    onChange?: (changeEvent: LamaDateRangePickerChangeEvent) => void;
    labelFunc?: (dateRange: Date[], invalidLabel: string) => string;
}

export const LamaDateRangePicker: FC<LamaDateRangePickerProps> = (props) => {
    const {
        value, onChange, labelFunc, format = 'dd.MM.yyyy', emptyLabel, autoOk,
        onOpen, onClose, open: openForward, ...otherProps
    } = props;

    const [begin, setBegin] = useState(value?.[0] || null);
    const [end, setEnd] = useState(value?.[1] || null);
    const [prevBegin, setPrevBegin] = useState<Date | null>(null);
    const [prevEnd, setPrevEnd] = useState<Date | null>(null);
    const [hasClicked, setHasClicked] = useState(false);

    const [hover, setHover] = useState<Date | null>(null);
    const [accepted, setAccepted] = useState(false);
    const utils = useUtils();

    const classes = useStyles();

    const min = [begin!, end || hover!].reduce((a, b) => a < b ? a : b);
    const max = [begin!, end || hover!].reduce((a, b) => a > b ? a : b);

    const [open, setOpen] = useState(false);

    const isOpen = openForward !== undefined ? openForward : open;

    useEffect(() => {
        //Only way to get to this state is is openForward is used
        if (isOpen && accepted && !prevBegin && !prevEnd) {
            setAccepted(false);
            setPrevBegin(begin);
            setPrevEnd(end);
            return;
        }
        //Closed without accepting, reset to prev state, don't find onChange
        if (!isOpen && !accepted) {
            setBegin(prevBegin);
            setEnd(prevEnd);
            setHover(null);
            setHasClicked(false);
        }
        //Auto ok and hasn't been accepted, but has all the items set, accept and close.
        //This will also triger the on change event by setting isOpen to false
        if (isOpen && autoOk && !accepted && begin && end && hasClicked) {
            setAccepted(true);
            onClose ? onClose() : setOpen(false);
        }
        if (accepted && begin && end && !isOpen && hasClicked) {
            setHasClicked(false);

            if (onChange) {
                onChange({ begin, end });
            }

            onClose ? onClose() : setOpen(false);
        }
    }, [begin, end, autoOk, accepted, isOpen, prevBegin, hasClicked, prevEnd]);

    const renderDay = (day: Date | null, selectedDate: Date | null, dayInCurrentMonth: boolean, dayComponent: JSX.Element) => {
        return React.cloneElement(dayComponent, {
            onClick: (e: React.MouseEvent) => {
                setHasClicked(true);
                e.stopPropagation();
                if (!begin) setBegin(day);
                else if (!end) {
                    if (utils.isBeforeDay(day, begin)) {
                        setEnd(begin);
                        setBegin(day);
                    } else {
                        setEnd(day);
                    }
                    if (autoOk) {
                        setPrevBegin(null);
                        setPrevEnd(null);
                    }
                } else {
                    setBegin(day);
                    setEnd(null);
                }
            },
            onMouseEnter: () => requestAnimationFrame(() => setHover(day)),
            onFocus: () => requestAnimationFrame(() => setHover(day)),
            className: classNames(classes.day, {
                [classes.hidden]: dayComponent.props.hidden,
                [classes.current]: dayComponent.props.current,
                [classes.dayDisabled]: dayComponent.props.disabled,
                [classes.focusedRange]:
                    (utils.isAfterDay(day, min) && utils.isBeforeDay(day, max)) ||
                    (utils.isSameDay(day, min) && !utils.isSameDay(day, max)) ||
                    (utils.isSameDay(day, max) && !utils.isSameDay(day, min)),
                [classes.focusedFirst]: utils.isSameDay(day, min) && !utils.isSameDay(day, max),
                [classes.focusedLast]: utils.isSameDay(day, max) && !utils.isSameDay(day, min),
                [classes.beginCap]: utils.isSameDay(day, min),
                [classes.endCap]: utils.isSameDay(day, max)
            })
        });
    }

    const formatDate = (date: Date) => utils.format(date, format || utils.dateFormat);

    const datePickerLabelFunc = (date: Date | null, invalidLabel: string) => {
        if (!isOpen) {
            if (begin && end) {
                if (labelFunc) {
                    return labelFunc([begin, end], invalidLabel);
                }
                else {
                    return `${formatDate(begin)} - ${formatDate(end)}`;
                }
            }

            return emptyLabel || '';
        }
        else if (prevBegin && prevEnd) {
            if (labelFunc) {
                return labelFunc([prevBegin, prevEnd], invalidLabel);
            }
            else {
                return `${formatDate(prevBegin)} - ${formatDate(prevEnd)}`;
            }
        }

        return emptyLabel || '';
    };

    return (
        <KeyboardDatePicker
            {...otherProps}
            value={begin}
            renderDay={renderDay}
            open={isOpen}
            onOpen={() => {
                setAccepted(false);
                setPrevBegin(begin);
                setPrevEnd(end);
                onOpen ? onOpen() : setOpen(true);
            }}
            onAccept={() => {
                if (!begin || !end) {
                    if (hover && utils.isBeforeDay(begin, hover)) {
                        setEnd(hover);
                    } else {
                        setEnd(begin);
                        setBegin(hover);
                    }
                }
                setPrevBegin(null);
                setPrevEnd(null);
                // if (!autoOk) {
                setAccepted(true);
                // }
            }}
            onClose={() => {
                onClose ? onClose() : setOpen(false);
            }}
            onChange={() => {}}
            labelFunc={datePickerLabelFunc}
            DialogProps={{ className: classes.dateRangePickerDialog }}
        />
    );
};
