import { applyMiddleware, createStore, compose, Middleware } from 'redux';
import { persistStore, persistReducer, PersistConfig } from 'redux-persist';
import storage from 'redux-persist/lib/storage'

import reducers, { IMainState } from './reducers';
import { Environment } from 'configs';

let persistConfig: PersistConfig<IMainState> = {
    key: 'root',
    storage
};

const composeEnhancers =
    typeof (window as any) === 'object' &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}) : compose;

const configureStore = () => {
    const middlewares = [] as Array<Middleware>;

    if (Environment.isDevelopment()) {
        //middlewares.push(logger);
    }

    const enhancers = [applyMiddleware(...middlewares)];
    const persistedReducer = persistReducer(persistConfig, reducers)

    return { ...createStore(persistedReducer, composeEnhancers(...enhancers))};
};

const createPersistedStore = () => {
    const store = configureStore();

    const persistor = persistStore(store);

    return {
        store,
        persistor
    };
}

const storeWithPersistor = createPersistedStore();

export const store = storeWithPersistor.store;
export const persistor = storeWithPersistor.persistor;
