import { useSelector, useDispatch } from 'react-redux';
import jwtDecode from 'jwt-decode';
import { IncomingOptions, CachePolicies } from 'use-http';

import { useHttpOptionsInterface } from './types';
import { getAccessTokenSelector, getRefreshTokenSelector, authenticationReducerActionCreators } from 'shared/authentication/reducers';

import { useLamaTenant } from 'shared/authentication';
import { Environment } from 'configs';
import { useApolloClient } from '@apollo/client';
import { RefreshTokenRequestInput, TokenResponseViewModel, JwtDecryptedToken } from 'shared/authentication/models';
import { REFRESH_TOKEN } from 'shared/authentication/mutations';
import { Logger } from 'shared/utilities';

export const useHttpOptions: useHttpOptionsInterface = () => {
    const apolloClient = useApolloClient();
    const dispatch = useDispatch();
    const { currentActiveTenantId } = useLamaTenant();
    const accessToken = useSelector(getAccessTokenSelector);
    const refreshToken = useSelector(getRefreshTokenSelector) || '';

    const getNewRefreshToken = async () => {
        const refreshTokenRequestInput: RefreshTokenRequestInput = {
            refreshToken,
            clientId: Environment.clientId
        };

        try {
            const mutateResult = await apolloClient.mutate<
                { refreshToken: TokenResponseViewModel },
                { refreshTokenRequestInput: RefreshTokenRequestInput }
            >({
                mutation: REFRESH_TOKEN,
                variables: {
                    refreshTokenRequestInput
                }
            });

            if (mutateResult.data) {
                const decodedToken = jwtDecode<JwtDecryptedToken>(mutateResult.data.refreshToken.accessToken);

                dispatch(authenticationReducerActionCreators.loggedIn(mutateResult.data.refreshToken!, decodedToken));

                return mutateResult.data.refreshToken.accessToken;
            } else {
                Logger.warn('Issue with refreshing token...');
            }
        } catch (e) {
            dispatch(authenticationReducerActionCreators.logout());

            return null;
        }
    }

    const httpOptions: IncomingOptions = {
        cachePolicy: CachePolicies.NO_CACHE,
        interceptors: {
            request: async ({ options: requestOptions }) => {
                if (accessToken) {
                    (requestOptions.headers as Record<string, string>).Authorization = `Bearer ${accessToken}`;
                }

                if (currentActiveTenantId) {
                    (requestOptions.headers as Record<string, string>)['Tenant-Id'] =  currentActiveTenantId;
                }

                if (requestOptions.headers && requestOptions.body instanceof FormData) {
                    delete (requestOptions.headers as Record<string, string>)['Content-Type'];
                }

                return requestOptions;
            }
        },
        retries: 1,
        retryOn: async ({ response }) => {
            if (response?.status === 401 && refreshToken) {
                const accessToken = await getNewRefreshToken();

                if (accessToken) {
                    return true;
                }
            }

            return false;
        }
    };

    return {
        httpOptions
    };
}
