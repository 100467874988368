import React, { FC, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Redirect, useHistory } from 'react-router-dom';
import { FormGroup } from 'reactstrap';
import { IFormlyFieldConfig } from '@rc-formly/core';
import Button from '@material-ui/core/Button';

import { FORGOTTEN_PASSWORD } from 'features/authentication/mutations';

import { useAuth } from 'shared/authentication';
import { useLamaAlerts } from 'shared/packages/alerts';
import { useModular } from 'shared/packages/react-modular';

import { LamaForm } from 'shared/components';
import { SessionContainer, SessionTitle, RegisterHeaderLink } from 'features/authentication/components';

import { useLamaClient, LamaGraphQlError } from 'shared/graphql';

interface ForgottenPasswordModel {
    email: string;
}

const defaultErrorMessage = 'authentication.forgottenPassword.error';

export const ForgottenPassword: FC = () => {
    const { addSuccessAlert } = useLamaAlerts();
    const { isAuthenticated } = useAuth();
    const lamaClient = useLamaClient();
    const history = useHistory();
    const { homeRoutePath } = useModular();
    const { formatMessage } = useIntl();

    const [serverErrorMessage, setServerErrorMessage] = useState<string>();
    const [showLoader, setShowLoader] = useState(false)

    const fields: IFormlyFieldConfig[] = [
        {
            key: 'email',
            type: 'lm-input',
            wrappers: ['lm-form-group'],
            templateOptions: {
                labelTranslate: 'shared.email',
                required: true,
                type: 'email'
            }
        }
    ];

    const translatedDefaultErrorMesage = formatMessage({ id: defaultErrorMessage });

    const onFormSubmit = async (values: ForgottenPasswordModel) => {
        setServerErrorMessage('');
        setShowLoader(true);

        try {
            const mutateResult = await lamaClient.mutate<
                boolean,
                { forgottenPasswordInput: ForgottenPasswordModel }
            >({
                gqlMutation: FORGOTTEN_PASSWORD,
                variables: {
                    forgottenPasswordInput: values
                }
            });

            if (mutateResult.data) {
                addSuccessAlert('authentication.forgottenPassword.success');

                history.push('login');
            }
        }
        catch (e) {
            let error = (e as LamaGraphQlError).messageTranslate || defaultErrorMessage;

            setShowLoader(false);
            setServerErrorMessage(error);
        }
    };

    if (isAuthenticated()) {
        return <Redirect to={homeRoutePath} />;
    }

    return (
        <SessionContainer showProgress={showLoader} headerRight={RegisterHeaderLink}>
            <SessionTitle title="authentication.forgottenPassword.subHeader" />
            {serverErrorMessage && (
                <p className="text-danger">
                    <FormattedMessage id={serverErrorMessage} defaultMessage={translatedDefaultErrorMesage} />
                </p>
            )}
            <LamaForm fields={fields} onSubmit={onFormSubmit}>
                <FormGroup className="mb-15">
                    <Button
                        color="primary"
                        disabled={showLoader}
                        className="btn-block text-white w-100"
                        variant="contained"
                        type={'submit'}
                        size="large"
                    >
                        <FormattedMessage id="authentication.forgottenPassword.resetPassword" />
                    </Button>
                </FormGroup>
            </LamaForm>
        </SessionContainer>
    );
};
