import React, { FC, useRef } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { LamaTabs, LamaTab, LamaTabsRef } from 'shared/components/tabs';

import { Media } from 'features/media/models';

import { MediaFileUpload } from './MediaFileUpload';
import { MediaFileSelector } from './MediaFileSelector';

interface MediaFileChooserProps {
    alreadySelectedMedia?: Media[];
    canDeleteMedia?: boolean;
    initialTab?: 'upload' | 'choose';
}

const useStyles = makeStyles((theme) =>
    createStyles({
        tabRoot: {
            display: 'flex',
            flexDirection: 'column'
        },
        tabContainer: {
            flexGrow: 1
        }
    }));

export const MediaFileChooser: FC<MediaFileChooserProps> = props => {
    const { canDeleteMedia, initialTab = 'choose' } = props;

    const lamaTabsRef = useRef<LamaTabsRef>(null);
    const { tabContainer, tabRoot } = useStyles();

    const onMediaUploadCompleted = () => {
        lamaTabsRef.current?.moveToTabName('choose');
    };

    const tabs: LamaTab[] = [
        {
            component: <MediaFileUpload onMediaUploadCompleted={onMediaUploadCompleted} />,
            name: 'upload',
            labelTranslate: 'media.chooser.upload'
        },
        {
            component: <MediaFileSelector canDeleteMedia={canDeleteMedia} />,
            name: 'choose',
            labelTranslate: 'shared.choose'
        }
    ];

    return <LamaTabs classes={{ container: tabContainer, root: tabRoot }} ref={lamaTabsRef} initial={initialTab} tabs={tabs} useQueryStrings={false} />;
};
