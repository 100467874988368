import React, { FC, Ref } from 'react';
import { FormattedMessage } from 'react-intl';

import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import { LamaGridColumn, LamaGridSort } from 'shared/components/grid/types';
import { SortOperationKind } from 'shared/graphql';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

interface LamaGridHeaderProps {
    columns: LamaGridColumn[];
    currentSort: LamaGridSort;
    headRef?: Ref<any>;
    onSortChange?: (gridSort?: LamaGridSort) => void;
}

interface LamaGridThProps {
    column: LamaGridColumn;
    currentSort: LamaGridSort;
    onSortChange?: (gridSort?: LamaGridSort) => void;
}

const getCellSort = (sortOperationKind: SortOperationKind) => {
    return sortOperationKind === SortOperationKind.asc ? 'asc' : 'desc';
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%'
        },
        paper: {
            width: '100%',
            marginBottom: theme.spacing(2)
        },
        table: {
            minWidth: 750
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1
        }
    })
);

const LamaGridTh: FC<LamaGridThProps> = (props) => {
    const { column, currentSort, onSortChange } = props;

    const classes = useStyles();

    if (column.sortable) {
        const onSortClick = () => {
            if (onSortChange) {
                let newSort: LamaGridSort | undefined = {
                    property: column.prop!,
                    sortOperation: SortOperationKind.desc
                };

                if (currentSort.property === column.prop) {
                    if (currentSort.sortOperation === SortOperationKind.desc) {
                        newSort.sortOperation = SortOperationKind.asc;
                    }
                    else {
                        newSort = undefined;
                    }
                }

                onSortChange(newSort);
            }
        };

        return (
            <TableCell key={column.id} sortDirection={currentSort.property === column.prop ? getCellSort(currentSort.sortOperation) : false}>
                <TableSortLabel
                    active={currentSort.property === column.prop}
                    direction={currentSort.property === column.prop ? getCellSort(currentSort.sortOperation) : 'desc'}
                    onClick={onSortClick}
                >
                    {column.title}
                    {column.titleTranslate && <FormattedMessage id={column.titleTranslate} />}
                    {currentSort.property === column.prop ? (
                        <span className={classes.visuallyHidden}>
                            {currentSort.sortOperation === SortOperationKind.desc ? 'sorted descending' : 'sorted ascending'}
                        </span>
                    ) : null}
                </TableSortLabel>
            </TableCell>
        );
    }

    return (
        <TableCell key={column.id}>
            {column.title}
            {column.titleTranslate && <FormattedMessage id={column.titleTranslate} />}
        </TableCell>
    );
}

export const LamaGridHead: FC<LamaGridHeaderProps> = props => {
    const { columns, headRef, ...otherProps } = props;

    return (
        <TableHead ref={headRef}>
            <TableRow>
                {columns.map(column => <LamaGridTh key={column.id} {...otherProps} column={column} /> )}
            </TableRow>
        </TableHead>
    );
};
