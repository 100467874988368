import jwtDecode from 'jwt-decode';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApolloError, useApolloClient } from '@apollo/client';

import { JwtDecryptedToken, PasswordRequestTokenInput, TokenResponseViewModel } from 'shared/authentication/models';
import { LOGIN } from 'shared/authentication/mutations';
import { useAuthInterface } from './types';

import { authenticationReducerActionCreators, isAuthenticatedSelector, getAccountSelector } from 'shared/authentication/reducers';

import { Environment } from 'configs';
import { UtilityHelper, Logger } from 'shared/utilities';
import { crudViewTypeGroups } from 'shared/types';
import { getFirstGrapQLErrorMessage } from 'shared/graphql';

export const defaultAuthenticationErrorMessage = 'authentication.login.error';

export const useAuth: useAuthInterface = () => {
    const apolloClient = useApolloClient();
    const dispatch = useDispatch();

    const account = useSelector(getAccountSelector);
    const isNowAuthenticated = useSelector(isAuthenticatedSelector);

    const [authenticationError, setAuthenticationError] = useState('');
    const [isAuthenticating, setIsAuthenticating] = useState(false);

    const isAuthenticated = () => {
        return isNowAuthenticated;
    };

    const isAuthorized = (...claimsToCheck: string[]) => {
        if (!isAuthenticated()) {
            return false;
        }

        if (UtilityHelper.isEmpty(claimsToCheck)) {
            return true;
        }

        if (UtilityHelper.isNotEmpty(account?.claims)) {
            const crudViewTypeGroupKeys = Array.from(crudViewTypeGroups.keys());
            const groupedClaims = claimsToCheck.filter(q => crudViewTypeGroupKeys.some(crudViewType => q.endsWith(crudViewType)));

            if (groupedClaims.length) {
                groupedClaims.forEach((claim) => {
                    const crudViewType = crudViewTypeGroupKeys.find(q => claim.endsWith(q))!;
                    const requiredClaimsForGroupedClaim = crudViewTypeGroups.get(crudViewType)!;

                    requiredClaimsForGroupedClaim.forEach((requiredClam) => {
                        claimsToCheck.push(claim.substr(0, claim.lastIndexOf(crudViewType)) + requiredClam);
                    });

                    claimsToCheck = claimsToCheck.filter(q => q !== claim);
                })
            }

            return claimsToCheck.every(q => account?.claims.includes(q));
        }

        return false;
    };

    const login = async (userName: string, password: string) => {
        if (!isAuthenticating) {
            setIsAuthenticating(true);
            setAuthenticationError('');

            try {
                const passwordRequestTokenInput: PasswordRequestTokenInput = {
                    clientId: Environment.clientId,
                    email: userName,
                    password
                };
                const mutateResult = await apolloClient.mutate<
                    { login: TokenResponseViewModel },
                    { passwordRequestTokenInput: PasswordRequestTokenInput }
                >({
                    mutation: LOGIN,
                    variables: {
                        passwordRequestTokenInput
                    }
                });

                if (UtilityHelper.isNotEmpty(mutateResult.errors)) {
                    let authenticationError = getFirstGrapQLErrorMessage(mutateResult.errors!, defaultAuthenticationErrorMessage);

                    setAuthenticationError(authenticationError);
                } else if (mutateResult.data) {
                    const decodedToken = jwtDecode<JwtDecryptedToken>(mutateResult.data.login.accessToken);

                    dispatch(authenticationReducerActionCreators.loggedIn(mutateResult.data.login, decodedToken));
                }
            } catch (e) {
                const authenticationError = getFirstGrapQLErrorMessage((e as ApolloError).graphQLErrors, defaultAuthenticationErrorMessage);

                setIsAuthenticating(false);
                setAuthenticationError(authenticationError);

                Logger.error(e);
            }
        }
    };

    const logout = () => {
        apolloClient.clearStore();

        dispatch(authenticationReducerActionCreators.logout());
    };

    return {
        account,
        authenticationError,
        isAuthenticating,
        isAuthenticated,
        isAuthorized,
        login,
        logout
    };
};
