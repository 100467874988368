import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { createStyles, makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Box from '@material-ui/core/Box';

import { createOrEditViewTypes } from 'features/base/models';
import { LamaCrudViewType } from 'shared/types';

import { useModularRoute } from 'shared/packages/react-modular';

import { CrudHelper } from 'features/base/utilities';

const useStyles = makeStyles(() =>
    createStyles({
        back: {
            cursor: 'pointer'
        }
    }));

interface ContentPageProps {
    titleTranslate?: string
}

export const ContentPage: FC<ContentPageProps> = props => {
    const { currentRoute } = useModularRoute();
    const { location, push } = useHistory();
    const { formatMessage } = useIntl();

    const styles = useStyles();

    let title = currentRoute?.title;

    if (currentRoute?.titleTranslate) {
        title = formatMessage({ id: currentRoute.titleTranslate });
    }

    const onBackClick = () => {
        if (CrudHelper.hasUrlCrudType(location.pathname, LamaCrudViewType.list)) {
            push('/');
        }
        else if (createOrEditViewTypes.some(q => CrudHelper.hasUrlCrudType(location.pathname, q))) {
            const newUrl = CrudHelper.convertUrlToListCrudType(location.pathname);

            push(newUrl);
        }
    };

    return (
        <Box display="flex" className="content-page-wrapper" flexDirection="column" flexGrow="1">
            {title && (
                <Helmet>
                    <title>{title}</title>
                </Helmet>
            )}
            <div className="page-title d-flex justify-content-between align-items-center">
                <div className="page-title-wrap">
                    <ArrowBackIcon className={styles.back} onClick={onBackClick} />
                    <h2 className="ml-3">
                        <span>{title}</span>
                    </h2>
                </div>
            </div>
            <Box flexGrow="1" className="content-wrapper position-relative">
                {props.children}
            </Box>
        </Box>
    );
};
