import { UtilityHelper } from "./UtilityHelper";

export class FormDataHelper {
    private static convertObjectToFormDataRecursive(obj: any, currentKey: string, formData: FormData) {
        if (UtilityHelper.isArray(obj)) {
            if ((obj as any[]).some(q => UtilityHelper.isFile(q))) {
                (obj as File[]).forEach((file, index) => {
                    formData.append(`${currentKey}[${index}]`, file, file.name);
                });
            }
            else {
                (obj as any[]).forEach((item, index) => {
                    FormDataHelper.convertObjectToFormDataRecursive(item, `${currentKey}[${index}]`, formData);
                })
            }
        }
        else {
            for (const key in obj) {
                const propertyValue = obj[key];
                const propertyKey = `${currentKey}${ currentKey ? '.' : ''}${key}`;

                if (UtilityHelper.isFile(propertyValue)) {
                    formData.append(propertyKey, propertyValue as File, (propertyValue as File).name);
                }
                else if (UtilityHelper.isObject(propertyValue)) {
                    FormDataHelper.convertObjectToFormDataRecursive(propertyValue, propertyKey, formData);
                }
                else {
                    formData.append(propertyKey, propertyValue);
                }
            }
        }
    }

    static convertObjectToFormData(obj: any) {
        const formData = new FormData();

        if (obj) {
            FormDataHelper.convertObjectToFormDataRecursive(obj, '', formData);
        }

        return formData;
    }
}
