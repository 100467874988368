import { ModularEntry } from 'shared/packages/react-modular';
import { RouteDefaultData } from 'features/base/models';
import { LamaCrudViewType } from 'shared/types';

import { ProductList,  ProductCreateOrUpdate } from './routes';
import { ProductResourceNames } from './models';

export const productModule: ModularEntry<RouteDefaultData> = {
    name: 'products',
    routes: [
        {
            header: 'menu.sidebarGroupTitle',
            name: 'productsMain',
            icon: 'zmdi zmdi-local-offer',
            titleTranslate: 'product.sidebarTitle',
            routeProps: {
                path: '/products'
            },
            routes: [
                {
                    header: 'menu.sidebarGroupTitle',
                    name: 'productsList',
                    titleTranslate: 'product.listSidebarTitle',
                    data: {
                        crudViewType: LamaCrudViewType.list,
                        primaryModelName: ProductResourceNames.product
                    },
                    routeProps: {
                        component: ProductList,
                        path: '/list'
                    }
                },
                {
                    header: 'menu.sidebarGroupTitle',
                    name: 'productsCreate',
                    titleTranslate: 'product.createSidebarTitle',
                    data: {
                        crudViewType: LamaCrudViewType.create,
                        primaryModelName: ProductResourceNames.product
                    },
                    routeProps: {
                        component: ProductCreateOrUpdate,
                        path: `/${LamaCrudViewType.create}`
                    }
                },
                {
                    header: 'menu.sidebarGroupTitle',
                    name: 'productsUpdate',
                    titleTranslate: 'product.updateSidebarTitle',
                    data: {
                        crudViewType: LamaCrudViewType.update,
                        hideOnSidebar: true,
                        primaryModelName: ProductResourceNames.product
                    },
                    routeProps: {
                        component: ProductCreateOrUpdate,
                        path: `/:id/${LamaCrudViewType.update}`
                    }
                }
            ]
        }
    ]
};
