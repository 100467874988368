import { RcFormlyConfig } from '@rc-formly/core';

import { ModularEntry } from 'shared/packages/react-modular';
import { RouteDefaultData } from 'features/base/models';
import { CategoryResourceNames, CategoryFormlyTypes } from './models';
import { LamaCrudViewType } from 'shared/types';

import { CategoriesSelectorToggleType } from './components';
import { CategoryList } from './routes';

RcFormlyConfig.addConfig({
    types: [
        {
            component: CategoriesSelectorToggleType,
            name: CategoryFormlyTypes.categorySelector
        }
    ]
});

export const categoryModule: ModularEntry<RouteDefaultData> = {
    name: 'categories',
    routes: [
        {
            header: 'menu.sidebarGroupTitle',
            name: 'categoriesMain',
            icon: 'material-icons category',
            titleTranslate: 'category.sidebarTitle',
            routeProps: {
                path: '/categories'
            },
            routes: [
                {
                    header: 'menu.sidebarGroupTitle',
                    name: 'categoriesList',
                    titleTranslate: 'category.listSidebarTitle',
                    data: {
                        crudViewType: LamaCrudViewType.manage,
                        primaryModelName: CategoryResourceNames.category
                    },
                    routeProps: {
                        component: CategoryList,
                        path: '/list'
                    }
                }
            ]
        }
    ]
};
