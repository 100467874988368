import { useContext } from 'react';
import { useModularRouteInterface } from './types';

import { ModularContext } from './ModularProvider';

export const useModularRoute: useModularRouteInterface = () => {
    const {
        setCurrentRoute, currentRoute
    } = useContext(ModularContext);

    return {
        setCurrentRoute,
        currentRoute
    };
};
