import React, { FC } from 'react';

import { LamaListRouteBase } from 'features/base/components';

import { AccountResourceNames } from 'features/accounts/models';
import { LamaGridProps, LamaGridColumnType } from 'shared/components/grid';

export const AccountList: FC = () => {
    const gridOptions: LamaGridProps = {
        columns: [
            {
                titleTranslate: 'account.email',
                prop: 'email'
            },
            {
                titleTranslate: 'account.firstName',
                prop: 'firstName'
            },
            {
                titleTranslate: 'account.lastName',
                prop: 'lastName'
            },
            {
                type: LamaGridColumnType.datetime,
                titleTranslate: 'shared.createdAt',
                prop: 'createdAt'
            }
        ],
        serverOptions: {
            modelName: AccountResourceNames.account
        }
    };

    return <LamaListRouteBase gridProps={gridOptions} />;
};
