// @ts-ignore
import { geocodeByPlaceId } from 'react-google-places-autocomplete';

import { LocationViewModel } from 'features/locations/models';

import { UtilityHelper } from 'shared/utilities';
import { KeyValueObject } from 'shared/types';

interface GeocoderPropertyType {
    type: 'long_name' | 'short_name';
    property: string;
}

export class LocationHelper {
    private static placeMap: KeyValueObject<GeocoderPropertyType> = {
        country: { property: 'country', type: 'long_name' },
        route: { property: 'address', type: 'long_name' },
        street_number: { property: 'streetNumber', type: 'short_name' },
        postal_code: { property: 'postalCode', type: 'short_name' },
        postal_town: { property: 'city', type: 'long_name' },
        locality: { property: 'city', type: 'long_name' },
        administrative_area_level_2: { property: 'city', type: 'short_name' },
        administrative_area_level_3: { property: 'address', type: 'short_name' },
        administrative_area_level_4: { property: 'address', type: 'short_name' },
    };

    static convertFromLatLngToLocation(latLng: google.maps.LatLng): Promise<LocationViewModel> {
        const geocoder = new google.maps.Geocoder();

        return new Promise((resolve, reject) => {
            geocoder.geocode({
                location: latLng
            }, (results, status) => {
                if (status !== google.maps.GeocoderStatus.OK) {
                    reject(new Error(status));

                    return;
                }

                const place = LocationHelper.getLocationFromGeocoderResult(results[0]);

                resolve(place!);
            });
        });
    }

    static convertFromLocationToGoogleLatLng(place: LocationViewModel) {
        return new google.maps.LatLng(place.latitude, place.longitude);
    }

    static async getLocationFromAutocompletePrediction(autocompletePrediction: google.maps.places.AutocompletePrediction) {
        const results: google.maps.GeocoderResult[] = await geocodeByPlaceId(autocompletePrediction.place_id);

        if (UtilityHelper.isEmpty(results)) {
            return null;
        }

        return LocationHelper.getLocationFromGeocoderResult(results[0]);
    }

    static getLocationFromGeocoderResult(place: google.maps.GeocoderResult) {
        if (place && place.geometry) {
            let location: LocationViewModel = {
                fullAddress: place.formatted_address,
                longitude: place.geometry.location.lng(),
                latitude: place.geometry.location.lat()
            };

            return place.address_components.reduce((acc, addressComponent) => {
                const addressType = addressComponent.types[0];
                const { property, type } = LocationHelper.placeMap[addressType] || {};

                if (property && !acc[property]) {
                    acc[property] = addressComponent[type];
                }

                return acc;
            }, location as KeyValueObject) as LocationViewModel;
        }

        return null;
    }
}
