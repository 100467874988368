import { RcFormlyConfig } from '@rc-formly/core';

import { MediaFormlyTypes, MediaResourceNames } from './models';
import { LamaCrudViewType } from 'shared/types';

import { GridCellType, LamaGridColumnType } from 'shared/components/grid';
import { ModularEntry } from 'shared/packages/react-modular';

import { MediaEntityChooserType, MediaEntitiesInlineChooserType, PictureCellType } from './components';
import { RouteDefaultData } from 'features/base/models';
import { MediaCrudRoute } from './routes';

RcFormlyConfig.addConfig({
    types: [
        {
            component: MediaEntityChooserType,
            name: MediaFormlyTypes.mediaSingleChooser
        },
        {
            component: MediaEntitiesInlineChooserType,
            name: MediaFormlyTypes.mediaEntitiesChooser
        }
    ]
});

export const mediaGridCellTypes: GridCellType[] = [
    { cellType: LamaGridColumnType.picture, component: PictureCellType }
];


export const mediaModule: ModularEntry<RouteDefaultData> = {
    name: 'media',
    routes: [],/*,
    routes: [
        {
            header: 'menu.sidebarGroupTitle',
            name: 'mediaCreateUpdate',
            titleTranslate: 'media.sidebarTitle',
            icon: 'zmdi zmdi-image',
            data: {
                crudViewType: LamaCrudViewType.createOrEdit,
                primaryModelName: MediaResourceNames.media
            },
            routeProps: {
                component: MediaCrudRoute,
                path: '/gallery'
            }
        }
    ]*/
};
