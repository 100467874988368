import { createPipe } from 'imask';

export const moneyPipeMaskOptions = {
    mask: Number,
    scale: 2,
    thousandsSeparator: '.',
    radix: ',',
    normalizeZeros: true,
    padFractionalZeros: true
} as IMask.MaskedNumber;

export const moneyPipe = createPipe({
    mask: [
        { mask: '' },
        {
            mask: 'num €',
            lazy: false,
            blocks: {
                num: moneyPipeMaskOptions
            }
        }
    ]
} as IMask.MaskedDynamic);
