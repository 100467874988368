import { ModularEntry } from 'shared/packages/react-modular';

import { Home } from './routes';

export { PublicHome, TermsOfUse } from './routes';

export const homeModule: ModularEntry = {
    name: 'home',
    routes: [
        {
            header: 'menu.sidebarGroupTitle',
            name: 'home',
            icon: 'zmdi zmdi-home',
            titleTranslate: 'home.sidebarTitle',
            routeProps: {
                component: Home,
                path: '/'
            }
        }
    ]
};
