import React, { FC, useState, useMemo, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Box from '@material-ui/core/Box';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import Paper from '@material-ui/core/Paper';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Typography from '@material-ui/core/Typography';

import { ImportRowErrorViewModel, ImportMapperResultPropertyViewModel, ImportMapperLabelPropertyViewModel } from 'features/imports/models';

import { useLamaAlerts } from 'shared/packages/alerts';
import { useImporter } from 'features/imports/hooks';
import { useLamaStepper } from 'shared/components/stepper';

import { ImporterErrors } from './ImporterErrors';
import { LamaLoader } from 'shared/components';
import { SelectOption, NumberKeyValueObject } from 'shared/types';
import { ImporterBottomButtons } from './ImporterBottomButtons';
import { LamaAutocomplete } from 'shared/components/forms';

export interface SelectRequiredOption extends SelectOption {
    required?: boolean;
}

export const ImporterStepTwo: FC = () => {
    const { formatMessage } = useIntl();
    const [importerErrors, setImporterErrors] = useState<ImportRowErrorViewModel[]>([]);
    const [newlyMappedFields, setNewlyMappedFields] = useState<NumberKeyValueObject<SelectOption>>({});

    const { loading, lastImport, lastImportCheckResult, importerCheck } = useImporter();
    const { addWarningAlert } = useLamaAlerts();
    const { activeStep, setStep, stepCompleted } = useLamaStepper();

    useEffect(() => {
        if (lastImport?.mappedFields?.length) {
            setNewlyMappedFields(lastImport.mappedFields.reduce((acc, mappedField, index) => {
                if (mappedField.sourcePropertyName) {
                    acc[index] =  {
                        label: mappedField.sourcePropertyName,
                        value: mappedField.sourcePropertyName
                    };
                }

                return acc;
            }, {} as NumberKeyValueObject<SelectOption>))
        }
    }, []);

    const newlyMappedFieldValues = Object.values(newlyMappedFields).map((q) => q.value);

    const sourcePropertyNames =
        lastImportCheckResult?.notMappedSourcePropertyNames
            ?.sort((a, b) => a.localeCompare(b))
            .map((q) => {
                let option: SelectOption = {
                    label: q,
                    value: q
                };

                return option;
            }) || [];

    const combinedFields = useMemo(() => {
        const fields =
            lastImport?.mappedFields?.reduce((acc, val) => {
                if (lastImportCheckResult?.bestImportSchema?.items?.length) {
                    const foundItem = lastImportCheckResult.bestImportSchema.items.find((q) => q.sourcePropertyName === val.sourcePropertyName);

                    if (foundItem) {
                        acc.push(foundItem);
                    }
                }

                return acc;
            }, [] as ImportMapperResultPropertyViewModel[]) || [];

        if (lastImportCheckResult?.notMappedFields?.length) {
            fields.push(...lastImportCheckResult.notMappedFields);
        }

        return fields
            .map(
                (q) =>
                    ({
                        ...q,
                        label: formatMessage({ id: `import.fields.${q.destinationPropertyName}` })
                    } as ImportMapperLabelPropertyViewModel)
            )
            .sort((a, b) => (a.required === b.required ? 0 : a.required ? -1 : 1) || a.label.localeCompare(b.label));
    }, [lastImport, lastImportCheckResult]);

    const isOptionDisabled = (option: SelectOption) => newlyMappedFieldValues.includes(option.value);

    const onAutoCompleteChange = (mapperPropertyIndex: number) => (e: React.ChangeEvent<SelectOption>, selectedOption: SelectOption) => {
        let newlyMappedFieldsCopy = { ...newlyMappedFields };

        if (selectedOption) {
            newlyMappedFieldsCopy[mapperPropertyIndex] = selectedOption;
        } else {
            delete newlyMappedFieldsCopy[mapperPropertyIndex];
        }

        setNewlyMappedFields(newlyMappedFieldsCopy);
    };

    const onSubmit = async () => {
        const newlyMappedFieldIndexes = Object.keys(newlyMappedFields).map(Number);
        const allStillNotMappedFields = combinedFields.filter(
            (q) => q.required && !newlyMappedFieldIndexes.some((i) => combinedFields[i].destinationPropertyName === q.destinationPropertyName)
        );

        if (allStillNotMappedFields.length) {
            addWarningAlert('import.stepTwo.stillRequiredFieldsNotMappedWarning', {
                requiredFields: allStillNotMappedFields.map((q) => q.destinationPropertyName).join(', ')
            });

            return;
        }

        if (!lastImport) {
            return;
        }

        setImporterErrors([]);

        const newImportCheckResult = await importerCheck({
            ...lastImport,
            mappedFields: newlyMappedFieldIndexes.map((index) => {
                const { label, required, ...otherProps  } = combinedFields[index];

                return {
                    ...otherProps,
                    sourcePropertyName: newlyMappedFields[index].value
                 } as ImportMapperResultPropertyViewModel;
            })
        });

        if (newImportCheckResult.errors?.length) {
            setImporterErrors(newImportCheckResult.errors);
        } else {
            if (newImportCheckResult.numberOfCategoryDifferences > 0) {
                stepCompleted();
            } else {
                setStep(activeStep + 2, {
                    [activeStep + 1]: true
                });
            }
        }
    };

    return (
        <Box position="relative">
            <LamaLoader showLoader={loading} />
            <Typography>
                <FormattedMessage id="import.stepTwo.summary" />
            </Typography>
            {combinedFields.length > 6 && (
                <>
                    <Box mt={2}>
                        <ImporterErrors errors={importerErrors} />
                    </Box>
                    <Box mt={2}>
                        <ImporterBottomButtons onSubmit={onSubmit} />
                    </Box>
                </>
            )}
            <Box mt={2}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <FormattedMessage id="import.stepTwo.ourProperty" />
                                </TableCell>
                                <TableCell>
                                    <FormattedMessage id="import.stepTwo.propertyFromImport" />
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {combinedFields.map((mapperProperty, i) => (
                                <TableRow key={mapperProperty.sourcePropertyName}>
                                    <TableCell>
                                        {mapperProperty.label}
                                        {mapperProperty.required && <span className="text-danger"> *</span>}
                                    </TableCell>
                                    <TableCell>
                                        <LamaAutocomplete
                                            isOptionDisabled={isOptionDisabled}
                                            options={sourcePropertyNames}
                                            onChange={onAutoCompleteChange(i)}
                                            placeholderTranslate="import.chooseFieldFromYourImport"
                                            value={newlyMappedFields[i]}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Box mt={2}>
                    <ImporterErrors errors={importerErrors} />
                </Box>
                <Box mt={2}>
                    <ImporterBottomButtons onSubmit={onSubmit} />
                </Box>
            </Box>
        </Box>
    );
};
