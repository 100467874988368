import { useContext, useEffect } from 'react';
import { useQuery } from '@apollo/client';

import { useLamaTenant } from 'shared/authentication';
import { useLamaAlerts } from 'shared/packages/alerts';
import { useRapidModel } from 'shared/packages/rapid-model';
import { useUpdateEffect } from 'shared/packages/use-update-effect';

import { useLamaQueryInterface } from './types';

import { GraphQlQueryType } from 'shared/graphql';
import { GetQueryDataOptions, GraphQlQueryVariables } from 'shared/graphql/types';
import { LamaGraphQlQueryHelper } from 'shared/graphql/utilities';
import { LamaGraphQlContext } from 'shared/graphql/providers';

import { UtilityHelper } from 'shared/utilities';

export const useLamaQuery: useLamaQueryInterface = (hookOptions) => {
    const { clone, skip, apolloOptions = {}, map, queryPrefixDotNotation: queryPrefixDotNotationFromProps, ...otherOptions } = hookOptions;
    let { gqlQuery, modelName, queryType = GraphQlQueryType.list, variables  } = hookOptions;
    const hasExistingGqlQuery = UtilityHelper.isNotEmpty(gqlQuery);
    const isListQuery = queryType === GraphQlQueryType.list || queryType === GraphQlQueryType.paginated || queryType === GraphQlQueryType.customList;

    let { queryPrefixDotNotation } = useContext(LamaGraphQlContext);
    const { currentActiveTenantId } = useLamaTenant();
    const { addWarningAlert } = useLamaAlerts();
    const { getModelNameTranslation} = useRapidModel({
        defaultModelName: modelName
    });

    if (UtilityHelper.isDefined(queryPrefixDotNotationFromProps)) {
        queryPrefixDotNotation = queryPrefixDotNotationFromProps;
    }

    if (!hasExistingGqlQuery) {
        const { gqlQuery: mappedGqlQuery, variables: rewrittenVariables } = LamaGraphQlQueryHelper.createQueryFromDotNotation({
            queryPrefixDotNotation,
            ...otherOptions
        });

        gqlQuery = mappedGqlQuery;

        if (isListQuery) {
            variables = rewrittenVariables;
        }
    }

    let { data, loading, error, refetch: apolloRefetch } = useQuery<any>(gqlQuery!, {
        skip,
        variables,
        //fetchPolicy: skip ? 'cache-only' : undefined,
        ...apolloOptions
    });

    const refetch = (newVariables?: GraphQlQueryVariables) => {
        if (!skip) {
            apolloRefetch(newVariables);
        }
    };

    useUpdateEffect(() => {
        if (currentActiveTenantId) {
            refetch();
        }
    }, [currentActiveTenantId])

    useEffect(() => {
        if (error) {
            addWarningAlert('shared.alerts.query.error', {
                entityName: getModelNameTranslation()
            });
        }
    }, [error]);

    if (data) {
        const { dotNotationProperties, modelName, flattenResult, queryObject: mappedDotNotationProperties  } = otherOptions;
        let queryDataOptions: GetQueryDataOptions;

        if (hasExistingGqlQuery) {
            queryDataOptions = {
                data,
                modelName,
                queryPrefixDotNotation,
                queryType
            }
        }
        else {
            queryDataOptions = {
                data,
                dotNotationProperties: flattenResult ? dotNotationProperties : undefined,
                modelName,
                mappedDotNotationProperties,
                queryPrefixDotNotation,
                queryType
            };
        }

        if (isListQuery) {
            data = LamaGraphQlQueryHelper.getResultFromQueryData(queryDataOptions);
        }
        else {
            data = LamaGraphQlQueryHelper.getResultFromSingleQueryData(queryDataOptions);
        }

        if (clone || map) {
            data = JSON.parse(JSON.stringify(data));

            if (map) {
                data = map(data) as any;
            }
        }


    }

    return {
        data,
        loading,
        error,
        refetch
    };
}
