import { IValidatorMessageOption  } from '@rc-formly/core';

import { baseValidationMessage } from './base';

export const requiredValidatorMessage: IValidatorMessageOption = {
    name: 'required',
    message: (value, field) => baseValidationMessage(value, field, (intl, label) => {
        return intl.formatMessage({
            id: 'validation.required'
        }, {
            label
        });
    })
};
