import { FC } from "react";

import { useMountEffect } from "shared/packages/use-mount-effect";
import { useLamaGridCreator, GridFilterType } from "shared/components/grid";

interface LamaGridIncludeCellsProps {
    gridFilterTypes: GridFilterType[];
}

export const LamaGridIncludeFilters: FC<LamaGridIncludeCellsProps> = (props) => {
    const { gridFilterTypes } = props;
    const { addGridFilterTypes } = useLamaGridCreator();

    useMountEffect(() => {
        addGridFilterTypes(gridFilterTypes);
    });

    return null;
};
