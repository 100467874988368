import React, { FC, useState, useEffect } from 'react';
import IMask from 'imask';

import { LamaInput, LamaInputProps } from './LamaInput';
import { moneyPipeMaskOptions, moneyPipe, UtilityHelper } from 'shared/utilities';

export const LamaMoneyInput: FC<LamaInputProps> = (props) => {
    const { onChange, ...otherProps } = props;
    let { value } = props;

    const [currentValue, setCurrentValue] = useState<string>('');

    useEffect(() => {
        if (UtilityHelper.isUndefinedOrNull(value)) {
            value = '';
        }

        setCurrentValue(moneyPipe(value?.toString()));
    }, [value])

    const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCurrentValue(e.target.value);
    };

    const onInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        let textValue = e.target.value;

        if (textValue && textValue.includes('.') && textValue.includes(',')) {
            textValue = textValue.replace(/\./g,'');
        }

        const newValue = IMask.pipe(textValue, moneyPipeMaskOptions, IMask.PIPE_TYPE.MASKED, IMask.PIPE_TYPE.TYPED);

        if (value === newValue) {
            setCurrentValue(moneyPipe(textValue));
        }
        else {
            if (onChange) {
                onChange({
                    target: {
                        value: newValue
                    }
                } as any);
            }
        }
    };

    return <LamaInput {...otherProps} onBlur={onInputBlur} onChange={onInputChange} type="text" value={currentValue} />;
};
