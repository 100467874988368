import React from 'react';
import { RcFormlyField } from "@rc-formly/core";

import { Media, MediaEntityChooserTypeTemplateOptions } from 'features/media/models';

import { MediaEntityChooser } from './MediaEntityChooser';

export class MediaEntityChooserType extends RcFormlyField<MediaEntityChooserTypeTemplateOptions> {
    mediaChanged = (media: Media | null) => {
        this.handleChange(media ? media.id : null);
    }

    render() {
        const { gridSize } = this.to || {};
        const value = this.getFieldValue();

        return (
            <MediaEntityChooser currentMediaId={value} gridSize={gridSize} onChange={this.mediaChanged} />
        );
    }
}
