import React, { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import Paper from '@material-ui/core/Paper';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';

import { ImportRowErrorViewModel, ImportMapperPropertyViewModel } from 'features/imports/models';
import { KeyValueObject } from 'shared/types';

import { useImporter } from 'features/imports/hooks';
import { useLamaAlerts } from 'shared/packages/alerts';
import { useLamaStepper } from 'shared/components/stepper';

import { LamaLoader } from 'shared/components';
import { ImporterBottomButtons } from './ImporterBottomButtons';
import { CategoriesSelectorToggle } from 'features/categories/components';
import { ImporterErrors } from './ImporterErrors';

export const ImporterStepThree: FC = () => {
    const [importerErrors, setImporterErrors] = useState<ImportRowErrorViewModel[]>([]);
    const [newlyMappedCategories, setNewlyMappedCategories] = useState<KeyValueObject>({});

    const { loading, lastImport, lastImportCheckResult: { notMappedCategories = [] } = {}, importerCheck } = useImporter();
    const { addWarningAlert } = useLamaAlerts();
    const { stepCompleted } = useLamaStepper();

    const onSelectedCategoryId = (sourceCategoryName: string) => (categoryId: string) => {
        setNewlyMappedCategories({
            ...newlyMappedCategories,
            [sourceCategoryName]: categoryId
        });
    };

    const onSubmit = async () => {
        const allStillNotMappedCategories = notMappedCategories.filter((q) => !newlyMappedCategories[q.sourcePropertyName]) || [];

        if (allStillNotMappedCategories.length) {
            addWarningAlert('import.stepThree.stillCategoriesNotMappedWarning', {
                requiredCategories: allStillNotMappedCategories.map((q) => q.sourcePropertyName).join(', ')
            });

            return;
        }

        if (!lastImport) {
            return;
        }

        setImporterErrors([]);

        const newImportCheckResult = await importerCheck({
            ...lastImport,
            mappedCategories: notMappedCategories.map(
                (notMappedCategory) =>
                    ({
                        ...notMappedCategory,
                        destinationPropertyName: newlyMappedCategories[notMappedCategory.sourcePropertyName]
                    } as ImportMapperPropertyViewModel)
            )
        });

        if (newImportCheckResult.errors?.length) {
            setImporterErrors(newImportCheckResult.errors);
        } else {
            stepCompleted();
        }
    };

    return (
        <Box position="relative">
            <LamaLoader showLoader={loading} />
            <Typography>
                <FormattedMessage id="import.stepThree.summary" />
            </Typography>
            <Box mt={2}>
                <Typography>
                    <FormattedMessage
                        id="import.stepThree.requestCategory"
                        values={{
                            mailto: (msg: string) => <a href="mailto:info@prodajko.si?subject=Prošnja za novo kategorijo">{msg}</a>
                        }}
                    />
                </Typography>
            </Box>
            {notMappedCategories.length > 6 && (
                <>
                 <Box mt={2}>
                        <ImporterErrors errors={importerErrors} />
                    </Box>
                    <Box mt={2}>
                    <ImporterBottomButtons onSubmit={onSubmit} />
                </Box>
                </>

            )}
            <Box mt={2}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <FormattedMessage id="import.stepTwo.ourProperty" />
                                </TableCell>
                                <TableCell>
                                    <FormattedMessage id="import.stepTwo.propertyFromImport" />
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {notMappedCategories.map((mapperProperty) => (
                                <TableRow key={mapperProperty.sourcePropertyName}>
                                    <TableCell>
                                        {mapperProperty.sourcePropertyName}
                                        <span className="text-danger"> *</span>
                                    </TableCell>
                                    <TableCell>
                                        <CategoriesSelectorToggle
                                            categoryId={newlyMappedCategories[mapperProperty.sourcePropertyName]}
                                            onSelectedCategoryId={onSelectedCategoryId(mapperProperty.sourcePropertyName)}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Box mt={2}>
                    <ImporterBottomButtons onSubmit={onSubmit} />
                </Box>
            </Box>
        </Box>
    );
};
