import { combineReducers, AnyAction } from 'redux';

import { appReducerFunction, IAppState } from 'features/base/reducers';
import { authenticationReducerFunction, IAuthenticationState } from 'shared/authentication/reducers';

const initialState = {};

const rootReducer = (state: any = initialState, action: any = {}) => {
	const appReducer = combineReducers({
        app: appReducerFunction,
        authentication: authenticationReducerFunction
	});

	return appReducer(state, action);
};

export interface IActionWithPayload<T = any> extends AnyAction {
    payload: T;
}

export interface IMainState {
    app: IAppState;
    authentication: IAuthenticationState;
}

export default rootReducer;
