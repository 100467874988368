import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import { LamaGridCellProps, LamaGridTagColumnAdditionalData } from 'shared/components/grid/types';

import { MaterialBadge } from 'shared/components';
import { EmptyCell } from './EmptyCell';
import { DefaultCellType } from './DefaultCellType';

import { FormHelper } from 'shared/components/forms/utilities';

export const EnumColorCellType: FC<LamaGridCellProps<any, LamaGridTagColumnAdditionalData<any>>> = props => {
    const {
        cellValue,
        column: { additionalData }
    } = props;

    const { formatMessage } = useIntl();

    if (!cellValue) {
        return <EmptyCell />;
    }

    if (!additionalData) {
        return <DefaultCellType {...props} />;
    }

    const { enumType, colorForEnums, translationPrefix } = additionalData;

    let newCellValue = FormHelper.converEnumValueToTranslationMessage(cellValue, translationPrefix, enumType);

    if (newCellValue) {
        newCellValue = formatMessage({ id: newCellValue });
    } else {
        newCellValue = cellValue;
    }

    if (colorForEnums?.has(cellValue)) {
        const colorType = colorForEnums.get(cellValue);

        if (colorType) {
            return <MaterialBadge color={colorType}>{newCellValue}</MaterialBadge>;
        }
    }

    return <DefaultCellType {...props} cellValue={newCellValue} />;
};
