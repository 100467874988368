import { createIntl, createIntlCache, IntlShape } from 'react-intl';

const cache = createIntlCache();

class TranslationService {
    private currentIntl: IntlShape | null = null;

    createIntl(locale: string, messages: any) {
        this.currentIntl = createIntl(
            {
                locale,
                messages
            },
            cache
        );

        return this.currentIntl;
    }

    getCurrentIntl() {
        return this.currentIntl;
    }

    hasIntl() {
        return this.currentIntl !== null;
    }
}

export default new TranslationService();
