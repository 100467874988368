import gql from 'graphql-tag';

export const REFRESH_TOKEN = gql`
    mutation refreshToken($refreshTokenRequestInput: RefreshTokenRequestInput!) {
        refreshToken(refreshTokenRequestInput: $refreshTokenRequestInput) {
            accessToken,
            expires,
            expiresIn,
            issued,
            refreshToken
        }
    }`;
